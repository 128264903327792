export type IAppRoutes = {
  [key in IAppRouteKey]: IAppRoute;
};

export interface IAppRoute {
  path: string;
}

export enum IAppRouteKey {
  LOGIN = 'login',
  MY_PROFILE = 'myProfile',
  ORDER_SERVICE = 'orderService',
}
