import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HTTPServiceType, IHTTPService, LocalStorageKey } from '../../types';
import { AppConfig } from '../../config';
import { getStorageService } from '../../selectors';

export class AxiosService implements IHTTPService {
  /**
   * Instance of the Axios, which will be
   * used to make all HTTP requests.
   */
  private axiosInstance: AxiosInstance | null = null;

  /**
   * Initialize the Axios instance with base URL and initial configurations.
   */
  public initialize(): void {
    this.axiosInstance = axios.create({
      baseURL: AppConfig.httpAPIGatewayURL,
    });
  }

  /**
   * Returns request configs
   * @param useCredentials
   * @param requestOptions
   */
  generateRequestConfig = (useCredentials: boolean, requestOptions = {}) => {
    const config: AxiosRequestConfig = requestOptions;
    if (useCredentials) {
      const currentUserToken = getStorageService().get(LocalStorageKey.ACCESS_TOKEN);
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${currentUserToken}`,
      };
    }

    return config;
  };

  /**
   * Performs HTTP GET request.
   * @param path
   * @param useCredentials
   * @param requestOptions
   */
  public get(path: string, useCredentials: boolean = true, requestOptions = {}): Promise<AxiosResponse> | undefined {
    const config: AxiosRequestConfig = this.generateRequestConfig(useCredentials, requestOptions);
    path = encodeURI(path);
    return this.axiosInstance?.get(path, config);
  }

  /**
   * Performs HTTP POST request.
   * @param path
   * @param postBody
   * @param useCredentials
   * @param requestOptions
   */
  public post(
    path: string,
    postBody: any,
    useCredentials: boolean = true,
    requestOptions = {}
  ): Promise<AxiosResponse> | undefined {
    const config: AxiosRequestConfig = this.generateRequestConfig(useCredentials, requestOptions);
    path = encodeURI(path);
    return this.axiosInstance?.post(path, postBody, config);
  }

  /**
   * Performs HTTP PUT request.
   * @param path
   * @param putBody
   * @param useCredentials
   */
  public put(path: string, putBody: any, useCredentials: boolean = true): Promise<AxiosResponse> | undefined {
    const config: AxiosRequestConfig = this.generateRequestConfig(useCredentials);
    path = encodeURI(path);
    return this.axiosInstance?.put(path, putBody, config);
  }

  /**
   * Performs HTTP DELETE request.
   * @param path
   * @param useCredentials
   */
  public delete(
    path: string,
    deleteBody: any = {},
    useCredentials: boolean = true
  ): Promise<AxiosResponse> | undefined {
    const config: AxiosRequestConfig = this.generateRequestConfig(useCredentials, { data: { ...deleteBody } });
    path = encodeURI(path);
    return this.axiosInstance?.delete(path, config);
  }

  /**
   * IHTTPService implementation of getType() method.
   */
  public getType(): HTTPServiceType {
    return HTTPServiceType.AXIOS;
  }
}
