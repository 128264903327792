import { connect } from 'react-redux';
import Login from '../../components/login/Login';
import { loadTranslations } from '../../actions/localization';
import { IAppState, IAppDispatch } from '../../types';

const mapStateToProps = (state: IAppState) => ({});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({
  loadTranslations: () => dispatch(loadTranslations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
