import { IStatusTagColor } from '../components';

export enum OrderStatus {
  ALL = 'all',
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  DONE = 'done',
  CLOSED = 'closed',
  CANCELLED = 'cancelled',
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
}

export enum OrderPaymentStatus {
  PAID = 'paid',
  UNPAID = 'unpaid',
  PENDING = 'pending',
}

export enum OrderPaymentMethod {
  CARD = 'card',
  CASH = 'cash',
}

export enum OrderFields {
  ADDRESS = 'address',
  APPOINTMENT_DATE = 'appointmentDate',
  CITY = 'city',
  COUNTRY = 'country',
  DIAGNOSIS = 'diagnosis',
  RECOMMENDATION = 'recommendation',
  STATUS = 'status',
  PAYMENT_STATUS = 'paymentStatus',
  PAYMENT_METHOD = 'paymentMethod',
  TRANSPORTATION_FEE = 'transportationFee',
  PRICE = 'price',
  PATIENT = 'patient',
  ENTRANCE = 'entrance',
  ENTRANCE_CODE = 'entranceCode',
  FLOOR = 'floor',
}

export enum OrderPatientFields {
  ID = 'id',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  GENDER = 'gender',
  PHONE = 'phone',
  BIRTH_DATE = 'birthDate',
  EMAIL = 'email',
  PROFILE_PICTURE = 'profilePicture',
  DEFAULT_PICTURE = 'defaultPicture',
  AGE = 'age',
}

export enum OrderServiceFields {
  ID = 'id',
  SERVICE_NAME = 'serviceName',
  BUNDLE_SERVICE_NAME = 'bundleServiceName',
  PRICE = 'price',
  AGENTS = 'agents',
  NOTES = 'notes',
  DIAGNOSIS = 'diagnosis',
  STATUS_TRANSITION = 'statusTransition',
}

export enum OrderTransitionFields {
  ID = 'id',
  STATUS = 'status',
  AUTHOR_NAME = 'authorName',
  DATE = 'date',
}

export type OrderStatusColors = {
  [key in OrderStatus]?: IStatusTagColor;
};

export type OrderPaymentStatusColors = {
  [key in OrderPaymentStatus]: IStatusTagColor;
};

export type OrderStatusFilter = {
  id: OrderStatus | string;
  name: OrderStatus | string;
};

export interface OrderNotification {
  show: boolean;
  message: string;
}

export interface OrderPrice {
  discount: number;
  orderPrice: number;
  transportationDetails: TransportationDetails;
}

interface TransportationDetails {
  branchLocation: string;
  duration: string;
  price: number;
  km: number;
}

export interface OrderPayment {
  amountPaid: number;
  currency: string;
  discount: number;
  id: number;
  method: OrderPaymentMethod;
  orderPrice: number;
  totalPrice: number;
  status: OrderPaymentStatus;
  system: string;
  transportationPrice: number;
}
