import { connect } from 'react-redux';
import { IAppState, IAppDispatch } from '../../types';
import ListQuickSearch from '../../components/quickSearch/ListQuickSearch';

const mapStateToProps = (state: IAppState) => ({
  role: state.auth.role,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListQuickSearch);
