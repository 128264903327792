import { BaseAPIService } from './BaseAPIService';
export class PatientAPIService extends BaseAPIService {
  constructor() {
    super('');
  }

  public async getList({}: any): Promise<any> {
    return this.get(`admin/patient`);
  }

  public async getOne(id: number): Promise<any> {
    return this.get(`admin/patient/${id}`);
  }

  public async create(data: any): Promise<any> {
    return this.post(`admin/user/${data.id}/patient`, data.patient);
  }

  public async update(data: any, id: number): Promise<any> {
    return this.put(`admin/patient/${id}`, data);
  }

  public async remove(id: number): Promise<any> {
    return this.delete(`admin/patient/${id}`);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(`admin/patient`, data);
  }
}
