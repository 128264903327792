import { connect } from 'react-redux';
import ListBranch from '../../components/branches/ListBranch';
import { IAppState, IAppDispatch } from '../../types';

const mapStateToProps = (state: IAppState) => ({
  currentUserRole: state.auth.role,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListBranch);
