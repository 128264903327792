import { IError } from '../components';

export enum UserStatus {
  ALL = 'all',
  BLOCKED = 'blocked',
  DELETED = 'deleted',
  ACTIVE = 'active',
  UNVERIFIED = 'unverified',
}

export enum DataNames {
  ID = 'id',
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phone',
  USER_VERIFIED_DATE = 'userVerifiedDate',
  PASSWORD = 'password',
  GENDER = 'gender',
  COUNTRY = 'country',
  CITY = 'city',
  BIRTHDATE = 'birthDate',
  ADDRESS = 'address',
  USER_VERIFY_KEY = 'userVerifyKey',
  USER_VERIFY_TOKEN = 'userVerifyToken',
  DEFAULT_PICTURE = 'defaultPicture',
  PROFILE_PICTURE = 'profilePicture',
  SPECIALISTS = 'specialists',
  RATING = 'rating',
  ROLEID = 'roleId',
  TEXT = 'text',
  PATIENT = 'patient',
  USER_VERIFIED_BY = 'userVerifiedBy',
}

export interface IUserData {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password?: string;
  gender?: string;
  country: string;
  city: string;
  birthDate?: Date | null | string;
  address: string;
  defaultPicture?: string;
  profilePicture?: string;
  userVerifiedDate?: string;
  deletedAt?: string | null;
  roleId?: string | number;
  specialists?: Array<string> | string;
  rating?: string;
  role?: string;
  userVerifyToken?: string;
  patients?: [];
  userVerifiedBy?: '';
  cards?: []
}

export interface IResetData {
  password: string;
  userVerifyKey: string;
}

export interface IResetError {
  [key: string]: IError;
}

export interface IUserError {
  [key: string]: IError;
}

export enum UserGender {
  MALE = 'male',
  FEMALE = 'female',
}

export enum ResetPassword {
  EMAIL = 'email',
  SMS = 'sms',
  PHONE = 'phone',
  AUTO = 'verified'
}

export enum PatientColumn {
  ID = 'id',
  PROFILE_IMAGE = 'profileImage',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phone',
  GENDER = 'gender',
  BIRTH_DATE = 'birthDate',
  ACTIONS = 'actions',
}

export interface IPatientError {
  [key: string]: IError;
}

export interface IPatientData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  gender: string;
  profilePicture?: string;
  isEditMode?: boolean;
  patientId?: number;
  birthDate: null | Date | string;
  defaultPicture?: string;
  id?: number;
}

export enum CardType {
  MASTER = 'master',
  VISA = 'visa',
}

export interface ICreditCard {
  id: 1
  cardholderName: string
  createdAt: string
  createdById: 1
  createdByName: string
  expiryDate: Date
  isDefault: boolean
  maskedNumber: string
  updatedAt: string
  vendorBindingId: string
}
