import { BaseAPIService } from './BaseAPIService';
import { IRolePermission } from '../../types';

export class RolesPermissionsAPIService extends BaseAPIService {
  constructor() {
    super('/admin/role');
  }

  public async getList({ query, filter }: any): Promise<any> {
    return this.get('?with_permissions=true');
  }

  public async getOne(id: number): Promise<any> {
    return this.get(`/${id}?with_permissions=true`);
  }

  public async create(data: IRolePermission): Promise<any> {
    return this.post(``, data);
  }

  public async update(data: IRolePermission, id: number): Promise<any> {
    return this.put(`/${id}`, data);
  }

  public async remove(id: number): Promise<any> {
    return this.delete(`/${id}`);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(``, data);
  }
}
