import { BaseAPIService } from './BaseAPIService';
import { ILoginRequest } from '../../types';

export class AuthAPIService extends BaseAPIService {
  constructor() {
    super('public/auth/');
  }

  public async login(data: ILoginRequest): Promise<any> {
    return this.post('login', data);
  }

  public async updateAccessToken(refreshKey: string): Promise<any> {
    return this.get(`refresh-token/${refreshKey}`);
  }

  public async verifyRegister(params: any): Promise<any> {
    return this.post(`register/verify`, params);
  }

  public async reSendVerifyKey(params: any): Promise<any> {
    return this.post(`resend-key`, params);
  }

  public async forgotPassword(params: any): Promise<any> {
    return this.post(`forgot-password`, params);
  }
  public async resetPassword(params: any): Promise<any> {
    return this.post(`reset-password`, params);
  }
}
