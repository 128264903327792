import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  Filter,
  TextInput,
  BulkDeleteButton,
  downloadCSV,
  useListContext,
  ExportButton,
} from 'react-admin';
import { hasPermission } from '../../utils';
import { RequestResource, PermissionLevel, IPermission, Role } from '../../types';

// @ts-ignore
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';


export interface IListUserNotificationProps {
  permissions: IPermission;
  currentUserRole: string;
}

const FilterBar = (props: any) => (
  <Filter {...props}>
    <TextInput
      className={'default_search_input'}
      label="Search"
      source="id~=|title~like|resource~like|action~like|entity~like|user>id~=|user>firstName~like|user>lastName~like"
      alwaysOn
      resettable
    />
  </Filter>
);

export default function ListUserNotification(props: IListUserNotificationProps) {
  const { permissions, currentUserRole } = props;
  const canDelete = hasPermission(permissions, RequestResource.USER_NOTIFICATION, PermissionLevel.DELETE);

  const exporter = (userNotifications: any, selectedIds: any) => {
    const userNotificationsForExport = Object.values(userNotifications).filter((userNotification: any) => {
      return selectedIds.includes(userNotification.id);
    });

    const csv = convertToCSV({
      data: userNotificationsForExport,
      fields: ['id', 'title', 'user', 'resource', 'entity', 'action'],
    });
    downloadCSV(csv, 'userNotifications');
  };

  const BulkActionButtons = (props: any) => {
    const { selectedIds } = props;
    const { total, data } = useListContext();

    return (
      <React.Fragment>
        {canDelete && <BulkDeleteButton {...props} />}
        {[Role.ADMIN, Role.SUPER_ADMIN].includes(currentUserRole as Role) && (
          <ExportButton disabled={!total} onClick={() => exporter(data, selectedIds)} />
        )}
      </React.Fragment>
    );
  };

  const getUserName = (user: any): string => {
    if (!user) {
      return '';
    }
    return `${user.id} - ${user.firstName} ${user.lastName}`;
  }

  return (
    <List
      {...props}
      className="user-notifications-list"
      exporter={false}
      filters={<FilterBar />}
      bulkActionButtons={<BulkActionButtons />}
    >
      <Datagrid isRowSelectable={() => canDelete} className="react-admin-table">
        <TextField source="id" />
        <FunctionField label="Title" render={(record: any) => record.title} />
        <FunctionField
          label="User"
          render={(record: any) =>
            record?.user?.id ? (
              <Link target="_blank" to={`/${RequestResource.USER}/${record?.user?.id}`}>{getUserName(record?.user) || ''}</Link>
            ) : (
              getUserName(record?.user) || ''
            )
          }
        />
        <FunctionField sortBy="resource" label="Resource" render={(record: any) => record.resource} />
        <FunctionField label="Entity" render={(record: any) => record.entity} />
        <FunctionField label="Action" render={(record: any) => record.action} />
        {hasPermission(permissions, RequestResource.USER_NOTIFICATION, PermissionLevel.UPDATE) ? (
          <Tooltip
            placement="top"
            title="Edit User Notification"
          >
            <EditButton label="" />
          </Tooltip>
        ) : null}
      </Datagrid>
    </List>
  );
}
