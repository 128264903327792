export type ObjectField = {
  [key: string]: any;
};

export type IField = string | number | boolean | Date | ObjectField | object[] | undefined;

export interface IRow {
  [key: string]: IField;
}

export enum ColumnType {
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  STRING = 'string',
  COMPONENT = 'COMPONENT',
  COLLAPSE = 'COLLAPSE',
}

export interface IColumn {
  field: string;
  headerName: string;
  editable: boolean;
  showTag?: boolean;
  width?: string;
  type: ColumnType;
  cellRenderer?: (row: IRow) => any;
}
