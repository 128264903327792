import { connect } from 'react-redux';
import ListUsers from '../../components/manageUsers/ListUsers';
import { IAppState, IAppDispatch } from '../../types';
import { IData, restoreUser } from '../../actions/user';

const mapStateToProps = (state: IAppState) => ({
  userId: state.auth.userId,
  successRestore: state.user.successRestore,
  restoreLoading: state.user.restoreLoading,
  currentUserRole: state.auth.role,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({
  restoreUser: (data: IData) => dispatch(restoreUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListUsers);
