import { BaseAPIService } from './BaseAPIService';
import { IService } from '../../types';

export class CategoriesAPIService extends BaseAPIService {
  constructor() {
    super('');
  }
  // public async getList({ query, filter }: any): Promise<any> {
  //   const hasQuery = query?.length;
  //   const extraQuery = filter?.extraQuery ? `?${filter.extraQuery}` : hasQuery ? '?' : '';
  //   query = hasQuery ? `&${query}` : '';
  //   return this.get(`public/category${extraQuery}${query}`);
  // }
  public async getList({ query, filter }: any): Promise<any> {
    query = query?.length ? `&${query}` : '';
    return this.get(`admin/category?with_tree=true${query}`);
  }

  public async getOne(id: number): Promise<any> {
    return this.get(`admin/category/${id}?with_tree=true`);
  }

  public async create(data: IService): Promise<any> {
    return this.post(`admin/category`, data);
  }

  public async update(data: IService, id: number): Promise<any> {
    return this.put(`admin/category/${id}`, data);
  }

  public async remove(id: number): Promise<any> {
    return this.delete(`admin/category/${id}`);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(`admin/category`, data);
  }
}
