import { connect } from 'react-redux';
import { IAppState, IAppDispatch } from '../../types';
import CreateOrder from '../../components/order/CreateOrder';
import { getUsers } from '../../actions/user';
import { emptyOrderService, calculateOrder, calculateOrderFailure } from '../../actions/order';
import { NotificationType, showNotification } from 'react-admin';

const mapStateToProps = (state: IAppState) => ({
  users: state.user.users,
  orderPriceInfo: state.order.orderPrice,
  loading: state.order.loading,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({
  getClients: (roleId: number) => dispatch(getUsers(roleId)),
  emptyOrderService: () => dispatch(emptyOrderService()),
  calculateOrder: (data: any) => dispatch(calculateOrder(data)),
  resetOrderPriceInfo: () => dispatch(calculateOrderFailure()),
  showNotification: (message: string, type: NotificationType | undefined) => dispatch(showNotification(message, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrder);
