import React, { useEffect, useState } from 'react';
import { useDataProvider, useRedirect, Loading } from 'react-admin';
import { Link } from 'react-router-dom';
import {
  capitalizeFirstLetter,
  DEFAULT_VALIDATIONS_INFO,
  findItemByField,
  getBase64Image,
  hasPermission,
  isBase64String,
  isValid,
  removeSymbolAndCapitalize,
  replaceInArray,
  validate,
} from '../../utils';
import {
  RequestResource,
  PermissionLevel,
  IPermission,
  IRow,
  CategoryColumn,
  ColumnType,
  IField,
  CategoryService,
  IInputField,
  ServiceField,
  IErrors,
  ValidationType,
} from '../../types';
import {
  Button,
  Card,
  CardActions,
  FormControl,
  IconButton,
  Typography,
  Divider,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../core/dialog/Dialog';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { GetListParams } from 'ra-core';
import Table from '../core/table/Table';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Input from '../core/input/Input';
import { AppConfig } from '../../config';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import ButtonWithConfirm from '../ButtonWithConfirm';

export interface IEditServiceProps {
  permissions: IPermission;
  id?: string;
}

interface ICategoriesProps {
  handleRowChange: (row: IRow, column: string, value: IField) => void;
  rows: IRow[];
}

const IS_OPEN: string = 'isOpen';
const RADIO_COLUMN: string = 'selected';
const IS_EDIT_MODE: string = 'isEditMode';
const COLLAPSE: string = 'collapse';

const COLUMNS = [
  {
    field: COLLAPSE,
    headerName: '',
    editable: false,
    type: ColumnType.COLLAPSE,
    width: '5%',
  },
  {
    field: CategoryColumn.ID,
    headerName: removeSymbolAndCapitalize(CategoryColumn.ID),
    editable: false,
    type: ColumnType.STRING,
    width: '5%',
  },
  {
    field: CategoryColumn.NAME,
    headerName: removeSymbolAndCapitalize(CategoryColumn.NAME),
    editable: false,
    type: ColumnType.STRING,
    width: '25%',
  },
  {
    field: CategoryColumn.DESCRIPTION,
    headerName: removeSymbolAndCapitalize(CategoryColumn.DESCRIPTION),
    editable: false,
    type: ColumnType.STRING,
    width: '60%',
  },
  {
    field: CategoryColumn.IS_PUBLIC,
    headerName: removeSymbolAndCapitalize(CategoryColumn.IS_PUBLIC),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: CategoryColumn.CREATED_BY,
    headerName: removeSymbolAndCapitalize(CategoryColumn.CREATED_BY),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: IRow) =>
      (row?.createdBy as any)?.id ? (
        <Link to={`/${RequestResource.USER}/${(row?.createdBy as any)?.id}`}>
          {(row?.createdBy as any)?.name || ''}
        </Link>
      ) : (
        (row?.createdBy as any)?.name || ''
      ),
  },
  {
    field: RADIO_COLUMN,
    headerName: '',
    editable: true,
    type: ColumnType.RADIO,
    width: '5%',
  },
];

const Categories = (props: ICategoriesProps) => {
  const { rows, handleRowChange } = props;
  return <Table handleChange={handleRowChange} rows={rows} columns={COLUMNS} />;
};

export default function EditService(props: IEditServiceProps) {
  const { id, permissions } = props;
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [showAddCategoryDialog, setShowAddCategoryDialog] = useState<boolean>(false);
  const [rows, setRows] = useState<IRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<any>({ id: null, name: '' });
  const [errors, setErrors] = useState<IErrors>({
    [ServiceField.NAME]: { ...DEFAULT_VALIDATIONS_INFO },
    [ServiceField.DESCRIPTION]: { ...DEFAULT_VALIDATIONS_INFO },
    [ServiceField.PRICE]: { ...DEFAULT_VALIDATIONS_INFO },
    [ServiceField.PRICE_CLINIC]: { ...DEFAULT_VALIDATIONS_INFO },
  });
  const [values, setValues] = useState<IInputField>({
    [ServiceField.NAME]: '',
    [ServiceField.DESCRIPTION]: '',
    [ServiceField.PRICE]: '',
    [ServiceField.PRICE_CLINIC]: '',
    [ServiceField.IS_PUBLIC]: '',
    [ServiceField.ICON]: '',
  });

  useEffect(() => {
    if (!id) {
      return;
    }
    setLoading(true);
    dataProvider
      .getOne(RequestResource.SERVICE, { id })
      .then(({ data }) => {
        setLoading(false);
        const serviceData = data as any;
        setValues({
          [ServiceField.NAME]: serviceData[ServiceField.NAME],
          [ServiceField.DESCRIPTION]: serviceData[ServiceField.DESCRIPTION],
          [ServiceField.PRICE]: serviceData[ServiceField.PRICE],
          [ServiceField.PRICE_CLINIC]: serviceData[ServiceField.PRICE_CLINIC],
          [ServiceField.ICON]: serviceData[ServiceField.ICON],
          [ServiceField.IS_PUBLIC]: serviceData[ServiceField.IS_PUBLIC],
        });
        dataProvider
          .getList(RequestResource.CATEGORY, {} as GetListParams)
          .then(({ data }) => {
            setLoading(false);
            const categoryData = data as any;
            const preparedRows = prepareCategoriesData(categoryData, serviceData.categoryId);
            setRows(preparedRows);
            const existingCategory = findItemByField(preparedRows, {
              key: 'id',
              value: serviceData.categoryId,
              arrayFieldKey: 'rows',
            });

            if (existingCategory) {
              setSelectedCategory({
                id: existingCategory.id,
                name: existingCategory.name,
              });
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const prepareCategoriesData = (categories: IRow[], categoryId: number): IRow[] => {
    if (!categories || !categories.length) {
      return [];
    }
    return categories.map(({ categories, ...rest }: IRow) => {
      return {
        ...rest,
        rows: prepareCategoriesData(categories as IRow[], categoryId),
        [IS_OPEN]: true,
        [RADIO_COLUMN]: categoryId === rest.id,
        [IS_EDIT_MODE]: true,
      };
    });
  };

  const prepareSingleCategoriesData = (categories: IRow[]): IRow[] => {
    if (!categories || !categories.length) {
      return [];
    }
    return categories.map((category: IRow) => {
      return {
        ...category,
        type: capitalizeFirstLetter(CategoryService.CATEGORY),
        rows: [
          ...prepareSingleCategoriesData(category.rows as IRow[]),
          ...prepareSingleServicesData(category.services as IRow[]),
        ],
        [IS_OPEN]: true,
      };
    });
  };

  const prepareSingleServicesData = (services: IRow[]): IRow[] => {
    if (!services || !services.length) {
      return [];
    }
    return services.map((service: IRow) => ({
      ...service,
      type: capitalizeFirstLetter(CategoryService.SERVICE),
      className: 'services',
      rows: [],
    }));
  };

  const toggleAddCategoryDialog = (toggle: boolean) => setShowAddCategoryDialog(toggle);
  if (loading) return <Loading />;
  const { name, description, price, priceClinic, isPublic } = values;
  const handleChange = (value: string | number | boolean, name: ServiceField) => {
    const { validationTypes, config } = getValidationTypes(name);
    const newErrors = {
      ...errors,
      [name]: validate('' + value, validationTypes, name, config),
    };
    setValues({ ...values, [name]: value });
    setErrors(newErrors);
  };

  const getValidationTypes = (name: ServiceField) => {
    const validationTypes = [ValidationType.REQUIRED];
    let config = {};

    if (name === ServiceField.PRICE || name === ServiceField.PRICE_CLINIC) {
      validationTypes.push(ValidationType.MIN_VALUE);
      config = {
        minValue: 1,
      };
    }
    return {
      validationTypes,
      config,
    };
  };

  const handleSave = () => {
    if (!id || !isValid(errors)) {
      return;
    }
    const { icon, ...rest } = values;
    const changedValues = isBase64String(values[ServiceField.ICON]) ? { ...values } : rest;
    dataProvider
      .update(RequestResource.SERVICE, {
        id: +id,
        data: {
          ...changedValues,
          categoryId: selectedCategory.id,
        },
      } as any)
      .then(() => {
        setLoading(false);
        redirect(`/${RequestResource.SERVICE}`);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleServiceDelete = () => {
    if (!id) {
      return;
    }
    dataProvider
      .delete(RequestResource.SERVICE, {
        id: +id,
      } as any)
      .then(() => {
        setLoading(false);
        redirect(`/${RequestResource.SERVICE}`);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleRowChange = (row: IRow, column: string, value: IField) => {
    const isRadioColumn: boolean = column === RADIO_COLUMN;
    const notFoundReplacementData = isRadioColumn ? { [column]: false } : {};
    const newRows: IRow[] = replaceInArray(
      rows,
      { key: 'id', value: row.id, arrayFieldKey: 'rows' },
      { [column]: value },
      notFoundReplacementData
    );

    if (isRadioColumn) {
      setSelectedCategory({
        id: row.id,
        name: row.name,
      });
    }
    setRows(newRows);
  };

  const handleCancelClick = () => {
    redirect(`/${RequestResource.SERVICE}`);
  };

  const handleRemove = () => {
    const updateRows = (categories: IRow[]): IRow[] => {
      if (!categories || !categories.length) {
        return [];
      }
      return categories.map((currentRow: IRow) => {
        return {
          ...currentRow,
          rows: updateRows(currentRow.rows as IRow[]),
          [IS_OPEN]: false,
          [RADIO_COLUMN]: false,
        };
      });
    };
    const newRows = updateRows(rows);
    setRows(newRows);
    setSelectedCategory({ id: null, name: '' });
  };

  const handleUploadIcon = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.files?.[0];
    let encoded;
    getBase64Image(value).then((result: string) => {
      encoded = result;
      setValues({ ...values, [ServiceField.ICON]: encoded });
    });
  };

  return (
    <div className="edit-service">
      <FormControl className="edit-service-form">
        <div className="service-header">
          <Typography variant="h5">Service edit</Typography>
          <div className="icon-wrapper">
            <input type="file" onChange={handleUploadIcon} id="iconUploader" />
            <IconButton>
              <label className="icon-upload-label" htmlFor="iconUploader">
                {values[ServiceField.ICON] ? (
                  <img
                    className="service-icon"
                    src={
                      isBase64String(values[ServiceField.ICON])
                        ? values[ServiceField.ICON]
                        : `${AppConfig.httpAPIGatewayURL}/${values[ServiceField.ICON]}`
                    }
                    alt="Preview"
                  />
                ) : (
                  <AddPhotoAlternateOutlinedIcon className="default-icon" />
                )}
              </label>
            </IconButton>
          </div>
        </div>
        <Divider className="divider" />
        <div className="body">
          <Input
            required={true}
            label="Name"
            value={name}
            errorInfo={errors[ServiceField.NAME]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, ServiceField.NAME)}
          />
          <Input
            multiline={true}
            required={true}
            label="Description"
            value={description}
            errorInfo={errors[ServiceField.DESCRIPTION]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.value, ServiceField.DESCRIPTION)
            }
          />
          <Input
            required={true}
            label="Price (Mobile App)"
            value={price}
            type="number"
            errorInfo={errors[ServiceField.PRICE]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, ServiceField.PRICE)}
          />
          <Input
            required={true}
            label="Price (Visit clinic)"
            value={priceClinic}
            type="number"
            errorInfo={errors[ServiceField.PRICE_CLINIC]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.value, ServiceField.PRICE_CLINIC)
            }
          />
          <FormControlLabel
            className="is-public-switcher"
            control={
              <Switch
                checked={!!isPublic}
                onChange={(e) => handleChange(e.target.checked, ServiceField.IS_PUBLIC)}
                name="Is Public"
                color="primary"
              />
            }
            label="Is Public"
          />
          <div className="parent-category">
            {selectedCategory.id ? (
              <Link to={`/${RequestResource.CATEGORY}/${selectedCategory.id}`}>{selectedCategory.name || ''}</Link>
            ) : (
              ''
            )}
            <div className="attached-category-actions">
              {selectedCategory.id ? (
                <React.Fragment>
                  <IconButton aria-label="edit" color="primary" onClick={() => toggleAddCategoryDialog(true)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton aria-label="delete" color="primary" onClick={handleRemove}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              ) : (
                <IconButton aria-label="add" color="primary" onClick={() => toggleAddCategoryDialog(true)}>
                  <AddIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          </div>
        </div>
        <Card className="card">
          <CardActions className="card-actions">
            <div>
              {hasPermission(permissions, RequestResource.SERVICE, PermissionLevel.UPDATE) ? (
                <Button variant="contained" color="primary" onClick={handleSave} startIcon={<SaveIcon />}>
                  Save
                </Button>
              ) : null}
              <Button variant="outlined" color="primary" onClick={handleCancelClick} className="cancel-button">
                Cancel
              </Button>
            </div>
            {hasPermission(permissions, RequestResource.SERVICE, PermissionLevel.DELETE) ? (
              <ButtonWithConfirm onConfirm={handleServiceDelete} title="Are you sure you want to delete this service?">
                <Button className="delete" variant="contained" color="secondary" startIcon={<DeleteIcon />}>
                  Delete
                </Button>
              </ButtonWithConfirm>
            ) : null}
          </CardActions>
        </Card>
      </FormControl>
      {showAddCategoryDialog && (
        <Dialog onClose={() => toggleAddCategoryDialog(false)}>
          <DialogTitle title="Add Category" onClose={() => toggleAddCategoryDialog(false)} />
          <DialogContent>
            <Categories rows={rows} handleRowChange={handleRowChange} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => toggleAddCategoryDialog(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
