import { BaseAPIService } from './BaseAPIService';

export class SettingsAPIService extends BaseAPIService {
  constructor() {
    super('admin/setting');
  }

  public async getList({ query, filter }: any): Promise<any> {
    return this.get(`?${query}`);
  }

  public async getOne(id: number): Promise<any> {
    return this.get(`/${id}`);
  }

  public async create(data: any): Promise<any> {
    return this.post(``, data);
  }

  public async update(data: any, id: number): Promise<any> {
    return this.put(`/${id}`, data);
  }

  public async remove(id: number): Promise<any> {
    return this.delete(`/${id}`);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(``, data);
  }
}
