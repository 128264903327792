import { BaseAPIService } from './BaseAPIService';
import { IBundle } from '../../types';
import { queryBuilder } from '../../utils';

export class BundlesAPIService extends BaseAPIService {
  constructor() {
    super('');
  }

  public async getList({ query, filter }: any): Promise<any> {
    let requestQuery = `admin/bundle?projection=bundlesServices,bundlesServices.service`;
    requestQuery += query?.length ? `&${query}` : '';
    requestQuery += filter?.bundleFor ? `&bundleFor=${filter.bundleFor}` : '';
    return this.get(requestQuery);
  }

  public async getOne(id: number): Promise<any> {
    return this.get(`admin/bundle/${id}?prefix=bundle&projection=bundlesServices,bundlesServices.service`);
  }

  public async create(data: IBundle): Promise<any> {
    return this.post(`admin/bundle`, data);
  }

  public async update(data: IBundle, id: number): Promise<any> {
    return this.put(`admin/bundle/${id}`, data);
  }

  public async remove(id: number): Promise<any> {
    return this.delete(`admin/bundle/${id}`);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(`admin/bundle`, data);
  }
}
