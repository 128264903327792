import React, { Fragment, useEffect, useState } from 'react';
import Input from '../core/input/Input';
import { IErrors, IRow, Translation, ValidationType } from '../../types';
import { DialogActions, DialogContent } from '../core/dialog/Dialog';
import { Button } from '@material-ui/core';
import { DEFAULT_VALIDATIONS_INFO, validate } from '../../utils';

interface IEditTranslationProps {
  newTranslationErrors: IErrors;
  checkValidationAndSave: (translation: IRow) => void;
  handleDelete: (id: number) => void;
  setSingleTranslationModalVisible: (visibility: boolean) => void;
  data: IRow;
}

const EditTranslation = (props: IEditTranslationProps) => {
  const { checkValidationAndSave, handleDelete, setSingleTranslationModalVisible, data } = props;

  const defaultErrors = {
    [Translation.TRANSLATION_KEY]: { ...DEFAULT_VALIDATIONS_INFO },
    [Translation.TRANSLATION]: { ...DEFAULT_VALIDATIONS_INFO },
    [Translation.TRANSLATION_ID]: { ...DEFAULT_VALIDATIONS_INFO },
  };

  const [newTranslationErrors, setNewTranslationErrors] = useState<IErrors>({ ...defaultErrors });
  const [currentTranslation, setCurrentTranslation] = useState<IRow>({});

  useEffect(() => {
    setCurrentTranslation({ ...data });
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, name: Translation) => {
    const value = e.target.value;
    const newErrors = {
      ...newTranslationErrors,
      [name]: validate(value, [ValidationType.REQUIRED, ValidationType.MIN_LENGTH], name, { minLength: 3 }),
    };
    const updatedTranslation = {
      ...currentTranslation,
      [name]: value,
    };
    setNewTranslationErrors(newErrors);
    setCurrentTranslation(updatedTranslation);
  };

  return (
    <Fragment>
      <DialogContent>
        <Input
          label="Translation ID"
          disabled={true}
          errorInfo={newTranslationErrors[Translation.TRANSLATION_ID]}
          value={currentTranslation[Translation.TRANSLATION_ID] as string}
        />
        <Input
          label="Translation Key"
          errorInfo={newTranslationErrors[Translation.TRANSLATION_KEY]}
          value={currentTranslation[Translation.TRANSLATION_KEY] as string}
          required
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, Translation.TRANSLATION_KEY)}
        />
        <Input
          label="Translation"
          multiline={true}
          errorInfo={newTranslationErrors[Translation.TRANSLATION]}
          value={currentTranslation[Translation.TRANSLATION] as string}
          required
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, Translation.TRANSLATION)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => checkValidationAndSave(currentTranslation)}>
          Save
        </Button>
        <Button variant="contained" color="primary" onClick={() => handleDelete(currentTranslation.id as number)}>
          Delete
        </Button>
        <Button variant="contained" color="primary" onClick={() => setSingleTranslationModalVisible(false)}>
          Close
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default EditTranslation;
