import {
  getBundlesAPIService,
  getCategoriesAPIService,
  getLanguagesAPIService,
  getPermissionsAPIService,
  getProfileAPIService,
  getRolesPermissionsAPIService,
  getServicesAPIService,
  getStorageService,
  getTranslationsAPIService,
  getUserProfilePictureAPIService,
  getUsersAPIService,
  getFaqAPIService,
  getOrdersAPIService,
  getLanguageFontFileAPIService,
  getNotificationService,
  getUserSpecializationListAPIService,
  getPatientAPIService,
  getPatientProfilePictureAPIService,
  getTemplatesAPIService,
  getSettingsAPIService,
  getTemplatePictureAPIService,
  getPaymentsAPIService,
  getTemplateActionsAPIService,
  getBranchesAPIService,
  getUserNotificationsAPIService,
  getQuickSearchAPIService,
} from './selectors';
import { LocalStorageKey, NotificationType, RequestResource, Role } from './types';
import { hasHigherRoleThan, queryBuilder } from './utils';

const getApiServiceByResource = (resource: RequestResource) => {
  switch (resource) {
    case RequestResource.BUNDLE:
      return getBundlesAPIService();
    case RequestResource.SERVICE:
      return getServicesAPIService();
    case RequestResource.ROLE:
      return getRolesPermissionsAPIService();
    case RequestResource.PERMISSIONS:
      return getPermissionsAPIService();
    case RequestResource.LANGUAGE:
      return getLanguagesAPIService();
    case RequestResource.TRANSLATION:
      return getTranslationsAPIService();
    case RequestResource.PROFILE:
      return getProfileAPIService();
    case RequestResource.CATEGORY:
      return getCategoriesAPIService();
    case RequestResource.USER:
      return getUsersAPIService();
    case RequestResource.USERPICTURE:
      return getUserProfilePictureAPIService();
    case RequestResource.FAQ:
      return getFaqAPIService();
    case RequestResource.LANGUAGE_FONT_FILE:
      return getLanguageFontFileAPIService();
    case RequestResource.ORDER:
      return getOrdersAPIService();
    case RequestResource.USER_SPECIALIZATION_LIST:
      return getUserSpecializationListAPIService();
    case RequestResource.PATIENT:
      return getPatientAPIService();
    case RequestResource.PATIENTPICTURE:
      return getPatientProfilePictureAPIService();
    case RequestResource.NOTIFICATION_TEMPLATE:
      return getTemplatesAPIService();
    case RequestResource.NOTIFICATION_TEMPLATE_PICTURE:
      return getTemplatePictureAPIService();
    case RequestResource.NOTIFICATION_TEMPLATE_ACTIONS:
      return getTemplateActionsAPIService();
    case RequestResource.SETTING:
      return getSettingsAPIService();
    case RequestResource.PAYMENT:
      return getPaymentsAPIService();
    case RequestResource.BRANCH:
      return getBranchesAPIService();
    case RequestResource.USER_NOTIFICATION:
      return getUserNotificationsAPIService();
    case RequestResource.QUICK_SEARCH:
      return getQuickSearchAPIService();
  }
};

const handleShowNotification = (message: string, type: NotificationType) => {
  const notificationService = getNotificationService();

  if (message) {
    notificationService.show({ message, type });
  }
};

const getList = async (resource: RequestResource, params: any) => {
  const api = getApiServiceByResource(resource);

  try {
    const request = await api?.getList(queryBuilder(params, resource));
    if (resource === RequestResource.USER) {
      const currentUserRole = getStorageService().get(LocalStorageKey.ROLE) || '';
      request.data.result = request.data.result.filter((elem: any) => {
        return hasHigherRoleThan(JSON.parse(currentUserRole) as Role, elem?.roles?.[0]?.name);
      });
    }
    return Promise.resolve({ data: request.data.result, total: request.data.count });
  } catch (request) {
    const message = request?.data?.message || null;
    handleShowNotification(message, NotificationType.ERROR);
    return Promise.reject({ data: [], total: 0 });
  }
};

const getOne = async (resource: RequestResource, params: any) => {
  const api = getApiServiceByResource(resource);

  try {
    const request = await api?.getOne(params.id);
    return Promise.resolve({ data: request.data.result });
  } catch (request) {
    const message = request?.data?.message || null;
    handleShowNotification(message, NotificationType.ERROR);
    return Promise.reject({ data: {} });
  }
};

const getMany = (): Promise<any> => new Promise((resolve, reject) => {});

const getManyReference = (): Promise<any> => new Promise((resolve, reject) => {});

const create = async (resource: RequestResource, params: any) => {
  const api = getApiServiceByResource(resource);
  try {
    const request = await api?.create(params.data);
    const message = request?.data?.message || null;
    handleShowNotification(message, NotificationType.SUCCESS);
    return Promise.resolve({ data: request.data.result });
  } catch (request) {
    const message = request?.data?.message || null;
    handleShowNotification(message, NotificationType.ERROR);
    return Promise.reject({ data: {} });
  }
};

const update = async (resource: RequestResource, params: any) => {
  const { data, id } = params;
  const api = getApiServiceByResource(resource);
  try {
    const request = await api?.update(data, id);
    const message = request?.data?.message || null;
    handleShowNotification(message, NotificationType.SUCCESS);
    return Promise.resolve({ data: request.data?.result });
  } catch (request) {
    const message = request?.data?.message || null;
    handleShowNotification(message, NotificationType.ERROR);
    return Promise.reject({ data: {} });
  }
};

const updateMany = (): Promise<any> => new Promise((resolve, reject) => {});

const deleteOne = async (resource: RequestResource, params: any) => {
  const api = getApiServiceByResource(resource);

  try {
    const request = await api?.remove(params.id);
    const message = request?.data?.message || null;
    handleShowNotification(message, NotificationType.SUCCESS);
    return Promise.resolve({ data: request.data?.result });
  } catch (request) {
    const message = request?.data?.message || null;
    handleShowNotification(message, NotificationType.ERROR);
    return Promise.reject({ data: [] });
  }
};

const deleteMany = async (resource: RequestResource, params: any) => {
  const api = getApiServiceByResource(resource);

  try {
    const request = await api?.removeMany(params);
    const message = request?.data?.message || null;
    handleShowNotification(message, NotificationType.SUCCESS);
    return Promise.resolve({ data: request.data?.result });
  } catch (request) {
    const message = request?.data?.message || null;
    handleShowNotification(message, NotificationType.ERROR);
    return Promise.reject({ data: [] });
  }
};

const verifyUser = async (id: number) => {
  const api = getUsersAPIService();

  try {
    const request = await api?.verify(id);
    const message = request?.data?.message || null;
    handleShowNotification(message, NotificationType.SUCCESS);
    return Promise.resolve({ data: request.data?.result });
  } catch (request) {
    const message = request?.data?.message || null;
    handleShowNotification(message, NotificationType.ERROR);
    return Promise.reject({ data: {} });
  }
};

const dataProvider = {
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  updateMany,
  deleteMany,
  delete: deleteOne,
  verifyUser,
};

export default dataProvider;
