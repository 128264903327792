import React from 'react';
import {
  Checkbox,
  IconButton,
  Paper,
  Table as MatTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Collapse,
  Box,
  Radio,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ColumnType, IColumn, IField, IRow } from '../../../types';

interface ITableProps {
  columns: IColumn[];
  rows: IRow[];
  handleChange?: (row: IRow, column: string, value: IField) => void;
  handleEditOrCancelIconClick?: (row: IRow, isEditMode: boolean) => void;
  handleSave?: (row: any) => void;
  handleDelete?: (id: number) => void;
  handleAdd?: (row: any) => void;
  handleEdit?: (row: any) => void;
  handlePrintService?: (row: any) => void;
  canSave?: boolean;
  canDelete?: boolean;
  index?: number;
  column?: string;
  canAdd?: boolean;
  canEdit?: boolean;
  noDataMessage?: string;
}

const DEFAULT_NO_DATA_MESSAGE = 'No data available';

export default function Table(props: ITableProps) {
  const {
    columns,
    rows,
    handleChange,
    handleEditOrCancelIconClick,
    canSave,
    canDelete,
    canAdd,
    canEdit,
    handleSave,
    handleDelete,
    handleAdd,
    handleEdit,
    index,
    column,
    noDataMessage,
  } = props;
  const handleRowChange = (row: any, column: string, value: IField) => {
    if (typeof handleChange === 'function') {
      handleChange(row, column, value);
    }
  };

  const renderCellByType = (row: IRow, { field, type, editable, cellRenderer }: IColumn, i: number) => {
    switch (type) {
      case ColumnType.CHECKBOX:
        return (
          <Checkbox
            checked={Boolean(row[field])}
            disabled={!editable}
            onChange={() => handleRowChange(row, field, !row[field])}
          />
        );
      case ColumnType.RADIO:
        return (
          <Radio
            checked={Boolean(row[field])}
            onChange={() => handleRowChange(row, field, !row[field])}
            value={row.id}
            color="default"
            disabled={row.disabled as boolean}
            name="radio-button"
          />
        );
      case ColumnType.STRING:
        return (
          <TextField
            inputRef={(input) => i === index && input && field === column && input.focus()}
            value={row[field]}
            onChange={({ target: { value } }) => {
              handleRowChange(row, field, value);
            }}
          />
        );
      default:
        const value = typeof cellRenderer === 'function' ? cellRenderer(row) : row[field];
        return <p>{value}</p>;
    }
  };

  const renderViewCellByType = (row: IRow, { field, type, cellRenderer }: IColumn) => {
    switch (type) {
      case ColumnType.CHECKBOX:
        return <Checkbox checked={Boolean(row[field])} disabled={true} />;
      case ColumnType.RADIO:
        return (
          <Radio checked={Boolean(row[field])} disabled={true} value={row.id} color="default" name="radio-button" />
        );
      default:
        const value = typeof cellRenderer === 'function' ? cellRenderer(row) : row[field];
        return <p>{value}</p>;
    }
  };

  const renderActionsCell = (row: IRow) => {
    if (!row.isEditMode && handleEditOrCancelIconClick) {
      return (
        <div className="table-actions">
          <IconButton className="icon" onClick={() => handleEditOrCancelIconClick(row, true)}>
            <EditIcon />
          </IconButton>
        </div>
      );
    }
    return (
      <div className="table-actions">
        {canSave && typeof handleSave === 'function' && (
          <IconButton className="icon" aria-label="save" onClick={() => handleSave(row)}>
            <SaveIcon />
          </IconButton>
        )}
        {canDelete && typeof handleDelete === 'function' && (
          <IconButton className="icon" aria-label="delete" onClick={() => handleDelete(row.id as number)}>
            <DeleteIcon />
          </IconButton>
        )}
        {canAdd && typeof handleAdd === 'function' && (
          <IconButton className="icon" aria-label="add" onClick={() => handleAdd(row)}>
            <AddIcon />
          </IconButton>
        )}
        {canEdit && typeof handleEdit === 'function' && (
          <IconButton className="icon" aria-label="edit" onClick={() => handleEdit(row)}>
            <EditIcon />
          </IconButton>
        )}
        {handleEditOrCancelIconClick && (
          <IconButton className="icon" aria-label="cancel" onClick={() => handleEditOrCancelIconClick(row, false)}>
            <CancelIcon />
          </IconButton>
        )}
      </div>
    );
  };
  const renderCell = (row: IRow, column: IColumn, i: number) => {
    const { editable, showTag } = column;

    if (column.type === ColumnType.COMPONENT && (canSave || canDelete || canAdd || canEdit)) {
      return renderActionsCell(row);
    }

    if (row.isEditMode && (editable || showTag)) {
      return renderCellByType(row, column, i);
    }
    return renderViewCellByType(row, column);
  };

  const renderHeader = () => (
    <TableRow className="crs-table-row">
      {columns?.map((column: IColumn) => (
        <TableCell
          className="crs-table-cell"
          style={{ width: column.width || 'auth' }}
          key={column.field + Math.random()}
          component="th"
        >
          {column.headerName}
        </TableCell>
      ))}
    </TableRow>
  );

  const renderBody = (customRows?: IRow[]) => {
    if (!rows?.length) {
      return (
        <TableRow className="no-data-row">
          <TableCell className="crs-table-cell" colSpan={12}>
            {noDataMessage || DEFAULT_NO_DATA_MESSAGE}
          </TableCell>
        </TableRow>
      );
    }
    const data = customRows?.length ? customRows : rows;
    return data.map((row: IRow, index: number) => {
      const subRows = row.rows as IRow[];
      const className = row.className ? ` ${row.className}` : '';

      return (
        <React.Fragment key={index.toString() + Math.random()}>
          <TableRow className={`crs-table-row${className}`}>
            {columns.map((column: IColumn) => {
              if (column.type === ColumnType.COLLAPSE) {
                return subRows.length ? (
                  <TableCell
                    style={{ width: column.width || 'auth' }}
                    className="crs-table-cell"
                    key={column.field + Math.random()}
                    component="td"
                  >
                    <IconButton
                      className="icon-button"
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleRowChange(row, 'isOpen', !row.isOpen)}
                    >
                      {(row.isOpen as boolean) ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                  </TableCell>
                ) : (
                  <TableCell
                    style={{ width: column.width || 'auth' }}
                    className="crs-table-cell empty-cell"
                    key={column.field + Math.random()}
                  >
                    <IconButton disabled={true} className="icon-button" aria-label="expand row" size="small" />
                  </TableCell>
                );
              }
              return (
                <TableCell
                  style={{ width: column.width || 'auth' }}
                  className="crs-table-cell"
                  key={column.field + Math.random()}
                  component="td"
                >
                  {renderCell(row, column, index)}
                </TableCell>
              );
            })}
          </TableRow>
          {subRows?.length ? (
            <TableRow key={row.id as number} className="crs-table-row collapsible">
              <TableCell className="crs-table-cell has-table" colSpan={12}>
                <Collapse in={row.isOpen as boolean} timeout="auto" unmountOnExit>
                  <Box margin={0}>{renderTable(subRows, false)}</Box>
                </Collapse>
              </TableCell>
            </TableRow>
          ) : null}
        </React.Fragment>
      );
    });
  };

  const renderTable = (customRows: IRow[] = [], showHeader: boolean = true) => {
    return (
      <TableContainer component={Paper} className="crs-table-container">
        <MatTable className="crs-table" aria-label="caption table">
          {showHeader ? <TableHead className="crs-table-header">{renderHeader()}</TableHead> : null}
          <TableBody className="crs-table-body">{renderBody(customRows)}</TableBody>
        </MatTable>
      </TableContainer>
    );
  };

  return renderTable();
}
