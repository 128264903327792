import { BaseAPIService } from './BaseAPIService';

export class TemplatesAPIService extends BaseAPIService {
  constructor() {
    super('admin/notification-template');
  }

  public async getList({ query, filter }: any): Promise<any> {
    query = query?.length ? `?${query}` : '';
    let filterSearch = '';
    filter?.q && (filterSearch += `&search=id,=,${filter.q}`);
    filter?.type && filter.type !== 'all' && (filterSearch += `&search=type,=,${filter.type}`);
    filter?.action && filter?.action !== 'all' && (filterSearch += `&search=action,=,${filter.action}`);
    return this.get(`${query}${filterSearch}`);
  }

  public async getOne(id: number): Promise<any> {
    return this.get(`/${id}`);
  }

  public async create(data: any): Promise<any> {
    return this.post(``, data);
  }

  public async update(data: any, id: number): Promise<any> {
    return this.put(`/${id}`, data);
  }

  public async remove(id: number): Promise<any> {
    return this.delete(`/${id}`);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(``, data);
  }
}
