import { IAppDispatch, IPermission } from '../types';
import { autoUpdateOrderList } from './order';

export const SET_ROLE_PERMISSION = 'Auth/SET_ROLE_PERMISSION';
export type SET_ROLE_PERMISSION = {
  role: string;
  permissions: IPermission;
  userId: string;
};

export const setRolePermission = (data: SET_ROLE_PERMISSION) => (dispatch: IAppDispatch) => {
  // Automatically updates table for orders.
  dispatch(autoUpdateOrderList());
  dispatch({
    type: SET_ROLE_PERMISSION,
    payload: data,
  });
};
