import React from 'react';
import Route from './containers/route/Route';
import ProfileEdit from './containers/profile/ProfileEdit';
import OrderService from './containers/order-service/OrderService';
import { AppRoutes } from './config';
import { IAppRouteKey } from './types';

const customRoutes: any[] = [
  <Route path={AppRoutes[IAppRouteKey.MY_PROFILE].path} exact={true} component={ProfileEdit} />,
  <Route path={AppRoutes[IAppRouteKey.ORDER_SERVICE].path} exact={true} component={OrderService} />,
];

export default customRoutes;
