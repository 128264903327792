import { connect } from 'react-redux';
import { handleOrderNotificationClose } from '../../actions/order';
import Layout from '../../components/layout/Layout';
import { IAppState, IAppDispatch } from '../../types';

const mapStateToProps = (state: IAppState) => ({
  open: state.admin.ui.sidebarOpen,
  orderNotification: state.order.orderNotification,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({
  handleOrderNotificationClose: () => dispatch(handleOrderNotificationClose),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
