import React from 'react';
import { Select as MatSelect, FormControl, InputLabel, MenuItem, FormHelperText } from '@material-ui/core';
import { IError, ISelectData } from '../../../types';

type Variant = 'standard' | 'outlined' | 'filled';

interface ISelectProps {
  id?: string;
  variant?: Variant;
  inputProps?: object;
  classes?: string[];
  required?: boolean;
  disabled?: boolean;
  showNotSelectedText?: boolean;
  data: ISelectData[];
  errorInfo: IError;
  label: string;
  value: string | number;
  handleChange?: (e: React.ChangeEvent<{ value: unknown }>) => void;
}

const DEFAULT_VARIANT = 'filled';

export default function Select(props: ISelectProps) {
  const {
    id,
    data,
    variant,
    label,
    value,
    handleChange,
    required,
    disabled,
    classes,
    inputProps,
    showNotSelectedText,
    errorInfo: { error, message },
  } = props;
  const className = classes ? ` ${classes.join(' ')}` : '';

  const renderOptions = () =>
    data?.map(({ value, label }: ISelectData) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    )) || (
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
    );

  return (
    <FormControl variant={variant || DEFAULT_VARIANT} error={error} className={`crs-select${className}`}>
      <InputLabel id={id}>{label}</InputLabel>
      <MatSelect
        inputProps={inputProps}
        required={required}
        disabled={disabled}
        labelId={id}
        id={id}
        value={value}
        onChange={handleChange}
      >
        {showNotSelectedText && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {renderOptions()}
      </MatSelect>
      <FormHelperText>{message}</FormHelperText>
    </FormControl>
  );
}
