import React, { useEffect, useState } from 'react';
import { Login as RALogin, useLogin } from 'react-admin';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { IErrors, IInputField, NotificationType, ValidationType, T, IUser } from '../../types';
import { DEFAULT_VALIDATIONS_INFO, isValid, validate } from '../../utils';
import { getNotificationService, t } from '../../selectors';

export interface ILoginProps {
  loadTranslations: () => void;
}

enum LoginFields {
  USERNAME = 'username',
  PASSWORD = 'password',
}

enum PasswordInputType {
  PASSWORD = 'password',
  TEXT = 'text',
}

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 350,
    minHeight: 300,
  },
  passwordLabel: {
    zIndex: 1,
  },
}));

export default function Login(props: ILoginProps) {
  const { loadTranslations } = props;
  const [loginData, setLoginData] = useState<IInputField>({
    [LoginFields.USERNAME]: '',
    [LoginFields.PASSWORD]: '',
  });
  const [errors, setErrors] = useState<IErrors>({
    [LoginFields.USERNAME]: { ...DEFAULT_VALIDATIONS_INFO },
    [LoginFields.PASSWORD]: { ...DEFAULT_VALIDATIONS_INFO },
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);

  useEffect(() => {
    loadTranslations();
  }, []);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const classes = useStyles();
  const login = useLogin();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: LoginFields) => {
    const value = e?.target?.value;

    const { validationTypes, config } = getValidationTypes(name);
    const newErrors = {
      ...errors,
      [name]: validate(value, validationTypes, name, config),
    };
    setErrors(newErrors);
    setLoginData({ ...loginData, [name]: value });
  };

  const getValidationTypes = (name: LoginFields) => {
    const validationTypes = [ValidationType.REQUIRED];
    let config = {};

    return {
      validationTypes,
      config,
    };
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e?.key === 'Enter' || e?.keyCode === 13) {
      handleLoginClick();
    }
  };

  const handleLoginClick = () => {
    const keys = Object.keys(errors);
    let newErrors = { ...errors };

    for (const key of keys) {
      const value = loginData[key];
      const { validationTypes, config } = getValidationTypes(key as LoginFields);
      newErrors = {
        ...newErrors,
        [key]: validate(value, validationTypes, key, config),
      };
    }
    setErrors(newErrors);
    if (isValid(newErrors)) {
      handleLogin();
    }
  };

  const handleLogin = () => {
    login({ ...loginData }).catch((e: any) => {
      getNotificationService().show({
        type: NotificationType.ERROR,
        message: t(e as T),
      });
    });
  };

  return (
    <RALogin
      classes={{
        card: classes.root,
      }}
      {...props}
    >
      <div className="login-form">
        <TextField
          id="username"
          className="text-field input"
          label="Username"
          type="text"
          value={loginData[LoginFields.USERNAME]}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
            handleChange(e, LoginFields.USERNAME)
          }
          onKeyDown={handleKeyDown}
          error={errors[LoginFields.USERNAME]?.error}
          helperText={errors[LoginFields.USERNAME]?.message}
        />
        <FormControl className="text-field" error={errors[LoginFields.PASSWORD]?.error}>
          <InputLabel className={classes.passwordLabel} htmlFor="password-input">
            Password
          </InputLabel>
          <Input
            id="password-input"
            className="input"
            type={showPassword ? PasswordInputType.TEXT : PasswordInputType.PASSWORD}
            value={loginData[LoginFields.PASSWORD]}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              handleChange(e, LoginFields.PASSWORD)
            }
            onKeyDown={handleKeyDown}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText id="password-error-text">{errors[LoginFields.PASSWORD]?.message}</FormHelperText>
        </FormControl>
        <Button variant="contained" color="primary" onClick={handleLoginClick}>
          Sign In
        </Button>
      </div>
    </RALogin>
  );
}
