import { IAppDispatch, IAppState, LocalStorageKey, NotificationType, RequestResource } from '../types';
import { isSuccessfulResponse } from '../utils';
import { getNotificationService, getOrdersAPIService, getStorageService } from '../selectors';
import { AppConfig } from '../config';

let timeoutId: NodeJS.Timeout | null = null;

export const DELETE_ATTACHMENT_REQUEST = 'Order/DELETE_ATTACHMENT_REQUEST';

export const removeOrderServiceAttachment = (orderServiceId: number, attachmentId: number) => (
  dispatch: IAppDispatch
) => async () => {
  dispatch({
    type: DELETE_ATTACHMENT_REQUEST,
  });
  try {
    const request = await getOrdersAPIService().removeOrderServiceAttachment(orderServiceId, attachmentId);
    const message = request.data.message;

    if (!isSuccessfulResponse(request.status)) {
      return dispatch(deleteAttachmentFailure(message));
    }
    dispatch(deleteAttachmentSuccess(message));
  } catch (e: any) {
    dispatch(deleteAttachmentFailure(e.response?.data?.message));
  }
};

export const DELETE_ATTACHMENT_SUCCESS = 'Orders/DELETE_ATTACHMENT_SUCCESS';

const deleteAttachmentSuccess = (message: string = '') => (dispatch: IAppDispatch) => {
  getNotificationService().show({
    message,
    type: NotificationType.SUCCESS,
  });
  dispatch({
    type: DELETE_ATTACHMENT_SUCCESS,
  });
};

export const DELETE_ATTACHMENT_FAILURE = 'Orders/DELETE_ATTACHMENT_FAILURE';

const deleteAttachmentFailure = (message: string = '') => (dispatch: IAppDispatch) => {
  getNotificationService().show({
    message,
    type: NotificationType.ERROR,
  });
  dispatch({
    type: DELETE_ATTACHMENT_FAILURE,
  });
};

export const SAVE_ATTACHMENTS_REQUEST = 'Orders/SAVE_ATTACHMENTS_REQUEST';

export const saveOrderServiceAttachments = (orderServiceId: number, files: any) => async (dispatch: IAppDispatch) => {
  dispatch({
    type: SAVE_ATTACHMENTS_REQUEST,
  });
  try {
    const request = await getOrdersAPIService().saveOrderServiceAttachments(orderServiceId, files);
    const message = request.data.message;

    if (!isSuccessfulResponse(request.status)) {
      return dispatch(saveAttachmentsFailure(message));
    }
    dispatch(saveAttachmentsSuccess(message));
  } catch (e) {
    dispatch(saveAttachmentsFailure(e.response?.data?.message));
  }
};

export const SAVE_ATTACHMENTS_SUCCESS = 'Orders/SAVE_ATTACHMENTS_SUCCESS';

const saveAttachmentsSuccess = (message: string = '') => (dispatch: IAppDispatch) => {
  getNotificationService().show({
    message,
    type: NotificationType.SUCCESS,
  });
  dispatch({
    type: SAVE_ATTACHMENTS_SUCCESS,
  });
};

export const SAVE_ATTACHMENTS_FAILURE = 'Orders/SAVE_ATTACHMENTS_FAILURE';

const saveAttachmentsFailure = (message: string = '') => (dispatch: IAppDispatch) => {
  getNotificationService().show({
    message,
    type: NotificationType.ERROR,
  });
  dispatch({
    type: SAVE_ATTACHMENTS_FAILURE,
  });
};

const ORDER_DATA_UPDATE = 'RA/CRUD_GET_LIST_SUCCESS';
const FETCH_RESPONSE = 'GET_LIST';
const FETCH_STATUS = 'RA/FETCH_END';

export const TOGGLE_ORDER_NOTIFICATION = 'Order/TOGGLE_ORDER_NOTIFICATION';

export const handleOrderNotificationClose = () => (dispatch: IAppDispatch) => {
  dispatch({
    type: TOGGLE_ORDER_NOTIFICATION,
    payload: {
      message: '',
      show: false,
    },
  });
};

export const autoUpdateOrderList = () => (dispatch: IAppDispatch, getState: () => IAppState) => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  timeoutId = setTimeout(async () => {
    if (!getStorageService().get(LocalStorageKey.REFRESH_KEY)) {
      return;
    }
    try {
      const request = await getOrdersAPIService().getList({
        query: `prefix=order&countRecentSearch=${new Date(
          new Date().getTime() - AppConfig.autoUpdateTime
        ).toISOString()}`,
        filter: '',
      });
      if (!isSuccessfulResponse(request.status)) {
        return;
      }
      const {
        params: { filter },
      } = getState().admin.resources.order.list;
      const { count, result, recentCount } = request.data || {};

      if (recentCount) {
        dispatch({
          type: TOGGLE_ORDER_NOTIFICATION,
          payload: {
            message: `There ${recentCount > 1 ? 'are' : 'is'} ${recentCount} new order${recentCount > 1 ? 's' : ''}`,
            show: true,
          },
        });

        if (!filter || !Object.keys(filter).length) {
          dispatch({
            type: ORDER_DATA_UPDATE,
            payload: {
              data: result,
              total: count,
            },
            meta: {
              resource: RequestResource.ORDER,
              fetchResponse: FETCH_RESPONSE,
              fetchStatus: FETCH_STATUS,
            },
          });
        }
      }
    } catch (e) {}
    dispatch(autoUpdateOrderList());
  }, AppConfig.autoUpdateTime);
};

export const GET_ORDER_SERVICE_REQUEST = 'Order/GET_ORDER_SERVICE_REQUEST';

export const getOrderService = (id: number) => (dispatch: IAppDispatch) => async () => {
  dispatch({
    type: GET_ORDER_SERVICE_REQUEST,
  });
  try {
    const request = await getOrdersAPIService().getOrderService(id);
    const data = request.data.result;

    if (!isSuccessfulResponse(request.status)) {
      return dispatch(getOrderServiceFailure());
    }
    dispatch(getOrderServiceSuccess(data));
  } catch (e: any) {
    dispatch(getOrderServiceFailure());
  }
};

export const GET_ORDER_SERVICE_SUCCESS = 'Orders/GET_ORDER_SERVICE_SUCCESS';
export type GET_ORDER_SERVICE_SUCCESS = any;

const getOrderServiceSuccess = (orderServices: GET_ORDER_SERVICE_SUCCESS) => (dispatch: IAppDispatch) => {
  dispatch({
    type: GET_ORDER_SERVICE_SUCCESS,
    payload: orderServices,
  });
};

export const GET_ORDER_SERVICE_FAILURE = 'Orders/GET_ORDER_SERVICE_FAILURE';

const getOrderServiceFailure = () => (dispatch: IAppDispatch) => {
  dispatch({
    type: GET_ORDER_SERVICE_FAILURE,
  });
};

export const emptyOrderService = () => (dispatch: IAppDispatch) => async () => {
  dispatch({
    type: GET_ORDER_SERVICE_SUCCESS,
    payload: {},
  });
};

export const SAVE_ORDER_SERVICE_REQUEST = 'Order/SAVE_ORDER_SERVICE_REQUEST';

export const saveOrderService = (id: number, data: any) => (dispatch: IAppDispatch) => async () => {
  dispatch({
    type: SAVE_ORDER_SERVICE_REQUEST,
  });
  try {
    const request = await getOrdersAPIService().saveOrderService(id, data);

    if (!isSuccessfulResponse(request.status)) {
      return dispatch(saveOrderServiceFailure());
    }
    dispatch(saveOrderServiceSuccess());
  } catch (e: any) {
    dispatch(saveOrderServiceFailure());
  }
};

export const SAVE_ORDER_SERVICE_SUCCESS = 'Orders/SAVE_ORDER_SERVICE_SUCCESS';

const saveOrderServiceSuccess = () => (dispatch: IAppDispatch) => {
  dispatch({
    type: SAVE_ORDER_SERVICE_SUCCESS,
  });
};

export const SAVE_ORDER_SERVICE_FAILURE = 'Orders/SAVE_ORDER_SERVICE_FAILURE';

const saveOrderServiceFailure = () => (dispatch: IAppDispatch) => {
  dispatch({
    type: SAVE_ORDER_SERVICE_FAILURE,
  });
};

export const CALCULATE_ORDER_PRICE_REQUEST = 'Order/CALCULATE_ORDER_PRICE_REQUEST';

export const calculateOrder = (order: any) => async (dispatch: IAppDispatch) => {
  dispatch({
    type: CALCULATE_ORDER_PRICE_REQUEST,
  });
  try {
    const request = await getOrdersAPIService().calculateOrderPrice(order);
    const data = request.data;

    if (!isSuccessfulResponse(request.status)) {
      return dispatch(calculateOrderFailure());
    }
    dispatch(calculateOrderSuccess(data));
  } catch (e: any) {
    dispatch(calculateOrderFailure());
  }
};

export const CALCULATE_ORDER_PRICE_SUCCESS = 'Order/CALCULATE_ORDER_PRICE_SUCCESS';

export const calculateOrderSuccess = (data: any) => (dispatch: IAppDispatch) => {
  dispatch({
    type: CALCULATE_ORDER_PRICE_SUCCESS,
    payload: data,
  });
};

export const CALCULATE_ORDER_PRICE_FAILURE = 'Order/CALCULATE_ORDER_PRICE_FAILURE';

export const calculateOrderFailure = () => (dispatch: IAppDispatch) => {
  dispatch({
    type: CALCULATE_ORDER_PRICE_FAILURE,
  });
};
