import * as React from 'react';
import { useSelector } from 'react-redux';
import { Theme, useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources } from 'react-admin';
import { IAppState } from '../../types';
import { capitalizeFirstLetter } from '../../utils';
import ViewListIcon from '@material-ui/icons/ViewList';
import { createElement } from 'react';

interface IMenuProps {
  logout?: () => void;
  onMenuClick?: () => void;
}

export default function Menu(props: IMenuProps) {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const open = useSelector((state: IAppState) => state.admin.ui.sidebarOpen);
  const selectedRoute = useSelector((state: IAppState) => state.router.location.pathname);
  const resources = useSelector(getResources);
  return (
    <div className="sidebar-menu-container">
      {resources.map((resource) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          className={`menu-item ${selectedRoute?.includes(`/${resource.name}`) ? 'selected' : ''}`}
          primaryText={
            <div className="menu-label">
              {resource.options && capitalizeFirstLetter(resource.options.label || resource.name)}
            </div>
          }
          leftIcon={resource?.icon ? createElement(resource.icon) : <ViewListIcon />}
          onClick={props.onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      {isXSmall && props.logout}
    </div>
  );
}
