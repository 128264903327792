import { connect } from 'react-redux';
import ListBundle from '../../components/bundles/ListBundle';
import { IAppState, IAppDispatch } from '../../types';

const mapStateToProps = (state: IAppState) => ({
  currentUserRole: state.auth.role,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListBundle);
