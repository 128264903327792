import React, { useEffect, useState } from 'react';
import { useDataProvider, Loading, DeleteButton } from 'react-admin';
import Autocomplete from '@material-ui/lab/Autocomplete';
import countries from 'countries-list';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
  IPermission,
  RequestResource,
  DataNames,
  IUserData,
  IUserError,
  UserGender,
  IRolePermission,
  ResetPassword,
  Role,
  LocalStorageKey,
  ValidationType,
  IErrors,
  T,
  PermissionLevel,
} from '../../types';
import { history } from '../../configureStore';
import {
  capitalizeFirstLetter,
  hasHigherRoleThan,
  removeSymbolAndCapitalize,
  isValid,
  validate,
  getMaxDate,
  DEFAULT_VALIDATIONS_INFO,
  FieldValue,
  hasPermission,
} from '../../utils';
import {
  Button,
  Input as InputField,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CardActions,
  Card,
  Typography,
  Divider,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import authProvider from '../../authProvider';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../core/dialog/Dialog';
import { getStorageService, t } from '../../selectors';
import { dateFormat } from '../../constants';
import { Checkbox, ListItemText } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DatePicker from '../core/picker/datepicker/DatePicker';
import Input from '../core/input/Input';
import { useHistory, useLocation } from 'react-router-dom';

const initialData: IUserData = {
  [DataNames.FIRSTNAME]: '',
  [DataNames.LASTNAME]: '',
  [DataNames.EMAIL]: '',
  [DataNames.PHONE]: '',
  [DataNames.CITY]: '',
  [DataNames.GENDER]: '',
  [DataNames.BIRTHDATE]: null,
  [DataNames.ADDRESS]: '',
  [DataNames.ROLEID]: '',
  [DataNames.SPECIALISTS]: undefined,
  [DataNames.COUNTRY]: '',
};

const initialError: IUserError = {
  [DataNames.FIRSTNAME]: { ...DEFAULT_VALIDATIONS_INFO },
  [DataNames.LASTNAME]: { ...DEFAULT_VALIDATIONS_INFO },
  // [DataNames.EMAIL]: { ...DEFAULT_VALIDATIONS_INFO },
  [DataNames.PHONE]: { ...DEFAULT_VALIDATIONS_INFO },
  // [DataNames.CITY]: { ...DEFAULT_VALIDATIONS_INFO },
  // [DataNames.GENDER]: { ...DEFAULT_VALIDATIONS_INFO },
  // [DataNames.BIRTHDATE]: { ...DEFAULT_VALIDATIONS_INFO },
  // [DataNames.ADDRESS]: { ...DEFAULT_VALIDATIONS_INFO },
  [DataNames.ROLEID]: { ...DEFAULT_VALIDATIONS_INFO },
  // [DataNames.SPECIALISTS]: { ...DEFAULT_VALIDATIONS_INFO },
  // [DataNames.COUNTRY]: { ...DEFAULT_VALIDATIONS_INFO },
};

export interface ICreateUserProps {
  permissions: IPermission;
  id?: number;
}

const genderData: Array<string> = [UserGender.MALE, UserGender.FEMALE];

export const medicalRole = Role.AGENT;
const defaultErrors = {
  [DataNames.USER_VERIFY_KEY]: { ...DEFAULT_VALIDATIONS_INFO },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CreateUser(props: ICreateUserProps) {
  const [rolesData, setRolesData] = useState<IRolePermission[]>([]);
  const [data, setData] = useState<IUserData>(initialData);
  const [error, setError] = useState<IUserError>(initialError);
  const [loading, setLoading] = useState<boolean>(false);
  const [userVerifyToken, setUserVerifyToken] = useState<string>('');
  const [userVerifyKey, setUserVerifyKey] = useState<string>('');
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [createOrder, setCreateOrder] = useState(false);
  const [value, setValue] = useState<string>(ResetPassword.SMS);
  const [agentAdditionalFields, setAgentAdditionalFields] = useState<boolean>(false);
  const [specializationList, setSpecializationList] = useState<{ type: string }[]>([]);
  const [errors, setErrors] = useState<IErrors>(defaultErrors);
  const [countriesData, setCountriesData] = useState<Array<{ name: string }>>([]);
  const dataProvider = useDataProvider();
  const [country, setCountry] = useState<{}>();
  const { permissions } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const { state } = useLocation();
  const history = useHistory();

  const fromQuickSearch = (state as any).fromQuickSearch;

  useEffect(() => {
    dataProvider
      .getList(RequestResource.ROLE, {} as any)
      .then((res) => {
        const newResult = res.data.filter((el) => {
          const currentUserRole = getStorageService().get(LocalStorageKey.ROLE) || '';
          return hasHigherRoleThan(JSON.parse(currentUserRole) as Role, el.name);
        });
        setRolesData(newResult as IRolePermission[]);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    dataProvider
      .getList(RequestResource.USER_SPECIALIZATION_LIST, {} as any)
      .then((res) => {
        setSpecializationList(res.data as any);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    const countriesList = Object.values(countries.countries);
    const countriesSortedList = countriesList.sort((a: { name: string }, b: { name: string }) => {
      return a.name > b.name ? 1 : -1;
    });
    setCountriesData(countriesSortedList);

    const defaultCountry = countriesSortedList?.find(({ name }) => name === 'Armenia');
    setCountry(defaultCountry);
    setData({ ...data, [DataNames.COUNTRY]: defaultCountry?.name || '' });
  }, []);

  useEffect(() => {
    if (fromQuickSearch) {
      setData((data) => ({ ...data, [DataNames.ROLEID]: 6 }));
    }
  }, []);

  const defaultProps = {
    options: countriesData,
    getOptionLabel: (option: any) => option.name,
  };

  const getValidationTypes = (name: DataNames) => {
    const validationTypes = [ValidationType.REQUIRED];
    let config = {};
    if (name === DataNames.FIRSTNAME || name === DataNames.LASTNAME) {
      validationTypes.push(ValidationType.MIN_LENGTH, ValidationType.MAX_LENGTH);
      config = {
        minLength: 3,
        maxLength: 30,
      };
    }
    // if (name === DataNames.EMAIL) {
    //   validationTypes.push(ValidationType.EMAIL);
    //   config = {};
    // }
    if (name === DataNames.PHONE) {
      validationTypes.push(ValidationType.PHONE);
      config = {
        minLength: 11,
      };
    }
    // if (name === DataNames.ADDRESS) {
    //   validationTypes.push(ValidationType.MIN_LENGTH);
    //   config = {
    //     minLength: 3,
    //   };
    // }
    // if (name === DataNames.CITY) {
    //   validationTypes.push(ValidationType.MIN_LENGTH);
    //   config = {
    //     minLength: 3,
    //   };
    // }
    return {
      validationTypes,
      config,
    };
  };

  const handleChangeData = (target: { name?: string; value: string | number | Date | null }) => {
    const { value, name } = target;
    const { validationTypes, config } = getValidationTypes(name as DataNames);

    if (name === DataNames.ROLEID) {
      const role = rolesData.find((el: { id: number; name: string }) => el.id === value)?.name || '';
      if (role === medicalRole) {
        setAgentAdditionalFields(true);
      } else {
        // newErrors = {
        //   ...newErrors,
        //   [DataNames.SPECIALISTS]: { ...DEFAULT_VALIDATIONS_INFO },
        // };
        setAgentAdditionalFields(false);
      }
    }

    setData({ ...data, [name as string]: value });

    if (name && initialError[name as string]) {
      let newErrors = {
        ...error,
        [name as string]: validate(value as FieldValue, validationTypes, name as string, config),
      };
      setError(newErrors);
    }
  };

  const handleClick = () => {
    const specialistData = data[DataNames.SPECIALISTS] as string[] | undefined;
    const checkedSpecializations = specialistData?.map((el: string) => {
      return specializationList.find((data: { type: string }) => data.type === el);
    });
    const newData = agentAdditionalFields
      ? {
          ...data,
          [DataNames.BIRTHDATE]: (data[DataNames.BIRTHDATE] as Date)?.toISOString() || '',
          [DataNames.SPECIALISTS]: checkedSpecializations,
        }
      : {
          ...data,
          [DataNames.BIRTHDATE]: (data[DataNames.BIRTHDATE] as Date)?.toISOString() || '',
        };
    const keys = Object.keys(error);
    let newErrors = { ...error };
    for (const key of keys) {
      const value = (newData as any)[key];
      const { validationTypes, config } = getValidationTypes(key as DataNames);
      newErrors = {
        ...newErrors,
        [key]: validate(value as FieldValue, validationTypes, key as string, config),
      };
    }
    // const role = rolesData.find((el: { id: number; name: string }) => el.id === data.roleId)?.name || '';
    // if (role !== medicalRole) {
    //   newErrors = {
    //     ...newErrors,
    //     [DataNames.SPECIALISTS]: { ...DEFAULT_VALIDATIONS_INFO },
    //   };
    // }

    if (isValid(newErrors)) {
      setShowPopup(!showPopup);
    }
    setError(newErrors);
  };

  const handleSaveAndCreateOrder = () => {
    setCreateOrder(true);
    handleClick();
  };

  const handleAddUser = () => {
    const specialistData = data[DataNames.SPECIALISTS] as string[] | undefined;
    const checkedSpecializations = specialistData
      ?.map((el: string) => {
        return specializationList.find((data: { type: string }) => data.type === el);
      })
      .map((s: any) => ({ id: s.id }));
    const newData = agentAdditionalFields
      ? {
          ...data,
          [DataNames.BIRTHDATE]: (data[DataNames.BIRTHDATE] as Date)?.toISOString() || '',
          [DataNames.SPECIALISTS]: checkedSpecializations,
        }
      : {
          ...data,
          [DataNames.BIRTHDATE]: (data[DataNames.BIRTHDATE] as Date)?.toISOString() || '',
        };
    setLoading(true);

    Object.keys(newData).map((key) => {
      // @ts-ignore
      if (!newData[key]) {
        // @ts-ignore
        delete newData[key];
      }
    });

    dataProvider
      .create(RequestResource.USER, { data: { ...newData, verifyBy: value } } as any)
      .then((res) => {
        if (createOrder) {
          history.push('/order/create?userId=' + res.data.id);
        } else {
          handleClose();
        }
        // console.log(newData);
        // if(newData !== ResetPassword.AUTO) {
        //   setUserVerifyToken(res.data?.userVerifyToken);
        // }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    const keys = Object.keys(errors);
    let newErrors = { ...errors };

    for (const key of keys) {
      const value = userVerifyKey;
      newErrors = {
        ...newErrors,
        [key]: validate(value, [ValidationType.REQUIRED, ValidationType.MIN_LENGTH], key, { minLength: 6 }),
      };
    }
    setErrors(newErrors);
    if (!isValid(newErrors)) {
      return;
    }
    authProvider
      .verifyUserRegister({
        userVerifyKey: userVerifyKey,
        userVerifyToken: userVerifyToken,
        verifyBy: value,
      })
      .then((res) => {
        setShowPopup(!showPopup);
        setUserVerifyToken('');
        handleClose();
      })
      .catch((err) => {});
  };

  const toggleDialog = (showPopup: boolean) => {
    setShowPopup(showPopup);
    setErrors(defaultErrors);
    setUserVerifyKey('');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
  };

  const handleUserVerifyKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setUserVerifyKey(value);
    const newErrors = {
      ...errors,
      [name]: validate(value, [ValidationType.REQUIRED, ValidationType.MIN_LENGTH], name, { minLength: 6 }),
    };
    setErrors(newErrors);
  };

  const handleClose = () => {
    history.push(`/${RequestResource.USER}`);
  };

  if (loading) return <Loading />;

  return (
    <div className="create-user">
      <div className="create-user-form">
        <Typography variant="h5">User create</Typography>
        <Divider className="divider" />
        <div className="create-user-data-container">
          <div className="create-user-data">
            <Input
              id="firstName-input"
              required={true}
              variant="standard"
              name={DataNames.FIRSTNAME}
              label="First name"
              value={data[DataNames.FIRSTNAME]}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
              errorInfo={error[DataNames.FIRSTNAME]}
            />
            <Input
              id="lastName-input"
              required={true}
              variant="standard"
              name={DataNames.LASTNAME}
              label="Last name"
              value={data[DataNames.LASTNAME]}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
              errorInfo={error[DataNames.LASTNAME]}
            />
            <Input
              id="email-input"
              variant="standard"
              name={DataNames.EMAIL}
              label="Email"
              value={data[DataNames.EMAIL]}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
              autoComplete="off"
            />
            <Input
              id="phone-input"
              required={true}
              variant="standard"
              name={DataNames.PHONE}
              label="Phone"
              value={data[DataNames.PHONE]}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
              errorInfo={error[DataNames.PHONE]}
            />
            <DatePicker
              id="birthdate-date-picker"
              inputVariant="standard"
              label="Birth date"
              format={dateFormat}
              value={data[DataNames.BIRTHDATE] || null}
              inputProps={{
                disabled: true,
              }}
              handleChange={(date: Date) => handleChangeData({ name: DataNames.BIRTHDATE, value: date })}
              maxDate={getMaxDate()}
            />

            <FormControl error={!!error?.password?.error}>
              <InputLabel htmlFor="password-input">Password*</InputLabel>
              <InputField
                id="password-input"
                type={showPassword ? DataNames.TEXT : DataNames.PASSWORD}
                name={DataNames.PASSWORD}
                value={data[DataNames.PASSWORD]}
                onChange={(e) => handleChangeData(e.target)}
                inputProps={{
                  autoComplete: 'off',
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="password-error-text">{error?.password?.message}</FormHelperText>
            </FormControl>
          </div>
          <div className="create-user-data">
            <FormControl>
              <InputLabel id="gender-input-label">Gender</InputLabel>
              <Select
                labelId="gender-select-label"
                id="gender-select"
                name={DataNames.GENDER}
                value={data[DataNames.GENDER]}
                onChange={(e) => handleChangeData(e.target as any)}
              >
                {genderData.map((el: string, index: number) => (
                  <MenuItem value={el} key={index}>
                    {capitalizeFirstLetter(el)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={true}>{error?.gender?.message}</FormHelperText>
            </FormControl>
            {countriesData?.length && (
              <Autocomplete
                {...defaultProps}
                id="country-input"
                value={country}
                onChange={(event: any, country: any) => {
                  setCountry(country);
                  // const newErrors = {
                  //   ...error,
                  //   [DataNames.COUNTRY]: validate(country?.name || '', [ValidationType.REQUIRED], DataNames.COUNTRY, {}),
                  // };
                  // setError(newErrors);
                  setData({ ...data, [DataNames.COUNTRY]: country?.name || '' });
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    error={!!error?.country?.error}
                    label="Country"
                    helperText={error?.country?.message}
                  />
                )}
              />
            )}
            <Input
              id="city-input"
              variant="standard"
              name={DataNames.CITY}
              label="City"
              value={capitalizeFirstLetter(data[DataNames.CITY])}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
            />
            <Input
              id="address-input"
              variant="standard"
              name={DataNames.ADDRESS}
              label="Address"
              value={capitalizeFirstLetter(data[DataNames.ADDRESS])}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
            />
            <FormControl error={!!error?.roleId?.error}>
              <InputLabel id="role-input-label">Role*</InputLabel>
              <Select
                labelId="role-select-label"
                id="role-select"
                name={DataNames.ROLEID}
                value={data[DataNames.ROLEID]}
                color="primary"
                onChange={(e) => handleChangeData(e.target as any)}
              >
                {rolesData?.map((data) => {
                  return (
                    <MenuItem key={data.id} value={data.id}>
                      {removeSymbolAndCapitalize(data.name)}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error={true}>{error?.roleId?.message}</FormHelperText>
            </FormControl>
            {agentAdditionalFields ? (
              <FormControl error={!!error?.specialists?.error}>
                <InputLabel id="specialist-input-label">Specialist*</InputLabel>
                <Select
                  labelId="specialist-select-label"
                  id="specialist-multiple-checkbox"
                  name={DataNames.SPECIALISTS}
                  multiple
                  value={data[DataNames.SPECIALISTS] || []}
                  onChange={(e) => handleChangeData(e.target as any)}
                  input={<InputField />}
                  renderValue={(selected: any) => selected.map(capitalizeFirstLetter).join(', ')}
                  MenuProps={MenuProps}
                >
                  {specializationList.map((item: { type: string }, index: number) => {
                    const specialistData = data[DataNames.SPECIALISTS] as string[] | undefined;
                    return (
                      <MenuItem key={index} value={item.type}>
                        <Checkbox checked={!!specialistData?.find((elem: any) => item.type === elem)} />
                        <ListItemText primary={t(item.type as T)} />
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText error={true}>{error?.specialists?.message}</FormHelperText>
              </FormControl>
            ) : null}
          </div>
        </div>
        {!showPopup ? (
          <Card className="card">
            <CardActions className="card-actions">
              <div>
                {hasPermission(permissions, RequestResource.USER, PermissionLevel.CREATE) ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setCreateOrder(false);
                        handleClick();
                      }}
                      className="create-user-button"
                      startIcon={<SaveIcon />}
                      style={{ marginRight: 15 }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveAndCreateOrder}
                      className="create-user-button"
                    >
                      Save & create order
                    </Button>
                  </>
                ) : null}
                <Button variant="contained" color="primary" onClick={handleClose} className="cancel-user-button">
                  Cancel
                </Button>
              </div>
            </CardActions>
          </Card>
        ) : (
          <Dialog maxWidth="xs" onClose={() => toggleDialog(false)} classes={['crc-create-user-dialog']}>
            <DialogTitle title={userVerifyToken ? 'Verify your account' : 'Account verification'} />
            <DialogContent>
              {userVerifyToken ? (
                <Input
                  id="userVerifyKey-input"
                  required={true}
                  variant="standard"
                  name={DataNames.USER_VERIFY_KEY}
                  label="User Verify Key"
                  value={userVerifyKey}
                  handleChange={handleUserVerifyKey}
                  errorInfo={errors[DataNames.USER_VERIFY_KEY]}
                />
              ) : (
                <FormControl component="fieldset">
                  <FormLabel component="legend"></FormLabel>
                  <RadioGroup aria-label="send-key" name="email-phone" value={value} onChange={handleChange}>
                    <FormControlLabel value={ResetPassword.EMAIL} control={<Radio />} label="Send to E-mail" />
                    <FormControlLabel value={ResetPassword.SMS} control={<Radio />} label="Send to Mobile" />
                    <FormControlLabel
                      value={ResetPassword.AUTO}
                      control={<Radio />}
                      label="Set as Verified"
                      className="text-red"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </DialogContent>
            <DialogActions>
              {userVerifyToken ? (
                <Button variant="contained" color="primary" className="submit-user-button" onClick={handleSubmit}>
                  Submit
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={handleAddUser}>
                  Continue
                </Button>
              )}
              <Button variant="contained" color="primary" onClick={() => toggleDialog(false)}>
                Cancel
              </Button>
              <DeleteButton />
            </DialogActions>
          </Dialog>
        )}
      </div>
    </div>
  );
}
