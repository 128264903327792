import React, { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  TextInput,
  BulkDeleteButton,
  useListContext,
  ExportButton,
  downloadCSV,
  FunctionField,
  SelectInput,
} from 'react-admin';
import { RequestResource, PermissionLevel, IPermission, Role, NOTIFICATION_TEMPLATE_TYPES } from '../../types';
import { capitalizeFirstLetter, formatDate, hasPermission, removeSymbolAndCapitalize } from '../../utils';

// @ts-ignore
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import dataProvider from '../../dataProvider';
import { Modal } from 'antd';
import { Button } from '@material-ui/core';

export interface IListTemplateProps {
  permissions: IPermission;
  currentUserRole: string;
}

const FilterBar = (props: any) => {
  const { setFilters } = useListContext();
  const [actionsList, setActionsList] = useState<string[]>([]);
  useEffect(() => {
    return () => {
      setFilters({}, []);
    };
  }, []);

  useEffect(() => {
    dataProvider
      .getList(RequestResource.NOTIFICATION_TEMPLATE_ACTIONS, {} as any)
      .then((res) => {
        let newActionsData = Object.values(res.data);
        newActionsData.unshift('all');
        setActionsList(newActionsData as string[]);
      })
      .catch(() => { });
  }, []);

  let types = [...NOTIFICATION_TEMPLATE_TYPES];
  types.unshift('all');

  const searchFilter = 'id~=|action~like|type~like';

  return (
    <Filter {...props}>
      <TextInput className={'default_search_input'} label="Search" source={searchFilter} alwaysOn />
      <SelectInput
        label="Form"
        source="action"
        alwaysOn
        choices={actionsList.map((action: string) => ({
          id: action,
          name: removeSymbolAndCapitalize(action),
        }))}
        allowEmpty={false}
      />
      <SelectInput
        label="Type"
        source="type"
        alwaysOn
        choices={types.map((type: string) => ({
          id: type,
          name: capitalizeFirstLetter(type),
        }))}
        allowEmpty={false}
      />
    </Filter>
  );
};

export default function ListTemplate(props: IListTemplateProps) {
  const { permissions, currentUserRole } = props;
  const [contentModalVal, setContentModalVal] = useState('')

  const exporter = (orders: any, selectedIds: any) => {
    const templatesForExport = Object.values(orders).filter((order: any) => {
      return selectedIds.includes(order.id);
    });
    const csv = convertToCSV({
      data: templatesForExport,
    });
    downloadCSV(csv, 'templates');
  };

  const canDelete = hasPermission(permissions, RequestResource.BUNDLE, PermissionLevel.DELETE);
  const BulkActionButtons = (props: any) => {
    const { selectedIds } = props;
    const { total, data } = useListContext();
    return (
      <React.Fragment>
        {canDelete && <BulkDeleteButton {...props} />}
        {[Role.ADMIN, Role.SUPER_ADMIN].includes(currentUserRole as Role) && (
          <ExportButton disabled={!total} onClick={() => exporter(data, selectedIds)} />
        )}
      </React.Fragment>
    );
  };

  return (
    <>
    <List
      {...props}
      className="list-template"
      filterDefaultValues={{ type: '', q: '', form: '' }}
      filters={<FilterBar />}
      exporter={false}
      bulkActionButtons={<BulkActionButtons />}
    >
      <Datagrid
        isRowSelectable={() => canDelete}
        isRowExpandable={(row: any) => !!row.services?.length}
        className="react-admin-table"
      >
        <TextField source="id" />
        <FunctionField sortBy="createdAt" label="Created At" render={(record: any) => formatDate(record.createdAt)} />
        <FunctionField
          sortBy="action"
          label="Form"
          render={(record: any) => removeSymbolAndCapitalize(record.action)}
        />
        <FunctionField
          sortBy="content"
          label="Content"
          render={(record: any) => {

            return (
              <Button
                onClick={() => setContentModalVal(record.content)}
                style={{textTransform: 'none'}}
              >
                {record.content.trim().slice(0, 25)}
                {record.content.length > 25 ? '...' : ''}
              </Button>
            )
          }}
          style={{cursor: 'pointer'}}
        />
        <FunctionField sortBy="type" label="Type" render={(record: any) => capitalizeFirstLetter(record.type)} />
        {hasPermission(permissions, RequestResource.NOTIFICATION_TEMPLATE, PermissionLevel.UPDATE) ? (
          <EditButton />
        ) : null}
      </Datagrid>
    </List>

    <Modal title="Content" visible={!!contentModalVal} onCancel={() => setContentModalVal('')} footer={null}>
      <div dangerouslySetInnerHTML={{__html: contentModalVal}}/>
    </Modal>
    </>

  );
}
