import { connect } from 'react-redux';
import ListTemplate from '../../components/templates/ListTemplate';
import { IAppState, IAppDispatch } from '../../types';

const mapStateToProps = (state: IAppState) => ({
  currentUserRole: state.auth.role,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListTemplate);
