import { IPermission, PermissionLevel, RequestResource, Role, RoleRate } from '../types';

export function hasPermission(permissions: IPermission, resource: RequestResource, level: PermissionLevel): boolean {
  return permissions && permissions[resource] ? permissions[resource][level] : false;
}

export function hasSomePermission(permissions: IPermission, resource: RequestResource): boolean {
  return permissions && permissions[resource] ? Object.values(permissions[resource])?.some((it: boolean) => it) : false;
}

export function hasHigherRoleThan(role: Role, targetRole: Role) {
  return !Object.keys(roleRateMapper).includes(targetRole) || roleRateMapper[role] >= roleRateMapper[targetRole];
}

const roleRateMapper = {
  [Role.SUPER_ADMIN]: RoleRate.SUPER_ADMIN,
  [Role.ADMIN]: RoleRate.ADMIN,
  [Role.OPERATOR]: RoleRate.OPERATOR,
  [Role.AGENT]: RoleRate.AGENT,
  [Role.CLIENT]: RoleRate.CLIENT,
  [Role.CONTENT_WRITER]: RoleRate.CONTENT_WRITER,
};
