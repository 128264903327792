export enum SettingFields {
  DISTANCE_PRICE = 'distancePrice',
  FREE_DISTANCE = 'freeDistance',
  APPOINTMENT_DATE_RANGE = 'appointmentDateRange',
  EXTRA_FEE_PRICE = 'extraFeePrice',
  EXTRA_FEE_PRICE_LIMIT = 'extraFeePriceLimit',
  CONTACT_ADDRESS = 'contactAddress',
  CONTACT_EMAIL = 'contactEmail',
  CONTACT_PHONE = 'contactPhone',
  CONTACT_GEOCOORDINATES = 'contactGeocoordinates',
}

export interface ISettings {
  distancePrice: string;
  freeDistance: string;
  appointmentDateRange: string;
  extraFeePrice: string;
  extraFeePriceLimit: string;
  contactAddress: string;
  contactEmail: string;
  contactPhone: string;
  contactGeocoordinates: string;
}
