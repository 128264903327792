export interface ILanguage {
  name: string;
  code: string;
  description: string;
  shortCode: string;
  isDefault: boolean;
  id: string;
}

export enum LanguageField {
  CODE = 'code',
  SHORT_CODE = 'shortCode',
  LABEL = 'label',
  FONT = 'font',
}

export interface IInitialData {
  [LanguageField.CODE]: string;
  [LanguageField.SHORT_CODE]: string;
  [LanguageField.LABEL]: string;
  [LanguageField.FONT]?: string;
}
