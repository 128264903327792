import { connect } from 'react-redux';
import CreateTemplate from '../../components/templates/CreateTemplate';
import { IAppState, IAppDispatch } from '../../types';
import { bindActionCreators } from 'redux';
import { showNotification } from 'react-admin';

const mapStateToProps = (state: IAppState) => ({});

const mapDispatchToProps = (dispatch: IAppDispatch) =>
  bindActionCreators(
    {
      showNotification,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplate);
