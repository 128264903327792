import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  Filter,
  TextInput,
  BulkDeleteButton,
  downloadCSV,
  useListContext,
  ExportButton,
} from 'react-admin';
import { booleanToYesOrNo, formatCurrency, hasPermission } from '../../utils';
import { RequestResource, PermissionLevel, IPermission, Role } from '../../types';

// @ts-ignore
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';


export interface IListServiceProps {
  permissions: IPermission;
  currentUserRole: string;
}

const FilterBar = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput className={'default_search_input'} label="Search" source="id~=|name~like|price~like|category^name~like|createdByName~like|description~like" alwaysOn />
    </Filter>
  )
};

export default function ListService(props: IListServiceProps) {
  const { permissions, currentUserRole } = props;
  const canDelete = hasPermission(permissions, RequestResource.CATEGORY, PermissionLevel.DELETE);

  const exporter = (orders: any, selectedIds: any) => {
    const servicesForExport = Object.values(orders).filter((order: any) => {
      return selectedIds.includes(order.id);
    });

    const csv = convertToCSV({
      data: servicesForExport,
      fields: ['id', 'icon', 'name', 'categoryId', 'description', 'price'],
    });
    downloadCSV(csv, 'services');
  };

  const BulkActionButtons = (props: any) => {
    const { selectedIds } = props;
    const { total, data } = useListContext();

    return (
      <React.Fragment>
        {canDelete && <BulkDeleteButton {...props} />}
        {[Role.ADMIN, Role.SUPER_ADMIN].includes(currentUserRole as Role) && (
          <ExportButton disabled={!total} onClick={() => exporter(data, selectedIds)} />
        )}
      </React.Fragment>
    );
  };

  return (
    <List
      {...props}
      className="services-list"
      exporter={false}
      filters={<FilterBar />}
      bulkActionButtons={<BulkActionButtons />}
    >
      <Datagrid isRowSelectable={() => canDelete} className="react-admin-table">
        <TextField source="id" />
        <FunctionField sortBy={'name'} label="Name" render={(record: any) => record.name} />
        <FunctionField sortBy={'description'} label="Description" render={(record: any) => record.description} />
        <FunctionField sortBy={'price'} label="Price App" render={(record: any) => formatCurrency(record.price)} />
        <FunctionField sortBy={'priceClinic'} label="Price Clinic" render={(record: any) => formatCurrency(record.priceClinic)} />
        <FunctionField sortBy={'category'} label="Category" render={(record: any) => record.category?.name} />
        <FunctionField source="isPublic" render={(record: any) => booleanToYesOrNo(record.isPublic)} />
        <FunctionField
          sortBy={'createdByName'}
          label="Created By"
          render={(record: any) => (
              record?.createdById ? (
                <Link to={`/${RequestResource.USER}/${record.createdById}`}>{record?.createdByName || ''}</Link>
              ) : (
                record?.createdByName || ''
              )
            )
          }
        />
        {hasPermission(permissions, RequestResource.SERVICE, PermissionLevel.UPDATE) ? (
          <Tooltip
            placement="top"
            title="Edit Service"
          >
            <EditButton label="" />
          </Tooltip>
        ) : null}
      </Datagrid>
    </List>
  );
}
