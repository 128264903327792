import { connect } from 'react-redux';
import ShowPayment from '../../components/payments/ShowPayment';
import { IAppState, IAppDispatch } from '../../types';

const mapStateToProps = (state: IAppState) => ({
  paymentData: state.admin?.resources?.payment?.data
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ShowPayment);
