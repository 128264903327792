import { applyMiddleware, compose, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import { IAppState } from './types';
import createRootReducer from './reducers';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory();

/**
 * Declare extensions for integrating ReduxDevTool into browser.
 * Will be used during the development for debugging purposes.
 * https://github.com/zalmoxisus/redux-devtools-extension
 */
declare var window: {
  __REDUX_DEVTOOLS_EXTENSION__: any;
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
};

const devTool = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

/**
 * Redux store configurator function.
 * The returned result should be passed to Provider's store prop.
 */
export default function configureStore(): Store<IAppState | {}> {
  /**
   * Include thunk middleware for handling asynchronous actions.
   */
  const middlewares = [thunk];

  const composeEnhancers = devTool ? devTool(REDUX_DEV_TOOL_OPTIONS) : compose;

  return createStore(
    createRootReducer(history), // root reducer with router state
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middlewares
      )
    )
  );
}

/**
 * Options for ReduxDevTool browser extension.
 */
const REDUX_DEV_TOOL_OPTIONS = {
  serialize: {
    replacer: (k: any, v: any) => (isTypedArray(v) && v.length ? '<<typed array>>' : v),
  },
};

function isTypedArray(x: any): boolean {
  return x instanceof Uint8Array || x instanceof Uint16Array || x instanceof Float32Array || x instanceof Float64Array;
}
