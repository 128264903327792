import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { adminReducer } from 'react-admin';
import { History } from 'history';
import { auth } from './auth';
import { order } from './order';
import { IAppState } from '../types';
import { localization } from './localization';
import { user } from './user';

/**
 * The combined reducers object which is being passed to Redux when creating a store.
 */
const createRootReducer = (history: History) =>
  combineReducers<IAppState>({
    admin: adminReducer,
    router: connectRouter(history),
    auth,
    order,
    localization,
    user,
  });

export default createRootReducer;
