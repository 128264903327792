import { BaseAPIService } from './BaseAPIService';
import { IUserNotification } from '../../types/data/userNotification';

export class UserNotificationAPIService extends BaseAPIService {
  constructor() {
    super('');
  }

  public async getList({ query, filter }: any): Promise<any> {
    query = query?.length ? `?${query}` : '';
    return this.get(`admin/user-notification${query}&projection=user`);
  }

  public async getOne(id: number): Promise<any> {
    return this.get(`admin/user-notification/${id}`);
  }

  public async create(data: IUserNotification): Promise<any> {
    return this.post(`admin/user-notification`, data);
  }

  public async update(data: IUserNotification, id: number): Promise<any> {
    return this.put(`admin/user-notification/${id}`, data);
  }

  public async remove(id: number): Promise<any> {
    return this.delete(`admin/user-notification/${id}`);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(`admin/user-notification`, data);
  }
}
