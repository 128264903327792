import { handleActions, Action } from 'redux-actions';
import { ILocalizationState } from '../types';
import { LOAD_TRANSLATIONS, LOAD_TRANSLATIONS_FAILURE, LOAD_TRANSLATIONS_SUCCESS } from '../actions/localization';

const DEFAULT_STATE: ILocalizationState = {
  loading: false,
  translations: {},
};

export const localization = handleActions<ILocalizationState, any>(
  {
    [LOAD_TRANSLATIONS]: (state: ILocalizationState): ILocalizationState => {
      return {
        ...state,
        loading: true,
      };
    },
    [LOAD_TRANSLATIONS_FAILURE]: (state: ILocalizationState): ILocalizationState => {
      return {
        ...state,
        loading: false,
      };
    },
    [LOAD_TRANSLATIONS_SUCCESS]: (
      state: ILocalizationState,
      action: Action<LOAD_TRANSLATIONS_SUCCESS>
    ): ILocalizationState => {
      return {
        ...state,
        loading: false,
        translations: action.payload,
      };
    },
  },
  DEFAULT_STATE
);
