import { BaseAPIService } from './BaseAPIService';
import { IService } from '../../types';

export class OrdersAPIService extends BaseAPIService {
  constructor() {
    super('admin');
  }

  public async getList({ query, filter }: any): Promise<any> {
    query = query?.length ? `&${query}` : '';
    const newQuery = `?projection=patient,user,user.patients,statusTransition,orderService,orderService.users,orderService.attachments,orderService.service,orderService.statusTransitions,payment${query}`;
    return this.get(`/order${newQuery}`);
  }

  public async getOne(id: number): Promise<any> {
    return this.get(
      `/order/${id}?prefix=order&projection=patient,user,user.patients,statusTransition,orderService,orderService.users,orderService.attachments,orderService.service,orderService.statusTransitions,payment`
    );
  }

  public async create(data: IService): Promise<any> {
    return this.post(`/order`, data);
  }

  public async update(data: IService, id: number): Promise<any> {
    return this.put(`/order/${id}`, data);
  }

  public async remove(id: number): Promise<any> {
    return this.delete(`/order/${id}`);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(`/order`, data);
  }

  public async removeOrderServiceAttachment(orderId: number, attachmentId: number): Promise<any> {
    return this.delete(`/order-service/${orderId}/attachment/${attachmentId}`);
  }

  public async saveOrderServiceAttachments(orderId: number, files: any): Promise<any> {
    return this.post(`/order-service/${orderId}/attachment`, files);
  }

  public async getOrderService(id: number): Promise<any> {
    return this.get(
      `/order-service/${id}?prefix=orderService&projection=user,attachment,service,statusTransition,order,patient`
    );
  }

  public async saveOrderService(id: number, data: any): Promise<any> {
    return this.put(`/order-service/${id}`, data);
  }

  public async calculateOrderPrice(data: any): Promise<any> {
    return this.post(`/order/price`, data);
  }
}
