import React, { useEffect, useState } from 'react';
import {
  BundleField,
  IErrors,
  IInputField,
  IPermission,
  PermissionLevel,
  RequestResource,
  SettingFields,
  ValidationType,
} from '../../types';
import { DEFAULT_VALIDATIONS_INFO, hasPermission, isValid, validate } from '../../utils';
import Input from '../core/input/Input';
import { Loading, useDataProvider } from 'react-admin';
import { GetListParams } from 'ra-core';
import { Button, Card, CardActions, FormControl } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

interface IListSettingsProps {
  permissions: IPermission;
}

export default function ListSettings(props: IListSettingsProps) {
  const { permissions } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [id, setId] = useState<number | null>(null);
  const [values, setValues] = useState<IInputField>({
    [SettingFields.DISTANCE_PRICE]: '',
    [SettingFields.FREE_DISTANCE]: '',
    [SettingFields.APPOINTMENT_DATE_RANGE]: '',
    [SettingFields.EXTRA_FEE_PRICE]: '',
    [SettingFields.EXTRA_FEE_PRICE_LIMIT]: '',
    [SettingFields.CONTACT_ADDRESS]: '',
    [SettingFields.CONTACT_EMAIL]: '',
    [SettingFields.CONTACT_PHONE]: '',
    [SettingFields.CONTACT_GEOCOORDINATES]: '',
  });
  const [errors, setErrors] = useState<IErrors>({
    [SettingFields.DISTANCE_PRICE]: DEFAULT_VALIDATIONS_INFO,
    [SettingFields.FREE_DISTANCE]: DEFAULT_VALIDATIONS_INFO,
    [SettingFields.APPOINTMENT_DATE_RANGE]: DEFAULT_VALIDATIONS_INFO,
    [SettingFields.EXTRA_FEE_PRICE]: DEFAULT_VALIDATIONS_INFO,
    [SettingFields.EXTRA_FEE_PRICE_LIMIT]: DEFAULT_VALIDATIONS_INFO,
    [SettingFields.CONTACT_ADDRESS]: DEFAULT_VALIDATIONS_INFO,
    [SettingFields.CONTACT_EMAIL]: DEFAULT_VALIDATIONS_INFO,
    [SettingFields.CONTACT_PHONE]: DEFAULT_VALIDATIONS_INFO,
    [SettingFields.CONTACT_GEOCOORDINATES]: DEFAULT_VALIDATIONS_INFO,
  });
  const dataProvider = useDataProvider();

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList(RequestResource.SETTING, {} as GetListParams)
      .then(({ data }) => {
        setLoading(false);
        const {
          distancePrice,
          freeDistance,
          appointmentDateRange,
          extraFeePrice,
          extraFeePriceLimit,
          contactAddress,
          contactEmail,
          contactPhone,
          contactGeocoordinates,
          id,
        } = (data as any)[0] || {};
        setId(id);
        setValues({
          distancePrice: distancePrice || '',
          freeDistance: freeDistance || '',
          appointmentDateRange: appointmentDateRange || '',
          extraFeePrice: extraFeePrice || '',
          extraFeePriceLimit: extraFeePriceLimit || '',
          contactAddress: contactAddress || '',
          contactEmail: contactEmail || '',
          contactPhone: contactPhone || '',
          contactGeocoordinates: contactGeocoordinates || '',
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, name: SettingFields) => {
    const { value } = e.target;
    const newErrors = {
      ...errors,
      [name]: validate(value, [ValidationType.REQUIRED, ValidationType.MIN_VALUE], name, { minValue: 0 }),
    };
    setValues({ ...values, [name]: value });
    setErrors(newErrors);
  };

  const checkValidationAndSave = () => {
    const keys = Object.keys(errors);
    let newErrors = { ...errors };

    for (const key of keys) {
      const value = values[key];
      newErrors = {
        ...newErrors,
        [key]: validate(value, [ValidationType.REQUIRED, ValidationType.MIN_VALUE], key, { minValue: 0 }),
      };
    }
    if (isValid(newErrors)) {
      handleSave();
    }
    setErrors(newErrors);
  };

  const handleSave = () => {
    if (id === null) {
      return;
    }
    dataProvider
      .update(RequestResource.SETTING, {
        id,
        data: { ...values },
      } as any)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const {
    freeDistance,
    distancePrice,
    appointmentDateRange,
    extraFeePrice,
    extraFeePriceLimit,
    contactAddress,
    contactEmail,
    contactPhone,
    contactGeocoordinates,
  } = values;
  if (loading) return <Loading />;

  return (
    <div className="list-settings">
      <FormControl className="settings-form">
        <div className="body">
          <Input
            required={true}
            label="Free Distance"
            type="number"
            value={freeDistance}
            errorInfo={errors[SettingFields.FREE_DISTANCE]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, SettingFields.FREE_DISTANCE)}
          />
          <Input
            required={true}
            label="Transportation Price per km"
            type="number"
            value={distancePrice}
            errorInfo={errors[SettingFields.DISTANCE_PRICE]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, SettingFields.DISTANCE_PRICE)}
          />
          <Input
            required={true}
            label="Minimal Appointment Date"
            type="number"
            value={appointmentDateRange}
            errorInfo={errors[SettingFields.APPOINTMENT_DATE_RANGE]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e, SettingFields.APPOINTMENT_DATE_RANGE)
            }
          />
          <Input
            required={true}
            label="Extra Fee Price"
            type="number"
            value={extraFeePrice}
            errorInfo={errors[SettingFields.EXTRA_FEE_PRICE]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e, SettingFields.EXTRA_FEE_PRICE)
            }
          />
          <Input
            required={true}
            label="Extra Fee Price Limit"
            type="number"
            value={extraFeePriceLimit}
            errorInfo={errors[SettingFields.EXTRA_FEE_PRICE_LIMIT]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e, SettingFields.EXTRA_FEE_PRICE_LIMIT)
            }
          />
          <Input
            required={true}
            label="Contact Us: Address"
            type="text"
            value={contactAddress}
            errorInfo={errors[SettingFields.CONTACT_ADDRESS]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e, SettingFields.CONTACT_ADDRESS)
            }
          />
          <Input
            required={true}
            label="Contact Us: Email"
            type="text"
            value={contactEmail}
            errorInfo={errors[SettingFields.CONTACT_EMAIL]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e, SettingFields.CONTACT_EMAIL)
            }
          />
          <Input
            required={true}
            label="Contact Us: Phone"
            type="text"
            value={contactPhone}
            errorInfo={errors[SettingFields.CONTACT_PHONE]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e, SettingFields.CONTACT_PHONE)
            }
          />
          <Input
            required={true}
            label="Contact Us: Geocoordinates (latitude, longitude)"
            type="text"
            value={contactGeocoordinates}
            errorInfo={errors[SettingFields.CONTACT_GEOCOORDINATES]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e, SettingFields.CONTACT_GEOCOORDINATES)
            }
          />
        </div>
        <Card className="card">
          <CardActions className="card-actions">
            {hasPermission(permissions, RequestResource.BUNDLE, PermissionLevel.UPDATE) ? (
              <Button variant="contained" color="primary" onClick={checkValidationAndSave} startIcon={<SaveIcon />}>
                Save
              </Button>
            ) : null}
          </CardActions>
        </Card>
      </FormControl>
    </div>
  );
}
