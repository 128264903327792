import { IStatusTagColor } from '../components';

export enum PaymentStatus {
  PENDING = 'pending',
  PAID = 'paid',
  NOT_PAID = 'not_paid',
  REFUNDED = 'refunded',
}

export type PaymentStatusColors = {
  [key in PaymentStatus]?: IStatusTagColor;
};


export enum PaymentTransitionFields {
  ID = 'id',
  STATUS = 'status',
  AUTHOR_NAME = 'authorName',
  DATE = 'date',
}
