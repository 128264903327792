import { IAppRouteKey, IAppRoutes } from '../types';

export const AppRoutes: IAppRoutes = {
  [IAppRouteKey.LOGIN]: {
    path: '/login',
  },
  [IAppRouteKey.MY_PROFILE]: {
    path: '/my-profile',
  },
  [IAppRouteKey.ORDER_SERVICE]: {
    path: '/order-service/:id',
  },
};
