export interface INotificationService {
  show: (notification: INotification) => void;
}

export interface INotification {
  type: NotificationType;
  message: any;
}

export enum NotificationType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}
