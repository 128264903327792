import React, { useEffect } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  FunctionField,
  Filter,
  TextInput,
  downloadCSV,
  useListContext,
  ExportButton,
  useRefresh,
  CheckboxGroupInput,
  CreateButton,
} from 'react-admin';
import { Button, Tooltip } from '@material-ui/core';
import { dateTimeFormatForMoment, ORDER_STATUS_COLORS, ORDER_PAYMENT_STATUS_COLORS } from '../../constants';
import StatusTag from '../core/status-tag/StatusTag';
import {
  RequestResource,
  PermissionLevel,
  IPermission,
  OrderStatus,
  OrderPaymentStatus,
  IStatusTagColor,
  OrderStatusFilter,
  OrderPaymentMethod,
  Role,
} from '../../types';
import {
  capitalizeFirstLetter,
  convertServerDateToLocal,
  formatCurrency,
  getYearsTillNow,
  hasPermission,
  removeSymbolAndCapitalize,
} from '../../utils';
// @ts-ignore
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import PhoneIcon from '@material-ui/icons/Phone';
import { Link } from 'react-router-dom';

export interface IListOrderProps {
  role: string;
  permissions: IPermission;
  orderCount: number;
}

export const statusOptions: OrderStatusFilter[] = [
  {
    name: OrderStatus.PENDING,
    id: OrderStatus.PENDING,
  },
  {
    name: OrderStatus.IN_PROGRESS,
    id: OrderStatus.IN_PROGRESS,
  },
  {
    name: OrderStatus.COMPLETED,
    id: OrderStatus.COMPLETED,
  },
  {
    name: OrderStatus.DONE,
    id: OrderStatus.DONE,
  },
  {
    name: OrderStatus.CLOSED,
    id: OrderStatus.CLOSED,
  },
  {
    name: OrderStatus.WAITING_FOR_PAYMENT,
    id: OrderStatus.WAITING_FOR_PAYMENT,
  },
  {
    name: OrderStatus.CANCELLED,
    id: OrderStatus.CANCELLED,
  },
];

const searchFilter =
  'id~=|address~like|user>id~=|user>firstName~like|user>lastName~like|user>email~like|user>phone~like';
const statusFilter = 'status~=';

const FilterBar = (props: any) => {
  const { setFilters } = useListContext();

  useEffect(() => {
    return () => setFilters({}, []);
  }, []);

  return (
    <Filter {...props}>
      <TextInput className={'default_search_input'} label="Search" source={searchFilter} resettable alwaysOn />
      <CheckboxGroupInput
        className="statuses-filter"
        alwaysOn
        label="Status"
        source={statusFilter}
        choices={statusOptions.map(({ id, name }: OrderStatusFilter) => ({
          name: removeSymbolAndCapitalize(name),
          id,
        }))}
      />
    </Filter>
  );
};

const Actions = (props: any) => {
  const { permissions } = props;
  const { hideFilter } = useListContext();

  return (
    <React.Fragment>
      <Button size="small" color="primary" onClick={() => hideFilter(statusFilter)}>
        Reset Status filter
      </Button>
      {hasPermission(permissions, RequestResource.ORDER, PermissionLevel.CREATE) ? <CreateButton /> : null}
    </React.Fragment>
  );
};

export default function ListOrder(props: IListOrderProps) {
  const { permissions, role, orderCount } = props;
  const refresh = useRefresh();

  useEffect(refresh, [orderCount]);

  const exporter = (orders: any, selectedIds: any) => {
    const ordersForExport = Object.values(orders).filter((order: any) => {
      return selectedIds.includes(order.id);
    });
    const csv = convertToCSV({
      data: ordersForExport,
      fields: ['id', 'status', 'diagnosis', 'address', 'city', 'country', 'appointmentDate', 'recommendation'],
    });
    downloadCSV(csv, 'orders');
  };

  const BulkActionButtons = (props: any) => {
    const { selectedIds } = props;
    const { total, data } = useListContext();
    return (
      <React.Fragment>
        <ExportButton disabled={!total} onClick={() => exporter(data, selectedIds)} />
      </React.Fragment>
    );
  };

  const getOrderAddress = (record: any) => {
    const { address, city, country } = record;
    return [address, city, country].filter((it: string) => it).join(', ');
  };

  const getAssignedServiceNames = (orderServices: any) => {
    return orderServices?.map(({ service }: any, i: number) => `${i + 1}. ${service?.name}`).join('\n') || '';
  };

  const getAssignedAgentNames = (agents: any) => {
    return agents?.map((agent: any) => `${agent.id} - ${agent.firstName}`).join('\n') || '';
  };

  const getAttachedAgents = (orderServices: any) => {
    const agents = [];
    for (let i = 0; i < orderServices.length; i++) {
      const users = orderServices[i].users || [];
      agents.push(...users);
    }
    return agents;
  };

  const getStatusInfo = (status: OrderStatus, statusTransitions: any) => {
    const transitionsLength = statusTransitions?.length || 0;
    const currentStatusTransitions = transitionsLength ? statusTransitions[transitionsLength - 1] : null;
    let title = '';

    if (currentStatusTransitions) {
      const date = currentStatusTransitions.date;
      title = convertServerDateToLocal(date, dateTimeFormatForMoment);
    }

    return {
      title,
    };
  };
  const getOrderPriceColor = (record: any) => {
    const status: OrderPaymentStatus = record?.payment?.status;

    return status ? ORDER_PAYMENT_STATUS_COLORS[status] : ORDER_PAYMENT_STATUS_COLORS[OrderPaymentStatus.UNPAID];
  };

  return (
    <List
      className="orders-list"
      filters={<FilterBar />}
      exporter={false}
      actions={<Actions permissions={permissions} />}
      bulkActionButtons={<BulkActionButtons />}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
    >
      <div className="order-list-container">
        <Datagrid className="react-admin-table">
          <TextField source="id" />
          <FunctionField
            sortBy="status"
            label="Status"
            render={(record: any) => {
              return (
                <StatusTag
                  styles={ORDER_STATUS_COLORS[record.status as OrderStatus] as IStatusTagColor}
                  label={removeSymbolAndCapitalize(record.status)}
                  tooltipText={getStatusInfo(record.status, record.statusTransitions).title}
                />
              );
            }}
          />
          <FunctionField
            sortBy="createdAt"
            label="Date/Time"
            render={(record: any) => convertServerDateToLocal(record.createdAt, dateTimeFormatForMoment)}
          />
          <FunctionField
            sortBy="user.firstName"
            label="First name"
            render={(record: any) => capitalizeFirstLetter(record?.user?.firstName)}
          />
          <FunctionField
            sortBy="user.lastName"
            label="Last name"
            render={(record: any) => capitalizeFirstLetter(record?.user?.lastName)}
          />
          <FunctionField
            sortBy="user.phone"
            label="Mobile"
            render={(record: any) => (
              <a href={`tel:${record?.user?.phone}`} className="phone">
                <Button variant="contained" color="primary" startIcon={<PhoneIcon />}>
                  {record?.user?.phone}
                </Button>
              </a>
            )}
          />
          <FunctionField label="Address" render={(record: any) => getOrderAddress(record)} />
          <FunctionField
            label="Services count"
            render={(record: any) => (
              <Tooltip
                PopperProps={{
                  className: 'tooltip',
                }}
                placement="left"
                className="service-indicator"
                title={getAssignedServiceNames(record.orderServices)}
              >
                <span>{record?.orderServices?.length}</span>
              </Tooltip>
            )}
          />
          <FunctionField
            label="Payment method"
            render={(record: any) => capitalizeFirstLetter(record.payment?.method || '')}
          />
          <FunctionField
            label="Price"
            render={(record: any) => (
              <StatusTag
                styles={getOrderPriceColor(record) as IStatusTagColor}
                label={formatCurrency(record?.payment?.totalPrice)}
              />
            )}
          />
          <FunctionField
            label="Agents count"
            render={(record: any) => {
              const agents = getAttachedAgents(record.orderServices);
              return (
                <Tooltip
                  PopperProps={{
                    className: 'tooltip',
                  }}
                  placement="left"
                  className="agents-indicator"
                  title={getAssignedAgentNames(agents)}
                >
                  <span>{agents.length}</span>
                </Tooltip>
              );
            }}
          />
          <FunctionField
            label="Patient age"
            render={(record: any) => {
              if (!record.patient?.birthDate) {
                return 'N/A';
              }
              return (
                <Tooltip
                  PopperProps={{
                    className: 'tooltip',
                  }}
                  placement="left"
                  className="patient-age"
                  title={convertServerDateToLocal(record.patient?.birthDate)}
                >
                  <span>{getYearsTillNow(record.patient?.birthDate, 'years')}</span>
                </Tooltip>
              );
            }}
          />
          <FunctionField
            label="Created By"
            render={(record: any) =>
              record?.createdById ? (
                <Link to={`/${RequestResource.USER}/${record.createdById}`}>{record?.createdByName || ''}</Link>
              ) : (
                record?.createdByName || ''
              )
            }
          />
          {hasPermission(permissions, RequestResource.ORDER, PermissionLevel.UPDATE) ? (
            <Tooltip placement="top" title="Edit Order">
              <EditButton label="" />
            </Tooltip>
          ) : null}
          {role === Role.AGENT && hasPermission(permissions, RequestResource.ORDER, PermissionLevel.READ) ? (
            <ShowButton />
          ) : null}
        </Datagrid>
      </div>
    </List>
  );
}
