import moment from 'moment';
import { dateFormatForMoment } from '../constants';
import { ScheduleOptions } from '../types';

export function convertLocalDateToServer(date: Date) {
  return moment(date).utc();
}

export function convertServerDateToLocal(date: Date, format: string = dateFormatForMoment) {
  return moment(date).format(format);
}

export function getMaxDate() {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date;
}

export function getYearsTillNow(date: Date, unitOfTime: any) {
  const endDate = moment([]);
  const startDate = moment(date);
  return endDate.diff(startDate, unitOfTime);
}

export function getPassedDateGraphic(date: Date, unitOfTimes: any[]) {
  const diffArr = [];

  for (let i = 0; i < unitOfTimes.length; i++) {
    diffArr.push(getYearsTillNow(date, unitOfTimes[i]));
  }
  return diffArr.join('/');
}

export function getMonthDays(yearData: Date | null, monthData: Date | null) {
  const now = new Date();
  const year = yearData?.getFullYear() || now.getFullYear();
  const month = (monthData?.getMonth() || now.getMonth()) + 1;
  return moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
}

export function getMinMonth(yearData: Date | null) {
  const date = new Date();
  if (yearData && date.getFullYear() === yearData.getFullYear()) {
    return date;
  }
}

export function getMinDay(yearData: Date | null, monthData: Date | null) {
  const date = new Date();
  if (
    yearData &&
    monthData &&
    date.getFullYear() === yearData.getFullYear() &&
    date.getMonth() === monthData.getMonth()
  ) {
    return date.getDate();
  }
}

export function getBirthDate(age: number) {
  const date = new Date();
  date.setFullYear(date.getFullYear() - age);
  return date.toISOString();
}
