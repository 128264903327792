import React, { useEffect } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  Filter,
  TextInput,
  BulkDeleteButton,
  useListContext,
  ExportButton,
  downloadCSV,
} from 'react-admin';
import { Link } from 'react-router-dom';
import Table from '../core/table/Table';
import { RequestResource, PermissionLevel, IPermission, ColumnType, ServiceColumn, Role, IRow } from '../../types';
import { booleanToYesOrNo, formatCurrency, hasPermission, removeSymbolAndCapitalize } from '../../utils';

// @ts-ignore
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { Tooltip } from '@material-ui/core';

export interface IListBundleProps {
  permissions: IPermission;
  currentUserRole: string;
}

const COLUMNS = [
  {
    field: ServiceColumn.ID,
    headerName: removeSymbolAndCapitalize(ServiceColumn.ID),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: ServiceColumn.NAME,
    headerName: removeSymbolAndCapitalize(ServiceColumn.NAME),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: ServiceColumn.DESCRIPTION,
    headerName: removeSymbolAndCapitalize(ServiceColumn.DESCRIPTION),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: ServiceColumn.PRICE,
    headerName: removeSymbolAndCapitalize(ServiceColumn.PRICE),
    editable: false,
    cellRenderer: (row: IRow) => formatCurrency(row.price as number),
    type: ColumnType.STRING,
  },
  {
    field: ServiceColumn.IS_PUBLIC,
    headerName: removeSymbolAndCapitalize(ServiceColumn.IS_PUBLIC),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: ServiceColumn.CREATED_BY,
    headerName: removeSymbolAndCapitalize(ServiceColumn.CREATED_BY),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: IRow) =>
      (row?.createdBy as any)?.id ? (
        <Link to={`/${RequestResource.USER}/${(row?.createdBy as any)?.id}`}>
          {(row?.createdBy as any)?.name || ''}
        </Link>
      ) : (
        (row?.createdBy as any)?.name || ''
      ),
  },
];

function Services(props: any) {
  const services = props.record.services || [];
  return <Table rows={services} columns={COLUMNS} />;
}

const FilterBar = (props: any) => {
  const { setFilters } = useListContext();
  useEffect(() => {
    return () => setFilters({}, []);
  }, []);

  return (<Filter {...props}>
    <TextInput className={'default_search_input'} label="Search" source="id~=|name~like|price~like|description~like" resettable alwaysOn />
  </Filter>)
};

export default function ListBundle(props: IListBundleProps) {
  const { permissions, currentUserRole } = props;

  const exporter = (orders: any, selectedIds: any) => {
    const ordersForExport = Object.values(orders).filter((order: any) => {
      return selectedIds.includes(order.id);
    });
    const csv = convertToCSV({
      data: ordersForExport,
      fields: ['id', 'icon', 'name', 'description', 'price'],
    });
    downloadCSV(csv, 'bundles');
  };

  const canDelete = hasPermission(permissions, RequestResource.BUNDLE, PermissionLevel.DELETE);
  const BulkActionButtons = (props: any) => {
    const { selectedIds } = props;
    const { total, data } = useListContext();
    return (
      <React.Fragment>
        {canDelete && <BulkDeleteButton {...props} />}
        {[Role.ADMIN, Role.SUPER_ADMIN].includes(currentUserRole as Role) && (
          <ExportButton disabled={!total} onClick={() => exporter(data, selectedIds)} />
        )}
      </React.Fragment>
    );
  };

  return (
    <List
      {...props}
      className="bundles-list"
      filters={<FilterBar />}
      exporter={false}
      bulkActionButtons={<BulkActionButtons />}
    >
      <Datagrid
        expand={<Services />}
        isRowSelectable={() => canDelete}
        isRowExpandable={(row: any) => !!row?.services?.length}
        className="react-admin-table"
      >
        <TextField source="id" />
        <FunctionField label="Name" render={(record: any) => record.name} />
        <FunctionField label="Description" render={(record: any) => record.description} />
        <FunctionField label="Price" render={(record: any) => formatCurrency(record.price)} />
        <FunctionField label="Bundle for" render={(record: any) => record.bundleFor === 1 ? 'App' : 'Clinic'} />
        <FunctionField source="isPublic" render={(record: any) => booleanToYesOrNo(record.isPublic)}/>
        <FunctionField
          label="Created By"
          render={(record: any) =>
            record?.createdById ? (
              <Link to={`/${RequestResource.USER}/${record.createdById}`}>
                {record?.createdByName || ''}
              </Link>
            ) : record?.createdByName || ''
          }
        />
        {hasPermission(permissions, RequestResource.BUNDLE, PermissionLevel.UPDATE) ? (
          <Tooltip
            placement="top"
            title="Edit Bundle"
          >
            <EditButton label="" />
          </Tooltip>
        ) : null}
      </Datagrid>
    </List>
  );
}
