import React, { useEffect, useState } from 'react';
import { useDataProvider, Loading, useRedirect } from 'react-admin';
import { GetListParams } from 'ra-core';
import { Button, Card, CardActions, Divider, TextField, Typography } from '@material-ui/core';
import Table from '../core/table/Table';
import {
  ColumnType,
  IField,
  IPermission,
  IPermissionLevel,
  IRolePermission,
  IRow,
  PermissionLevel,
  RequestResource,
} from '../../types';
import { findIndexInArray, removeSymbolAndCapitalize } from '../../utils';

export interface ICreateRolePermissionProps {
  id?: string;
}

const PERMISSION_NAME_COLUMN: string = 'name';
const IS_EDIT_MODE: string = 'isEditMode';

const COLUMNS = [
  {
    field: PERMISSION_NAME_COLUMN,
    headerName: removeSymbolAndCapitalize(PERMISSION_NAME_COLUMN),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: PermissionLevel.READ,
    headerName: removeSymbolAndCapitalize(PermissionLevel.READ),
    editable: true,
    type: ColumnType.CHECKBOX,
  },
  {
    field: PermissionLevel.CREATE,
    headerName: removeSymbolAndCapitalize(PermissionLevel.CREATE),
    editable: true,
    type: ColumnType.CHECKBOX,
  },
  {
    field: PermissionLevel.UPDATE,
    headerName: removeSymbolAndCapitalize(PermissionLevel.UPDATE),
    editable: true,
    type: ColumnType.CHECKBOX,
  },
  {
    field: PermissionLevel.DELETE,
    headerName: removeSymbolAndCapitalize(PermissionLevel.DELETE),
    editable: true,
    type: ColumnType.CHECKBOX,
  },
];

export default function CreateRolePermission(props: ICreateRolePermissionProps) {
  const dataProvider = useDataProvider();
  const [rows, setRows] = useState<IRow[]>([]);
  const [roleName, setRoleName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const redirect = useRedirect();

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList(RequestResource.PERMISSIONS, {} as GetListParams)
      .then(({ data }) => {
        const permissionsData = data as IRolePermission[];
        prepareRowData(permissionsData[0].permissions);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const prepareRowData = (permissions: IPermission) => {
    const permissionNames = Object.keys(permissions);
    const data: IRow[] = [];
    permissionNames.map((permissionName: string) => {
      const currentPermission: IPermissionLevel = permissions[permissionName];
      const rowData: IRow = {
        [PERMISSION_NAME_COLUMN]: permissionName,
        [PermissionLevel.READ]: currentPermission[PermissionLevel.READ],
        [PermissionLevel.CREATE]: currentPermission[PermissionLevel.CREATE],
        [PermissionLevel.UPDATE]: currentPermission[PermissionLevel.UPDATE],
        [PermissionLevel.DELETE]: currentPermission[PermissionLevel.DELETE],
        [IS_EDIT_MODE]: true,
      };
      data.push(rowData);
    });
    setRows(data);
  };

  const handleChange = (row: IRow, column: string, value: IField) => {
    const rowIndex: number = findIndexInArray(rows, row);
    const newRows: IRow[] = [...rows];
    newRows[rowIndex] = {
      ...newRows[rowIndex],
      [column]: value,
    };
    setRows(newRows);
  };

  const handleSave = () => {
    setLoading(true);
    dataProvider
      .create(RequestResource.ROLE, { data: { permissions: preparePermissions(), name: roleName } } as any)
      .then(({ data }) => {
        setLoading(false);
        redirect(`/${RequestResource.ROLE}`);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const preparePermissions = () => {
    const data: any = {};
    rows?.map(({ name, isEditModem, ...rest }: IRow) => {
      data[name as string] = rest;
    });
    return data;
  };

  const handleRoleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoleName(e.currentTarget.value);
  };

  if (loading) return <Loading />;

  const handleCancelClick = () => {
    redirect(`/${RequestResource.ROLE}`);
  };

  return (
    <div className="create-role-permission">
      <Typography variant="h5">Role create</Typography>
      <Divider className="divider" />
      <div className="role-info">
        <div className="role-name">
          <TextField required label="Role name" variant="outlined" value={roleName} onChange={handleRoleNameChange} />
        </div>
        <div className="permissions-table">
          <Table columns={COLUMNS} rows={rows} handleChange={handleChange} />
        </div>
      </div>
      <div className="permissions-action">
        <Card className="card">
          <CardActions className="card-actions">
            <div>
              <Button variant="contained" color="primary" onClick={handleSave}>
                SAVE
              </Button>
              <Button variant="outlined" color="primary" onClick={handleCancelClick} className="cancel-button">
                Cancel
              </Button>
            </div>
          </CardActions>
        </Card>
      </div>
    </div>
  );
}
