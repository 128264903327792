import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Loading, useDataProvider, useRedirect } from 'react-admin';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  Divider,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Table from '../core/table/Table';
import {
  ColumnType,
  IColumn,
  IRow,
  IStatusTagColor,
  ObjectField,
  OrderFields,
  OrderPatientFields,
  OrderPaymentMethod,
  OrderPaymentStatus,
  OrderServiceFields,
  OrderStatus,
  OrderTransitionFields,
  RequestResource,
  OrderPrice,
  OrderPayment,
} from '../../types';
import Input from '../core/input/Input';
import Select from '../core/select/Select';
import DatePicker from '../core/picker/datepicker/DatePicker';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../core/dialog/Dialog';
import {
  capitalizeFirstLetter,
  convertCamelCaseToSnakeCase,
  convertServerDateToLocal,
  DEFAULT_VALIDATIONS_INFO,
  formatCurrency,
  getYearsTillNow,
  removeSymbolAndCapitalize,
} from '../../utils';
import { AppConfig } from '../../config';
import { dateTimeFormat, ORDER_STATUS_COLORS } from '../../constants';
import StatusTag from '../core/status-tag/StatusTag';
import { history } from '../../configureStore';

export interface IViewOrderProps {
  id?: string;
  orderLoading: boolean;
  role: string;
}

interface IOrderServicesProps {
  rows: IRow[];
  handleEdit: (row: any) => void;
}

interface IOrderPatientProps {
  rows: IRow[];
  columns?: IColumn[];
}

const ACTIONS = 'actions';
const RADIO_COLUMN: string = 'selected';
const IS_EDIT_MODE: string = 'selected';
const NO_TRANSITION_AUTHOR = 'No Transition Author';

const getAssignedAgentNames = (agents: any) => {
  return agents?.map((agent: any, i: number) => `${i + 1}. ${agent.name}`).join('\n') || '';
};

const getStatusTransitions = (statusTransitions: any) => {
  return (
    statusTransitions
      ?.map((statusTransition: any, i: number) => `${i + 1}. ${removeSymbolAndCapitalize(statusTransition.status)}`)
      .join('\n') || ''
  );
};

const handleRedirect = (to: string) => history.push(to);

const ORDER_SERVICE_COLUMNS: IColumn[] = [
  {
    field: OrderServiceFields.ID,
    headerName: capitalizeFirstLetter(OrderServiceFields.ID),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderServiceFields.SERVICE_NAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderServiceFields.SERVICE_NAME)),
    cellRenderer: (row: IRow) => {
      const service = row.service as ObjectField;
      return (
        <Button className="redirect-button" onClick={() => handleRedirect(`/${RequestResource.SERVICE}/${service.id}`)}>
          {service.name || 'service name'}
        </Button>
      );
    },
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderServiceFields.BUNDLE_SERVICE_NAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderServiceFields.BUNDLE_SERVICE_NAME)),
    cellRenderer: (row: IRow) => {
      const bundle = row.bundle as ObjectField;

      if (!bundle) {
        return '';
      }
      return (
        <Button className="redirect-button" onClick={() => handleRedirect(`/${RequestResource.BUNDLE}/${bundle.id}`)}>
          {bundle.name || 'bundle name'}
        </Button>
      );
    },
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderServiceFields.PRICE,
    headerName: capitalizeFirstLetter(OrderServiceFields.PRICE),
    cellRenderer: (row: IRow) => formatCurrency(row.price as number),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderServiceFields.AGENTS,
    headerName: capitalizeFirstLetter(OrderServiceFields.AGENTS),
    cellRenderer: (row: IRow) => {
      const agents = row.users as Array<any>;
      return (
        <Tooltip
          PopperProps={{
            className: 'tooltip',
          }}
          placement="left"
          className="agents-indicator"
          title={getAssignedAgentNames(agents)}
        >
          <span>{agents.length}</span>
        </Tooltip>
      );
    },
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderServiceFields.STATUS_TRANSITION,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderServiceFields.STATUS_TRANSITION)),
    editable: false,
    cellRenderer: (row: IRow) => {
      const { statusTransitions, status } = row;
      return (
        <Tooltip
          PopperProps={{
            className: 'tooltip',
          }}
          placement="left"
          className="agents-indicator"
          title={getStatusTransitions(statusTransitions)}
        >
          <span>{removeSymbolAndCapitalize(status as string)}</span>
        </Tooltip>
      );
    },
    type: ColumnType.STRING,
  },
  {
    field: ACTIONS,
    headerName: capitalizeFirstLetter(ACTIONS),
    editable: false,
    type: ColumnType.COMPONENT,
  },
];

const PATIENT_COLUMNS: IColumn[] = [
  {
    field: OrderPatientFields.FIRST_NAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderPatientFields.FIRST_NAME)),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderPatientFields.LAST_NAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderPatientFields.LAST_NAME)),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderPatientFields.GENDER,
    headerName: capitalizeFirstLetter(OrderPatientFields.GENDER),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderPatientFields.PHONE,
    headerName: capitalizeFirstLetter(OrderPatientFields.PHONE),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderPatientFields.BIRTH_DATE,
    headerName: capitalizeFirstLetter(OrderPatientFields.AGE),
    cellRenderer: (row: IRow) => (
      <Tooltip
        PopperProps={{
          className: 'tooltip',
        }}
        placement="left"
        className="patient-age"
        title={convertServerDateToLocal(row[OrderPatientFields.BIRTH_DATE] as Date)}
      >
        <span>{getYearsTillNow(row[OrderPatientFields.BIRTH_DATE] as Date, 'years')}</span>
      </Tooltip>
    ),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderPatientFields.PROFILE_PICTURE,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderPatientFields.PROFILE_PICTURE)),
    editable: false,
    cellRenderer: (row: IRow) => {
      if (!row || !Object.keys(row).length) {
        return null;
      }
      return (
        <Avatar
          className="order-patient-image-small"
          alt=""
          src={`${AppConfig.httpAPIGatewayURL}/${
            row[OrderPatientFields.PROFILE_PICTURE] || row[OrderPatientFields.DEFAULT_PICTURE]
          }`}
        />
      );
    },
    type: ColumnType.STRING,
  },
  {
    field: RADIO_COLUMN,
    headerName: '',
    editable: true,
    type: ColumnType.RADIO,
    width: '5%',
  },
];

const TRANSITION_COLUMNS: IColumn[] = [
  {
    field: OrderTransitionFields.ID,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderTransitionFields.ID)),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderTransitionFields.STATUS,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderTransitionFields.STATUS)),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: any) => (
      <StatusTag
        styles={ORDER_STATUS_COLORS[row.status as OrderStatus] as IStatusTagColor}
        label={removeSymbolAndCapitalize(row.status as string)}
      />
    ),
  },
  {
    field: OrderTransitionFields.AUTHOR_NAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderTransitionFields.AUTHOR_NAME)),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: any) =>
      row?.author?.id ? (
        <Link to={`/${RequestResource.USER}/${row?.author?.id}`}>{row?.author?.name || NO_TRANSITION_AUTHOR}</Link>
      ) : (
        row?.author?.name || NO_TRANSITION_AUTHOR
      ),
  },
];

function AttachedServices(props: IOrderServicesProps) {
  const { rows, handleEdit } = props;
  return (
    <Table
      rows={rows}
      canEdit={true}
      handleEdit={handleEdit}
      columns={ORDER_SERVICE_COLUMNS}
      noDataMessage="No services"
    />
  );
}

const Patients = (props: IOrderPatientProps) => {
  const { rows, columns } = props;
  return <Table rows={rows} columns={columns || PATIENT_COLUMNS} />;
};

export default function ViewOrder(props: IViewOrderProps) {
  const { id, orderLoading } = props;
  const [changePatientDialogOpen, setChangePatientDialogOpen] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});
  const [patients, setPatients] = useState<IRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [orderPatient, setOrderPatient] = useState<any>({});
  const [orderServices, setOrderServices] = useState<any>([]);
  const [statusTransitions, setStatusTransitions] = useState<any>([]);
  const [payment, setPayment] = useState<OrderPayment | {}>({});
  const [status, setStatus] = useState<OrderStatus | string>('');
  const [orderInfo, setOrderInfo] = useState<any>({
    [OrderFields.ADDRESS]: '',
    [OrderFields.APPOINTMENT_DATE]: '',
    [OrderFields.CITY]: '',
    [OrderFields.COUNTRY]: '',
    [OrderFields.PAYMENT_STATUS]: '',
    [OrderFields.PAYMENT_METHOD]: '',
    [OrderFields.ENTRANCE]: '',
    [OrderFields.ENTRANCE_CODE]: '',
    [OrderFields.FLOOR]: '',
  });
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  useEffect(() => {
    getOrder();
  }, [orderLoading]);

  useEffect(() => {
    getOrder();
  }, []);

  const getOrder = () => {
    if (!id) {
      return;
    }
    dataProvider
      .getOne(RequestResource.ORDER, { id })
      .then(({ data }) => {
        const { patient, orderServices, statusTransitions, user, status, payment, ...rest } = data;
        setOrderInfo({
          ...rest,
          [OrderFields.ADDRESS]: rest[OrderFields.ADDRESS],
          [OrderFields.APPOINTMENT_DATE]: rest[OrderFields.APPOINTMENT_DATE],
          [OrderFields.CITY]: rest[OrderFields.CITY],
          [OrderFields.COUNTRY]: rest[OrderFields.COUNTRY],
          [OrderFields.PAYMENT_STATUS]: payment.status,
          [OrderFields.PAYMENT_METHOD]: payment.method,
          [OrderFields.PRICE]: rest[OrderFields.PRICE],
          [OrderFields.TRANSPORTATION_FEE]: rest[OrderFields.TRANSPORTATION_FEE],
          [OrderFields.ENTRANCE]: rest[OrderFields.ENTRANCE],
          [OrderFields.ENTRANCE_CODE]: rest[OrderFields.ENTRANCE_CODE],
          [OrderFields.FLOOR]: rest[OrderFields.FLOOR],
        });
        setUser(user);
        setPatients(preparePatientsData(user?.patients, patient));
        setOrderPatient(patient || {});
        setOrderServices(orderServices || []);
        setStatusTransitions(statusTransitions || []);
        setStatus(status);
        setPayment(payment);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const preparePatientsData = (patients: IRow[], attachedPatient: any) => {
    if (!patients || !patients.length || (attachedPatient && !Object.keys(attachedPatient).length)) {
      return [];
    }
    return patients.map((patient: IRow) => ({
      ...patient,
      [IS_EDIT_MODE]: true,
      [RADIO_COLUMN]: patient.id === attachedPatient.id,
    }));
  };

  const handleCancelClick = () => {
    redirect(`/${RequestResource.ORDER}`);
  };

  if (loading) return <Loading />;

  const {
    address,
    appointmentDate,
    city,
    country,
    paymentStatus,
    paymentMethod,
    entrance,
    entranceCode,
    floor,
  } = orderInfo;
  const { orderPrice, totalPrice, transportationPrice } = payment as OrderPayment;

  const toggleChangePatientDialog = (toggle: boolean, row: any = null) => {
    row = row || orderPatient;
    setChangePatientDialogOpen(toggle);
    setPatients(preparePatientsData(patients, row));
  };

  const getPaymentData = (type: OrderFields) => {
    const data = type === OrderFields.PAYMENT_METHOD ? OrderPaymentMethod : OrderPaymentStatus;
    return Object.values(data)?.map((it: string) => ({
      value: it,
      label: capitalizeFirstLetter(it),
    }));
  };

  const attachedPatientColumns = PATIENT_COLUMNS.filter((column: IColumn) => column.field !== RADIO_COLUMN);
  const { id: userId, firstName: userFirstName, lastName: userLastName } = user || {};

  const handleUserClick = () => {
    redirect(`/${RequestResource.USER}/${userId}`);
  };

  return (
    <div className="edit-order">
      <FormControl className="edit-order-form">
        <Typography variant="h5">Order edit</Typography>
        <Divider className="divider" />
        <div className="body">
          <div className="order-info">
            <Typography variant="h6">User</Typography>
            <Divider className="divider secondary" />
            <div className="order-user">
              <List component="div">
                <ListItem>
                  <ListItemIcon className="list-item">ID</ListItemIcon>
                  <ListItemText className="href" primary={userId} onClick={handleUserClick} />
                </ListItem>
                <ListItem>
                  <ListItemIcon className="list-item">First Name</ListItemIcon>
                  <ListItemText primary={userFirstName} />
                </ListItem>
                <ListItem>
                  <ListItemIcon className="list-item">Last Name</ListItemIcon>
                  <ListItemText primary={userLastName} />
                </ListItem>
              </List>
            </div>
            <Divider className="divider secondary" />
            <Typography variant="h6">Patient</Typography>
            <Divider className="divider secondary" />
            <div className="order-patient">
              <Patients rows={[orderPatient]} columns={attachedPatientColumns} />
              <IconButton
                aria-label="edit"
                className="change-patient-button"
                color="primary"
                onClick={() => toggleChangePatientDialog(true)}
              >
                <EditIcon fontSize="large" />
              </IconButton>
            </div>
            <Divider className="divider secondary" />
            <Typography variant="h6">Order</Typography>
            <Divider className="divider secondary" />
            <Input
              disabled={true}
              label="Address"
              value={address || ''}
              errorInfo={DEFAULT_VALIDATIONS_INFO}
              classes={['text-field']}
            />
            <Input
              label="Entrance"
              value={entrance || ''}
              errorInfo={DEFAULT_VALIDATIONS_INFO}
              classes={['text-field']}
              disabled={true}
            />
            <Input
              label="Entrance code"
              value={entranceCode || ''}
              errorInfo={DEFAULT_VALIDATIONS_INFO}
              classes={['text-field']}
              disabled={true}
            />
            <Input
              label="Floor"
              value={floor || ''}
              errorInfo={DEFAULT_VALIDATIONS_INFO}
              classes={['text-field']}
              disabled={true}
            />
            <Input
              disabled={true}
              label="City"
              value={city || ''}
              errorInfo={DEFAULT_VALIDATIONS_INFO}
              classes={['text-field']}
            />
            <Input
              disabled={true}
              label="Country"
              value={country || ''}
              errorInfo={DEFAULT_VALIDATIONS_INFO}
              classes={['text-field']}
            />
            <DatePicker
              disabled={true}
              label="Appointment Date"
              format={dateTimeFormat}
              value={appointmentDate || null}
              errorInfo={DEFAULT_VALIDATIONS_INFO}
              classes={['text-field']}
              disablePast={true}
              inputProps={{
                disabled: true,
              }}
            />
            <Select
              disabled={true}
              label="Payment Method"
              data={getPaymentData(OrderFields.PAYMENT_METHOD)}
              value={paymentMethod || ''}
              errorInfo={DEFAULT_VALIDATIONS_INFO}
              classes={['text-field']}
            />
            <Select
              disabled={true}
              label="Payment Status"
              data={getPaymentData(OrderFields.PAYMENT_STATUS)}
              value={paymentStatus || ''}
              errorInfo={DEFAULT_VALIDATIONS_INFO}
              classes={['text-field']}
            />
            <Input
              disabled={true}
              label="Price"
              value={formatCurrency(orderPrice)}
              errorInfo={DEFAULT_VALIDATIONS_INFO}
              classes={['text-field']}
            />
            <Input
              disabled={true}
              label="Transportation Fee"
              value={formatCurrency(transportationPrice)}
              errorInfo={DEFAULT_VALIDATIONS_INFO}
              classes={['text-field']}
            />
            <Input
              disabled={true}
              label="Total"
              value={formatCurrency(totalPrice)}
              errorInfo={DEFAULT_VALIDATIONS_INFO}
              classes={['text-field']}
            />
            <Divider className="divider secondary" />
            <Typography variant="h6">Status Transition</Typography>
            <Divider className="divider secondary" />
            <Table columns={TRANSITION_COLUMNS} rows={statusTransitions} />
            <Divider className="divider secondary" />
            <Typography variant="h6">Service</Typography>
            <Divider className="divider secondary" />
            <div className="attached-services">
              <AttachedServices
                rows={orderServices}
                handleEdit={(row: IRow) => handleRedirect(`/order-service/${row.id}`)}
              />
            </div>
          </div>
        </div>
        <Card className="card">
          <CardActions className="card-actions">
            <div>
              <Button variant="outlined" color="primary" onClick={handleCancelClick} className="cancel-button">
                Cancel
              </Button>
            </div>
          </CardActions>
        </Card>
      </FormControl>
      {changePatientDialogOpen && (
        <Dialog onClose={() => toggleChangePatientDialog(false)}>
          <DialogTitle title="View Order Patients" onClose={() => toggleChangePatientDialog(false)} />
          <DialogContent>
            <Patients rows={patients} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => toggleChangePatientDialog(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
