import { handleActions, Action } from 'redux-actions';
import {
  GET_USER_CARD_FAILURE,
  GET_USER_CARD_SUCCESS,
  GET_USER_CARDS_REQUEST,
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  RESTORE_FAILURE,
  RESTORE_REQUEST,
  RESTORE_SUCCESS,
} from '../actions/user';
import { IUserState } from '../types/state/IUserState';

const DEFAULT_STATE: IUserState = {
  restoreLoading: false,
  successRestore: null,
  loading: false,
  users: [],
  cards: [],
};

export const user = handleActions<IUserState, any>(
  {
    [RESTORE_REQUEST]: (state: IUserState): IUserState => {
      return {
        ...state,
        restoreLoading: true,
        successRestore: false,
      };
    },
    [RESTORE_FAILURE]: (state: IUserState): IUserState => {
      return {
        ...state,
        restoreLoading: false,
        successRestore: null,
      };
    },
    [RESTORE_SUCCESS]: (state: IUserState): IUserState => {
      return {
        ...state,
        restoreLoading: false,
        successRestore: true,
      };
    },
    [GET_USERS_REQUEST]: (state: IUserState): IUserState => {
      return {
        ...state,
        loading: true,
      };
    },
    [GET_USERS_SUCCESS]: (state: IUserState, action: Action<GET_USERS_SUCCESS>): IUserState => {
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    },
    [GET_USERS_FAILURE]: (state: IUserState): IUserState => {
      return {
        ...state,
        loading: false,
        users: [],
      };
    },
    [GET_USER_CARDS_REQUEST]: (state: IUserState): IUserState => {
      return {
        ...state,
        loading: true,
      };
    },
    [GET_USER_CARD_SUCCESS]: (state: IUserState, action: Action<GET_USERS_SUCCESS>): IUserState => {
      return {
        ...state,
        loading: false,
        cards: action.payload,
      };
    },
    [GET_USER_CARD_FAILURE]: (state: IUserState): IUserState => {
      return {
        ...state,
        loading: false,
        cards: [],
      };
    },
  },
  DEFAULT_STATE
);
