import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  useListContext,
  ExportButton,
  downloadCSV,
  FunctionField,
  ShowButton,
} from 'react-admin';
import {
  RequestResource,
  PermissionLevel,
  IPermission,
  Role,
  IStatusTagColor,
} from '../../types';
import {
  convertServerDateToLocal,
  formatCurrency,
  formatDate,
  hasPermission,
  removeSymbolAndCapitalize,
} from '../../utils';
// @ts-ignore
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { dateTimeFormatForMoment } from '../../constants';
import { Link } from 'react-router-dom';
import StatusTag from '../core/status-tag/StatusTag';
import { PaymentStatus, PaymentStatusColors } from '../../types/data/payment';
import { Tooltip } from '@material-ui/core';

export const PAYMENT_STATUS_COLORS: PaymentStatusColors = {
  [PaymentStatus.NOT_PAID]: {
    backgroundColor: '#FFEBE6',
    color: '#BF2600',
  },
  [PaymentStatus.REFUNDED]: {
    backgroundColor: '#EAE6FF',
    color: '#403294',
  },
  [PaymentStatus.PENDING]: {
    backgroundColor: '#DEEBFF',
    color: '#0747A6',
  },
  [PaymentStatus.PAID]: {
    backgroundColor: '#E3FCEF',
    color: '#006644',
  },
};

export interface IListPaymentProps {
  permissions: IPermission;
  currentUserRole: string;
}

const FilterBar = (props: any) => (
  <Filter {...props}>
    <TextInput
      className={'default_search_input'}
      label='Search'
      source='id~=|method~like|totalPrice~=|status~like|user>firstName~like|user>lastName~like|order>id~='
      alwaysOn
    />
  </Filter>
);

export default function ListPayment(props: IListPaymentProps) {
  const { permissions, currentUserRole } = props;

  const exporter = (payments: any, selectedIds: any) => {
    const templatesForExport = Object.values(payments).filter((payment: any) => {
      return selectedIds.includes(payment.id);
    });
    const csv = convertToCSV({
      data: templatesForExport,
    });
    downloadCSV(csv, 'payments');
  };

  const BulkActionButtons = (props: any) => {
    const { selectedIds } = props;
    const { total, data } = useListContext();
    return (
      <React.Fragment>
        {[Role.ADMIN, Role.SUPER_ADMIN].includes(currentUserRole as Role) && (
          <ExportButton disabled={!total} onClick={() => exporter(data, selectedIds)} />
        )}
      </React.Fragment>
    );
  };

  return (
    <List
      {...props}
      className='list-payment'
      filters={<FilterBar />}
      exporter={false}
      bulkActionButtons={<BulkActionButtons />}
    >
      <Datagrid className='react-admin-table'>
        <TextField source='id' />
        <FunctionField
          label='Date/Time'
          render={(record: any) => convertServerDateToLocal(record.createdAt, dateTimeFormatForMoment)}
        />
        <FunctionField
          label='User'
          render={(record: any) =>
            record?.createdById ? (
              <Link to={`/${RequestResource.USER}/${record.createdById}`}>
                {record?.createdByName || ''}
              </Link>
            ) : record?.createdByName || ''
          }
        />
        <FunctionField
          label='Order Id'
          render={(record: any) => {
            return <Link to={`/${RequestResource.ORDER}/${record.order?.id}`}>
              {record.order?.id}
            </Link>;
          }}
        />
        <TextField label='Payment type' source='method' />
        <FunctionField
          sortBy='status'
          label='Status'
          render={(record: any) => {
            return (
              <StatusTag
                styles={PAYMENT_STATUS_COLORS[record.status as PaymentStatus] as IStatusTagColor}
                label={record.status}
                tooltipText={(() => {
                  const keys = Object.keys(record?.paymentStatusTransitions);

                  const date = (record?.paymentStatusTransitions?.[keys[keys.length - 1]] as { date: Date })?.date;

                  if (!date) return '';

                  return convertServerDateToLocal(date, dateTimeFormatForMoment);
                })()}
              />
            );
          }}
        />
        <FunctionField
          label='Price'
          source={'totalPrice'}
          render={(record: any) => {
            return <Tooltip
              PopperProps={{
                // className: 'tooltip',
              }}
              className='status-indicator'
              title={<div>
                <span>transportationPrice - {formatCurrency(record.transportationPrice)}</span>
                <br/>
                <span>orderPrice - {formatCurrency(record.orderPrice)}</span>
                <br/>
                <span>extraFee - {formatCurrency(record.extraFee)}</span>
              </div>}
            >
              <span>
                {formatCurrency(record.totalPrice)}
              </span>
            </Tooltip>;
          }}
        />
        <FunctionField
          label='Amount paid'
          render={(record: any) => {
            return formatCurrency(record?.amountPaid);
          }}
        />
        {hasPermission(permissions, RequestResource.PAYMENT, PermissionLevel.READ) ? <ShowButton /> : null}
      </Datagrid>
    </List>
  );
}
