import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Loading, NotificationType, ShowNotificationAction, useDataProvider, useRedirect } from 'react-admin';
import moment from 'moment';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  Divider,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { GetListParams } from 'ra-core';
import Table from '../core/table/Table';
import { usePlacesWidget } from 'react-google-autocomplete';
import {
  BundleColumn,
  ColumnType,
  IColumn,
  IErrors,
  IField,
  IPermission,
  IRow,
  OrderFields,
  OrderPatientFields,
  OrderPaymentMethod,
  OrderPaymentStatus,
  OrderPrice,
  PermissionLevel,
  RequestResource,
  Role,
  ServiceColumn,
  ValidationType,
} from '../../types';
import Input from '../core/input/Input';
import Select from '../core/select/Select';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../core/dialog/Dialog';
import {
  capitalizeFirstLetter,
  convertCamelCaseToSnakeCase,
  convertServerDateToLocal,
  DEFAULT_VALIDATIONS_INFO,
  findItemByField,
  formatCurrency,
  getYearsTillNow,
  hasPermission,
  isValid,
  removeSymbolAndCapitalize,
  replaceInArray,
  validate,
} from '../../utils';
import { AppConfig } from '../../config';
import _ from 'lodash';
import DateTimePicker from '../core/picker/datepicker/DateTimePicker';
import { useQuery } from '../../hooks/useQuery';

export interface ICreateOrderProps {
  id?: string;
  permissions: IPermission;
  loading: boolean;
  removeOrderAttachment: ({ orderId, attachmentId }: { orderId: number; attachmentId: number }) => void;
  users: any[];
  getClients: (roleId: number) => void;
  emptyOrderService: () => void;
  calculateOrder: (orderData: any) => void;
  resetOrderPriceInfo: () => void;
  orderPriceInfo: OrderPrice | {};
  showNotification: (message: string, type?: NotificationType) => ShowNotificationAction;
}

interface IOrderServicesProps {
  handleDelete: (id: number) => void;
  rows: IRow[];
}

interface IOrderBundlesProps {
  handleDelete: (id: number) => void;
  rows: IRow[];
}

interface IServicesProps {
  handleAdd: (row: IRow) => void;
  rows: IRow[];
}

interface IBundlesProps {
  handleAdd: (row: IRow) => void;
  rows: IRow[];
}

interface IOrderPatientProps {
  handleRowChange?: (row: IRow, column: string, value: IField) => void;
  rows: IRow[];
  columns?: IColumn[];
}

const ACTIONS = 'actions';
const RADIO_COLUMN: string = 'selected';
const IS_EDIT_MODE: string = 'isEditMode';

const ORDER_SERVICE_COLUMNS = [
  {
    field: ServiceColumn.ID,
    headerName: capitalizeFirstLetter(ServiceColumn.ID),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: ServiceColumn.NAME,
    headerName: capitalizeFirstLetter(ServiceColumn.NAME),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: ServiceColumn.DESCRIPTION,
    headerName: capitalizeFirstLetter(ServiceColumn.DESCRIPTION),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: ServiceColumn.PRICE_CLINIC,
    headerName: capitalizeFirstLetter(ServiceColumn.PRICE_CLINIC),
    editable: false,
    cellRenderer: (row: IRow) => formatCurrency(row.priceClinic as number),
    type: ColumnType.STRING,
  },
  {
    field: ACTIONS,
    headerName: capitalizeFirstLetter(ACTIONS),
    editable: false,
    type: ColumnType.COMPONENT,
  },
];

const SERVICE_COLUMNS = [
  {
    field: ServiceColumn.ID,
    headerName: capitalizeFirstLetter(ServiceColumn.ID),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: ServiceColumn.NAME,
    headerName: capitalizeFirstLetter(ServiceColumn.NAME),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: ServiceColumn.DESCRIPTION,
    headerName: capitalizeFirstLetter(ServiceColumn.DESCRIPTION),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: ServiceColumn.PRICE_CLINIC,
    headerName: capitalizeFirstLetter(ServiceColumn.PRICE),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: IRow) => formatCurrency(row.priceClinic as number),
  },
  {
    field: ACTIONS,
    headerName: capitalizeFirstLetter(ACTIONS),
    editable: false,
    type: ColumnType.COMPONENT,
  },
];

const ORDER_BUNDLE_COLUMNS = [
  {
    field: BundleColumn.ID,
    headerName: capitalizeFirstLetter(BundleColumn.ID),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: BundleColumn.NAME,
    headerName: capitalizeFirstLetter(BundleColumn.NAME),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: BundleColumn.DESCRIPTION,
    headerName: capitalizeFirstLetter(BundleColumn.DESCRIPTION),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: BundleColumn.PRICE,
    headerName: capitalizeFirstLetter(BundleColumn.PRICE),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: IRow) => formatCurrency(row.price as number),
  },
  {
    field: ACTIONS,
    headerName: capitalizeFirstLetter(ACTIONS),
    editable: false,
    type: ColumnType.COMPONENT,
  },
];

const BUNDLE_COLUMNS = [
  {
    field: BundleColumn.ID,
    headerName: capitalizeFirstLetter(BundleColumn.ID),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: BundleColumn.NAME,
    headerName: capitalizeFirstLetter(BundleColumn.NAME),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: BundleColumn.DESCRIPTION,
    headerName: capitalizeFirstLetter(BundleColumn.DESCRIPTION),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: BundleColumn.PRICE,
    headerName: capitalizeFirstLetter(BundleColumn.PRICE),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: IRow) => formatCurrency(row.price as number),
  },
  {
    field: ACTIONS,
    headerName: capitalizeFirstLetter(ACTIONS),
    editable: false,
    type: ColumnType.COMPONENT,
  },
];

const PATIENT_COLUMNS: IColumn[] = [
  {
    field: OrderPatientFields.FIRST_NAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderPatientFields.FIRST_NAME)),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderPatientFields.LAST_NAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderPatientFields.LAST_NAME)),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderPatientFields.GENDER,
    headerName: capitalizeFirstLetter(OrderPatientFields.GENDER),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderPatientFields.PHONE,
    headerName: capitalizeFirstLetter(OrderPatientFields.PHONE),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderPatientFields.BIRTH_DATE,
    headerName: capitalizeFirstLetter(OrderPatientFields.AGE),
    cellRenderer: (row: IRow) => (
      <Tooltip
        PopperProps={{
          className: 'tooltip',
        }}
        placement="left"
        className="patient-age"
        title={convertServerDateToLocal(row[OrderPatientFields.BIRTH_DATE] as Date)}
      >
        <span>
          {row[OrderPatientFields.BIRTH_DATE] && getYearsTillNow(row[OrderPatientFields.BIRTH_DATE] as Date, 'years')}
        </span>
      </Tooltip>
    ),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderPatientFields.PROFILE_PICTURE,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderPatientFields.PROFILE_PICTURE)),
    editable: false,
    cellRenderer: (row: IRow) => {
      if (!row || !Object.keys(row).length) {
        return null;
      }
      return (
        <Avatar
          className="order-patient-image-small"
          alt=""
          src={`${AppConfig.httpAPIGatewayURL}/${
            row[OrderPatientFields.PROFILE_PICTURE] || row[OrderPatientFields.DEFAULT_PICTURE]
          }`}
        />
      );
    },
    type: ColumnType.STRING,
  },
  {
    field: RADIO_COLUMN,
    headerName: '',
    editable: true,
    type: ColumnType.RADIO,
    width: '5%',
  },
];

function OrderServices(props: IOrderServicesProps) {
  const { handleDelete, rows } = props;
  return (
    <Table
      rows={rows}
      columns={ORDER_SERVICE_COLUMNS}
      canDelete={true}
      handleDelete={handleDelete}
      noDataMessage="No services"
    />
  );
}

function OrderBundles(props: IOrderBundlesProps) {
  const { handleDelete, rows } = props;
  return (
    <Table
      rows={rows}
      columns={ORDER_BUNDLE_COLUMNS}
      canDelete={true}
      handleDelete={handleDelete}
      noDataMessage="No bundles"
    />
  );
}

const Services = (props: IServicesProps) => {
  const { handleAdd, rows } = props;
  return <Table rows={rows} columns={SERVICE_COLUMNS} canAdd={true} handleAdd={handleAdd} />;
};

const Bundles = (props: IBundlesProps) => {
  const { handleAdd, rows } = props;
  return <Table rows={rows} columns={BUNDLE_COLUMNS} canAdd={true} handleAdd={handleAdd} />;
};

const Patients = (props: IOrderPatientProps) => {
  const { handleRowChange, rows, columns } = props;
  return <Table rows={rows} columns={columns || PATIENT_COLUMNS} canAdd={true} handleChange={handleRowChange} />;
};

export default function CreateOrder(props: ICreateOrderProps) {
  const {
    permissions,
    getClients,
    emptyOrderService,
    users,
    calculateOrder,
    orderPriceInfo,
    loading: orderLoading,
    resetOrderPriceInfo,
    showNotification,
  } = props;

  const queryParams = useQuery();

  const { ref: mapsAutocompleteRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      setAddrData(place.formatted_address, OrderFields.ADDRESS);
      setAddrData(place.address_components.find((c: any) => c.types.includes('locality')).long_name, OrderFields.CITY);
    },
    options: {
      types: [],
      componentRestrictions: { country: 'am' },
    },
  });

  const predefinedUserId = useMemo(() => {
    return queryParams.get('userId');
  }, [queryParams]);

  const predefinedPatientId = useMemo(() => {
    return queryParams.get('patientId');
  }, [queryParams]);

  const [showAddServiceDialog, setShowAddServiceDialog] = useState<boolean>(false);
  const [showAddBundleDialog, setShowAddBundleDialog] = useState<boolean>(false);
  const [changePatientDialogOpen, setChangePatientDialogOpen] = useState<boolean>(false);
  const [appointmentDateRange, setAppointmentDateRange] = useState<number>(0);
  const [patients, setPatients] = useState<IRow[]>([]);
  const [orderPatient, setOrderPatient] = useState<any>({});
  const [selectedUser, setSelectedUser] = useState<any>('');
  const [attachedServiceIds, setAttachedServiceIds] = useState<number[]>([]);
  const [attachedBundleIds, setAttachedBundleIds] = useState<number[]>([]);
  const [services, setServices] = useState<IRow[]>([]);
  const [bundles, setBundles] = useState<IRow[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [userFilter, setUserFilter] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [orderServices, setOrderServices] = useState<IRow[]>([]);
  const [orderBundles, setOrderBundles] = useState<IRow[]>([]);
  const [orderInfo, setOrderInfo] = useState<any>({
    [OrderFields.ADDRESS]: '',
    [OrderFields.APPOINTMENT_DATE]: '',
    [OrderFields.CITY]: '',
    [OrderFields.COUNTRY]: 'Armenia',
    [OrderFields.PAYMENT_METHOD]: OrderPaymentMethod.CASH,
    [OrderFields.ENTRANCE]: '',
    [OrderFields.ENTRANCE_CODE]: '',
    [OrderFields.FLOOR]: '',
  });
  const [orderInfoErrors, setOrderInfoErrors] = useState<IErrors>({
    [OrderFields.ADDRESS]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.APPOINTMENT_DATE]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.CITY]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.COUNTRY]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.PAYMENT_METHOD]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.PATIENT]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.ENTRANCE]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.ENTRANCE_CODE]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.FLOOR]: { ...DEFAULT_VALIDATIONS_INFO },
  });
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const dispatch = useDispatch();

  useEffect(() => {
    dataProvider
      .getList(RequestResource.SERVICE, {} as GetListParams)
      .then(({ data }) => {
        setLoading(false);
        const servicesData = data as any;
        setServices(servicesData);
      })
      .catch((error) => {
        setLoading(false);
      });
    dataProvider
      .getList(RequestResource.BUNDLE, { filter: { bundleFor: 2 } } as any) //as GetListParams
      .then(({ data }) => {
        setLoading(false);
        const bundleData = data as any;
        setBundles(bundleData);
      })
      .catch((error) => {
        setLoading(false);
      });
    dataProvider
      .getList(RequestResource.ROLE, {} as any)
      .then((res) => {
        const roles: any = res.data;
        const clientRoleId = roles?.find((role: any) => role.name === Role.CLIENT)?.id;
        if (clientRoleId) {
          dispatch(getClients(clientRoleId as number));
        }
      })
      .catch(() => {});
    dataProvider
      .getList(RequestResource.SETTING, {} as GetListParams)
      .then(({ data }) => {
        const range: number = (data as any).appointmentDateRange || 1;
        setAppointmentDateRange(range);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    return () => {
      resetOrderPriceInfo();
      dispatch(emptyOrderService());
    };
  }, []);

  useEffect(() => {
    if (users.length && predefinedUserId) {
      const user = users.find((user) => user.id === +predefinedUserId);

      if (user) {
        handleUserChange(user);

        if (predefinedPatientId) {
          const patient = user.patients.find((p: any) => p.id === +predefinedPatientId);
          setOrderPatient(patient);
        }
      } else {
        showNotification('Cannot create order for that user.', 'error');
      }
    }
  }, [predefinedPatientId, predefinedUserId, users]);

  const setAddrData = (value: string, name: OrderFields) => {
    const { validationTypes, config } = getValidationTypes(name);
    const isOptionalField = [
      OrderFields.ENTRANCE,
      OrderFields.ENTRANCE_CODE,
      OrderFields.FLOOR,
      OrderFields.CITY,
    ].includes(name);
    const newErrors = {
      ...orderInfoErrors,
      [name]: validate(value, isOptionalField ? [] : validationTypes, name, config),
    };
    setOrderInfo((orderInfo: any) => ({ ...orderInfo, [name]: value }));
    setOrderInfoErrors(newErrors);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, name: OrderFields) => {
    const { value } = e.target;
    setAddrData(value, name);
  };

  const handleDateChange = (date: any, name: OrderFields) => {
    const newErrors = {
      ...orderInfoErrors,
      [name]: validate(date, [ValidationType.REQUIRED, ValidationType.MIN_DATE], name, {
        minDate: moment().add(appointmentDateRange, 'days'),
      }),
    };
    setOrderInfo({ ...orderInfo, [name]: date?.toDate() || null });
    setOrderInfoErrors(newErrors);
  };

  const getValidationTypes = (name: OrderFields) => {
    const validationTypes = [ValidationType.REQUIRED];
    let config = {};

    if ([OrderFields.PRICE, OrderFields.TRANSPORTATION_FEE].includes(name)) {
      validationTypes.push(ValidationType.MIN_VALUE);
      config = {
        minValue: 1,
      };
    } else if ([OrderFields.APPOINTMENT_DATE].includes(name)) {
      validationTypes.push(ValidationType.MIN_DATE);
      config = {
        minDate: moment().add(appointmentDateRange, 'days'),
      };
    } else if (![OrderFields.PATIENT, OrderFields.PAYMENT_METHOD].includes(name)) {
      validationTypes.push(ValidationType.MIN_LENGTH);
      config = {
        minLength: 3,
      };
    }
    return {
      validationTypes,
      config,
    };
  };

  const handlePaymentChange = (e: React.ChangeEvent<{ value: unknown }>, name: OrderFields) => {
    const { value } = e.target;
    const newErrors = {
      ...orderInfoErrors,
      [name]: validate(value as string, [ValidationType.REQUIRED], name),
    };
    setOrderInfo({ ...orderInfo, [name]: value });
    setOrderInfoErrors(newErrors);
  };

  const getPaymentData = (type: OrderFields) => {
    const data = type === OrderFields.PAYMENT_METHOD ? OrderPaymentMethod : OrderPaymentStatus;
    return Object.values(data)?.map((it: string) => ({
      value: it,
      label: capitalizeFirstLetter(it),
    }));
  };

  const checkValidationAndSave = () => {
    const keys = Object.keys(orderInfoErrors);
    let newErrors = { ...orderInfoErrors };

    for (const key of keys) {
      const value = key === OrderFields.PATIENT ? orderPatient : orderInfo[key];
      const { validationTypes, config } = getValidationTypes(key as OrderFields);
      const isOptionalField = [
        OrderFields.ENTRANCE,
        OrderFields.ENTRANCE_CODE,
        OrderFields.FLOOR,
        OrderFields.CITY,
      ].includes(key as OrderFields);

      newErrors = {
        ...newErrors,
        [key]: validate(value, isOptionalField ? [] : validationTypes, key, config),
      };
    }
    if (isValid(newErrors)) {
      handleSave();
    }
    setOrderInfoErrors(newErrors);
  };

  const handleSave = () => {
    setLoading(true);
    dataProvider
      .create(RequestResource.ORDER, {
        data: {
          ...orderInfo,
          bundles: orderBundles,
          services: orderServices,
          patient: orderPatient,
        },
      } as any)
      .then(() => {
        setLoading(false);
        redirect(`/${RequestResource.ORDER}`);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleCancelClick = () => {
    redirect(`/${RequestResource.ORDER}`);
  };

  const handleServiceAdd = (row: IRow) => {
    const newOrderServices = [...orderServices, row];
    setOrderServices(newOrderServices);
    setAttachedServiceIds(getAttachedServiceIds(newOrderServices));
  };

  const handleBundleAdd = (row: IRow) => {
    const newOrderBundles = [...orderBundles, row];
    setOrderBundles(newOrderBundles);
    setAttachedBundleIds(getAttachedServiceIds(newOrderBundles));
  };

  const getAttachedServiceIds = (services: IRow[]): number[] => {
    return services.length ? services.map((service: IRow) => service.id as number) : [];
  };

  const getAttachedBundleIds = (bundles: IRow[]): number[] => {
    return bundles.length ? bundles.map((bundle: IRow) => bundle.id as number) : [];
  };

  const getUniqueServices = () => {
    return services.filter((service: IRow) => !attachedServiceIds.includes(service.id as number));
  };

  const getUniqueBundles = () => {
    return bundles.filter((bundle: IRow) => !attachedBundleIds.includes(bundle.id as number));
  };

  const handleServiceDelete = (id: number) => {
    const newServices = [...orderServices];
    const index = orderServices.findIndex((row: IRow) => row.id === id);
    newServices.splice(index, 1);
    setAttachedServiceIds(getAttachedServiceIds(newServices));
    setOrderServices(newServices);
  };

  const handleBundleDelete = (id: number) => {
    const newBundles = [...orderBundles];
    const index = orderBundles.findIndex((row: IRow) => row.id === id);
    newBundles.splice(index, 1);
    setAttachedBundleIds(getAttachedBundleIds(newBundles));
    setOrderBundles(newBundles);
  };

  const toggleAddServiceDialog = (toggle: boolean) => {
    setShowAddServiceDialog(toggle);
    setFilter('');
  };

  const toggleAddBundleDialog = (toggle: boolean) => {
    setShowAddBundleDialog(toggle);
    setFilter('');
  };

  const toggleChangePatientDialog = (toggle: boolean, row: any = null) => {
    row = row || orderPatient;
    setChangePatientDialogOpen(toggle);
    setPatients(preparePatientsData(patients, row));
  };

  const preparePatientsData = (patients: IRow[], attachedPatient: any) => {
    if (!patients || !patients.length || (attachedPatient && !Object.keys(attachedPatient).length)) {
      return [];
    }
    return patients.map((patient: IRow) => ({
      ...patient,
      [IS_EDIT_MODE]: true,
      [RADIO_COLUMN]: patient.id === attachedPatient.id,
    }));
  };

  // if (loading || orderLoading) return <Loading />;
  const attachedPatientColumns = PATIENT_COLUMNS.filter((column: IColumn) => column.field !== RADIO_COLUMN);
  const { address, appointmentDate, city, country, paymentMethod, entrance, entranceCode, floor } = orderInfo;

  const handleChangePatient = () => {
    const selectedPatient = findItemByField(patients, {
      key: RADIO_COLUMN,
      value: true,
    });

    if (selectedPatient) {
      handlePatientAdd(selectedPatient);
    }
  };

  const handlePatientAdd = (row: IRow) => {
    setOrderPatient(row);
    toggleChangePatientDialog(false, row);
  };

  const handleRowChange = (row: IRow, column: string, value: IField) => {
    const isRadioColumn: boolean = column === RADIO_COLUMN;
    const notFoundReplacementData = isRadioColumn ? { [column]: false } : {};
    let newPatients: IRow[] = replaceInArray(
      patients,
      { key: 'id', value: row.id, arrayFieldKey: 'rows' },
      { [column]: value },
      notFoundReplacementData
    );

    setPatients(newPatients);
  };

  const getOrderPatient = () => {
    if (!orderPatient || !Object.keys(orderPatient).length) {
      return [];
    }
    return [orderPatient];
  };

  const handleUserChange = (user: any) => {
    const { patients, phone } = user || {};

    setSelectedUser(user);
    // if (user && Object.keys(user).length) {
    //   const { firstName, lastName, gender, birthDate, profilePicture, defaultPicture, id } = user;
    //   orderPatient = {
    //     [OrderPatientFields.FIRST_NAME]: firstName || '',
    //     [OrderPatientFields.LAST_NAME]: lastName || '',
    //     [OrderPatientFields.GENDER]: gender || '',
    //     [OrderPatientFields.PHONE]: phone || '',
    //     [OrderPatientFields.BIRTH_DATE]: birthDate || '',
    //     [OrderPatientFields.PROFILE_PICTURE]: profilePicture || '',
    //     [OrderPatientFields.DEFAULT_PICTURE]: defaultPicture || '',
    //     [OrderPatientFields.ID]: id || '',
    //   };
    // }
    if (!patients?.length) return;
    const newErrors = {
      ...orderInfoErrors,
      [OrderFields.PATIENT]: validate(patients[0], [ValidationType.REQUIRED], OrderFields.PATIENT),
    };
    setOrderPatient(patients[0]);
    setOrderInfoErrors(newErrors);
    setPatients(preparePatientsData(patients, patients[0]));
  };

  const handleFilter = _.debounce((term: string) => {
    setFilter(term);
  }, 300);

  const getFilteredBundles = () => {
    return (
      getUniqueBundles()?.filter(
        ({ id, name }: IRow) => (name as string).toLowerCase().includes(filter) || (id as number).toString() === filter
      ) || []
    );
  };

  const getFilteredServices = () =>
    getUniqueServices()?.filter(
      ({ id, name, description }: IRow) =>
        (name as string).toLowerCase().includes(filter) ||
        (id as number).toString() === filter ||
        (description as string).toLowerCase().includes(filter)
    ) || [];

  const handleCalculate = () => {
    calculateOrder({
      ...orderInfo,
      bundles: orderBundles,
      services: orderServices,
      patient: orderPatient,
    });
  };

  const { discount, orderPrice, transportationDetails } = (orderPriceInfo as OrderPrice) || {};
  const { duration, km, price: transportationPrice } = transportationDetails || {};

  return (
    <>
      {(loading || orderLoading) && <Loading />}
      <div className={`create-order ${loading || orderLoading ? 'hidden' : ''}`}>
        <FormControl className="create-order-form">
          <Typography variant="h5">Order create</Typography>
          <Divider className="divider" />
          <div className="body">
            <div className="order-info">
              <Autocomplete
                className="users text-field"
                value={selectedUser || ''}
                onChange={(e: any, data: any) => handleUserChange(data)}
                inputValue={userFilter}
                onInputChange={(event, newInputValue) => {
                  setUserFilter(newInputValue);
                }}
                filterOptions={(options: any[], state: any) => {
                  const inputValue = state?.inputValue?.toLowerCase() || '';

                  const newOptions = options.filter((option: any) => {
                    const { id, firstName, lastName, email, phone, patients } = option || {};
                    const patientMatch = patients.find((patient: any) => {
                      const { firstName: patientFirstName, lastName: patientLastName, phone: patientPhone } =
                        patient || {};
                      return (
                        [patientFirstName?.toLowerCase(), patientLastName.toLowerCase()]
                          .filter((it: any) => it)
                          .join(' ')
                          .indexOf(inputValue) > -1 || patientPhone?.indexOf(inputValue) > -1
                      );
                    });
                    return (
                      (patientMatch && Object.keys(patientMatch).length) ||
                      id === +inputValue ||
                      [firstName?.toLowerCase(), lastName?.toLowerCase()]
                        .filter((it: any) => it)
                        .join(' ')
                        .indexOf(inputValue) > -1 ||
                      email?.toLowerCase().indexOf(inputValue) > -1 ||
                      phone?.toLowerCase().indexOf(inputValue) > -1
                    );
                  });
                  return newOptions;
                }}
                options={users}
                getOptionLabel={(option: any) => {
                  const { id, firstName, lastName, phone } = option;
                  return option
                    ? `${id} - ${[firstName, lastName].filter((it: any) => it).join(' ')}${phone ? ' ' + phone : ''}`
                    : '';
                }}
                renderInput={(params) => {
                  return (
                    <Input
                      required={true}
                      label="User"
                      value={country || ''}
                      errorInfo={orderInfoErrors[OrderFields.PATIENT]}
                      classes={['text-field']}
                      params={params}
                    />
                  );
                }}
              />
              <Input
                inputRef={mapsAutocompleteRef}
                required={true}
                label="Address"
                disabled={!selectedUser}
                value={address || ''}
                errorInfo={orderInfoErrors[OrderFields.ADDRESS]}
                classes={['text-field']}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, OrderFields.ADDRESS)}
              />
              <Input
                label="Entrance"
                disabled={!selectedUser}
                value={entrance || ''}
                errorInfo={orderInfoErrors[OrderFields.ENTRANCE]}
                classes={['text-field']}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, OrderFields.ENTRANCE)}
              />
              <Input
                label="Entrance code"
                disabled={!selectedUser}
                value={entranceCode || ''}
                errorInfo={orderInfoErrors[OrderFields.ENTRANCE_CODE]}
                classes={['text-field']}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, OrderFields.ENTRANCE_CODE)}
              />
              <Input
                label="Floor"
                disabled={!selectedUser}
                value={floor || ''}
                errorInfo={orderInfoErrors[OrderFields.FLOOR]}
                classes={['text-field']}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, OrderFields.FLOOR)}
              />
              <Input
                disabled={!selectedUser}
                label="City"
                value={city || ''}
                errorInfo={orderInfoErrors[OrderFields.CITY]}
                classes={['text-field']}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, OrderFields.CITY)}
              />
              <Input
                required={true}
                disabled={true}
                label="Country"
                value={country || ''}
                errorInfo={orderInfoErrors[OrderFields.COUNTRY]}
                classes={['text-field']}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, OrderFields.COUNTRY)}
              />
              <DateTimePicker
                required={true}
                disabled={!selectedUser}
                label="Appointment Date"
                value={appointmentDate || null}
                errorInfo={orderInfoErrors[OrderFields.APPOINTMENT_DATE]}
                classes={['text-field']}
                handleChange={(date: Date) => handleDateChange(date, OrderFields.APPOINTMENT_DATE)}
              />
              <Select
                required={true}
                disabled={true}
                label="Payment Method"
                data={getPaymentData(OrderFields.PAYMENT_METHOD)}
                value={paymentMethod || ''}
                errorInfo={orderInfoErrors[OrderFields.PAYMENT_METHOD]}
                classes={['text-field']}
                handleChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  handlePaymentChange(e, OrderFields.PAYMENT_METHOD)
                }
              />
              <Divider className="divider secondary" />
              <Typography variant="h6">Calculate Order</Typography>
              <Button className="calculate-order-price" variant="contained" color="primary" onClick={handleCalculate}>
                Calculate Order
              </Button>
              {orderPriceInfo && Object.keys(orderPriceInfo).length ? (
                <div className="order-price">
                  <List component="div">
                    <ListItem>
                      <ListItemIcon className="list-item">Discount</ListItemIcon>
                      <ListItemText primary={discount} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className="list-item">Duration</ListItemIcon>
                      <ListItemText primary={duration} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className="list-item">Distance</ListItemIcon>
                      <ListItemText primary={`${km} KM`} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className="list-item">Price</ListItemIcon>
                      <ListItemText primary={formatCurrency(orderPrice)} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon className="list-item">Transportation price</ListItemIcon>
                      <ListItemText primary={formatCurrency(transportationPrice)} />
                    </ListItem>
                    <Divider className="divider secondary" />
                    <ListItem>
                      <ListItemIcon className="list-item">Total</ListItemIcon>
                      <ListItemText primary={formatCurrency(orderPrice + transportationPrice)} />
                    </ListItem>
                  </List>
                </div>
              ) : (
                ''
              )}
              <Divider className="divider secondary" />
              <Typography variant="h6">Selected Patient</Typography>
              <Divider className="divider secondary" />
              <div className="order-patient">
                <Patients rows={getOrderPatient()} columns={attachedPatientColumns} />
                <IconButton
                  aria-label="edit"
                  className="change-patient-button"
                  color="primary"
                  onClick={() => toggleChangePatientDialog(true)}
                >
                  <EditIcon fontSize="large" />
                </IconButton>
              </div>
              <Divider className="divider secondary" />
              <div className="order-services">
                <Typography variant="h6">Order Services</Typography>
                <IconButton
                  aria-label="add"
                  className="add-service-button"
                  color="primary"
                  onClick={() => toggleAddServiceDialog(true)}
                >
                  <AddIcon fontSize="large" />
                </IconButton>
              </div>
              <Divider className="divider secondary" />
              <OrderServices rows={orderServices} handleDelete={handleServiceDelete} />
              <Divider className="divider secondary" />
              <div className="order-bundles">
                <Typography variant="h6">Order Bundles</Typography>
                <IconButton
                  aria-label="add"
                  className="add-bundle-button"
                  color="primary"
                  onClick={() => toggleAddBundleDialog(true)}
                >
                  <AddIcon fontSize="large" />
                </IconButton>
              </div>
              <Divider className="divider secondary" />
              <OrderBundles rows={orderBundles} handleDelete={handleBundleDelete} />
            </div>
          </div>
          <Card className="card">
            <CardActions className="card-actions">
              <div>
                {hasPermission(permissions, RequestResource.ORDER, PermissionLevel.CREATE) ? (
                  <Button variant="contained" color="primary" onClick={checkValidationAndSave} startIcon={<SaveIcon />}>
                    Save
                  </Button>
                ) : null}
                <Button variant="outlined" color="primary" onClick={handleCancelClick} className="cancel-button">
                  Cancel
                </Button>
              </div>
            </CardActions>
          </Card>
        </FormControl>
        {showAddServiceDialog && (
          <Dialog onClose={() => toggleAddServiceDialog(false)} classes={['services-dialog']}>
            <DialogTitle title="Add Service" onClose={() => toggleAddServiceDialog(false)} />
            <DialogContent>
              <TextField
                variant="filled"
                label="Search"
                className="filter"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilter(e.target.value)}
              />
              <Services rows={getFilteredServices()} handleAdd={handleServiceAdd} />
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={() => toggleAddServiceDialog(false)}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {showAddBundleDialog && (
          <Dialog onClose={() => toggleAddBundleDialog(false)} classes={['bundles-dialog']}>
            <DialogTitle title="Add Bundle" onClose={() => toggleAddBundleDialog(false)} />
            <DialogContent>
              <TextField
                variant="filled"
                label="Search"
                className="filter"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilter(e.target.value)}
              />
              <Bundles rows={getFilteredBundles()} handleAdd={handleBundleAdd} />
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={() => toggleAddBundleDialog(false)}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {changePatientDialogOpen && (
          <Dialog onClose={() => toggleChangePatientDialog(false)}>
            <DialogTitle title="Change Patient" onClose={() => toggleChangePatientDialog(false)} />
            <DialogContent>
              <Patients rows={patients} handleRowChange={handleRowChange} />
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={handleChangePatient}>
                Change Patient
              </Button>
              <Button variant="contained" color="primary" onClick={() => toggleChangePatientDialog(false)}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </>
  );
}
