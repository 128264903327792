import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import UploadIcon from '@material-ui/icons/CloudUpload';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { OrderStatus } from '../../../types';

interface IUploaderProps {
  classes?: string[];
  maxFiles?: number;
  onSave?: (files: File[]) => void;
  onUpload?: (files: File[]) => void;
  unLimitFiles?: boolean;
  readOnly?: boolean;
}

const DEFAULT_MAX_FILES = 1;

export default function Uploader(props: IUploaderProps) {
  const { classes, maxFiles, onSave, onUpload, unLimitFiles, readOnly } = props;
  const [files, setFiles] = useState<File[]>([]);
  const className = classes ? ` ${classes.join(' ')}` : '';
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = [...files, ...acceptedFiles];
      setFiles(newFiles);

      if (typeof onUpload === 'function') {
        onUpload(newFiles);
      }
    },
    [files]
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: unLimitFiles ? undefined : maxFiles || DEFAULT_MAX_FILES,
    onDrop,
  });

  const removeFile = (file: File) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const acceptedFileItems = files.map((file: any) => (
    <div className="uploaded-file" key={file.path}>
      <p>{file.path}</p>
      <IconButton className="icon" aria-label="delete" onClick={() => removeFile(file)}>
        <RemoveCircleIcon />
      </IconButton>
    </div>
  ));

  const handleSave = () => {
    if (typeof onSave === 'function') {
      onSave(files);
    }
  };

  const uploadedFilesCount = files?.length || 0;

  return (
    <section className={`crs-uploader${className}`}>
      {!readOnly && (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <Button
            className="upload-button"
            variant="contained"
            color="secondary"
            startIcon={<UploadIcon fontSize="large" />}
          >
            Upload
          </Button>
        </div>
      )}
      {!readOnly && onSave ? (
        <Button
          className="save-button"
          variant="contained"
          color="secondary"
          onClick={handleSave}
          startIcon={<SaveIcon fontSize="large" />}
        >
          Save
        </Button>
      ) : null}
      {uploadedFilesCount > 0 && (
        <aside>
          <h4>{`Uploaded file${uploadedFilesCount > 1 ? 's' : ''}`}</h4>
          <div className="uploaded-files">{acceptedFileItems}</div>
        </aside>
      )}
    </section>
  );
}
