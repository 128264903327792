import React, { useEffect, useState } from 'react';
import {
  BulkDeleteButton,
  Datagrid,
  DeleteButton,
  downloadCSV,
  ExportButton,
  Filter,
  FunctionField,
  List,
  Loading,
  SelectInput,
  TextField,
  TextInput,
  useDataProvider,
  useListContext,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { history } from '../../configureStore';
import {
  capitalizeFirstLetter,
  convertServerDateToLocal,
  DEFAULT_VALIDATIONS_INFO,
  formatDate,
  getYearsTillNow,
  hasHigherRoleThan,
  hasPermission,
  isValid,
  removeSymbolAndCapitalize,
  validate,
} from '../../utils';
import { AppConfig } from '../../config';
import {
  DataNames,
  IErrors,
  IPermission,
  IRolePermission,
  LocalStorageKey,
  PermissionLevel,
  RequestResource,
  ResetPassword,
  Role,
  UserStatus,
  ValidationType,
} from '../../types';
import {
  Avatar,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from '@material-ui/core';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../core/dialog/Dialog';
import { getStorageService } from '../../selectors';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import authProvider from '../../authProvider';
import Input from '../core/input/Input';
import { IData } from '../../actions/user';
// @ts-ignore
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { Link } from 'react-router-dom';
import ButtonWithConfirm from '../ButtonWithConfirm';

export interface IListUserProps {
  permissions: IPermission;
  currentUserRole: string;
  id: number;
  users: any;
  restoreUser: (data: IData) => void;
  successRestore: null | boolean;
  restoreLoading: boolean;
  userId: number | any;
}

export const statusOptions: Array<UserStatus> = [
  UserStatus.ALL,
  UserStatus.ACTIVE,
  UserStatus.DELETED,
  UserStatus.BLOCKED,
  UserStatus.UNVERIFIED,
];

const StatusFilter = (props: any) => {
  const { setFilters } = useListContext();
  useEffect(() => {
    return () => {
      if (!history.location.pathname.includes('show')) {
        setFilters({}, []);
      }
    };
  }, []);

  return (
    <Filter {...props}>
      <TextInput className={'default_search_input'} label="Search" source="q" alwaysOn />
      <SelectInput
        label="Status"
        source="status"
        alwaysOn
        onChange={(e) => {
          props.handleChange(e);
        }}
        choices={statusOptions.map((status: UserStatus) => ({
          id: status,
          name: capitalizeFirstLetter(status),
        }))}
        allowEmpty={false}
      />
      <SelectInput label="Role" source="role" alwaysOn choices={props.rolesData} allowEmpty={false} />
    </Filter>
  );
};
const initialData = { [DataNames.PHONE]: '', [DataNames.EMAIL]: '' };
const defaultErrors = {
  [DataNames.USER_VERIFY_KEY]: { ...DEFAULT_VALIDATIONS_INFO },
};

export default function ListUsers(props: IListUserProps) {
  const redirect = useRedirect();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [rolesData, setRolesData] = useState<IRolePermission[]>([]);
  const [showReSendKeyPopup, setShowReSendKeyPopup] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [userVerifyKey, setUserVerifyKey] = useState<string>('');
  const [userVerifyToken, setUserVerifyToken] = useState<string>('');
  const [value, setValue] = useState<string>(ResetPassword.EMAIL);
  const [status, setStatus] = useState<string>(UserStatus.ALL);
  const [data, setData] = useState<any>(initialData);
  const [errors, setErrors] = useState<IErrors>({ ...defaultErrors });
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { permissions, restoreUser, successRestore, restoreLoading, currentUserRole, userId } = props;
  useEffect(() => {
    dataProvider
      .getList(RequestResource.ROLE, {} as any)
      .then((res) => {
        const newResult = res.data
          .filter((el) => {
            const currentUserRole = getStorageService().get(LocalStorageKey.ROLE) || '';
            return hasHigherRoleThan(JSON.parse(currentUserRole) as Role, el.name);
          })
          .map((elem) => ({
            id: elem.id,
            name: removeSymbolAndCapitalize(elem.name),
          }));
        newResult.unshift({
          id: -1,
          name: 'All',
        });
        setRolesData(newResult as IRolePermission[]);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (successRestore) {
      refresh();
    }
  }, [successRestore]);

  const toggleDeleteDialog = (toggle: boolean) => {
    setIsDeleteDialogOpen(toggle);
  };

  const onRowSelect = (id: any) => {
    setSelectedRowId(id);
    return id;
  };

  const handleDelete = () => {
    setLoading(true);
    dataProvider
      .delete(RequestResource.USER, { id: selectedRowId } as any)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    setIsDeleteDialogOpen(false);
  };

  const handleEditCLick = (rowData: any) => {
    redirect(`/${RequestResource.USER}/${rowData.id}`);
  };

  const handleShowCLick = (rowData: any) => {
    redirect(`/${RequestResource.USER}/${rowData.id}/show`);
  };
  const canDelete = hasPermission(permissions, RequestResource.USER, PermissionLevel.DELETE);

  const toggleResentKeyDialog = (toggle: boolean, record: any) => {
    setShowReSendKeyPopup(toggle);
    setSuccessMessage(false);
    setUserVerifyKey('');
    setErrors(defaultErrors);
    setData({
      ...data,
      [DataNames.PHONE]: record?.phone || '',
      [DataNames.EMAIL]: record?.email || '',
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
  };

  const handleStatusChange = (value: string) => {
    setStatus(value);
  };

  const handleUserVerifyKey = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const value = e.target.value;
    setUserVerifyKey(value);
    const newErrors = {
      ...errors,
      [name]: validate(value, [ValidationType.REQUIRED, ValidationType.MIN_LENGTH], name, { minLength: 6 }),
    };
    setErrors(newErrors);
  };

  const handleResendKey = () => {
    if (value === ResetPassword.AUTO) {
      console.log(selectedRowId);

      dataProvider.verifyUser(selectedRowId);
      setShowReSendKeyPopup(false);
      return;
    }

    const sendType = value;
    const sendTo = data[value];
    authProvider
      .reSendVerifyKey({ [sendType]: sendTo } as any)
      .then((res) => {
        setSuccessMessage(true);
        setUserVerifyToken(res.data[DataNames.USER_VERIFY_TOKEN]);
      })
      .catch((err) => {});
  };

  const handleVerifyRegister = () => {
    const keys = Object.keys(errors);
    let newErrors = { ...errors };

    for (const key of keys) {
      const value = userVerifyKey;
      newErrors = {
        ...newErrors,
        [key]: validate(value, [ValidationType.REQUIRED, ValidationType.MIN_LENGTH], key, { minLength: 6 }),
      };
    }
    setErrors(newErrors);
    if (!isValid(newErrors)) {
      return;
    }

    authProvider
      .verifyUserRegister({
        userVerifyKey: userVerifyKey,
        userVerifyToken: userVerifyToken,
        verifyBy: value,
      } as any)
      .then((res) => {
        setShowReSendKeyPopup(false);
        setSuccessMessage(false);
        refresh();
      })
      .catch((err) => {});
  };

  const toggleRegisterVerifyDialog = (showPopup: boolean) => {
    setShowReSendKeyPopup(showPopup);
    setSuccessMessage(false);
    setUserVerifyKey('');
    setErrors(defaultErrors);
  };

  const handleRestoreUser = (selectedIds: Array<number>) => {
    restoreUser({ ids: selectedIds });
  };

  const CustomRestoreButton = (props: any) => {
    return (
      <Button onClick={() => handleRestoreUser(props.selectedIds)} variant="outlined" color="primary">
        Restore
      </Button>
    );
  };

  const exporter = (orders: any, selectedIds: any) => {
    const usersForExport = Object.values(orders).filter((order: any) => {
      return selectedIds.includes(order.id);
    });

    const csv = convertToCSV({
      data: usersForExport,
      fields: [
        'id',
        'profilePicture',
        'firstName',
        'lastName',
        'email',
        'phone',
        'gender',
        'userVerifiedDate',
        'createdBy',
        'country',
        'city',
        'address',
        'birthDate',
        'department',
        'rating',
      ],
    });
    downloadCSV(csv, 'users');
  };

  const BulkActionButtons = (props: any) => {
    const { selectedIds } = props;
    const { total, data } = useListContext();
    return (
      <React.Fragment>
        {canDelete && status !== UserStatus.DELETED && <BulkDeleteButton {...props} />}
        {[Role.ADMIN, Role.SUPER_ADMIN].includes(currentUserRole as Role) && (
          <ExportButton disabled={!total} onClick={() => exporter(data, selectedIds)} />
        )}
        {canDelete && status === UserStatus.DELETED && <CustomRestoreButton {...props} />}
      </React.Fragment>
    );
  };

  if (loading) return <Loading />;
  if (restoreLoading) return <Loading />;
  return (
    <div className="list-user">
      <List
        filterDefaultValues={{ status: UserStatus.ALL, q: '' }}
        bulkActionButtons={<BulkActionButtons />}
        filters={<StatusFilter rolesData={rolesData} handleChange={(e: any) => handleStatusChange(e.target.value)} />}
        exporter={false}
        className="list-user-table"
        {...props}
      >
        <Datagrid
          rowClick={(id: any) => onRowSelect(id)}
          isRowSelectable={(record: any) => canDelete && !record.blockedAt}
        >
          <TextField source="id" label="Id" />
          <TextField source="firstName" label="First name" />
          <TextField source="lastName" label="Last name" />
          <FunctionField label="Role" render={(record: any) => capitalizeFirstLetter(record.roles[0]?.name) || ''} />
          <TextField source="email" label="Email" />
          <TextField source="phone" label="Mobile" />
          <FunctionField
            label="Verified"
            render={(record: any) => {
              return record.userVerifiedBy ? `${capitalizeFirstLetter(record.userVerifiedBy)} verified` : '';
            }}
          />
          <FunctionField label="Verified date" render={(record: any) => formatDate(record?.userVerifiedDate || '')} />
          <FunctionField
            label="Address"
            render={(record: any) => (record.address ? capitalizeFirstLetter(record.address) : '')}
          />
          <FunctionField
            label="Gender"
            render={(record: any) => (record.gender ? capitalizeFirstLetter(record.gender) : '')}
          />
          <FunctionField
            label="Age"
            render={(record: any) => {
              return (
                <Tooltip
                  PopperProps={{
                    className: 'tooltip',
                  }}
                  placement="left"
                  className="user-age"
                  title={convertServerDateToLocal(record.birthDate) || ''}
                >
                  <span>{getYearsTillNow(record.birthDate, 'years') || ''}</span>
                </Tooltip>
              );
            }}
          />
          <FunctionField
            label="Specialist"
            render={(record: any) => {
              return record.specialists?.map((el: any) => capitalizeFirstLetter(el.type)).join(', ');
            }}
          />
          <TextField source="rating" label="Rating" />
          <FunctionField
            label="Photo"
            render={(record: any) => (
              <Avatar className="avatar-picture">
                <img src={`${AppConfig.httpAPIGatewayURL}/${record?.profilePicture || record?.defaultPicture}`} />
              </Avatar>
            )}
          />
          <FunctionField
            label="Created By"
            render={(record: any) =>
              record?.createdById ? (
                <Link to={`/${RequestResource.USER}/${record.createdById}`}>{record?.createdByName || ''}</Link>
              ) : (
                record?.createdByName || ''
              )
            }
          />
          <FunctionField
            label="Actions"
            className="buttons-container"
            render={(record: any) => {
              return (
                <>
                  {hasPermission(permissions, RequestResource.USER, PermissionLevel.READ) ? (
                    <Tooltip placement="top" title="Show User">
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleShowCLick(record)}
                        className="show-button"
                        startIcon={<VisibilityIcon />}
                      ></Button>
                    </Tooltip>
                  ) : null}
                  {hasPermission(permissions, RequestResource.USER, PermissionLevel.UPDATE) &&
                  !record.userVerifiedDate ? (
                    <Tooltip placement="top" title="Verify User">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => toggleResentKeyDialog(true, record)}
                        className="verify-button"
                        startIcon={<VerifiedUserIcon />}
                      ></Button>
                    </Tooltip>
                  ) : null}
                  {hasPermission(permissions, RequestResource.USER, PermissionLevel.UPDATE) &&
                  !record.blockedAt &&
                  !record.deletedAt &&
                  record.userVerifiedDate ? (
                    <Tooltip placement="top" title="Edit User">
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => handleEditCLick(record)}
                        className="edit-button"
                        startIcon={<EditIcon />}
                      ></Button>
                    </Tooltip>
                  ) : null}
                  {hasPermission(permissions, RequestResource.USER, PermissionLevel.DELETE) &&
                  !record.blockedAt &&
                  !record.deletedAt &&
                  record.userVerifiedDate &&
                  record.id !== userId ? (
                    <ButtonWithConfirm title="Are you sure you want to delete this user?" onConfirm={handleDelete}>
                      <Tooltip placement="top" title="Delete User">
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          className="delete-button"
                          startIcon={<DeleteIcon />}
                        ></Button>
                      </Tooltip>
                    </ButtonWithConfirm>
                  ) : null}
                </>
              );
            }}
          />
        </Datagrid>
      </List>

      {showReSendKeyPopup ? (
        <Dialog onClose={() => toggleResentKeyDialog(false, null)} classes={['crc-list-resend-user-dialog']}>
          <DialogTitle title={successMessage ? 'Verify your account' : 'Resend verify key'} />
          <DialogContent>
            {successMessage ? (
              <>
                <Input
                  id="userVerifyKey-input"
                  required
                  variant="standard"
                  errorInfo={errors[DataNames.USER_VERIFY_KEY]}
                  label="User Verify Key"
                  value={userVerifyKey}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleUserVerifyKey(e, DataNames.USER_VERIFY_KEY)
                  }
                />
              </>
            ) : (
              <FormControl component="fieldset">
                <FormLabel component="legend"></FormLabel>
                <RadioGroup aria-label="reset-password" name="email-phone" value={value} onChange={handleChange}>
                  <FormControlLabel value={ResetPassword.EMAIL} control={<Radio />} label="Reset via E-mail" />
                  <FormControlLabel value={ResetPassword.PHONE} control={<Radio />} label="Reset via Mobile" />
                  <FormControlLabel value={ResetPassword.AUTO} control={<Radio />} label="Verify" />
                </RadioGroup>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            {successMessage ? (
              <>
                <Button variant="contained" color="primary" onClick={handleVerifyRegister}>
                  Submit
                </Button>
                <Button variant="contained" color="primary" onClick={() => toggleRegisterVerifyDialog(false)}>
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button variant="contained" color="primary" onClick={handleResendKey}>
                  Continue
                </Button>
                <Button variant="contained" color="primary" onClick={() => toggleResentKeyDialog(false, null)}>
                  Cancel
                </Button>
              </>
            )}
            <DeleteButton />
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
}
