import React, { useState } from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import { IError } from '../../../../types';
import moment from 'moment';

type PickerSize = 'large' | 'middle' | 'small';

interface IDatePickerProps {
  classes?: string[];
  errorInfo: IError;
  label: string;
  value: Date | null;
  required?: boolean;
  disabled?: boolean;
  handleChange?: any;
  size?: PickerSize;
  disabledDate?: any;
  showTime?: boolean;
}

const DEFAULT_SIZE = 'large';

export default function DateTimePicker(props: IDatePickerProps) {
  const [isFocused, setFocused] = useState(false)

  const {
    label,
    value,
    handleChange,
    required,
    disabled,
    classes,
    errorInfo: { error, message },
    size,
    disabledDate,
    showTime,
  } = props;
  const className = classes ? ` ${classes.join(' ')}` : '';
  const labelClasses = ['label'];

  if (value) {
    labelClasses.push('has-value');
  }
  if (error) {
    labelClasses.push('has-error');
  }

  return (
    <div className={`crs-datepicker-container${className}`}>
      <label className={labelClasses.join(' ')} style={isFocused ? {top: 2} : undefined}>{`${label} ${required ? '*' : ''}`}</label>
      <AntDatePicker
        showTime={{
          format: 'HH:mm',
        }}
        format="YYYY-MM-DD HH:mm"
        inputReadOnly
        className="crs-date-picker"
        disabled={disabled}
        disabledDate={disabledDate}
        size={size || DEFAULT_SIZE}
        placeholder=""
        onChange={handleChange}
        value={value ? moment(value || undefined) : value}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      <label className="error-message">{message}</label>
    </div>
  );
}
