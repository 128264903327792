import React, { useEffect, useState } from 'react';
import { DeleteButton, Loading, useDataProvider } from 'react-admin';
import { history } from '../../configureStore';
import {
  capitalizeFirstLetter,
  convertCamelCaseToSnakeCase,
  DEFAULT_VALIDATIONS_INFO,
  formatDate,
  hasPermission, isBase64String,
  removeSymbolAndCapitalize,
} from '../../utils';
import {
  IPermission,
  RequestResource,
  IUserData,
  Role,
  PermissionLevel,
  DataNames,
  PatientColumn,
  ColumnType,
  IRow,
  LocalStorageKey,
  ICreditCard,
  CardType,
} from '../../types';
import { TextField, Button, Avatar, CardActions, Card, Typography, Divider, IconButton } from '@material-ui/core';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../core/dialog/Dialog';
import { AppConfig } from '../../config';
import { makeStyles } from '@material-ui/core/styles';
import { getStorageService, t } from '../../selectors';
import AttachedPatients from '../../containers/manageUsers/AttachedPatients';
import CreditCard  from '../credit-card/CreditCard';
import EyeIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import Input from '../core/input/Input';

const initialData: IUserData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  country: '',
  city: '',
  gender: '',
  birthDate: '',
  address: '',
  userVerifiedDate: '',
  role: '',
  specialists: undefined,
  rating: '',
};

const COLUMNS = [
  {
    field: PatientColumn.ID,
    headerName: capitalizeFirstLetter(PatientColumn.ID),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: PatientColumn.PROFILE_IMAGE,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(PatientColumn.PROFILE_IMAGE)),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: IRow) => (
      <Avatar className="avatar-picture">
        <img
          src={
            isBase64String(row?.profilePicture as string)
              ? (row?.profilePicture as string)
              : `${AppConfig.httpAPIGatewayURL}/${row?.profilePicture || row?.defaultPicture}`
          }
          alt={'default image'}
        />
      </Avatar>
    ),
  },
  {
    field: PatientColumn.FIRST_NAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(PatientColumn.FIRST_NAME)),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: PatientColumn.LAST_NAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(PatientColumn.LAST_NAME)),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: PatientColumn.EMAIL,
    headerName: capitalizeFirstLetter(PatientColumn.EMAIL),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: PatientColumn.PHONE,
    headerName: capitalizeFirstLetter(PatientColumn.PHONE),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: PatientColumn.GENDER,
    headerName: capitalizeFirstLetter(PatientColumn.GENDER),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: IRow) => capitalizeFirstLetter(row?.[PatientColumn.GENDER] as string) || '',
  },
  {
    field: PatientColumn.BIRTH_DATE,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(PatientColumn.BIRTH_DATE)),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: IRow) => formatDate((row?.[PatientColumn.BIRTH_DATE] as string) || ''),
  },
];

export interface IShowUserProps {
  permissions: IPermission;
  id?: number;
  users: any;
  deletedAt: string | null;
  cards: ICreditCard[];
}

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42);',
    },
    '&&:after': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42);',
    },
  },
});

export const medicalRole = Role.AGENT;

export default function ShowUser(props: IShowUserProps) {
  const { cards } = props;
  const [data, setData] = useState<IUserData>(initialData);
  const [loading, setLoading] = useState<boolean>(false);
  const [isBlocked, setIsBlocked] = useState<string | null>(null);
  const [showUnBlockPopup, setShowUnBlockPopup] = React.useState<boolean>(false);
  const [specializationList, setSpecializationList] = useState<any>([]);
  const [selectedCard, setSelectedCard] = useState<ICreditCard | null>(null);
  const [roleId, setRoleId] = useState<any>('');
  const [specialists, setSpecialists] = useState<any>([]);
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const { id, users, permissions } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    const withDeleted = users?.[id || '']?.deletedAt !== null;
    setLoading(true);
    dataProvider
      .getOne(RequestResource.USER, { id: { id, withDeleted } } as any)
      .then((res) => {
        setLoading(false);
        const {
          firstName,
          lastName,
          email,
          phone,
          country,
          city,
          gender,
          birthDate,
          address,
          userVerifiedDate,
          blockedAt,
          profilePicture,
          defaultPicture,
          roles,
          specialists,
          rating,
          patients,
          userVerifiedBy,
        } = res.data;
        console.log(specialists);
        setSpecialists(specialists);
        setRoleId(roles[0].id);
        const newData = {
          firstName,
          lastName,
          email,
          phone,
          country,
          city,
          gender,
          birthDate,
          address,
          userVerifiedDate,
          profilePicture,
          defaultPicture,
          role: roles[0].name,
          specialists: specialists.map((el: any) => t(el.type)).join(', '),
          rating,
          userVerifiedBy,
          patients: patients,
        };
        setData(newData);
        setIsBlocked(blockedAt);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const currentUserRole = JSON.parse(getStorageService().get(LocalStorageKey.ROLE) as string) || '';
    if (currentUserRole !== Role.CONTENT_WRITER && currentUserRole) {
      dataProvider
        .getList(RequestResource.USER_SPECIALIZATION_LIST, {} as any)
        .then((res) => {
          setSpecializationList(res.data);
        })
        .catch(() => {});
    }
  }, []);

  const toggleUnBlockDialog = (showPopup: boolean) => {
    setShowUnBlockPopup(showPopup);
  };

  const handleClose = () => {
    history.push('/user');
  };

  const handleUnBlockUser = () => {
    setLoading(true);
    const newData =
      data.role !== medicalRole
        ? {
            ...data,
            [DataNames.ROLEID]: roleId,
            [DataNames.SPECIALISTS]: [],
          }
        : {
            ...data,
            [DataNames.ROLEID]: roleId,
            [DataNames.SPECIALISTS]: specialists,
          };
    dataProvider
      .update(RequestResource.USER, { data: { ...newData, blockedAt: null }, id } as any)
      .then(() => {
        setIsBlocked(null);
        setLoading(false);
        toggleUnBlockDialog(false);
        handleClose();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handleRemoveCard = (id: number) => {
    console.log(id);
  };

  const handleViewCard = (handleCardView: ICreditCard) => {
    setSelectedCard(handleCardView);
  };

  const renderAttachedCards = () => {
    return data?.cards?.map((props: ICreditCard) => {
      const { id, maskedNumber, expiryDate } = props

      return (
        <div className="credit-card-info">
          <label className="card-info">{`Credit card ending in ${maskedNumber?.slice(
            maskedNumber.length - 4
          )}, Exp date ${expiryDate}`}</label>
          <div className="card-actions">
            <IconButton className="icon view" aria-label="view" onClick={() => handleViewCard(props)}>
              <EyeIcon />
            </IconButton>
            <IconButton className="icon delete" aria-label="delete" onClick={() => handleRemoveCard(id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )
    });
  };

  if (loading) return <Loading />;

  return (
    <div className="show-user">
      <div className="show-user-form">
        <div className="wrapper">
          <Typography variant="h5">User Show</Typography>
          <Divider className="divider" />
          <img
            className="profile-picture"
            src={
              data.profilePicture || data.defaultPicture
                ? `${AppConfig.httpAPIGatewayURL}/${data.profilePicture || data.defaultPicture}`
                : ''
            }
            alt="Preview"
          />
          <div className="show-user-data-container">
            <div className="show-user-data">
              <Input
                id="firstName-input"
                variant="standard"
                label="First name"
                value={data.firstName}
                disabled
                inputProps={{ classes }}
                errorInfo={DEFAULT_VALIDATIONS_INFO}
              />
              <Input
                id="email-input"
                variant="standard"
                label="Email"
                value={data.email}
                disabled
                inputProps={{ classes }}
                errorInfo={DEFAULT_VALIDATIONS_INFO}
              />
              <Input
                id="country-input"
                variant="standard"
                label="Country"
                value={data.country}
                disabled
                inputProps={{ classes }}
                errorInfo={DEFAULT_VALIDATIONS_INFO}
              />
              <Input
                id="gender-select"
                variant="standard"
                label="Gender"
                value={removeSymbolAndCapitalize(data.gender)}
                disabled
                inputProps={{ classes }}
                errorInfo={DEFAULT_VALIDATIONS_INFO}
              />
              <Input
                id="role-input"
                variant="standard"
                label="Role"
                value={removeSymbolAndCapitalize(data.role)}
                disabled
                inputProps={{ classes }}
                errorInfo={DEFAULT_VALIDATIONS_INFO}
              />
              {data.role === medicalRole ? (
                // <TextField
                //   id="specialist-input"
                //   label="Specialist"
                //   value={data.specialists}
                //   disabled
                //   InputProps={{ classes }}
                // />
                <Input
                  id="specialist-input"
                  variant="standard"
                  label="Specialist"
                  value={(data.specialists as string) || ''}
                  disabled
                  inputProps={{ classes }}
                  errorInfo={DEFAULT_VALIDATIONS_INFO}
                />
              ) : null}
            </div>
            <div className="show-user-data">
              <Input
                id="lastName-input"
                variant="standard"
                label="Last name"
                value={data.lastName}
                disabled
                inputProps={{ classes }}
                errorInfo={DEFAULT_VALIDATIONS_INFO}
              />
              <Input
                id="phone-input"
                variant="standard"
                label="Mobile"
                value={data.phone}
                disabled
                inputProps={{ classes }}
                errorInfo={DEFAULT_VALIDATIONS_INFO}
              />
              <Input
                id="city-input"
                variant="standard"
                label="City"
                value={capitalizeFirstLetter(data.city)}
                disabled
                inputProps={{ classes }}
                errorInfo={DEFAULT_VALIDATIONS_INFO}
              />
              <Input
                id="birthdate-input"
                variant="standard"
                label="Birth date"
                value={formatDate((data.birthDate as string) || '')}
                disabled
                inputProps={{ classes }}
                errorInfo={DEFAULT_VALIDATIONS_INFO}
              />
              <Input
                id="address-input"
                variant="standard"
                label="Address"
                value={capitalizeFirstLetter(data.address)}
                disabled
                inputProps={{ classes }}
                errorInfo={DEFAULT_VALIDATIONS_INFO}
              />
            </div>
            <div className="show-user-data">
              <Input
                id="userVerifiedBy-input"
                variant="standard"
                label="Verified"
                value={data.userVerifiedBy ? `${capitalizeFirstLetter(data.userVerifiedBy)} verified` : ''}
                disabled
                inputProps={{ classes }}
                errorInfo={DEFAULT_VALIDATIONS_INFO}
              />
              <Input
                id="userVerifiedDate-input"
                variant="standard"
                label="Verified date"
                value={formatDate(data.userVerifiedDate || '')}
                disabled
                inputProps={{ classes }}
                errorInfo={DEFAULT_VALIDATIONS_INFO}
              />

              {data.role === medicalRole ? (
                <Input
                  id="rating-input"
                  variant="standard"
                  label="Rating"
                  value={data.rating || ''}
                  disabled
                  inputProps={{ classes }}
                  errorInfo={DEFAULT_VALIDATIONS_INFO}
                />
              ) : null}
            </div>
          </div>
          <div className="container">
            {data.userVerifiedDate && data.role === Role.CLIENT ? (
              <div>
                {!!cards?.length && (
                  <div className="payment-methods">
                    <Typography variant="h5">Payment Methods</Typography>
                    <div className="attached-cards">{renderAttachedCards()}</div>
                  </div>
                )}
                <AttachedPatients rows={data.patients} columns={COLUMNS} />
              </div>
            ) : null}
          </div>
        </div>
        <Card className="card">
          <CardActions className="card-actions">
            <div>
              {isBlocked && hasPermission(permissions, RequestResource.USER, PermissionLevel.DELETE) ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => toggleUnBlockDialog(true)}
                  className="unBlock-buttom"
                >
                  Unblock
                </Button>
              ) : null}
              <Button variant="contained" color="primary" onClick={handleClose}>
                Close
              </Button>
            </div>
          </CardActions>
        </Card>
      </div>
      {showUnBlockPopup ? (
        <Dialog onClose={() => toggleUnBlockDialog(false)} classes={['crs-dialog-unblock']}>
          <DialogTitle title="Are you sure you want to unblock this user?" />
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleUnBlockUser}>
              Continue
            </Button>
            <Button variant="contained" color="primary" onClick={() => toggleUnBlockDialog(false)}>
              Cancel
            </Button>
            <DeleteButton />
          </DialogActions>
        </Dialog>
      ) : null}
      {selectedCard ? (
        <Dialog onClose={() => setSelectedCard(null)} classes={['credit-card-dialog']}>
          <DialogTitle title="Card details" />
          <DialogContent>
            <CreditCard {...selectedCard} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => setSelectedCard(null)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
}
