import React from 'react';
import Input from '../core/input/Input';
import { convertServerDateToLocal, DEFAULT_VALIDATIONS_INFO } from '../../utils';
import { ICreditCard } from '../../types';

const cardHiddenPart = '****';

export default function CreditCard(props: ICreditCard) {
  const { cardholderName, maskedNumber, expiryDate } = props;

  const prepareCardNumber = () => {
    const start = maskedNumber.slice(0, 4);
    const end = maskedNumber.slice(maskedNumber.length - 4);
    return [start, cardHiddenPart, cardHiddenPart, end].join(' ');
  };

  return (
    <div className="credit-card">
      <Input variant="outlined" errorInfo={DEFAULT_VALIDATIONS_INFO} label="Card name" value={cardholderName} disabled />
      <Input
        variant="outlined"
        errorInfo={DEFAULT_VALIDATIONS_INFO}
        label="Card number"
        value={prepareCardNumber()}
        disabled
      />
      <div className="info">
        {/*<Input variant="outlined" errorInfo={DEFAULT_VALIDATIONS_INFO} label="CVC" value="***" disabled />*/}
        <Input variant="outlined" errorInfo={DEFAULT_VALIDATIONS_INFO} label="Exp. Date" value={convertServerDateToLocal(expiryDate)} disabled />
      </div>
    </div>
  );
}

// return (
//   <div className="credit-card">
//     <div className="info single">
//       <label className="label">Name on Card</label>
//       <Input
//         variant="outlined"
//         errorInfo={DEFAULT_VALIDATIONS_INFO}
//         label="Card name"
//         value={cardName}
//         disabled
//       />
//     </div>
//     <div className="info single">
//       <label className="label">Card Number</label>
//       <Input
//         variant="outlined"
//         errorInfo={DEFAULT_VALIDATIONS_INFO}
//         label="Card number"
//         value={cardNumber}
//         disabled
//       />
//     </div>
//     <div className="info couple">
//       <div className="info single">
//         <label className="label">CVC</label>
//         <Input
//           variant="outlined"
//           errorInfo={DEFAULT_VALIDATIONS_INFO}
//           label="CVC"
//           value={cvc?.toString()}
//           disabled
//         />
//       </div>
//       <div className="info single">
//         <label className="label">Exp. Date</label>
//         <Input
//           variant="outlined"
//           errorInfo={DEFAULT_VALIDATIONS_INFO}
//           label="Exp. Date"
//           value={expDate}
//           disabled
//         />
//       </div>
//     </div>
//   </div>
// );
