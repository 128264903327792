export function arrayDeepEqual(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const arr1Sorted = arr1.slice().sort();
  const arr2Sorted = arr2.slice().sort();

  return arr1Sorted.every((value: any, index: number) => {
    if (isObject(value) === isObject(arr2Sorted[index])) {
      return objectDeepEqual(value, arr2Sorted[index]);
    }
    return value === arr2Sorted[index];
  });
}

export function findIndexInArray(arr: any[], item: any): number {
  let index = -1;

  for (let i = 0; i < arr.length; i++) {
    const currentItem = arr[i];

    if ((isObject(currentItem) === isObject(item) && objectDeepEqual(currentItem, item)) || currentItem === item) {
      index = i;
      break;
    }
  }

  return index;
}

export function objectDeepEqual(object1: any, object2: any): boolean {
  const keys1 = object1 && Object.keys(object1);
  const keys2 = object2 && Object.keys(object2);

  if (keys1?.length !== keys2?.length) {
    return false;
  }

  if (keys1) {
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if ((areObjects && !objectDeepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false;
      }
    }
  }

  return true;
}

export function replaceInArray(
  array: any[],
  identificationData: { key: string; value: any; arrayFieldKey?: string },
  data: any,
  notFoundData: any = {}
) {
  const { key, value, arrayFieldKey } = identificationData;

  const changeSubData = (arrays: any[], item: any) => {
    let newItem = { ...item };
    for (let i = 0; i < arrays.length; i++) {
      const { key, value } = arrays[i];
      newItem = {
        ...newItem,
        ...notFoundData,
        [key]: replaceInArray(value, identificationData, data, notFoundData),
      };
    }
    return newItem;
  };

  return array.map((item: any) => {
    const arrays = getArrays(item, arrayFieldKey);
    let newItem = { ...item };

    if (item[key] === value) {
      if (arrays.length) {
        newItem = changeSubData(arrays, newItem);
      }
      return {
        ...newItem,
        ...data,
      };
    }

    if (arrays.length) {
      newItem = changeSubData(arrays, newItem);
    }
    return {
      ...newItem,
      ...notFoundData,
    };
  });
}

export function findItemByField(
  array: any[],
  identificationData: { key: string; value: any; arrayFieldKey?: any }
): any {
  const { key, value, arrayFieldKey } = identificationData;
  let foundItem = null;

  for (let i = 0; i < array.length; i++) {
    const currentItem = array[i];

    if (currentItem[key] === value) {
      foundItem = currentItem;
      break;
    }

    const arrays = getArrays(currentItem, arrayFieldKey);

    if (arrays.length) {
      for (let j = 0; j < arrays.length; j++) {
        const { value } = arrays[j];
        foundItem = findItemByField(value, identificationData);

        if (foundItem) {
          break;
        }
      }
    }
    if (foundItem) {
      break;
    }
  }
  return foundItem;
}

export function isObject(object: any): boolean {
  return object != null && typeof object === 'object';
}

export function isArray(array: any): boolean {
  return Array.isArray(array);
}

export function getArrays(data: any, arrayFieldKey: string = ''): any[] {
  const result = [];

  if (isObject(data)) {
    const keys = Object.keys(data);

    for (const key of keys) {
      const value = data[key];

      if (isArray(value) && ((arrayFieldKey && arrayFieldKey === key) || !arrayFieldKey)) {
        result.push({
          key,
          value,
        });
      }
    }
  }
  return result;
}
