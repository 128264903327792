export enum PermissionLevel {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export enum Role {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin',
  OPERATOR = 'operator',
  AGENT = 'agent',
  CLIENT = 'client',
  CONTENT_WRITER = 'content_writer',
}

export interface IRolePermission {
  id: number;
  name: string;
  permissions: IPermission;
}

export interface IPermission {
  [key: string]: IPermissionLevel;
}

export type IPermissionLevel = {
  [key in PermissionLevel]: boolean;
};

export enum RoleRate {
  CONTENT_WRITER = 0,
  CLIENT = 1,
  AGENT = 2,
  OPERATOR = 3,
  ADMIN = 4,
  SUPER_ADMIN = 5,
}
