export type ITranslations = {
  [key in T]?: string;
};

export enum T {
  SIGN_IN = 'sign_in',
  SIGN_OUT = 'sign_out',
  BUNDLES = 'bundles',
  SERVICE = 'service',
  ONLINE_PHARMACY = 'online_pharmacy',
  CONTACT_US = 'contact_us',
  HELP = 'help',
  DOWNLOAD_IOS = 'download_ios',
  DOWNLOAD_IOS_SUB = 'download_ios_sub',
  DOWNLOAD_ANDROID = 'download_android',
  DOWNLOAD_ANDROID_SUB = 'download_android_sub',
  DOCTOR_IMG_ALT = 'doctor_image_alt',
  BUNDLES_IMG_ALT = 'doctor_image_alt',
  SERVICES_IMG_ALT = 'services_image_alt',
  ONLINE_PHARMACY_IMG_ALT = 'online_pharmacy_image_alt',
  HELP_IMG_ALT = 'help_image_alt',
  CONTACT_US_IMG_ALT = 'contact_us_image_alt',
  DOWNLOAD_APP_FOR = 'download_app_for',
  FREQUENTLY_ASKED_QUESTIONS = 'frequently_asked_questions',
  NAME = 'name',
  EMAIL = 'email',
  MESSAGE = 'message',
  SEND = 'send',
  TERMS_CONDITIONS = 'terms_conditions',
  PRIVACY_POLICY = 'privacy_policy',
  FOLLOW_US_ON = 'follow_us_on',
  ALL_RIGHTS_RESERVED = 'all_rights_reserved',
  IPHONE_AND_ANDROID = 'iphone_and_android',
  FEEDBACK_SUCCESS = 'feedback_success',
  FEEDBACK_FAILED = 'feedback_failed',
  PAGE_NOT_FOUND = 'page_not_found',
  NOT_FOUND_MESSAGE = 'not_found_message',
  GO_HOME = 'go_home',
  INVALID_EMAIL = 'invalid_email',
  GO_TO_PAGE = 'go_to_page',
  GO = 'go',
  All = 'all',
  COMPLETED = 'completed',
  FINISHED = 'finished',
  SAVE = 'save',
  CANCEL = 'cancel',
  CONFIRM = 'confirm',
  UPLOAD_YOUR_FILES = 'upload_your_files',
  UPLOAD_YOUR_FILES_DESCRIPTION = 'upload_your_files_description',
  UPLOAD = 'upload',
  ATTACHMENT_DELETE_CONFIRM_MESSAGE = 'attachment_delete_confirm_message',
  SEE_MORE = 'see_more',
  COMING_SOON = 'coming_soon',
  DRAG_AND_DROP = 'drag_and_drop',
  WELCOME_BACK = 'welcome_back',
  LOGIN_DESCRIPTION = 'login_description',
  LOGIN_YOUR_ACCOUNT = 'login_your_account',
  USERNAME = 'username',
  PASSWORD = 'password',
  REMEMBER_ME = 'remember_me',
  LOGIN = 'login',
  FORGOT_PASSWORD = 'forgot_password',
  FORGOT_PASSWORD_MESSAGE = 'forgot_password_message',
  VIA_EMAIL = 'via_email',
  VIA_SMS = 'via_sms',
  SUBMIT = 'submit',
  ENTER_RECOVERY_CODE = 'enter_recovery_code',
  ENTER_RECOVERY_CODE_DESCRIPTION = 'enter_recovery_code_description',
  VERIFICATION_TEXT = 'verification_text',
  RESET_PASSWORD_DESCRIPTION = 'reset_password_description',
  NEW_PASSWORD = 'new_password',
  READ_MORE = 'read_more',
  MISSED_CONTACT_INFORMATION = 'missed_contact_information',
  RESEND_VERIFICATION_CODE = 'resend_verification_code',
  FORGOT_PASSWORD_SUCCESS = 'forgot_password_success',
  FORGOT_PASSWORD_FAILURE = 'forgot_password_failure',
  LOGIN_FAILURE_ERROR = 'login_failure_error',
  RESET_PASSWORD_SUCCESS = 'reset_password_success',
  RESET_PASSWORD_ERROR = 'reset_password_error',
  ORDER_SAVE_SUCCESS = 'order_save_success',
  ORDER_SAVE_FAILURE = 'order_save_failure',
  ATTACHMENT_DELETE_SUCCESS = 'attachment_delete_success',
  ATTACHMENT_DELETE_FAILURE = 'attachment_delete_failure',
  ATTACHMENT_SAVE_SUCCESS = 'attachment_save_success',
  ATTACHMENT_SAVE_FAILURE = 'attachment_save_failure',
  SEARCH = 'search',
  NO_BUNDLE_MATCH = 'no_bundle_match',
  NO_SERVICE_MATCH = 'no_service_match',
  ENTER_PHONE_NUMBER = 'enter_phone_number',
  INVALID_PHONE_NUMBER = 'invalid_phone_number',
  ORDER_LIST = 'order_list',
  INVALID_ADDRESS = 'invalid_address',
  INVALID_BIRTHDATE = 'invalid_birthdate',
  INVALID_APPOINTMENT_DATE = 'invalid_appointment_date',
  ADD_ATTACHMENT = 'add_attachment',
  INVALID_CREDENTIALS = 'invalid_credentials',
}
