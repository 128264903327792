import React, { Fragment, useState } from 'react';
import Input from '../core/input/Input';
import { IErrors, ILanguage, RequestResource, Translation, ValidationType } from '../../types';
import { DialogActions, DialogContent, DialogTitle } from '../core/dialog/Dialog';
import { Button } from '@material-ui/core';
import { DEFAULT_VALIDATIONS_INFO, isValid, validate } from '../../utils';
import { ITranslations } from './ListTranslation';
import { useDataProvider } from 'react-admin';

interface IAddTranslationProps {
  language: string;
  locale: ILanguage[];
  toggleDialog: (visibility: boolean) => void;
  setRows: any;
  setFilteredRows: any;
  setPreviousRows: any;
  setLoading: any;
}

const AddTranslation = (props: IAddTranslationProps) => {
  const { language, locale, toggleDialog, setRows, setFilteredRows, setPreviousRows, setLoading } = props;

  const dataProvider = useDataProvider();
  const IS_EDIT_MODE: string = 'isEditMode';
  const initialRow = { [Translation.TRANSLATION_KEY]: '', [Translation.TRANSLATION]: '', languageId: '' };
  const defaultErrors = {
    [Translation.TRANSLATION_KEY]: { ...DEFAULT_VALIDATIONS_INFO },
    [Translation.TRANSLATION]: { ...DEFAULT_VALIDATIONS_INFO },
  };

  const [errors, setErrors] = useState<IErrors>({ ...defaultErrors });
  const [row, setRow] = useState<any>(initialRow);

  const handleChangeNewRow = (e: React.ChangeEvent<HTMLInputElement>, name: Translation) => {
    const value = e.target.value;
    const newErrors = {
      ...errors,
      [name]: validate(value, [ValidationType.REQUIRED, ValidationType.MIN_LENGTH], name, { minLength: 3 }),
    };
    setErrors(newErrors);
    const languageId = locale.find((el: ILanguage) => el.code === language)?.id || '';
    setRow({ ...row, languageId, [name]: value });
  };

  const handleSubmit = () => {
    const keys = Object.keys(errors);
    let newErrors = { ...errors };

    for (const key of keys) {
      const value = row[key];
      newErrors = {
        ...newErrors,
        [key]: validate(value, [ValidationType.REQUIRED, ValidationType.MIN_LENGTH], key, { minLength: 3 }),
      };
    }
    setErrors(newErrors);

    if (!isValid(newErrors)) {
      return;
    }

    const { [Translation.TRANSLATION_KEY]: key, [Translation.TRANSLATION]: value, languageId } = row;
    const data = {
      key,
      value,
      languageId,
    };

    dataProvider
      .create(RequestResource.TRANSLATION, { data } as any)
      .then(({ data }) => {
        dataProvider
          .getList(RequestResource.TRANSLATION, {
            filter: { query: `language_code=${language}` },
            customQuery: true,
          } as any)
          .then((res: any) => {
            const rowData: any = res.data.map(({ id, key, value, languageId }: ITranslations, i: string) => {
              return { id, key, value, languageId, [IS_EDIT_MODE]: false };
            });
            setRows(rowData);
            setFilteredRows(rowData);
            setPreviousRows(rowData);
            setLoading(false);
            setRow(initialRow);
          });
      })
      .catch((error) => {
        setLoading(false);
      });
    toggleDialog(false);
  };

  return (
    <Fragment>
      <DialogTitle title="Add row" />
      <DialogContent>
        <Input
          id="translationKey-input"
          label="Translation Key"
          errorInfo={errors[Translation.TRANSLATION_KEY]}
          value={row[Translation.TRANSLATION_KEY] as string}
          required
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeNewRow(e, Translation.TRANSLATION_KEY)}
        />
        <Input
          id="translation-input"
          label="Translation"
          errorInfo={errors[Translation.TRANSLATION]}
          value={row[Translation.TRANSLATION] as string}
          required
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeNewRow(e, Translation.TRANSLATION)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" className="submit-user-button" onClick={handleSubmit}>
          Submit
        </Button>
        <Button variant="contained" color="primary" onClick={() => toggleDialog(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default AddTranslation;
