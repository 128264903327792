export interface ICategory {
  name: string;
  description: string;
  price: number;
}

export enum CategoryColumn {
  ID = 'id',
  NAME = 'name',
  DESCRIPTION = 'description',
  PRICE = 'price',
  CATEGORY = 'category',
  CREATED_BY = 'createdBy',
  IS_PUBLIC = 'isPublic',
}

export enum CategoryService {
  CATEGORY = 'category',
  SERVICE = 'service',
}

export enum CategoryServiceColumn {
  ID = 'id',
  NAME = 'name',
  DESCRIPTION = 'description',
  TYPE = 'type',
  CREATED_BY = 'createdBy',
  IS_PUBLIC = 'isPublic',
}

export enum CategoryField {
  NAME = 'name',
  DESCRIPTION = 'description',
  IS_PUBLIC = 'isPublic',
  ICON = 'icon',
}
