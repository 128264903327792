import { BaseAPIService } from './BaseAPIService';
import { IService } from '../../types';

export class ServicesAPIService extends BaseAPIService {
  constructor() {
    super('');
  }

  public async getList({ query, filter }: any): Promise<any> {
    query = query?.length ? `?${query}` : '';
    return this.get(`admin/service${query}&projection=category`);
  }

  public async getOne(id: number): Promise<any> {
    return this.get(`admin/service/${id}`);
  }

  public async create(data: IService): Promise<any> {
    return this.post(`admin/service`, data);
  }

  public async update(data: IService, id: number): Promise<any> {
    return this.put(`admin/service/${id}`, data);
  }

  public async remove(id: number): Promise<any> {
    return this.delete(`admin/service/${id}`);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(`admin/service`, data);
  }
}
