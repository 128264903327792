import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Loading, useDataProvider, useRedirect } from 'react-admin';
import { GetListParams } from 'ra-core';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  Divider,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Table from '../core/table/Table';
import {
  ColumnType,
  IColumn,
  IErrors,
  IField,
  IPermission,
  IRow,
  ISettings,
  IStatusTagColor,
  ObjectField,
  OrderFields,
  OrderPatientFields,
  OrderPayment,
  OrderPaymentMethod,
  OrderPaymentStatus,
  OrderPrice,
  OrderServiceFields,
  OrderStatus,
  OrderTransitionFields,
  PermissionLevel,
  RequestResource,
  Role,
  ValidationType,
} from '../../types';
import Input from '../core/input/Input';
import Select from '../core/select/Select';
import DateTimePicker from '../core/picker/datepicker/DateTimePicker';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../core/dialog/Dialog';
import {
  capitalizeFirstLetter,
  convertCamelCaseToSnakeCase,
  convertServerDateToLocal,
  DEFAULT_VALIDATIONS_INFO,
  findItemByField,
  formatCurrency,
  getYearsTillNow,
  hasPermission,
  isValid,
  removeSymbolAndCapitalize,
  replaceInArray,
  validate,
} from '../../utils';
import { AppConfig } from '../../config';
import { dateTimeFormatForMoment, ORDER_STATUS_COLORS } from '../../constants';
import StatusTag from '../core/status-tag/StatusTag';
import { history } from '../../configureStore';
import moment from 'moment';
import OrderService from '../../containers/order-service/OrderService';

export interface IEditOrderProps {
  id?: string;
  permissions: IPermission;
  orderLoading: boolean;
  loading: boolean;
  role: string;
  calculateOrder: (orderData: any) => void;
  resetOrderPriceInfo: () => void;
  orderPriceInfo: OrderPrice | {};
}

interface IOrderServicesProps {
  rows: IRow[];
  handleEdit: (row: any) => void;
}

interface IOrderPatientProps {
  handleRowChange?: (row: IRow, column: string, value: IField) => void;
  rows: IRow[];
  columns?: IColumn[];
}

const ACTIONS = 'actions';
const RADIO_COLUMN: string = 'selected';
const IS_EDIT_MODE: string = 'isEditMode';
const NO_TRANSITION_AUTHOR = 'No Transition Author';

const getAssignedAgentNames = (agents: any) => {
  return agents?.map((agent: any, i: number) => `${i + 1}. ${agent.name}`).join('\n') || '';
};

const getStatusTransitions = (statusTransitions: any) => {
  return (
    statusTransitions
      ?.map((statusTransition: any, i: number) => `${i + 1}. ${removeSymbolAndCapitalize(statusTransition.status)}`)
      .join('\n') || ''
  );
};

const handleRedirect = (to: string) => history.push(to);

const ORDER_SERVICE_COLUMNS: IColumn[] = [
  {
    field: OrderServiceFields.ID,
    headerName: capitalizeFirstLetter(OrderServiceFields.ID),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderServiceFields.SERVICE_NAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderServiceFields.SERVICE_NAME)),
    cellRenderer: (row: IRow) => {
      const service = row.service as ObjectField;
      if (!service) {
        return '';
      }
      return (
        <Button className="redirect-button" onClick={() => handleRedirect(`/${RequestResource.SERVICE}/${service.id}`)}>
          {service.name || 'service name'}
        </Button>
      );
    },
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderServiceFields.BUNDLE_SERVICE_NAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderServiceFields.BUNDLE_SERVICE_NAME)),
    cellRenderer: (row: IRow) => {
      const bundle = row.bundle as ObjectField;

      if (!bundle) {
        return '';
      }
      return (
        <Button className="redirect-button" onClick={() => handleRedirect(`/${RequestResource.BUNDLE}/${bundle.id}`)}>
          {bundle.name || 'bundle name'}
        </Button>
      );
    },
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderServiceFields.PRICE,
    headerName: capitalizeFirstLetter(OrderServiceFields.PRICE),
    cellRenderer: (row: IRow) => formatCurrency(row.price as number),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderServiceFields.AGENTS,
    headerName: capitalizeFirstLetter(OrderServiceFields.AGENTS),
    cellRenderer: (row: IRow) => {
      const agents = row.users as Array<any>;
      return (
        <Tooltip
          PopperProps={{
            className: 'tooltip',
          }}
          placement="left"
          className="agents-indicator"
          title={getAssignedAgentNames(agents)}
        >
          <span>{agents.length}</span>
        </Tooltip>
      );
    },
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderServiceFields.STATUS_TRANSITION,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderServiceFields.STATUS_TRANSITION)),
    editable: false,
    cellRenderer: (row: IRow) => {
      const { statusTransitions, status } = row;
      return (
        <StatusTag
          styles={ORDER_STATUS_COLORS[status as OrderStatus] as IStatusTagColor}
          label={removeSymbolAndCapitalize(status as string)}
          tooltipText={getStatusTransitions(statusTransitions)}
        />
      );
    },
    type: ColumnType.STRING,
  },
  {
    field: ACTIONS,
    headerName: capitalizeFirstLetter(ACTIONS),
    editable: false,
    type: ColumnType.COMPONENT,
  },
];

const PATIENT_COLUMNS: IColumn[] = [
  {
    field: OrderPatientFields.FIRST_NAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderPatientFields.FIRST_NAME)),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderPatientFields.LAST_NAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderPatientFields.LAST_NAME)),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderPatientFields.GENDER,
    headerName: capitalizeFirstLetter(OrderPatientFields.GENDER),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderPatientFields.PHONE,
    headerName: capitalizeFirstLetter(OrderPatientFields.PHONE),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderPatientFields.BIRTH_DATE,
    headerName: capitalizeFirstLetter(OrderPatientFields.AGE),
    cellRenderer: (row: IRow) => (
      <Tooltip
        PopperProps={{
          className: 'tooltip',
        }}
        placement="left"
        className="patient-age"
        title={convertServerDateToLocal(row[OrderPatientFields.BIRTH_DATE] as Date)}
      >
        <span>
          {row[OrderPatientFields.BIRTH_DATE] && getYearsTillNow(row[OrderPatientFields.BIRTH_DATE] as Date, 'years')}
        </span>
      </Tooltip>
    ),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderPatientFields.PROFILE_PICTURE,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderPatientFields.PROFILE_PICTURE)),
    editable: false,
    cellRenderer: (row: IRow) => {
      if (!row || !Object.keys(row).length) {
        return null;
      }
      return (
        <Avatar
          className="order-patient-image-small"
          alt=""
          src={`${AppConfig.httpAPIGatewayURL}/${
            row[OrderPatientFields.PROFILE_PICTURE] || row[OrderPatientFields.DEFAULT_PICTURE]
          }`}
        />
      );
    },
    type: ColumnType.STRING,
  },
  {
    field: RADIO_COLUMN,
    headerName: '',
    editable: true,
    type: ColumnType.RADIO,
    width: '5%',
  },
];

const TRANSITION_COLUMNS: IColumn[] = [
  {
    field: OrderTransitionFields.ID,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderTransitionFields.ID)),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderTransitionFields.STATUS,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderTransitionFields.STATUS)),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: any) => (
      <StatusTag
        styles={ORDER_STATUS_COLORS[row.status as OrderStatus] as IStatusTagColor}
        label={removeSymbolAndCapitalize(row.status as string)}
      />
    ),
  },
  {
    field: OrderTransitionFields.AUTHOR_NAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderTransitionFields.AUTHOR_NAME)),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: any) =>
      row?.author?.id ? (
        <Link to={`/${RequestResource.USER}/${row?.author?.id}`}>{row?.author?.name || NO_TRANSITION_AUTHOR}</Link>
      ) : (
        row?.author?.name || NO_TRANSITION_AUTHOR
      ),
  },
  {
    field: OrderTransitionFields.DATE,
    headerName: capitalizeFirstLetter(OrderTransitionFields.DATE),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: any) => convertServerDateToLocal(row.date, dateTimeFormatForMoment),
  },
];

function AttachedServices(props: IOrderServicesProps) {
  const { rows, handleEdit} = props;
  return (
    <Table
      rows={rows}
      canEdit={true}
      handleEdit={handleEdit}
      columns={ORDER_SERVICE_COLUMNS}
      noDataMessage="No services"
    />
  );
}

const Patients = (props: IOrderPatientProps) => {
  const { handleRowChange, rows, columns } = props;
  return <Table rows={rows} columns={columns || PATIENT_COLUMNS} canAdd={true} handleChange={handleRowChange} />;
};

export default function EditOrder(props: IEditOrderProps) {
  const {
    id,
    permissions,
    orderLoading,
    role,
    calculateOrder,
    orderPriceInfo,
    resetOrderPriceInfo,
    loading: orderPriceLoading,
  } = props;
  const [changePatientDialogOpen, setChangePatientDialogOpen] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});
  const [appointmentDateRange, setAppointmentDateRange] = useState<number>(0);
  const [patients, setPatients] = useState<IRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [orderPatient, setOrderPatient] = useState<any>({});
  const [orderServices, setOrderServices] = useState<any>([]);
  const [statusTransitions, setStatusTransitions] = useState<any>([]);
  const [payment, setPayment] = useState<OrderPayment | {}>({});
  const [status, setStatus] = useState<OrderStatus | string>('');
  const [orderInfo, setOrderInfo] = useState<any>({
    [OrderFields.ADDRESS]: '',
    [OrderFields.APPOINTMENT_DATE]: '',
    [OrderFields.CITY]: '',
    [OrderFields.COUNTRY]: '',
    [OrderFields.PAYMENT_STATUS]: '',
    [OrderFields.PAYMENT_METHOD]: '',
    [OrderFields.PRICE]: '',
    [OrderFields.TRANSPORTATION_FEE]: '',
    [OrderFields.ENTRANCE]: '',
    [OrderFields.ENTRANCE_CODE]: '',
    [OrderFields.FLOOR]: '',
  });
  const [orderInfoErrors, setOrderInfoErrors] = useState<IErrors>({
    [OrderFields.ADDRESS]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.APPOINTMENT_DATE]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.CITY]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.COUNTRY]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.PAYMENT_STATUS]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.PAYMENT_METHOD]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.PRICE]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.TRANSPORTATION_FEE]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.ENTRANCE]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.ENTRANCE_CODE]: { ...DEFAULT_VALIDATIONS_INFO },
    [OrderFields.FLOOR]: { ...DEFAULT_VALIDATIONS_INFO },
  });

  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  useEffect(() => {
    getOrder();
  }, [orderLoading]);

  useEffect(() => {
    getOrder();
    dataProvider
      .getList(RequestResource.SETTING, {} as GetListParams)
      .then(({ data }) => {
        const range: number = (data as any).appointmentDateRange || 1;
        setAppointmentDateRange(range);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    return () => {
      resetOrderPriceInfo();
    };
  }, []);

  const getOrder = () => {
    if (!id) {
      return;
    }
    dataProvider
      .getOne(RequestResource.ORDER, { id })
      .then(({ data }) => {
        const { patient, orderServices, statusTransitions, user, status, payment, ...rest } = data;
        setOrderInfo({
          ...rest,
          [OrderFields.ADDRESS]: rest[OrderFields.ADDRESS],
          [OrderFields.APPOINTMENT_DATE]: rest[OrderFields.APPOINTMENT_DATE],
          [OrderFields.CITY]: rest[OrderFields.CITY],
          [OrderFields.COUNTRY]: rest[OrderFields.COUNTRY],
          [OrderFields.PAYMENT_STATUS]: payment.status,
          [OrderFields.PAYMENT_METHOD]: payment.method,
          [OrderFields.ENTRANCE]: rest[OrderFields.ENTRANCE],
          [OrderFields.ENTRANCE_CODE]: rest[OrderFields.ENTRANCE_CODE],
          [OrderFields.FLOOR]: rest[OrderFields.FLOOR],
        });
        setUser(user);
        setPatients(preparePatientsData(user?.patients, patient));
        setOrderPatient(patient || {});
        setOrderServices(orderServices || []);
        setStatusTransitions(statusTransitions || []);
        setStatus(status);
        setPayment(payment);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const preparePatientsData = (patients: IRow[], attachedPatient: any) => {
    if (!patients || !patients.length || (attachedPatient && !Object.keys(attachedPatient).length)) {
      return [];
    }
    return patients.map((patient: IRow) => ({
      ...patient,
      [IS_EDIT_MODE]: true,
      [RADIO_COLUMN]: patient.id === attachedPatient.id,
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, name: OrderFields) => {
    const { value } = e.target;
    const validationTypes = [OrderFields.ENTRANCE, OrderFields.ENTRANCE_CODE, OrderFields.FLOOR].includes(name)
      ? []
      : [ValidationType.REQUIRED];

    const newErrors = {
      ...orderInfoErrors,
      [name]: validate(value, validationTypes, name),
    };
    setOrderInfo({ ...orderInfo, [name]: value });
    setOrderInfoErrors(newErrors);
  };

  const handleDateChange = (date: any, name: OrderFields) => {
    const newErrors = {
      ...orderInfoErrors,
      [name]: validate(date, [ValidationType.REQUIRED, ValidationType.MIN_DATE], name, {
        minDate: moment().add(appointmentDateRange, 'days'),
      }),
    };
    setOrderInfo({ ...orderInfo, [name]: date?.toDate() || null });
    setOrderInfoErrors(newErrors);
  };

  const handlePaymentChange = (e: React.ChangeEvent<{ value: unknown }>, name: OrderFields) => {
    const { value } = e.target;
    const newErrors = {
      ...orderInfoErrors,
      [name]: validate(value as string, [ValidationType.REQUIRED], name),
    };
    setOrderInfo({ ...orderInfo, [name]: value });
    setOrderInfoErrors(newErrors);
  };

  const handleSave = () => {
    if (!isValid(orderInfoErrors)) {
      return;
    }
    updateOrder({ ...orderInfo, patient: orderPatient });
  };

  const updateOrder = (data: any) => {
    if (!id) {
      return;
    }
    setLoading(true);
    dataProvider
      .update(RequestResource.ORDER, {
        id: +id,
        data,
      } as any)
      .then(() => {
        setLoading(false);
        redirect(`/${RequestResource.ORDER}`);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleOrderDelete = () => {
    if (!id) {
      return;
    }
    setLoading(true);
    dataProvider
      .delete(RequestResource.ORDER, {
        id: +id,
      } as any)
      .then(() => {
        setLoading(false);
        redirect(`/${RequestResource.ORDER}`);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleCancelClick = () => {
    redirect(`/${RequestResource.ORDER}`);
  };

  const handlePatientAdd = (row: IRow) => {
    setOrderPatient(row);
    toggleChangePatientDialog(false, row);
  };

  if (loading || orderPriceLoading) return <Loading />;

  const {
    address,
    appointmentDate,
    city,
    country,
    paymentStatus,
    paymentMethod,
    entrance,
    entranceCode,
    floor,
  } = orderInfo;
  const { orderPrice, totalPrice, transportationPrice } = payment as OrderPayment;

  const toggleChangePatientDialog = (toggle: boolean, row: any = null) => {
    row = row || orderPatient;
    setChangePatientDialogOpen(toggle);
    setPatients(preparePatientsData(patients, row));
  };
  
  const getPaymentData = (type: OrderFields) => {
    const data = type === OrderFields.PAYMENT_METHOD ? OrderPaymentMethod : OrderPaymentStatus;
    return Object.values(data)?.map((it: string) => ({
      value: it,
      label: capitalizeFirstLetter(it),
    }));
  };

  const handleChangePatient = () => {
    const selectedPatient = findItemByField(patients, {
      key: RADIO_COLUMN,
      value: true,
    });

    if (selectedPatient) {
      handlePatientAdd(selectedPatient);
    }
  };

  const attachedPatientColumns = PATIENT_COLUMNS.filter((column: IColumn) => column.field !== RADIO_COLUMN);
  const { id: userId, firstName: userFirstName, lastName: userLastName } = user || {};

  const handleUserClick = () => {
    redirect(`/${RequestResource.USER}/${userId}`);
  };

  const handleOrderStatusChange = (status: OrderStatus) => {
    updateOrder({ status });
  };

  const handleRowChange = (row: IRow, column: string, value: IField) => {
    const isRadioColumn: boolean = column === RADIO_COLUMN;
    const notFoundReplacementData = isRadioColumn ? { [column]: false } : {};
    let newPatients: IRow[] = replaceInArray(
      patients,
      { key: 'id', value: row.id, arrayFieldKey: 'rows' },
      { [column]: value },
      notFoundReplacementData
    );

    setPatients(newPatients);
  };
  
  const getOrderPatient = () => {
    if (!orderPatient || !Object.keys(orderPatient).length) {
      return [];
    }
    return [orderPatient];
  };
  
  const handleCalculate = () => {
    calculateOrder({
      address: orderInfo[OrderFields.ADDRESS],
      city: orderInfo[OrderFields.CITY],
      country: orderInfo[OrderFields.COUNTRY],
      orderId: id,
    });
  };

  const isOrderInactive = [OrderStatus.CANCELLED, OrderStatus.CLOSED].includes(status as OrderStatus);
  const { discount, transportationDetails } = (orderPriceInfo as OrderPrice) || {};
  const { duration, km, price: newTransportationPrice } = transportationDetails || {};

  return (
    <div className="edit-order">
      <FormControl className="edit-order-form">
        <Typography variant="h5">Order edit</Typography>
        <Divider className="divider" />
        <div className="body">
          <div className="order-info">
            <Typography variant="h6">User</Typography>
            <Divider className="divider secondary" />
            <div className="order-user">
              <List component="div">
                <ListItem>
                  <ListItemIcon className="list-item">ID</ListItemIcon>
                  <ListItemText className="href" primary={userId} onClick={handleUserClick} />
                </ListItem>
                <ListItem>
                  <ListItemIcon className="list-item">First Name</ListItemIcon>
                  <ListItemText primary={userFirstName} />
                </ListItem>
                <ListItem>
                  <ListItemIcon className="list-item">Last Name</ListItemIcon>
                  <ListItemText primary={userLastName} />
                </ListItem>
              </List>
            </div>
            <Divider className="divider secondary" />
            <Typography variant="h6">Patient</Typography>
            <Divider className="divider secondary" />
            <div className="order-patient">
              <Patients rows={getOrderPatient()} columns={attachedPatientColumns} />
              {isOrderInactive ? null : (
                <IconButton
                  aria-label="edit"
                  className="change-patient-button"
                  color="primary"
                  onClick={() => toggleChangePatientDialog(true)}
                >
                  <EditIcon fontSize="large" />
                </IconButton>
              )}
            </div>
            <Divider className="divider secondary" />
            <Typography variant="h6">Order</Typography>
            <Divider className="divider secondary" />
            <Input
              required={true}
              label="Address"
              value={address || ''}
              errorInfo={orderInfoErrors[OrderFields.ADDRESS]}
              classes={['text-field']}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, OrderFields.ADDRESS)}
              disabled={isOrderInactive}
            />
            <Input
              label="Entrance"
              value={entrance || ''}
              errorInfo={orderInfoErrors[OrderFields.ENTRANCE]}
              classes={['text-field']}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, OrderFields.ENTRANCE)}
              disabled={isOrderInactive}
            />
            <Input
              label="Entrance code"
              value={entranceCode || ''}
              errorInfo={orderInfoErrors[OrderFields.ENTRANCE_CODE]}
              classes={['text-field']}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, OrderFields.ENTRANCE_CODE)}
              disabled={isOrderInactive}
            />
            <Input
              label="Floor"
              value={floor || ''}
              errorInfo={orderInfoErrors[OrderFields.FLOOR]}
              classes={['text-field']}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, OrderFields.FLOOR)}
              disabled={isOrderInactive}
            />
            <Input
              required={true}
              label="City"
              value={city || ''}
              errorInfo={orderInfoErrors[OrderFields.CITY]}
              classes={['text-field']}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, OrderFields.CITY)}
              disabled={isOrderInactive}
            />
            <Input
              required={true}
              label="Country"
              value={country || ''}
              errorInfo={orderInfoErrors[OrderFields.COUNTRY]}
              classes={['text-field']}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, OrderFields.COUNTRY)}
              disabled={true}
            />
            <DateTimePicker
              required={true}
              label="Appointment Date"
              value={appointmentDate || null}
              errorInfo={orderInfoErrors[OrderFields.APPOINTMENT_DATE]}
              classes={['text-field']}
              disabled={isOrderInactive}
              handleChange={(date: Date) => handleDateChange(date, OrderFields.APPOINTMENT_DATE)}
            />
            <Select
              required={true}
              disabled={true}
              label="Payment Method"
              data={getPaymentData(OrderFields.PAYMENT_METHOD)}
              value={paymentMethod || ''}
              errorInfo={orderInfoErrors[OrderFields.PAYMENT_METHOD]}
              classes={['text-field']}
              handleChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                handlePaymentChange(e, OrderFields.PAYMENT_METHOD)
              }
            />
            <Select
              required={true}
              disabled={true}
              label="Payment Status"
              data={getPaymentData(OrderFields.PAYMENT_STATUS)}
              value={paymentStatus || ''}
              errorInfo={orderInfoErrors[OrderFields.PAYMENT_STATUS]}
              classes={['text-field']}
              handleChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                handlePaymentChange(e, OrderFields.PAYMENT_STATUS)
              }
            />
            <Input
              disabled={true}
              label="Price"
              value={formatCurrency(orderPrice)}
              errorInfo={orderInfoErrors[OrderFields.PRICE]}
              classes={['text-field']}
            />
            <Input
              disabled={true}
              label="Transportation Fee"
              value={formatCurrency(transportationPrice)}
              errorInfo={orderInfoErrors[OrderFields.TRANSPORTATION_FEE]}
              classes={['text-field']}
            />
            <Input
              disabled={true}
              label="Total"
              value={formatCurrency(totalPrice)}
              errorInfo={orderInfoErrors[OrderFields.PRICE]}
              classes={['text-field']}
            />
            <div className="button-wrapper">
              <div className="order-status-buttons">
                {role === Role.ADMIN && status === OrderStatus.DONE && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOrderStatusChange(OrderStatus.CLOSED)}
                    className="order-status-button"
                  >
                    Close Order
                  </Button>
                )}
                {role === Role.ADMIN && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOrderStatusChange(OrderStatus.CANCELLED)}
                    className="order-status-button"
                    disabled={isOrderInactive}
                  >
                    Cancel Order
                  </Button>
                )}
              </div>
            </div>
            <Divider className="divider secondary" />
            <Typography variant="h6">Calculate Order</Typography>
            {status !== OrderStatus.CANCELLED && status !== OrderStatus.CLOSED && (
              <Button className="calculate-order-price" variant="contained" color="primary" onClick={handleCalculate}>
                Calculate Order
              </Button>
            )}
            {orderPriceInfo && Object.keys(orderPriceInfo).length ? (
              <div className="order-price">
                <List component="div">
                  <ListItem>
                    <ListItemIcon className="list-item">Discount</ListItemIcon>
                    <ListItemText primary={discount} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon className="list-item">Duration</ListItemIcon>
                    <ListItemText primary={duration} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon className="list-item">Distance</ListItemIcon>
                    <ListItemText primary={`${km} KM`} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon className="list-item">Price</ListItemIcon>
                    <ListItemText primary={formatCurrency(orderPrice)} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon className="list-item">Transportation price</ListItemIcon>
                    <ListItemText primary={formatCurrency(newTransportationPrice)} />
                  </ListItem>
                  <Divider className="divider secondary" />
                  <ListItem>
                    <ListItemIcon className="list-item">Total</ListItemIcon>
                    <ListItemText primary={formatCurrency(orderPrice + newTransportationPrice)} />
                  </ListItem>
                </List>
              </div>
            ) : (
              ''
            )}
            <Divider className="divider secondary" />
            <Typography variant="h6">Status Transition</Typography>
            <Divider className="divider secondary" />
            <Table columns={TRANSITION_COLUMNS} rows={statusTransitions} />
            <Divider className="divider secondary" />
            <Typography variant="h6">Service</Typography>
            <Divider className="divider secondary" />
            <div className="attached-services">
              <AttachedServices
                rows={orderServices}
                handleEdit={(row: IRow) => handleRedirect(`/order-service/${row.id}`)}
              />
            </div>
          </div>
        </div>
        <Card className="card">
          <CardActions className="card-actions">
            <div>
              {hasPermission(permissions, RequestResource.ORDER, PermissionLevel.UPDATE) &&
              status !== OrderStatus.CANCELLED &&
              status !== OrderStatus.CLOSED ? (
                <Button variant="contained" color="primary" onClick={handleSave} startIcon={<SaveIcon />}>
                  Save
                </Button>
              ) : null}
              <Button variant="outlined" color="primary" onClick={handleCancelClick} className="cancel-button">
                Cancel
              </Button>
            </div>
            {hasPermission(permissions, RequestResource.ORDER, PermissionLevel.DELETE) &&
            status !== OrderStatus.CANCELLED &&
            status !== OrderStatus.CLOSED ? (
              <Button
                className="delete"
                variant="contained"
                color="secondary"
                onClick={handleOrderDelete}
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            ) : null}
          </CardActions>
        </Card>
      </FormControl>
      {changePatientDialogOpen && (
        <Dialog onClose={() => toggleChangePatientDialog(false)}>
          <DialogTitle title="Change Patient" onClose={() => toggleChangePatientDialog(false)} />
          <DialogContent>
            <Patients rows={patients} handleRowChange={handleRowChange} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleChangePatient}>
              Change Patient
            </Button>
            <Button variant="contained" color="primary" onClick={() => toggleChangePatientDialog(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
