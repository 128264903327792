import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  EditButton,
  useRedirect,
  BulkDeleteButton,
  downloadCSV,
  useListContext,
  ExportButton,
  FunctionField,
  TOGGLE_LIST_ITEM_EXPAND,
} from 'react-admin';
import {
  booleanToYesOrNo,
  capitalizeFirstLetter,
  hasPermission,
  removeSymbolAndCapitalize,
  replaceInArray,
} from '../../utils';
import {
  RequestResource,
  PermissionLevel,
  IPermission,
  CategoryServiceColumn,
  CategoryService,
  ColumnType,
  IRow,
  IField,
  Role,
} from '../../types';
import Table from '../core/table/Table';

// @ts-ignore
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';

export interface IListCategoryProps {
  permissions: IPermission;
  currentUserRole: string;
  expanded: number[];
}

const IS_OPEN: string = 'isOpen';
const COLLAPSE: string = 'collapse';
const ACTIONS: string = 'actions';

const COLUMNS = [
  {
    field: COLLAPSE,
    headerName: '',
    editable: false,
    type: ColumnType.COLLAPSE,
    width: '5%',
  },
  {
    field: CategoryServiceColumn.ID,
    headerName: removeSymbolAndCapitalize(CategoryServiceColumn.ID),
    editable: false,
    type: ColumnType.STRING,
    width: '5%',
  },
  {
    field: CategoryServiceColumn.NAME,
    headerName: removeSymbolAndCapitalize(CategoryServiceColumn.NAME),
    editable: false,
    type: ColumnType.STRING,
    width: '10%',
  },
  {
    field: CategoryServiceColumn.DESCRIPTION,
    headerName: removeSymbolAndCapitalize(CategoryServiceColumn.DESCRIPTION),
    editable: false,
    type: ColumnType.STRING,
    width: '35%',
  },
  {
    field: CategoryServiceColumn.TYPE,
    headerName: removeSymbolAndCapitalize(CategoryServiceColumn.TYPE),
    editable: false,
    type: ColumnType.STRING,
    width: '10%',
  },
  {
    field: CategoryServiceColumn.IS_PUBLIC,
    headerName: removeSymbolAndCapitalize(CategoryServiceColumn.IS_PUBLIC),
    editable: false,
    type: ColumnType.STRING,
    width: '10%',
  },
  {
    field: CategoryServiceColumn.CREATED_BY,
    headerName: removeSymbolAndCapitalize(CategoryServiceColumn.CREATED_BY),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: IRow) => {
      console.log('row', row);
      console.log('RequestResource', RequestResource);

      return row?.createdById ? (
        <Link to={`/${RequestResource.USER}/${row.createdById}`}>{row?.createdByName || ''}</Link>
      ) : (
        row?.createdByName || ''
      );
    },
    width: '15%',
  },
  {
    field: ACTIONS,
    headerName: capitalizeFirstLetter(ACTIONS),
    editable: false,
    type: ColumnType.COMPONENT,
    width: '10%',
  },
];

function Category(props: any) {
  const [rows, setRows] = useState<IRow[]>([]);
  const { services, categories } = props.record || {};
  const redirect = useRedirect();

  useEffect(() => {
    let data: any = [];
    if (services) {
      data = [...data, ...prepareServicesData(services)];
    }
    if (categories) {
      data = [...data, ...prepareCategoriesData(categories)];
    }
    setRows(data);
  }, []);

  const prepareCategoriesData = (categories: IRow[]): IRow[] => {
    if (!categories || !categories.length) {
      return [];
    }
    return categories.map(({ categories, services, isPublic, ...rest }: IRow) => {
      isPublic = String(isPublic);

      return {
        ...rest,
        isPublic,
        type: capitalizeFirstLetter(CategoryService.CATEGORY),
        rows: [...prepareCategoriesData(categories as IRow[]), ...prepareServicesData(services as IRow[])],
        [IS_OPEN]: false,
      };
    });
  };

  const prepareServicesData = (services: IRow[]): IRow[] => {
    if (!services || !services.length) {
      return [];
    }

    return services.map((service: IRow) => {
      service.isPublic = String(service.isPublic);

      return {
        ...service,
        type: capitalizeFirstLetter(CategoryService.SERVICE),
        className: 'services',
        rows: [],
      };
    });
  };

  const handleRowChange = (row: IRow, column: string, value: IField) => {
    const newRows: IRow[] = replaceInArray(
      rows,
      { key: 'id', value: row.id, arrayFieldKey: 'rows' },
      { [column]: value }
    );
    setRows(newRows);
  };
  const handleEdit = ({ id, type }: IRow) => {
    let redirectTo = '';

    switch ((type as string)?.toLowerCase()) {
      case CategoryService.SERVICE:
        redirectTo = `${RequestResource.SERVICE}/${id}`;
        break;
      case CategoryService.CATEGORY:
      default:
        redirectTo = `${RequestResource.CATEGORY}/${id}`;
        break;
    }
    if (redirectTo) {
      redirect(redirectTo);
    }
  };
  return <Table rows={rows} columns={COLUMNS} handleChange={handleRowChange} canEdit={true} handleEdit={handleEdit} />;
}

const FilterBar = (props: any) => {
  const { setFilters } = useListContext();
  useEffect(() => {
    return () => setFilters({}, []);
  }, []);

  return (
    <Filter {...props}>
      <TextInput
        className={'default_search_input'}
        label="Search"
        source="id~=|name~like|description~like"
        resettable
        alwaysOn
      />
    </Filter>
  );
};

export default function ListCategory(props: IListCategoryProps) {
  const { permissions, currentUserRole, expanded } = props;
  const [resetExpand, setResetExpand] = useState<boolean>(false);
  const canDelete = hasPermission(permissions, RequestResource.CATEGORY, PermissionLevel.DELETE);
  const dispatch = useDispatch();

  useEffect(() => {
    setResetExpand(true);
  }, []);

  if (resetExpand) {
    expanded?.map((it: number) => {
      dispatch({
        type: TOGGLE_LIST_ITEM_EXPAND,
        payload: it,
        meta: { resource: RequestResource.CATEGORY },
      });
    });
    setResetExpand(false);
  }

  const exporter = (orders: any, selectedIds: any) => {
    const categoriesForExport = Object.values(orders).filter((order: any) => {
      return selectedIds.includes(order.id);
    });

    const csv = convertToCSV({
      data: categoriesForExport,
      fields: ['id', 'icon', 'name', 'description', 'categoryId'],
    });
    downloadCSV(csv, 'categories');
  };

  const BulkActionButtons = (props: any) => {
    const { selectedIds } = props;
    const { total, data } = useListContext();

    return (
      <React.Fragment>
        {canDelete && <BulkDeleteButton {...props} />}
        {[Role.ADMIN, Role.SUPER_ADMIN].includes(currentUserRole as Role) && (
          <ExportButton disabled={!total} onClick={() => exporter(data, selectedIds)} />
        )}
      </React.Fragment>
    );
  };

  return (
    <List
      exporter={false}
      className="list-categories"
      filters={<FilterBar />}
      bulkActionButtons={canDelete ? <BulkActionButtons /> : false}
      pagination={false}
      {...props}
    >
      <Datagrid
        className="react-admin-table"
        expand={<Category />}
        isRowSelectable={() => canDelete}
        isRowExpandable={(row: any) => !!row.services?.length || !!row.categories?.length}
      >
        <TextField source="id" />
        <FunctionField label="Name" render={(record: any) => record.name} />
        <FunctionField label="Description" render={(record: any) => record.description} />
        <FunctionField source="isPublic" sortable={false} render={(record: any) => booleanToYesOrNo(record.isPublic)} />
        <FunctionField
          label="Created By"
          render={(record: any) =>
            record?.createdById ? (
              <Link to={`/${RequestResource.USER}/${record.createdById}`}>{record?.createdByName || ''}</Link>
            ) : (
              record?.createdByName || ''
            )
          }
        />
        {hasPermission(permissions, RequestResource.CATEGORY, PermissionLevel.UPDATE) ? (
          <Tooltip placement="top" title="Edit Category">
            <EditButton label="" />
          </Tooltip>
        ) : null}
      </Datagrid>
    </List>
  );
}
