import React from 'react';
import { Tooltip } from '@material-ui/core';
import { IStatusTagColor } from '../../../types';

interface IStatusTagProps {
  label: string;
  tooltipText?: string;
  classes?: string[];
  styles: IStatusTagColor;
}

export default function StatusTag(props: IStatusTagProps) {
  const { label, classes, styles, tooltipText } = props;
  const className = classes ? ` ${classes.join(' ')}` : '';

  return (
    <Tooltip
      PopperProps={{
        className: 'tooltip',
      }}
      className="status-indicator"
      title={tooltipText || ''}
    >
      <span className={`crs-chip${className}`} style={styles}>
        {label}
      </span>
    </Tooltip>
  );
}
