import { connect } from 'react-redux';
import EditOrder from '../../components/order/EditOrder';
import { IAppState, IAppDispatch } from '../../types';
import { calculateOrder, calculateOrderFailure } from '../../actions/order';

const mapStateToProps = (state: IAppState) => ({
  orderLoading: state.order.orderLoading,
  orderPriceInfo: state.order.orderPrice,
  role: state.auth.role,
  loading: state.order.loading,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({
  calculateOrder: (data: any) => dispatch(calculateOrder(data)),
  resetOrderPriceInfo: () => dispatch(calculateOrderFailure()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditOrder);
