import { ServiceType, StorageServiceType, IStorageService } from '../../types';
import { StorageFactory } from '../../factories';

class StorageSelector {
  /**
   * Singleton instance of the local storage service.
   */
  static storage: IStorageService;

  /**
   * Singleton getter method for the storage service.
   * Returns the existing one in the opposite case.
   */
  public static getStorageService(): IStorageService {
    if (!this.storage) {
      this.storage = StorageFactory.createStorageService(ServiceType.STORAGE, StorageServiceType.LOCAL);
    }
    return this.storage;
  }
}

export function getStorageService(): IStorageService {
  return StorageSelector.getStorageService();
}
