export interface IBundle {
  name: string;
  description: string;
  price: number;
  bundleFor: number;
}

export enum BundleColumn {
  ID = 'id',
  NAME = 'name',
  DESCRIPTION = 'description',
  PRICE = 'price',
  BUNDLE_FOR = 'bundleFor',
}

export enum BundleField {
  NAME = 'name',
  DESCRIPTION = 'description',
  PRICE = 'price',
  BUNDLE_FOR = 'bundleFor',
  ICON = 'icon',
  IS_PUBLIC = 'isPublic',
}
