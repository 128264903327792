import { connect } from 'react-redux';
import ShowUser from '../../components/manageUsers/ShowUser';
import { IAppState, IAppDispatch } from '../../types';

const mapStateToProps = (state: IAppState) => ({
  users: state.admin.resources.user.data,
  cards: state.user.cards,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ShowUser);
