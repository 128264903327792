import {
  AuthAPIService,
  BundlesAPIService,
  ServicesAPIService,
  RolesPermissionsAPIService,
  LanguagesAPIService,
  TranslationsAPIService,
  PermissionsAPIService,
  ProfileAPIService,
  CategoriesAPIService,
  UsersAPIService,
  UserProfilePictureAPIService,
  FaqAPIService,
  LanguageFontFileAPIService,
  UserSpecializationListAPIService,
  PatientAPIService,
  PatientProfilePictureAPIService,
  OrdersAPIService,
  TemplatePictureAPIService,
  TemplatesAPIService,
  SettingsAPIService,
  PaymentsAPIService,
  CreditCardsAPIService,
  BranchesAPIService,
  TemplateActionsAPIService,
  UserNotificationAPIService,
} from '../../services';
import { QuickSearchAPIService } from '../../services/api/QuickSearchApiService';

class ApiServiceSelector {
  private static authAPIService: AuthAPIService;
  private static bundlesAPIService: BundlesAPIService;
  private static servicesAPIService: ServicesAPIService;
  private static rolesPermissionsAPIService: RolesPermissionsAPIService;
  private static permissionsAPIService: PermissionsAPIService;
  private static languagesAPIService: LanguagesAPIService;
  private static translationsAPIService: TranslationsAPIService;
  private static profileAPIService: ProfileAPIService;
  private static usersAPIService: UsersAPIService;
  private static categoriesAPIService: CategoriesAPIService;
  private static userProfilePictureAPIService: UserProfilePictureAPIService;
  private static faqAPIService: FaqAPIService;
  private static ordersAPIService: OrdersAPIService;
  private static languageFontFileAPIService: LanguageFontFileAPIService;
  private static userSpecializationListAPIService: UserSpecializationListAPIService;
  private static patientAPIService: UserSpecializationListAPIService;
  private static patientProfilePictureAPIService: PatientProfilePictureAPIService;
  private static templatesAPIService: TemplatesAPIService;
  private static templatePictureAPIService: TemplatePictureAPIService;
  private static settingsAPIService: SettingsAPIService;
  private static paymentsAPIService: PaymentsAPIService;
  private static creditCardsAPIService: CreditCardsAPIService;
  private static branchesAPIService: BranchesAPIService;
  private static templateActionsAPIService: TemplateActionsAPIService;
  private static userNotificationAPIService: UserNotificationAPIService;
  private static quickSearchApiService: QuickSearchAPIService;

  public static getAuthAPIService(): AuthAPIService {
    if (!this.authAPIService) {
      this.authAPIService = new AuthAPIService();
    }
    return this.authAPIService;
  }

  public static getBundlesAPIService(): BundlesAPIService {
    if (!this.bundlesAPIService) {
      this.bundlesAPIService = new BundlesAPIService();
    }
    return this.bundlesAPIService;
  }

  public static getServicesAPIService(): ServicesAPIService {
    if (!this.servicesAPIService) {
      this.servicesAPIService = new ServicesAPIService();
    }
    return this.servicesAPIService;
  }

  public static getRolesPermissionsAPIService(): RolesPermissionsAPIService {
    if (!this.rolesPermissionsAPIService) {
      this.rolesPermissionsAPIService = new RolesPermissionsAPIService();
    }
    return this.rolesPermissionsAPIService;
  }

  public static getPermissionsAPIService(): PermissionsAPIService {
    if (!this.permissionsAPIService) {
      this.permissionsAPIService = new PermissionsAPIService();
    }
    return this.permissionsAPIService;
  }

  public static getLanguagesAPIService(): LanguagesAPIService {
    if (!this.languagesAPIService) {
      this.languagesAPIService = new LanguagesAPIService();
    }
    return this.languagesAPIService;
  }

  public static getTranslationsAPIService(): TranslationsAPIService {
    if (!this.translationsAPIService) {
      this.translationsAPIService = new TranslationsAPIService();
    }
    return this.translationsAPIService;
  }

  public static getProfileAPIService(): ProfileAPIService {
    if (!this.profileAPIService) {
      this.profileAPIService = new ProfileAPIService();
    }
    return this.profileAPIService;
  }

  public static getCategoriesAPIService(): CategoriesAPIService {
    if (!this.categoriesAPIService) {
      this.categoriesAPIService = new CategoriesAPIService();
    }
    return this.categoriesAPIService;
  }

  public static getUsersAPIService(): UsersAPIService {
    if (!this.usersAPIService) {
      this.usersAPIService = new UsersAPIService();
    }
    return this.usersAPIService;
  }

  public static getQuickSearchAPIService(): QuickSearchAPIService {
    if (!this.quickSearchApiService) {
      this.quickSearchApiService = new QuickSearchAPIService();
    }
    return this.quickSearchApiService;
  }

  public static getUserProfilePictureAPIService(): UserProfilePictureAPIService {
    if (!this.userProfilePictureAPIService) {
      this.userProfilePictureAPIService = new UserProfilePictureAPIService();
    }
    return this.userProfilePictureAPIService;
  }

  public static getFaqAPIService(): FaqAPIService {
    if (!this.faqAPIService) {
      this.faqAPIService = new FaqAPIService();
    }
    return this.faqAPIService;
  }

  public static getOrdersAPIService(): OrdersAPIService {
    if (!this.ordersAPIService) {
      this.ordersAPIService = new OrdersAPIService();
    }
    return this.ordersAPIService;
  }

  public static getLanguageFontFileAPIService(): LanguageFontFileAPIService {
    if (!this.languageFontFileAPIService) {
      this.languageFontFileAPIService = new LanguageFontFileAPIService();
    }
    return this.languageFontFileAPIService;
  }

  public static getUserSpecializationListAPIService(): UserSpecializationListAPIService {
    if (!this.userSpecializationListAPIService) {
      this.userSpecializationListAPIService = new UserSpecializationListAPIService();
    }
    return this.userSpecializationListAPIService;
  }

  public static getPatientAPIService(): PatientAPIService {
    if (!this.patientAPIService) {
      this.patientAPIService = new PatientAPIService();
    }
    return this.patientAPIService;
  }

  public static getPatientProfilePictureAPIService(): PatientProfilePictureAPIService {
    if (!this.patientProfilePictureAPIService) {
      this.patientProfilePictureAPIService = new PatientProfilePictureAPIService();
    }
    return this.patientProfilePictureAPIService;
  }

  public static getTemplatesAPIService(): TemplatesAPIService {
    if (!this.templatesAPIService) {
      this.templatesAPIService = new TemplatesAPIService();
    }
    return this.templatesAPIService;
  }

  public static getTemplatePictureAPIService(): TemplatePictureAPIService {
    if (!this.templatePictureAPIService) {
      this.templatePictureAPIService = new TemplatePictureAPIService();
    }
    return this.templatePictureAPIService;
  }

  public static getSettingsAPIService(): SettingsAPIService {
    if (!this.settingsAPIService) {
      this.settingsAPIService = new SettingsAPIService();
    }
    return this.settingsAPIService;
  }

  public static getPaymentsAPIService(): PaymentsAPIService {
    if (!this.paymentsAPIService) {
      this.paymentsAPIService = new PaymentsAPIService();
    }
    return this.paymentsAPIService;
  }

  public static getCreditCardsAPIService(): CreditCardsAPIService {
    if (!this.creditCardsAPIService) {
      this.creditCardsAPIService = new CreditCardsAPIService();
    }
    return this.creditCardsAPIService;
  }

  public static getBranchesAPIService(): BranchesAPIService {
    if (!this.branchesAPIService) {
      this.branchesAPIService = new BranchesAPIService();
    }
    return this.branchesAPIService;
  }

  public static getTemplateActionsAPIService(): TemplateActionsAPIService {
    if (!this.templateActionsAPIService) {
      this.templateActionsAPIService = new TemplateActionsAPIService();
    }
    return this.templateActionsAPIService;
  }

  public static getUserNotificationsAPIService(): UserNotificationAPIService {
    if (!this.userNotificationAPIService) {
      this.userNotificationAPIService = new UserNotificationAPIService();
    }
    return this.userNotificationAPIService;
  }
}

export function getAuthAPIService(): AuthAPIService {
  return ApiServiceSelector.getAuthAPIService();
}

export function getBundlesAPIService(): BundlesAPIService {
  return ApiServiceSelector.getBundlesAPIService();
}

export function getServicesAPIService(): ServicesAPIService {
  return ApiServiceSelector.getServicesAPIService();
}

export function getRolesPermissionsAPIService(): RolesPermissionsAPIService {
  return ApiServiceSelector.getRolesPermissionsAPIService();
}

export function getPermissionsAPIService(): PermissionsAPIService {
  return ApiServiceSelector.getPermissionsAPIService();
}

export function getLanguagesAPIService(): LanguagesAPIService {
  return ApiServiceSelector.getLanguagesAPIService();
}

export function getTranslationsAPIService(): TranslationsAPIService {
  return ApiServiceSelector.getTranslationsAPIService();
}

export function getProfileAPIService(): ProfileAPIService {
  return ApiServiceSelector.getProfileAPIService();
}

export function getCategoriesAPIService(): CategoriesAPIService {
  return ApiServiceSelector.getCategoriesAPIService();
}

export function getUsersAPIService(): UsersAPIService {
  return ApiServiceSelector.getUsersAPIService();
}

export function getUserProfilePictureAPIService(): UserProfilePictureAPIService {
  return ApiServiceSelector.getUserProfilePictureAPIService();
}

export function getFaqAPIService(): FaqAPIService {
  return ApiServiceSelector.getFaqAPIService();
}

export function getOrdersAPIService(): OrdersAPIService {
  return ApiServiceSelector.getOrdersAPIService();
}

export function getLanguageFontFileAPIService(): LanguageFontFileAPIService {
  return ApiServiceSelector.getLanguageFontFileAPIService();
}

export function getUserSpecializationListAPIService(): UserSpecializationListAPIService {
  return ApiServiceSelector.getUserSpecializationListAPIService();
}

export function getPatientAPIService(): PatientAPIService {
  return ApiServiceSelector.getPatientAPIService();
}

export function getPatientProfilePictureAPIService(): PatientProfilePictureAPIService {
  return ApiServiceSelector.getUserProfilePictureAPIService();
}

export function getTemplatesAPIService(): TemplatesAPIService {
  return ApiServiceSelector.getTemplatesAPIService();
}

export function getTemplatePictureAPIService(): TemplatesAPIService {
  return ApiServiceSelector.getTemplatePictureAPIService();
}

export function getSettingsAPIService(): SettingsAPIService {
  return ApiServiceSelector.getSettingsAPIService();
}

export function getPaymentsAPIService(): TemplatesAPIService {
  return ApiServiceSelector.getPaymentsAPIService();
}

export function getCreditCardsAPIService(): CreditCardsAPIService {
  return ApiServiceSelector.getCreditCardsAPIService();
}

export function getBranchesAPIService(): BranchesAPIService {
  return ApiServiceSelector.getBranchesAPIService();
}

export function getTemplateActionsAPIService(): TemplatesAPIService {
  return ApiServiceSelector.getTemplateActionsAPIService();
}

export function getUserNotificationsAPIService(): UserNotificationAPIService {
  return ApiServiceSelector.getUserNotificationsAPIService();
}

export function getQuickSearchAPIService(): QuickSearchAPIService {
  return ApiServiceSelector.getQuickSearchAPIService();
}
