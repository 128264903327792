import Table from '../core/table/Table';
import React from 'react';
import { ColumnType, IRow, PatientColumn } from '../../types';
import {
  capitalizeFirstLetter,
  convertCamelCaseToSnakeCase,
  isBase64String,
  formatDate,
  removeSymbolAndCapitalize,
} from '../../utils';
import { Avatar } from '@material-ui/core';
import { AppConfig } from '../../config';

export default function AttachedPatients(props: any) {
  const { handleDelete = () => {}, rows, defaultPicture, handleEditOrCancelIconClick } = props;

  const COLUMNS = [
    {
      field: PatientColumn.ID,
      headerName: capitalizeFirstLetter(PatientColumn.ID),
      editable: false,
      type: ColumnType.STRING,
    },
    {
      field: PatientColumn.PROFILE_IMAGE,
      headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(PatientColumn.PROFILE_IMAGE)),
      editable: false,
      type: ColumnType.STRING,
      cellRenderer: (row: IRow) => (
        <Avatar className="avatar-picture">
          <img
            src={
              isBase64String(row?.profilePicture as string)
                ? (row?.profilePicture as string)
                : `${AppConfig.httpAPIGatewayURL}/${row?.profilePicture || row?.defaultPicture || defaultPicture}`
            }
            alt={'default image'}
          />
        </Avatar>
      ),
    },
    {
      field: PatientColumn.FIRST_NAME,
      headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(PatientColumn.FIRST_NAME)),
      editable: false,
      type: ColumnType.STRING,
    },
    {
      field: PatientColumn.LAST_NAME,
      headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(PatientColumn.LAST_NAME)),
      editable: false,
      type: ColumnType.STRING,
    },
    {
      field: PatientColumn.EMAIL,
      headerName: capitalizeFirstLetter(PatientColumn.EMAIL),
      editable: false,
      type: ColumnType.STRING,
    },
    {
      field: PatientColumn.PHONE,
      headerName: capitalizeFirstLetter(PatientColumn.PHONE),
      editable: false,
      type: ColumnType.STRING,
    },
    {
      field: PatientColumn.GENDER,
      headerName: capitalizeFirstLetter(PatientColumn.GENDER),
      editable: false,
      type: ColumnType.STRING,
      cellRenderer: (row: IRow) => capitalizeFirstLetter(row?.[PatientColumn.GENDER] as string) || '',
    },
    {
      field: PatientColumn.BIRTH_DATE,
      headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(PatientColumn.BIRTH_DATE)),
      editable: false,
      type: ColumnType.STRING,
      cellRenderer: (row: IRow) => formatDate((row?.[PatientColumn.BIRTH_DATE] as string) || ''),
    },
    {
      field: '',
      headerName: capitalizeFirstLetter(PatientColumn.ACTIONS),
      editable: false,
      type: ColumnType.COMPONENT,
    },
  ];
  const { columns = COLUMNS } = props;

  return (
    <Table
      rows={rows}
      columns={columns}
      canDelete={true}
      handleDelete={handleDelete}
      noDataMessage="No patients"
      handleEditOrCancelIconClick={handleEditOrCancelIconClick}
    />
  );
}
