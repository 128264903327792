import { HTTPServiceType, IAppConfig } from '../types';

/**
 * The main configuration object for the application.
 */
export const AppConfig: IAppConfig = {
  /**
   * URL of the API Gateway for the REST API requests.
   */
  httpAPIGatewayURL: process.env.REACT_APP_API_URL || '',

  /**
   * Specifies the wrapper of the HTTP service implementation.
   * Possible values: AXIOS|FETCH
   * Default value: AXIOS
   */
  httpServiceType: HTTPServiceType.AXIOS,

  /**
   * Contact number
   */
  contactNumber: process.env.REACT_APP_CONTACT_NUMBER || '+37495500515',

  /**
   * Default APP language
   */
  defaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE || 'en_US',

  /**
   * Notification Sound URL
   */
  notificationSoundURL:
    process.env.REACT_APP_NOTIFICATION_SOUND_URL ||
    'https://notificationsounds.com/storage/sounds/file-sounds-1151-swiftly.ogg',

  /**
   * Order List update time
   */
  autoUpdateTime: +(process.env.REACT_APP_AUTO_UPDATE_TIME || 30000),
};
