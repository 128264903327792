import { BaseAPIService } from './BaseAPIService';
import { ILanguage } from '../../types';

export class LanguagesAPIService extends BaseAPIService {
  constructor() {
    super('');
  }

  public async getList({ query, filter }: any): Promise<any> {
    return this.get(`public/language`);
  }

  public async getOne(id: number): Promise<any> {
    return this.get(`public/language/${id}`);
  }

  public async create(data: ILanguage): Promise<any> {
    return this.post(`admin/language`, data);
  }

  public async update(data: ILanguage, id: number): Promise<any> {
    return this.put(`admin/language/${id}`, data);
  }

  public async remove(id: number): Promise<any> {
    return this.delete(`admin/language/${id}`);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(`admin/language`, data);
  }
}
