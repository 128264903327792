import React, { useState } from 'react';
import { Title } from 'react-admin';
import PieChart from '../core/charts/PieChart';
import BarChart from '../core/charts/BarChart';
import { Grid } from '@material-ui/core';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const data = [
  { service: 'laboratory services', quantity: 110 },
  { service: 'pediatric specialty care', quantity: 30 },
  { service: 'dental services', quantity: 100 },
  { service: 'emergency room services', quantity: 90 },
  { service: 'x ray/radiology services', quantity: 85 },
  { service: 'primary care services', quantity: 250 },
  { service: 'other services', quantity: 100 },
];

export default function Dashboard() {
  const [dates, setDates] = useState<any>(null);

  return (
    <div className="dashboard">
      <Title title="Welcome to the administration" />
      <div className="datePicker-container">
        <RangePicker showTime={false} format="YYYY-MM-DD" value={dates} onChange={(date: any) => setDates(date)} />
      </div>
      <Grid container className="charts-container">
        <Grid item xs={12} md={6} xl={3}>
          <PieChart data={data} />
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <PieChart data={data} innerRadius={0.6} />
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <PieChart data={data} />
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <PieChart data={data} innerRadius={0.6} />
        </Grid>
        <Grid item xs={12}>
          <BarChart data={data} rotated={false} />
        </Grid>
        <Grid item xs={12} xl={6}>
          <BarChart data={data} rotated={true} />
        </Grid>
        <Grid item xs={12} xl={6}>
          <BarChart data={data} rotated={true} />
        </Grid>
      </Grid>
    </div>
  );
}
