import React, { useState } from 'react';
import { useDataProvider, useRedirect, Loading } from 'react-admin';
import {
  Button,
  Card,
  CardActions,
  Divider,
  FormControl,
  Typography,
} from '@material-ui/core';
import {
  capitalizeFirstLetter,
  DEFAULT_VALIDATIONS_INFO,
  hasPermission,
  isValid,
  validate,
} from '../../utils';
import {
  IErrors,
  IInputField,
  IPermission, OrderFields, OrderPaymentMethod, OrderPaymentStatus,
  PermissionLevel,
  RequestResource,
  ValidationType,
} from '../../types';
import SaveIcon from '@material-ui/icons/Save';
import Input from '../core/input/Input';
import Select from '../core/select/Select';
import { UserNotificationAction, UserNotificationResource } from '../../types/data/userNotification';

export interface ICreateUserNotificationProps {
  permissions: IPermission;
}

export default function CreateUserNotification(props: ICreateUserNotificationProps) {
  const { permissions } = props;
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<IErrors>({
    title: { ...DEFAULT_VALIDATIONS_INFO },
    fullText: { ...DEFAULT_VALIDATIONS_INFO },
    resource: { ...DEFAULT_VALIDATIONS_INFO },
    entity: { ...DEFAULT_VALIDATIONS_INFO },
    action: { ...DEFAULT_VALIDATIONS_INFO },
    userId: { ...DEFAULT_VALIDATIONS_INFO },
  });
  const [values, setValues] = useState<IInputField>({
    title: '',
    fullText: '',
    resource: '',
    entity: '',
    action: '',
    userId: '',
  });

  if (loading) return <Loading />;

  const { title, fullText, resource, entity, action, userId } = values;

  const handleChange = (value: string | number | boolean, name: string) => {
    const { validationTypes, config } = getValidationTypes(name);
    const newErrors = {
      ...errors,
      [name]: validate('' + value, validationTypes, name, config),
    };
    setValues({ ...values, [name]: value });
    setErrors(newErrors);
  };

  const checkValidationAndSave = () => {
    const keys = Object.keys(errors);
    let newErrors = { ...errors };

    for (const key of keys) {
      const value = values[key];
      const { validationTypes, config } = getValidationTypes(key);
      newErrors = {
        ...newErrors,
        [key]: validate(value, validationTypes, key, config),
      };
    }
    if (isValid(newErrors)) {
      handleSave();
    }
    setErrors(newErrors);
  };

  const getValidationTypes = (name: string) => {
    const validationTypes = [];
    let config = {};

    if (name === 'title' || name === 'userId') {
      validationTypes.push(ValidationType.REQUIRED);
    }

    config = {
      minLength: 3,
    };
    return {
      validationTypes,
      config,
    };
  };

  const handleSave = () => {
    const newValues = { ...values };
    for (const k of Object.keys(newValues)) {
      if (newValues[k] === '') {
        delete newValues[k];
      }
    }
    setLoading(true);
    dataProvider
      .create(RequestResource.USER_NOTIFICATION, {
        data: {
          ...newValues,
        },
      } as any)
      .then(() => {
        setLoading(false);
        redirect(`/${RequestResource.USER_NOTIFICATION}`);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleCancelClick = () => {
    redirect(`/${RequestResource.USER_NOTIFICATION}`);
  };

  const getResourceData = () => {
    return Object.values(UserNotificationResource)?.map((it: string) => ({
      value: it,
      label: capitalizeFirstLetter(it, true),
    }));
  };

  const getActionData = () => {
    return Object.values(UserNotificationAction)?.map((it: string) => ({
      value: it,
      label: capitalizeFirstLetter(it, true),
    }));
  };

  return (
    <div className="edit-user-notification">
      <FormControl className="edit-user-notification-form">
        <div className="user-notification-header">
          <Typography variant="h5">User Notification create</Typography>
        </div>
        <Divider className="divider" />
        <div className="body">
          <Input
            required={true}
            label="User ID"
            value={userId}
            errorInfo={errors.userId}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'userId')}
          />
          <Input
            required={true}
            label="Title"
            value={title}
            errorInfo={errors.title}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'title')}
          />
          <Input
            required={false}
            label="Full Text"
            value={fullText}
            errorInfo={errors.fullText}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'fullText')}
          />
          <Select
            required={false}
            label="Action"
            data={getActionData()}
            value={action || ''}
            errorInfo={errors.action}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<{ value: any }>) => {
              if (e.target.value === UserNotificationAction.TEXT) {
                setValues({
                  ...values,
                  resource: '',
                  entity: '',
                })
              }
              return handleChange(e.target.value, 'action');
            }}
          />
          {action === UserNotificationAction.NAVIGATE ? (
            <>
              <Select
                required={false}
                label="Resource"
                data={getResourceData()}
                value={resource || ''}
                errorInfo={errors.resource}
                classes={['text-field']}
                handleChange={(e: React.ChangeEvent<{ value: any }>) =>
                  handleChange(e.target.value, 'resource')
                }
              />
              <Input
                required={false}
                label="Entity ID"
                value={entity}
                errorInfo={errors.entity}
                classes={['text-field']}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'entity')}
              />
            </>
          ) : ''}
        </div>
        <Card className="card">
          <CardActions className="card-actions">
            <div>
              {hasPermission(permissions, RequestResource.USER_NOTIFICATION, PermissionLevel.CREATE) ? (
                <Button variant="contained" color="primary" onClick={checkValidationAndSave} startIcon={<SaveIcon />}>
                  Save
                </Button>
              ) : null}
              <Button variant="outlined" color="primary" onClick={handleCancelClick} className="cancel-button">
                Cancel
              </Button>
            </div>
          </CardActions>
        </Card>
      </FormControl>
    </div>
  );
}
