import { ServiceType, StorageServiceType, IStorageService } from '../types';
import { LocalStorage } from '../services';

export class StorageFactory {
  /**
   * Generate a service instance based on the type and subType.
   * @param type: ServiceType
   * @param subType: StorageServiceType
   */
  public static createStorageService(type: ServiceType, subType: StorageServiceType): IStorageService {
    switch (type) {
      case ServiceType.STORAGE:
      default:
        switch (subType) {
          case StorageServiceType.LOCAL:
            return new LocalStorage();
        }
    }
  }
}
