import React, { useEffect, useState } from 'react';
import { useDataProvider, Loading, useRedirect } from 'react-admin';
import { Button, Card, CardActions, Divider, Typography } from '@material-ui/core';
import Table from '../core/table/Table';
import {
  RequestResource,
  IRolePermission,
  PermissionLevel,
  IPermissionLevel,
  ColumnType,
  IPermission,
  IRow,
} from '../../types';
import { removeSymbolAndCapitalize } from '../../utils';

export interface IShowRolePermissionProps {
  id?: string;
}

const PERMISSION_NAME_COLUMN: string = 'name';
const IS_EDIT_MODE: string = 'isEditMode';

const COLUMNS = [
  {
    field: PERMISSION_NAME_COLUMN,
    headerName: removeSymbolAndCapitalize(PERMISSION_NAME_COLUMN),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: PermissionLevel.READ,
    headerName: removeSymbolAndCapitalize(PermissionLevel.READ),
    editable: false,
    showTag: true,
    type: ColumnType.CHECKBOX,
  },
  {
    field: PermissionLevel.CREATE,
    headerName: removeSymbolAndCapitalize(PermissionLevel.CREATE),
    editable: false,
    showTag: true,
    type: ColumnType.CHECKBOX,
  },
  {
    field: PermissionLevel.UPDATE,
    headerName: removeSymbolAndCapitalize(PermissionLevel.UPDATE),
    editable: false,
    showTag: true,
    type: ColumnType.CHECKBOX,
  },
  {
    field: PermissionLevel.DELETE,
    headerName: removeSymbolAndCapitalize(PermissionLevel.DELETE),
    editable: false,
    showTag: true,
    type: ColumnType.CHECKBOX,
  },
];

export default function ShowRolePermission(props: IShowRolePermissionProps) {
  const { id } = props;

  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [roleName, setRoleName] = useState<string>('');
  const [rows, setRows] = useState<IRow[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) {
      return;
    }
    setLoading(true);
    dataProvider
      .getOne(RequestResource.ROLE, { id })
      .then(({ data }) => {
        setLoading(false);
        const permissionsData = data as IRolePermission;
        setRoleName(permissionsData.name);
        prepareRowData({ ...permissionsData.permissions });
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const prepareRowData = (permissions: IPermission) => {
    const permissionNames = Object.keys(permissions);
    const data: IRow[] = [];
    permissionNames.map((permissionName: string) => {
      const currentPermission: IPermissionLevel = permissions[permissionName];
      const rowData: IRow = {
        [PERMISSION_NAME_COLUMN]: permissionName,
        [PermissionLevel.READ]: currentPermission[PermissionLevel.READ],
        [PermissionLevel.CREATE]: currentPermission[PermissionLevel.CREATE],
        [PermissionLevel.UPDATE]: currentPermission[PermissionLevel.UPDATE],
        [PermissionLevel.DELETE]: currentPermission[PermissionLevel.DELETE],
        [IS_EDIT_MODE]: false,
      };
      data.push(rowData);
    });
    setRows(data);
  };

  if (loading) return <Loading />;

  const handleCancelClick = () => {
    redirect(`/${RequestResource.ROLE}`);
  };

  return (
    <div className="view-role-permission">
      <Typography variant="h5">Role view</Typography>
      <Divider className="divider" />
      <div className="role-info">
        <div className="role-name">
          <Typography variant="h5" component="h2">
            {removeSymbolAndCapitalize(roleName)}
          </Typography>
        </div>
        <div className="permissions-table">
          <Table columns={COLUMNS} rows={rows} />
        </div>
      </div>
      <div className="permissions-action">
        <Card className="card">
          <CardActions className="card-actions">
            <Button variant="outlined" color="primary" onClick={handleCancelClick} className="cancel-button">
              Cancel
            </Button>
          </CardActions>
        </Card>
      </div>
    </div>
  );
}
