/**
 * Describes the methods which should be implemented by Storage Service wrappers.
 */
export interface IStorageService {
  get: (key: string) => string | null;
  set: (key: string, value: string) => void;
  remove: (key: string) => void;
  clear: () => void;
}

export enum StorageServiceType {
  LOCAL = 'LOCAL',
}

export enum LocalStorageKey {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  REFRESH_KEY = 'REFRESH_KEY',
  DEVICE_ID = 'DEVICE_ID',
  ROLE = 'ROLE',
  EXP = 'EXP',
}
