import { OrderPaymentStatus, OrderPaymentStatusColors, OrderStatus, OrderStatusColors } from '../types';

export const ORDER_STATUS_COLORS: OrderStatusColors = {
  [OrderStatus.PENDING]: {
    backgroundColor: '#FFEBE6',
    color: '#BF2600',
  },
  [OrderStatus.IN_PROGRESS]: {
    backgroundColor: '#EAE6FF',
    color: '#403294',
  },
  [OrderStatus.COMPLETED]: {
    backgroundColor: '#DEEBFF',
    color: '#0747A6',
  },
  [OrderStatus.DONE]: {
    backgroundColor: '#E3FCEF',
    color: '#006644',
  },
  [OrderStatus.CLOSED]: {
    backgroundColor: '#DFE1E6',
    color: '#42526E',
  },
  [OrderStatus.CANCELLED]: {
    backgroundColor: '#FFF0B3',
    color: '#172B4D',
  },
};

export const ORDER_PAYMENT_STATUS_COLORS: OrderPaymentStatusColors = {
  [OrderPaymentStatus.PAID]: {
    backgroundColor: 'green',
    color: 'white',
  },
  [OrderPaymentStatus.UNPAID]: {
    backgroundColor: 'red',
    color: 'white',
  },
  [OrderPaymentStatus.PENDING]: {
    backgroundColor: 'purple',
    color: 'white',
  },
};
