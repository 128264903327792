import { connect } from 'react-redux';
import ListCategory from '../../components/categories/ListCategory';
import { IAppState, IAppDispatch } from '../../types';

const mapStateToProps = (state: IAppState) => ({
  currentUserRole: state.auth.role,
  expanded: state.admin.resources?.category?.list?.expanded,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListCategory);
