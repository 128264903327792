import { connect } from 'react-redux';
import App from '../components/App';
import { SET_ROLE_PERMISSION, setRolePermission } from '../actions/auth';
import { IAppState, IAppDispatch } from '../types';
import { loadTranslations } from '../actions/localization';

const mapStateToProps = (state: IAppState) => ({
  role: state.auth.role,
  permissions: state.auth.permissions,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({
  setRolePermission: (data: SET_ROLE_PERMISSION) => dispatch(setRolePermission(data)),
  loadTranslations: () => dispatch(loadTranslations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
