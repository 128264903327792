import { handleActions, Action } from 'redux-actions';
import {
  DELETE_ATTACHMENT_REQUEST,
  DELETE_ATTACHMENT_SUCCESS,
  DELETE_ATTACHMENT_FAILURE,
  SAVE_ATTACHMENTS_REQUEST,
  SAVE_ATTACHMENTS_SUCCESS,
  SAVE_ATTACHMENTS_FAILURE,
  GET_ORDER_SERVICE_REQUEST,
  GET_ORDER_SERVICE_SUCCESS,
  GET_ORDER_SERVICE_FAILURE,
  SAVE_ORDER_SERVICE_REQUEST,
  SAVE_ORDER_SERVICE_SUCCESS,
  SAVE_ORDER_SERVICE_FAILURE,
  TOGGLE_ORDER_NOTIFICATION,
  CALCULATE_ORDER_PRICE_REQUEST,
  CALCULATE_ORDER_PRICE_SUCCESS,
  CALCULATE_ORDER_PRICE_FAILURE,
} from '../actions/order';
import { IOrderState, OrderNotification, OrderPrice } from '../types';

const DEFAULT_STATE: IOrderState = {
  orderLoading: false,
  loading: false,
  orderService: {},
  orderNotification: {
    show: false,
    message: '',
  },
  orderPrice: {},
};

export const order = handleActions<IOrderState, any>(
  {
    [DELETE_ATTACHMENT_REQUEST]: (state: IOrderState): IOrderState => {
      return {
        ...state,
        loading: true,
      };
    },
    [DELETE_ATTACHMENT_SUCCESS]: (state: IOrderState): IOrderState => {
      return {
        ...state,
        loading: false,
      };
    },
    [DELETE_ATTACHMENT_FAILURE]: (state: IOrderState): IOrderState => {
      return {
        ...state,
        loading: false,
      };
    },
    [SAVE_ATTACHMENTS_REQUEST]: (state: IOrderState): IOrderState => {
      return {
        ...state,
        loading: true,
      };
    },
    [SAVE_ATTACHMENTS_SUCCESS]: (state: IOrderState): IOrderState => {
      return {
        ...state,
        loading: false,
      };
    },
    [SAVE_ATTACHMENTS_FAILURE]: (state: IOrderState): IOrderState => {
      return {
        ...state,
        loading: false,
      };
    },
    [GET_ORDER_SERVICE_REQUEST]: (state: IOrderState): IOrderState => {
      return {
        ...state,
        orderLoading: true,
      };
    },
    [GET_ORDER_SERVICE_SUCCESS]: (state: IOrderState, action: Action<GET_ORDER_SERVICE_SUCCESS>): IOrderState => {
      return {
        ...state,
        orderLoading: false,
        orderService: action.payload,
      };
    },
    [GET_ORDER_SERVICE_FAILURE]: (state: IOrderState): IOrderState => {
      return {
        ...state,
        orderLoading: false,
        orderService: [],
      };
    },
    [SAVE_ORDER_SERVICE_REQUEST]: (state: IOrderState): IOrderState => {
      return {
        ...state,
        loading: true,
      };
    },
    [SAVE_ORDER_SERVICE_SUCCESS]: (state: IOrderState): IOrderState => {
      return {
        ...state,
        loading: false,
      };
    },
    [SAVE_ORDER_SERVICE_FAILURE]: (state: IOrderState): IOrderState => {
      return {
        ...state,
        loading: false,
      };
    },
    [TOGGLE_ORDER_NOTIFICATION]: (state: IOrderState, action: Action<OrderNotification>): IOrderState => {
      return {
        ...state,
        orderNotification: action.payload,
      };
    },
    [CALCULATE_ORDER_PRICE_REQUEST]: (state: IOrderState): IOrderState => {
      return {
        ...state,
        loading: true,
      };
    },
    [CALCULATE_ORDER_PRICE_SUCCESS]: (state: IOrderState, action: Action<OrderPrice>): IOrderState => {
      return {
        ...state,
        loading: false,
        orderPrice: action.payload,
      };
    },
    [CALCULATE_ORDER_PRICE_FAILURE]: (state: IOrderState): IOrderState => {
      return {
        ...state,
        loading: false,
        orderPrice: {},
      };
    },
  },
  DEFAULT_STATE
);
