import React, { useEffect } from 'react';
import { Admin, Resource, Loading } from 'react-admin';
import UserIcon from '@material-ui/icons/Group';
import jwtDecode from 'jwt-decode';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Layout from '../containers/layout/Layout';
import ListBundle from '../containers/bundles/ListBundle';
import EditBundle from '../containers/bundles/EditBundle';
import CreateBundle from '../containers/bundles/CreateBundle';
import ListTemplate from '../containers/templates/ListTemplate';
import CreateTemplate from '../containers/templates/CreateTemplate';
import EditTemplate from '../containers/templates/EditTemplate';
import ListService from '../containers/services/ListService';
import EditService from '../containers/services/EditService';
import CreateService from '../containers/services/CreateService';
import ListRolePermission from '../containers/role-permission/ListRolePermission';
import EditRolePermission from '../containers/role-permission/EditRolePermission';
import ListLanguage from '../containers/languages/ListLanguage';
import EditLanguage from '../containers/languages/EditLanguage';
import CreateLanguage from '../containers/languages/CreateLanguage';
import ListTranslation from '../containers/translations/ListTranslation';
import CreateRolePermission from '../containers/role-permission/CreateRolePermission';
import ShowRolePermission from '../containers/role-permission/ShowRolePermission';
import ListCategory from '../containers/categories/ListCategory';
import EditCategory from '../containers/categories/EditCategory';
import CreateCategory from '../containers/categories/CreateCategory';
import Dashboard from '../containers/dashboard/Dashboard';
import Login from '../containers/login/Login';
import ListUsers from '../containers/manageUsers/ListUsers';
import ShowUser from '../containers/manageUsers/ShowUser';
import CreateUser from '../containers/manageUsers/CreateUser';
import EditUser from '../containers/manageUsers/EditUser';
import CreateFaq from '../containers/faq/CreateFaq';
import ListFaq from '../containers/faq/ListFaq';
import EditFaq from '../containers/faq/EditFaq';
import ShowPayment from '../containers/payments/ShowPayment';
import ListPayment from '../containers/payments/ListPayment';
import ListOrder from '../containers/order/ListOrder';
import EditOrder from '../containers/order/EditOrder';
import ViewOrder from '../containers/order/ViewOrder';
import ListSettings from '../containers/settings/ListSettings';
import CreateOrder from '../containers/order/CreateOrder';
import ListBranch from '../containers/branches/ListBranch';
import EditBranch from '../containers/branches/EditBranch';
import CreateBranch from '../containers/branches/CreateBranch';
import { history } from '../configureStore';
import dataProvider from '../dataProvider';
import authProvider from '../authProvider';
import customRoutes from '../customRoutes';
import { APP_MAIN_COLOR } from '../constants';
import { hasPermission, hasSomePermission } from '../utils';
import { RequestResource, PermissionLevel, LocalStorageKey, IUser, Role, IPermission, IAppRouteKey } from '../types';
import { getStorageService } from '../selectors';
import { SET_ROLE_PERMISSION } from '../actions/auth';
import TranslationIcon from '@material-ui/icons/Translate';
import GroupWork from '@material-ui/icons/GroupWork';
import Category from '@material-ui/icons/Category';
import RoomService from '@material-ui/icons/RoomService';
import CallToAction from '@material-ui/icons/CallToAction';
import Notifications from '@material-ui/icons/Notifications';
import LiveHelp from '@material-ui/icons/LiveHelp';
import LanguageIcon from '@material-ui/icons/Language';
import Payment from '@material-ui/icons/Payment';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import ImportContacts from '@material-ui/icons/ImportContacts';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Business from '@material-ui/icons/Business';
import { AppRoutes } from '../config';
import ListUserNotification from '../containers/userNotification/ListUserNotification';
import EditUserNotification from '../containers/userNotification/EditUserNotification';
import CreateUserNotification from '../containers/userNotification/CreateUserNotification';
import ListQuickSearch from '../containers/quickSearch/ListQuickSearch';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface ThemeOptions {
    themeName?: string;
  }
}

const palette = {
  secondary: { main: APP_MAIN_COLOR },
};

const themeName = '';
const theme = createMuiTheme({
  palette,
  themeName,
  overrides: {
    MuiTableRow: {
      head: {
        // position: 'sticky',
        // backgroundColor: 'white',
        // zIndex: 9999999,
        '& > th ': {
          color: '#303030',
          fontWeight: 'bold',
          position: 'unset',
          // top: -30,
        },
      },
    },
  },
});

export interface IAppComponentProps {
  role: string;
  setRolePermission: (data: SET_ROLE_PERMISSION) => void;
  permissions: IPermission;
  loadTranslations: () => void;
}

export default function App(props: IAppComponentProps) {
  const { setRolePermission, role, permissions, loadTranslations } = props;
  const storageService = getStorageService();
  const accessToken = storageService.get(LocalStorageKey.ACCESS_TOKEN);

  useEffect(() => {
    if (!accessToken) {
      history.push(AppRoutes[IAppRouteKey.LOGIN].path);
      return;
    }
    const {
      role: { name, permissions },
      userId,
    } = jwtDecode(accessToken) as IUser;
    setRolePermission({
      role: name,
      permissions,
      userId: userId as string,
    });
    loadTranslations();
  }, []);

  if ((!permissions || (!Object.keys(permissions).length && role !== Role.SUPER_ADMIN)) && accessToken) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Admin
        history={history}
        dataProvider={dataProvider as any}
        authProvider={authProvider}
        layout={Layout as any}
        customRoutes={customRoutes}
        loginPage={Login}
      >
        {(permissions: IPermission) => [
          <Resource
            name={RequestResource.QUICK_SEARCH}
            icon={SearchIcon}
            list={ListQuickSearch}
            create={CreateUser as any}
          />,
          hasSomePermission(permissions, RequestResource.ORDER) ? (
            <Resource
              name={RequestResource.ORDER}
              list={hasPermission(permissions, RequestResource.ORDER, PermissionLevel.READ) ? (ListOrder as any) : null}
              edit={
                hasPermission(permissions, RequestResource.ORDER, PermissionLevel.UPDATE) ? (EditOrder as any) : null
              }
              create={
                hasPermission(permissions, RequestResource.ORDER, PermissionLevel.CREATE) ? (CreateOrder as any) : null
              }
              show={hasPermission(permissions, RequestResource.ORDER, PermissionLevel.READ) ? (ViewOrder as any) : null}
              icon={ImportContacts}
            />
          ) : null,
          hasSomePermission(permissions, RequestResource.BUNDLE) ? (
            <Resource
              name={RequestResource.BUNDLE}
              list={
                hasPermission(permissions, RequestResource.BUNDLE, PermissionLevel.READ) ? (ListBundle as any) : null
              }
              edit={
                hasPermission(permissions, RequestResource.BUNDLE, PermissionLevel.UPDATE) ? (EditBundle as any) : null
              }
              create={
                hasPermission(permissions, RequestResource.BUNDLE, PermissionLevel.CREATE)
                  ? (CreateBundle as any)
                  : null
              }
              icon={GroupWork}
            />
          ) : null,
          hasSomePermission(permissions, RequestResource.CATEGORY) ? (
            <Resource
              name={RequestResource.CATEGORY}
              list={
                hasPermission(permissions, RequestResource.CATEGORY, PermissionLevel.READ)
                  ? (ListCategory as any)
                  : null
              }
              edit={
                hasPermission(permissions, RequestResource.CATEGORY, PermissionLevel.UPDATE)
                  ? (EditCategory as any)
                  : null
              }
              create={
                hasPermission(permissions, RequestResource.CATEGORY, PermissionLevel.CREATE)
                  ? (CreateCategory as any)
                  : null
              }
              icon={Category}
            />
          ) : null,
          hasSomePermission(permissions, RequestResource.SERVICE) ? (
            <Resource
              name={RequestResource.SERVICE}
              list={
                hasPermission(permissions, RequestResource.SERVICE, PermissionLevel.READ) ? (ListService as any) : null
              }
              edit={
                hasPermission(permissions, RequestResource.SERVICE, PermissionLevel.UPDATE)
                  ? (EditService as any)
                  : null
              }
              create={
                hasPermission(permissions, RequestResource.SERVICE, PermissionLevel.CREATE)
                  ? (CreateService as any)
                  : null
              }
              icon={RoomService}
            />
          ) : null,
          hasSomePermission(permissions, RequestResource.NOTIFICATION_TEMPLATE) ? (
            <Resource
              name={RequestResource.NOTIFICATION_TEMPLATE}
              list={
                hasPermission(permissions, RequestResource.NOTIFICATION_TEMPLATE, PermissionLevel.READ)
                  ? (ListTemplate as any)
                  : null
              }
              edit={
                hasPermission(permissions, RequestResource.NOTIFICATION_TEMPLATE, PermissionLevel.UPDATE)
                  ? (EditTemplate as any)
                  : null
              }
              create={
                hasPermission(permissions, RequestResource.NOTIFICATION_TEMPLATE, PermissionLevel.CREATE)
                  ? (CreateTemplate as any)
                  : null
              }
              icon={CallToAction}
            />
          ) : null,
          hasSomePermission(permissions, RequestResource.LANGUAGE) ? (
            <Resource
              name={RequestResource.LANGUAGE}
              list={
                hasPermission(permissions, RequestResource.LANGUAGE, PermissionLevel.READ)
                  ? (ListLanguage as any)
                  : null
              }
              edit={
                hasPermission(permissions, RequestResource.LANGUAGE, PermissionLevel.UPDATE)
                  ? (EditLanguage as any)
                  : null
              }
              create={
                hasPermission(permissions, RequestResource.LANGUAGE, PermissionLevel.CREATE)
                  ? (CreateLanguage as any)
                  : null
              }
              icon={LanguageIcon}
            />
          ) : null,
          hasSomePermission(permissions, RequestResource.TRANSLATION) ? (
            <Resource
              name={RequestResource.TRANSLATION}
              list={
                hasPermission(permissions, RequestResource.TRANSLATION, PermissionLevel.READ)
                  ? (ListTranslation as any)
                  : null
              }
              icon={TranslationIcon}
            />
          ) : null,
          hasSomePermission(permissions, RequestResource.USER_NOTIFICATION) ? (
            <Resource
              name={RequestResource.USER_NOTIFICATION}
              options={{
                label: 'User Notification',
              }}
              list={
                hasPermission(permissions, RequestResource.USER_NOTIFICATION, PermissionLevel.READ)
                  ? (ListUserNotification as any)
                  : null
              }
              edit={
                hasPermission(permissions, RequestResource.USER_NOTIFICATION, PermissionLevel.UPDATE)
                  ? (EditUserNotification as any)
                  : null
              }
              create={
                hasPermission(permissions, RequestResource.USER_NOTIFICATION, PermissionLevel.CREATE)
                  ? (CreateUserNotification as any)
                  : null
              }
              icon={Notifications}
            />
          ) : null,
          hasSomePermission(permissions, RequestResource.ROLE) ? (
            <Resource
              name={RequestResource.ROLE}
              list={ListRolePermission as any}
              edit={EditRolePermission as any}
              create={CreateRolePermission as any}
              show={ShowRolePermission as any}
              icon={AccessibilityIcon}
            />
          ) : null,
          hasSomePermission(permissions, RequestResource.USER) ? (
            <Resource
              name={RequestResource.USER}
              list={hasPermission(permissions, RequestResource.USER, PermissionLevel.READ) ? (ListUsers as any) : null}
              show={hasPermission(permissions, RequestResource.USER, PermissionLevel.READ) ? (ShowUser as any) : null}
              create={
                hasPermission(permissions, RequestResource.USER, PermissionLevel.CREATE) ? (CreateUser as any) : null
              }
              edit={hasPermission(permissions, RequestResource.USER, PermissionLevel.UPDATE) ? (EditUser as any) : null}
              icon={UserIcon}
            />
          ) : null,
          hasSomePermission(permissions, RequestResource.FAQ) ? (
            <Resource
              name={RequestResource.FAQ}
              list={hasPermission(permissions, RequestResource.FAQ, PermissionLevel.READ) ? (ListFaq as any) : null}
              create={
                hasPermission(permissions, RequestResource.FAQ, PermissionLevel.CREATE) ? (CreateFaq as any) : null
              }
              edit={hasPermission(permissions, RequestResource.FAQ, PermissionLevel.UPDATE) ? (EditFaq as any) : null}
              icon={LiveHelp}
            />
          ) : null,
          hasSomePermission(permissions, RequestResource.PAYMENT) ? (
            <Resource
              name={RequestResource.PAYMENT}
              list={
                hasPermission(permissions, RequestResource.PAYMENT, PermissionLevel.READ) ? (ListPayment as any) : null
              }
              show={
                hasPermission(permissions, RequestResource.PAYMENT, PermissionLevel.READ) ? (ShowPayment as any) : null
              }
              icon={Payment}
            />
          ) : null,
          hasSomePermission(permissions, RequestResource.SETTING) ? (
            <Resource
              name={RequestResource.SETTING}
              list={
                hasPermission(permissions, RequestResource.SETTING, PermissionLevel.READ) ? (ListSettings as any) : null
              }
              icon={SettingsIcon}
            />
          ) : null,
          hasSomePermission(permissions, RequestResource.BRANCH) ? (
            <Resource
              name={RequestResource.BRANCH}
              list={
                hasPermission(permissions, RequestResource.BRANCH, PermissionLevel.READ) ? (ListBranch as any) : null
              }
              create={
                hasPermission(permissions, RequestResource.BRANCH, PermissionLevel.CREATE)
                  ? (CreateBranch as any)
                  : null
              }
              edit={
                hasPermission(permissions, RequestResource.BRANCH, PermissionLevel.UPDATE) ? (EditBranch as any) : null
              }
              icon={Business}
            />
          ) : null,
          <Resource name={RequestResource.DASHBOARD} list={Dashboard as any} icon={DashboardIcon} />,
        ]}
      </Admin>
    </ThemeProvider>
  );
}
