import {
  ColumnType,
  IColumn,
  IPermission,
  IStatusTagColor,
  RequestResource,
} from '../../types';
import { Button, Divider, Tooltip, Typography } from '@material-ui/core';
import { Show, FunctionField, TextField, SimpleShowLayout, ShowButton } from 'react-admin';
import React from 'react';
import { history } from '../../configureStore';
import {
  convertCamelCaseToSnakeCase,
  convertServerDateToLocal,
  formatCurrency,
  removeSymbolAndCapitalize,
} from '../../utils';
import { dateTimeFormatForMoment } from '../../constants';
import { Link } from 'react-router-dom';
import StatusTag from '../core/status-tag/StatusTag';
import { PaymentStatus, PaymentTransitionFields } from '../../types/data/payment';
import { PAYMENT_STATUS_COLORS } from './ListPayment';
import Table from '../core/table/Table';

export interface IShowPaymentProps {
  permissions: IPermission;
  paymentData: {[key: string]: {
      paymentStatusTransitions: []
    }},
  match: {params: {id: number}}
}

const NO_TRANSITION_AUTHOR = 'No Transition Author';

const TRANSITION_COLUMNS: IColumn[] = [
  {
    field: PaymentTransitionFields.ID,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(PaymentTransitionFields.ID)),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: PaymentTransitionFields.STATUS,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(PaymentTransitionFields.STATUS)),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: any) => (
      <StatusTag
        styles={PAYMENT_STATUS_COLORS[row.status as PaymentStatus] as IStatusTagColor}
        label={removeSymbolAndCapitalize(row.status as string)}
      />
    ),
  },
  {
    field: PaymentTransitionFields.AUTHOR_NAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(PaymentTransitionFields.AUTHOR_NAME)),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: any) =>
      row?.author?.id ? (
        <Link to={`/${RequestResource.USER}/${row?.author?.id}`}>{row?.author?.name || NO_TRANSITION_AUTHOR}</Link>
      ) : (
        row?.author?.name || NO_TRANSITION_AUTHOR
      ),
  },
  {
    field: PaymentTransitionFields.DATE,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(PaymentTransitionFields.DATE)),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (record: any) => convertServerDateToLocal(record.createdAt, dateTimeFormatForMoment)
},
];

export default function ShowPayment(props: IShowPaymentProps) {
  const handleCancel = () => {
    history.push(`/${RequestResource.PAYMENT}`);
  };

  return (
    <div className="show-payment">
      <div className="show-payment-form">
        <Typography variant="h5" className="show-payment-title">
          Payment show
        </Typography>
        <Divider className="divider" />
        {/*// @ts-ignore*/}
        <Show {...props} className={'show_payment-single'}>
          <SimpleShowLayout>
            <TextField source='id' />
            <FunctionField
              label='Date/Time'
              render={(record: any) => convertServerDateToLocal(record.createdAt, dateTimeFormatForMoment)}
            />
            <FunctionField
              label='User'
              render={(record: any) =>
                record?.createdById ? (
                  <Link to={`/${RequestResource.USER}/${record.createdById}`}>
                    {record?.createdByName || ''}
                  </Link>
                ) : record?.createdByName || ''
              }
            />
            <FunctionField
              label='Order Id'
              render={(record: any) => {
                return <Link to={`/${RequestResource.ORDER}/${record.order?.id}`}>
                  {record.order?.id}
                </Link>;
              }}
            />
            <TextField label='Payment type' source='method' />
            <FunctionField
              sortBy='status'
              label='Status'
              render={(record: any) => {
                return (
                  <StatusTag
                    styles={PAYMENT_STATUS_COLORS[record.status as PaymentStatus] as IStatusTagColor}
                    label={record.status}
                    tooltipText={(() => {
                      const keys = Object.keys(record?.paymentStatusTransitions);

                      const date = (record?.paymentStatusTransitions?.[keys[keys.length - 1]] as { date: Date })?.date;

                      if (!date) return '';

                      return convertServerDateToLocal(date, dateTimeFormatForMoment);
                    })()}
                  />
                );
              }}
            />
            <FunctionField
              label='Price'
              source={'totalPrice'}
              render={(record: any) => {
                return <Tooltip
                  PopperProps={{
                    // className: 'tooltip',
                  }}
                  className='status-indicator'
                  title={<div>
                    <span>transportationPrice - {formatCurrency(record.transportationPrice)}</span>
                    <br/>
                    <span>orderPrice - {formatCurrency(record.orderPrice)}</span>
                    <br/>
                    <span>extraFee - {formatCurrency(record.extraFee)}</span>
                  </div>}
                >
              <span>
                {formatCurrency(record.totalPrice)}
              </span>
                </Tooltip>;
              }}
            />
            <FunctionField
              label='Amount paid'
              render={(record: any) => {
                return formatCurrency(record?.amountPaid);
              }}
            />
          </SimpleShowLayout>
        </Show>
      </div>

      <Typography variant="h6">Transitions History</Typography>
      <Divider className="divider secondary" />
      <Table
        columns={TRANSITION_COLUMNS}
        rows={props.paymentData?.[props.match.params.id]?.paymentStatusTransitions}
        noDataMessage={'No Transition Data'}
      />

      <div>
        <Button variant='outlined' color='primary' onClick={handleCancel} className='cancel-button'>
          Cancel
        </Button>
      </div>
    </div>
  );
}
