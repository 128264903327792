import { IStorageService } from '../../types';

export class LocalStorage implements IStorageService {
  private _storage = localStorage;

  get(key: string): string | null {
    return this._storage.getItem(key);
  }

  set(key: string, value: string): void {
    this._storage.setItem(key, value);
  }

  remove(key: string): void {
    this._storage.removeItem(key);
  }

  clear(): void {
    this._storage.clear();
  }
}
