import Dialog, { DialogActions, DialogContent, DialogTitle } from '../core/dialog/Dialog';
import { Button, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core';
import { DataNames, IPatientData, IPatientError, UserGender, ValidationType } from '../../types';
import React, { useState } from 'react';
import { dateFormat } from '../../constants';
import {
  capitalizeFirstLetter,
  DEFAULT_VALIDATIONS_INFO,
  FieldValue,
  getBase64Image,
  getMaxDate,
  isValid,
  validate,
} from '../../utils';
import { AppConfig } from '../../config';
import DatePicker from '../core/picker/datepicker/DatePicker';
import Input from '../core/input/Input';

const initialData: IPatientData = {
  [DataNames.FIRSTNAME]: '',
  [DataNames.LASTNAME]: '',
  [DataNames.EMAIL]: '',
  [DataNames.PHONE]: '',
  [DataNames.GENDER]: '',
  [DataNames.BIRTHDATE]: null,
};

const initialError: IPatientError = {
  [DataNames.FIRSTNAME]: { ...DEFAULT_VALIDATIONS_INFO },
  [DataNames.LASTNAME]: { ...DEFAULT_VALIDATIONS_INFO },
  // [DataNames.EMAIL]: { ...DEFAULT_VALIDATIONS_INFO },
  // [DataNames.PHONE]: { ...DEFAULT_VALIDATIONS_INFO },
  // [DataNames.GENDER]: { ...DEFAULT_VALIDATIONS_INFO },
  // [DataNames.BIRTHDATE]: { ...DEFAULT_VALIDATIONS_INFO },
};
const genderData: Array<string> = [UserGender.MALE, UserGender.FEMALE];

export interface ICreatePatientProps {
  addPatient: (arg: IPatientData) => void;
  userId: number | undefined;
  defaultPicture: string | undefined;
  toggleCreatePatientDialog: (arg: boolean) => void;
  showNotification: () => void;
}

export default function CreatePatient(props: ICreatePatientProps) {
  const { toggleCreatePatientDialog, addPatient, defaultPicture } = props;
  const [data, setData] = useState<IPatientData>(initialData);
  const [error, setError] = useState<IPatientError>(initialError);
  const [uploadedImage, setUploadedImage] = useState<File>();
  const [encodedImg, setEncodedImg] = useState<string>();

  const getValidationTypes = (name: DataNames) => {
    const validationTypes = [ValidationType.REQUIRED];
    let config = {};
    if (name === DataNames.FIRSTNAME || name === DataNames.LASTNAME) {
      validationTypes.push(ValidationType.MIN_LENGTH, ValidationType.MAX_LENGTH);
      config = {
        minLength: 3,
        maxLength: 30,
      };
    }
    return {
      validationTypes,
      config,
    };
  };

  const handleChangeData = (target: { name?: string; value: string | number | Date | null }) => {
    const { value, name } = target;
    const { validationTypes, config } = getValidationTypes(name as DataNames);

    if (name && initialError[name as string]) {
      let newErrors = {
        ...error,
        [name as string]: validate(value as FieldValue, validationTypes, name as string, config),
      };
      setError(newErrors);
    }

    setData({ ...data, [name as string]: value });
  };

  const handleAddPatient = () => {
    const newData = {
      ...data,
      [DataNames.BIRTHDATE]: (data[DataNames.BIRTHDATE] as Date)?.toISOString() || '',
    };
    const keys = Object.keys(error);
    let newErrors = { ...error };
    for (const key of keys) {
      const value = (newData as any)[key];
      const { validationTypes, config } = getValidationTypes(key as DataNames);
      newErrors = {
        ...newErrors,
        [key]: validate(value as FieldValue, validationTypes, key as string, config),
      };
    }
    if (isValid(newErrors)) {
      addPatient({ ...newData, profilePicture: encodedImg, isEditMode: false, patientId: Math.random() });
      toggleCreatePatientDialog(false);
    }
    setError(newErrors);
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.files?.[0];
    setUploadedImage(value);
    let encoded;
    getBase64Image(value).then((result: string) => {
      encoded = result;
      setEncodedImg(encoded);
    });
  };

  return (
    <Dialog onClose={() => toggleCreatePatientDialog(false)} classes={['crc-patient-dialog']}>
      <DialogTitle title="Add patient" />
      <DialogContent>
        <div className="uploader-container">
          <div className="image-wrapper">
            <input type="file" onChange={handleUpload} id="patientImageUploader" />
            <IconButton>
              <label className="profile-image-upload-label" htmlFor="patientImageUploader">
                <img
                  className="profile-picture"
                  src={
                    uploadedImage
                      ? URL.createObjectURL(uploadedImage)
                      : `${AppConfig.httpAPIGatewayURL}/${data.profilePicture || defaultPicture}`
                  }
                  alt="Preview"
                />
              </label>
            </IconButton>
          </div>
        </div>
        <Input
          id="firstName-input"
          required={true}
          variant="standard"
          name={DataNames.FIRSTNAME}
          label="First name"
          value={data[DataNames.FIRSTNAME]}
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
          errorInfo={error[DataNames.FIRSTNAME]}
        />
        <Input
          id="lastName-input"
          required={true}
          variant="standard"
          name={DataNames.LASTNAME}
          label="Last name"
          value={data[DataNames.LASTNAME]}
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
          errorInfo={error[DataNames.LASTNAME]}
        />
        <DatePicker
          id="birthdate-date-picker"
          inputVariant="standard"
          label="Birth date"
          format={dateFormat}
          value={data[DataNames.BIRTHDATE] || null}
          errorInfo={error[DataNames.BIRTHDATE]}
          inputProps={{
            disabled: true,
          }}
          handleChange={(date: Date) => handleChangeData({ name: DataNames.BIRTHDATE, value: date })}
          maxDate={getMaxDate()}
        />
        <FormControl>
          <InputLabel id="gender-input-label">Gender</InputLabel>
          <Select
            labelId="gender-select-label"
            id="gender-select"
            name={DataNames.GENDER}
            value={data[DataNames.GENDER]}
            onChange={(e) => handleChangeData(e.target as any)}
          >
            {genderData.map((el: string, index: number) => (
              <MenuItem value={el} key={index}>
                {capitalizeFirstLetter(el)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Input
          id="email-input"
          variant="standard"
          name={DataNames.EMAIL}
          label="Email"
          value={data[DataNames.EMAIL]}
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
          autoComplete="off"
        />
        <Input
          id="phone-input"
          variant="standard"
          name={DataNames.PHONE}
          label="Phone"
          value={data[DataNames.PHONE]}
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleAddPatient} className="add-button">
          Add
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => toggleCreatePatientDialog(false)}
          className="cancel-button"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
