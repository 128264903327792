import { BaseAPIService } from './BaseAPIService';
import { IRolePermission } from '../../types';

export class PermissionsAPIService extends BaseAPIService {
  constructor() {
    super('/admin/role/permissions/default');
  }

  public async getList({ query, filter }: any): Promise<any> {
    return this.get('');
  }

  public async getOne(): Promise<any> {
    return this.get(``);
  }

  public async create(): Promise<any> {
    return this.post(``, {});
  }

  public async update(): Promise<any> {
    return this.put(``, {});
  }

  public async remove(): Promise<any> {
    return this.delete(``);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(``, data);
  }
}
