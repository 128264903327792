import { connect } from 'react-redux';
import { IAppState, IAppDispatch } from '../../types';
import ListOrder from '../../components/order/ListOrder';

const mapStateToProps = (state: IAppState) => ({
  orderCount: state.admin.resources.order.list.total,
  role: state.auth.role,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListOrder);
