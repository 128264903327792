import React, { useEffect, useState } from 'react';
import { useDataProvider, Loading, useRedirect } from 'react-admin';
import { GetListParams } from 'ra-core';
import {
  Button,
  Card,
  CardActions,
  Divider,
  FormControl,
  FormLabel,
  Typography,
  IconButton,
  FormControlLabel,
  Switch,
  TextField,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import Dialog, { DialogTitle, DialogContent, DialogActions } from '../core/dialog/Dialog';
import Input from '../core/input/Input';
import {
  capitalizeFirstLetter,
  DEFAULT_VALIDATIONS_INFO,
  formatCurrency,
  hasPermission,
  isValid,
  removeSymbolAndCapitalize,
  validate,
  getBase64Image,
  isBase64String,
} from '../../utils';
import {
  RequestResource,
  PermissionLevel,
  IPermission,
  ColumnType,
  IRow,
  IErrors,
  ValidationType,
  BundleField,
  IInputField,
  BundleColumn,
} from '../../types';
import Table from '../core/table/Table';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import { AppConfig } from '../../config';
import _ from 'lodash';

export interface IEditBundleProps {
  id?: string;
  permissions: IPermission;
}

interface IAttachedServicesProps {
  handleDelete: (id: number) => void;
  rows: IRow[];
}

interface IServicesProps {
  handleAdd: (row: IRow) => void;
  rows: IRow[];
}

const ACTIONS = 'actions';

const COLUMNS = [
  {
    field: BundleColumn.ID,
    headerName: removeSymbolAndCapitalize(BundleColumn.ID),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: BundleColumn.NAME,
    headerName: removeSymbolAndCapitalize(BundleColumn.NAME),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: BundleColumn.DESCRIPTION,
    headerName: removeSymbolAndCapitalize(BundleColumn.DESCRIPTION),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: BundleColumn.PRICE,
    headerName: removeSymbolAndCapitalize(BundleColumn.PRICE),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: IRow) => formatCurrency(row.price as number),
  },
  {
    field: BundleColumn.BUNDLE_FOR,
    headerName: removeSymbolAndCapitalize(BundleColumn.BUNDLE_FOR),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: IRow) => formatCurrency(row.bundleFor as number),
  },
  {
    field: ACTIONS,
    headerName: capitalizeFirstLetter(ACTIONS),
    editable: false,
    type: ColumnType.COMPONENT,
  },
];

function AttachedServices(props: IAttachedServicesProps) {
  const { handleDelete, rows } = props;

  return (
    <Table rows={rows} columns={COLUMNS} canDelete={true} handleDelete={handleDelete} noDataMessage="No services" />
  );
}

const Services = (props: IServicesProps) => {
  const { handleAdd, rows } = props;
  return <Table rows={rows} columns={COLUMNS} canAdd={true} handleAdd={handleAdd} />;
};

export default function EditBundle(props: IEditBundleProps) {
  const { id, permissions } = props;
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [showAddServiceDialog, setShowAddServiceDialog] = useState<boolean>(false);
  const [currentBundle, setCurrentBundle] = useState<IInputField>({});
  const [rows, setRows] = useState<IRow[]>([]);
  const [icon, setIcon] = useState<string>('');
  const [services, setServices] = useState<IRow[]>([]);
  const [bundleServices, setBundleServices] = useState<IRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [attachedServiceIds, setAttachedServiceIds] = useState<number[]>([]);
  const [errors, setErrors] = useState<IErrors>({
    [BundleField.NAME]: { ...DEFAULT_VALIDATIONS_INFO },
    [BundleField.DESCRIPTION]: { ...DEFAULT_VALIDATIONS_INFO },
    [BundleField.PRICE]: { ...DEFAULT_VALIDATIONS_INFO },
    [BundleField.BUNDLE_FOR]: { ...DEFAULT_VALIDATIONS_INFO },
  });
  const [values, setValues] = useState<IInputField>({
    [BundleField.NAME]: '',
    [BundleField.DESCRIPTION]: '',
    [BundleField.PRICE]: '',
    [BundleField.BUNDLE_FOR]: '',
    [BundleField.IS_PUBLIC]: false,
  });
  const [filter, setFilter] = useState<string>('');

  useEffect(() => {
    getBundle();
  }, []);

  const setBundle = (bundleData: IInputField) => {
    setCurrentBundle({
      [BundleField.NAME]: bundleData[BundleField.NAME],
      [BundleField.DESCRIPTION]: bundleData[BundleField.DESCRIPTION],
      [BundleField.PRICE]: bundleData[BundleField.PRICE],
      [BundleField.BUNDLE_FOR]: bundleData[BundleField.BUNDLE_FOR],
      [BundleField.IS_PUBLIC]: bundleData[BundleField.IS_PUBLIC],
    });
    setCurrentBundle(bundleData);
    setIcon(bundleData.icon);
  };

  const getBundle = () => {
    if (!id) {
      return;
    }
    setLoading(true);
    dataProvider
      .getOne(RequestResource.BUNDLE, { id })
      .then(({ data }) => {
        setLoading(false);
        const bundleData = data as any;
        const currentBundleServices = bundleData.services || [];
        setBundle(bundleData);
        setRows(currentBundleServices);
        setBundleServices(currentBundleServices);
        setAttachedServiceIds(getAttachedServiceIds(currentBundleServices));
        setValues({
          [BundleField.NAME]: bundleData[BundleField.NAME],
          [BundleField.DESCRIPTION]: bundleData[BundleField.DESCRIPTION],
          [BundleField.PRICE]: bundleData[BundleField.PRICE],
          [BundleField.BUNDLE_FOR]: bundleData[BundleField.BUNDLE_FOR],
          [BundleField.IS_PUBLIC]: bundleData[BundleField.IS_PUBLIC],
          [BundleField.ICON]: bundleData[BundleField.ICON],
        });
        setIcon(bundleData[BundleField.ICON]);
      })
      .catch((error) => {
        setLoading(false);
      });
    setLoading(true);
    dataProvider
      .getList(RequestResource.SERVICE, {} as GetListParams)
      .then(({ data }) => {
        setLoading(false);
        const servicesData = data as any;
        setServices(servicesData);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const getAttachedServiceIds = (services: IRow[]): number[] => {
    return services.length ? services.map((service: IRow) => service.id as number) : [];
  };

  const toggleAddServiceDialog = (toggle: boolean) => {
    const services = currentBundle?.services || [];
    setShowAddServiceDialog(toggle);
    setBundleServices(services);
    setAttachedServiceIds(getAttachedServiceIds(services));
  };
  const handleAdd = (row: IRow) => {
    const newRows = [...bundleServices, row];
    setBundleServices(newRows);
    setAttachedServiceIds(getAttachedServiceIds(newRows));
  };
  const handleDelete = (id: number) => {
    const newRows = [...rows];
    const index = rows.findIndex((row: IRow) => row.id === id);
    newRows.splice(index, 1);
    setAttachedServiceIds(getAttachedServiceIds(newRows));
    setRows(newRows);
  };

  if (loading) return <Loading />;

  const { name, description, price, bundleFor, isPublic } = values;

  const handleChange = (value: string | number | boolean, name: BundleField) => {
    const { validationTypes, config } = getValidationTypes(name);
    const newErrors = {
      ...errors,
      [name]: validate('' + value, validationTypes, name, config),
    };

    setValues({ ...values, [name]: value });

    setErrors(newErrors);
  };

  const getValidationTypes = (name: BundleField) => {
    const validationTypes = [ValidationType.REQUIRED];
    let config = {};

    if (name === BundleField.PRICE || name === BundleField.BUNDLE_FOR) {
      validationTypes.push(ValidationType.MIN_VALUE);
      config = {
        minValue: 1,
      };
    } else {
      validationTypes.push(ValidationType.MIN_LENGTH);
      config = {
        minLength: 3,
      };
    }
    return {
      validationTypes,
      config,
    };
  };

  const getUniqueServices = () =>
    services
      .filter((service: IRow) => !attachedServiceIds.includes(service.id as number))
      ?.filter(
        ({ id, name, description }: IRow) =>
          (name as string).toLowerCase().includes(filter) ||
          (id as number).toString() === filter ||
          (description as string).toLowerCase().includes(filter)
      ) || [];

  const handleSave = (fromDialog: boolean) => {
    if (!id || !isValid(errors)) {
      return;
    }
    setLoading(true);
    let { icon: bundleIcon, ...data } = values;
    let services = rows;

    if (fromDialog) {
      const { icon: bundleIcon, ...rest } = currentBundle;
      data = { ...rest };
      services = bundleServices;
    }
    data.services = services;
    data.services = data.services.map((s: any) => ({ id: s.id }));
    if (isBase64String(icon)) {
      data.icon = icon;
    }
    dataProvider
      .update(RequestResource.BUNDLE, {
        id: +id,
        data,
      } as any)
      .then(() => {
        setLoading(false);
        if (!fromDialog) {
          redirect(`/${RequestResource.BUNDLE}`);
        } else {
          getBundle();
          setShowAddServiceDialog(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleBundleDelete = () => {
    if (!id) {
      return;
    }
    setLoading(true);
    dataProvider
      .delete(RequestResource.BUNDLE, {
        id: +id,
      } as any)
      .then(() => {
        setLoading(false);
        redirect(`/${RequestResource.BUNDLE}`);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleCancelClick = () => {
    redirect(`/${RequestResource.BUNDLE}`);
  };

  const handleUploadIcon = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.files?.[0];
    getBase64Image(value).then((result: string) => {
      setIcon(result);
    });
  };

  const handleFilter = _.debounce((term: string) => {
    setFilter(term);
  }, 300);

  return (
    <div className="edit-bundle">
      <FormControl className="edit-bundle-form">
        <div className="bundle-header">
          <Typography variant="h5">Bundle edit</Typography>
          <div className="icon-wrapper">
            <input type="file" onChange={handleUploadIcon} id="iconUploader" />
            <IconButton>
              <label className="icon-upload-label" htmlFor="iconUploader">
                {icon ? (
                  <img
                    className="bundle-icon"
                    src={isBase64String(icon) ? icon : `${AppConfig.httpAPIGatewayURL}/${icon}`}
                    alt="Preview"
                  />
                ) : (
                  <AddPhotoAlternateOutlinedIcon className="default-icon" />
                )}
              </label>
            </IconButton>
          </div>
        </div>
        <Divider className="divider" />
        <div className="body">
          <Input
            required={true}
            label="Name"
            value={name}
            errorInfo={errors[BundleField.NAME]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, BundleField.NAME)}
          />
          <Input
            multiline={true}
            required={true}
            label="Description"
            value={description}
            errorInfo={errors[BundleField.DESCRIPTION]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.value, BundleField.DESCRIPTION)
            }
          />
          <Input
            required={true}
            label="Price"
            value={price}
            type="number"
            errorInfo={errors[BundleField.PRICE]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, BundleField.PRICE)}
          />

          <Divider className="divider" />
          <FormControl>
            <FormLabel id="bundle-for" className="">
              Bundle for
            </FormLabel>
            <RadioGroup
              onChange={(e, val) => handleChange(val, BundleField.BUNDLE_FOR)}
              aria-labelledby="bundle-for"
              // defaultValue="1"
              name="bundleFor"
              value={parseInt(bundleFor)}
              row
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="App"
                //checked={values.bundleFor === 1}
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="Clinic"
                //checked={values.bundleFor === 2}
              />
            </RadioGroup>
          </FormControl>

          <Divider className="divider" />
          <FormControlLabel
            className="is-public-switcher"
            control={
              <Switch
                checked={!!isPublic}
                onChange={(e) => handleChange(e.target.checked, BundleField.IS_PUBLIC)}
                name="Is Public"
                color="primary"
              />
            }
            label="Is Public"
          />
          <Divider className="divider secondary" />
          <div className="bundle-services">
            <Typography variant="h6">Services</Typography>
            <IconButton
              aria-label="add"
              className="add-service-button"
              color="primary"
              onClick={() => toggleAddServiceDialog(true)}
            >
              <AddIcon fontSize="large" />
            </IconButton>
          </div>
          <Divider className="divider secondary" />
          <AttachedServices rows={rows} handleDelete={handleDelete} />
        </div>
        <Card className="card">
          <CardActions className="card-actions">
            <div>
              {hasPermission(permissions, RequestResource.BUNDLE, PermissionLevel.UPDATE) ? (
                <Button variant="contained" color="primary" onClick={() => handleSave(false)} startIcon={<SaveIcon />}>
                  Save
                </Button>
              ) : null}
              <Button variant="outlined" color="primary" onClick={handleCancelClick} className="cancel-button">
                Cancel
              </Button>
            </div>
            {hasPermission(permissions, RequestResource.BUNDLE, PermissionLevel.DELETE) ? (
              <Button
                className="delete"
                variant="contained"
                color="secondary"
                onClick={handleBundleDelete}
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            ) : null}
          </CardActions>
        </Card>
      </FormControl>
      {showAddServiceDialog && (
        <Dialog onClose={() => toggleAddServiceDialog(false)}>
          <DialogTitle title="Add Service" onClose={() => toggleAddServiceDialog(false)} />
          <DialogContent>
            <TextField
              variant="filled"
              label="Search"
              className="filter"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilter(e.target.value)}
            />
            <Services rows={getUniqueServices()} handleAdd={handleAdd} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => handleSave(true)}>
              Save
            </Button>
            <Button variant="contained" color="primary" onClick={() => toggleAddServiceDialog(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
