import React, { useEffect, useState } from 'react';
import { ColumnType, IErrors, ILanguage, IRow, RequestResource, Translation, ValidationType } from '../../types';
import { Loading, useDataProvider } from 'react-admin';
import { GetListParams } from 'ra-core';
import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { DEFAULT_VALIDATIONS_INFO, isValid, validate } from '../../utils';
import Table from '../core/table/Table';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
import Dialog, { DialogTitle } from '../core/dialog/Dialog';

import EditTranslation from './EditTranslation';
import AddTranslation from './AddTranslation';

export interface IListTranslationProps {
  id?: string;
}

const IS_EDIT_MODE: string = 'isEditMode';

export interface ITranslations {
  id: string;
  key: string;
  value: string;
  languageId: number;
}

export enum TranslationColumnNames {
  ID = 'ID',
  KEY = 'Translation_key',
  TRANSLATION = 'Translation',
}

const COLUMNS = [
  {
    field: 'id',
    headerName: TranslationColumnNames.ID,
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: 'key',
    headerName: TranslationColumnNames.KEY,
    editable: true,
    type: ColumnType.STRING,
  },
  {
    field: 'value',
    headerName: TranslationColumnNames.TRANSLATION,
    editable: true,
    type: ColumnType.STRING,
  },
  {
    field: '',
    headerName: 'Actions',
    editable: false,
    type: ColumnType.COMPONENT,
  },
];

export default function ListTranslation(props: IListTranslationProps) {
  const defaultErrors = {
    [Translation.TRANSLATION_KEY]: { ...DEFAULT_VALIDATIONS_INFO },
    [Translation.TRANSLATION]: { ...DEFAULT_VALIDATIONS_INFO },
  };
  const [locale, setLocale] = useState<ILanguage[]>([]);
  const [language, setLanguage] = useState<string>('');
  const [rows, setRows] = useState<any>([]);
  const [filteredRows, setFilteredRows] = useState<any>([]);
  const [previousRows, setPreviousRows] = useState<any>([]);
  const [changedFieldIndex, setIndex] = useState<number>();
  const [column, setColumn] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [errors, setErrors] = useState<IErrors>({ ...defaultErrors });
  const [newTranslationErrors, setNewTranslationErrors] = useState<IErrors>({ ...defaultErrors });
  const [singleTranslationModalVisible, setSingleTranslationModalVisible] = useState(false);
  const [currentTranslation, setCurrentTranslation] = useState<IRow>({});

  const dataProvider = useDataProvider();
  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList(RequestResource.LANGUAGE, {} as GetListParams)
      .then((res: any) => {
        const languages: ILanguage[] = res.data;
        setLocale(languages);
        setLoading(false);
        const selectedLanguageKey: string = languages.find((language: ILanguage) => language.isDefault)?.code || '';
        handleChangeLanguage(selectedLanguageKey);
      })
      .catch(() => setLoading(false));
  }, []);

  if (loading) return <Loading />;

  const getTranslations = () => {
    const selectedLanguageKey: string = locale?.find((language: ILanguage) => language.isDefault)?.code || '';
    handleChangeLanguage(selectedLanguageKey);
  };

  const handleChangeLanguage = (selectedLanguage: string) => {
    if (!selectedLanguage) {
      return;
    }
    setLanguage(selectedLanguage);
    setLoading(true);
    dataProvider
      .getList(RequestResource.TRANSLATION, {
        filter: { query: `language_code=${selectedLanguage}` },
        customQuery: true,
      } as any)
      .then((res: any) => {
        const rowData: any = res.data.map(({ id, key, value, languageId }: ITranslations, i: string) => {
          return { id, key, value, languageId, [IS_EDIT_MODE]: !!rows[i]?.isEditMode };
        });
        setRows(rowData);
        setFilteredRows(rowData);
        setPreviousRows(rowData);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleSearch = _.debounce(function (e: any) {
    const value = e.target.value;
    const newRows = rows.filter((el: any) => {
      return (
        el.id == value ||
        el?.key?.toLowerCase()?.includes(value?.toLowerCase()) ||
        el?.value?.toLowerCase()?.includes(value?.toLowerCase())
      );
    });
    setFilteredRows(newRows);
    setPreviousRows(newRows);
  }, 700);

  const handleEditOrCancelIconClick = (row: IRow) => {
    const { key, value, isEditMode, ...rest } = row;
    setCurrentTranslation({
      ...rest,
      [Translation.TRANSLATION]: value,
      [Translation.TRANSLATION_KEY]: key,
    });
    setSingleTranslationModalVisible(true);
  };

  const handleDelete = (id: number) => {
    if (!id) {
      return;
    }
    setLoading(true);
    dataProvider
      .delete(RequestResource.TRANSLATION, { id } as any)
      .then(() => {
        const newRows = rows.filter((el: any) => el.id !== id);
        const newFilteredRows = filteredRows.filter((el: any) => el.id !== id);
        setFilteredRows(newFilteredRows);
        setRows(newRows);
        setLoading(false);
        translationUpdateDeleteSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const translationUpdateDeleteSuccess = () => {
    getTranslations();
    setSingleTranslationModalVisible(false);
    setCurrentTranslation({});
    setNewTranslationErrors({ ...defaultErrors });
  };

  const checkValidationAndSave = (translation: IRow) => {
    const keys = Object.keys(newTranslationErrors);
    let newErrors = { ...newTranslationErrors };

    for (const key of keys) {
      const value = translation[key];

      if (key === Translation.TRANSLATION_ID) {
        continue;
      }
      newErrors = {
        ...newErrors,
        [key]: validate(value as string, [ValidationType.REQUIRED, ValidationType.MIN_LENGTH], key, { minLength: 3 }),
      };
    }
    if (isValid(newErrors)) {
      handleSaveChanges(translation);
    }
    setErrors(newErrors);
  };

  const handleSaveChanges = (translation: IRow) => {
    const data = {
      languageId: translation.languageId,
      key: translation[Translation.TRANSLATION_KEY],
      value: translation[Translation.TRANSLATION],
    };
    setLoading(true);
    dataProvider
      .update(RequestResource.TRANSLATION, { data, id: translation[Translation.TRANSLATION_ID] } as any)
      .then(({ data }) => {
        setLoading(false);
        translationUpdateDeleteSuccess();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const toggleDialog = (showPopup: boolean) => {
    setShowPopup(showPopup);

    if (!showPopup) {
      setErrors({ ...defaultErrors });
    }
  };

  return (
    <div className="list-translations">
      <TextField className={'default_search_input'} id="search-input" label="Search" variant="filled" onChange={handleSearch} />
      <FormControl variant="filled" className="language-custom-select" error={false}>
        <InputLabel id="demo-simple-select-filled-label">Language</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="language-select"
          value={language}
          color="primary"
          onChange={(e) => handleChangeLanguage(e.target.value as string)}
        >
          {locale?.map((elem: ILanguage) => {
            return (
              <MenuItem key={elem.code} value={elem.code}>
                {elem.shortCode}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <IconButton className="add-service-button" aria-label="add" onClick={() => toggleDialog(true)} color="primary">
        <AddIcon fontSize="large" />
      </IconButton>
      <div className="translations-table">
        <Table
          columns={COLUMNS}
          rows={filteredRows}
          handleEditOrCancelIconClick={handleEditOrCancelIconClick}
          canSave={true}
          canDelete={true}
          handleSave={handleSaveChanges}
          handleDelete={handleDelete}
          index={changedFieldIndex}
          column={column}
        />
      </div>
      {showPopup && (
        <Dialog maxWidth="xs" onClose={() => toggleDialog(false)} classes={['create-translation-dialog']}>
          <AddTranslation
            language={language}
            locale={locale}
            toggleDialog={toggleDialog}
            setRows={setRows}
            setFilteredRows={setFilteredRows}
            setPreviousRows={setPreviousRows}
            setLoading={setLoading}
          />
        </Dialog>
      )}
      {singleTranslationModalVisible && (
        <Dialog onClose={() => setSingleTranslationModalVisible(false)} classes={['edit-translation-dialog']}>
          <DialogTitle title="Edit Translation" onClose={() => setSingleTranslationModalVisible(false)} />
          <EditTranslation
            newTranslationErrors={newTranslationErrors}
            data={currentTranslation}
            checkValidationAndSave={checkValidationAndSave}
            handleDelete={handleDelete}
            setSingleTranslationModalVisible={setSingleTranslationModalVisible}
          />
        </Dialog>
      )}
    </div>
  );
}
