import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppBar, Notification, Sidebar, setSidebarVisibility, MenuItemLink, UserMenu } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import MuiAlert from '@material-ui/lab/Alert';
import SettingsIcon from '@material-ui/icons/Settings';
import Menu from './Menu';
import { IAppRouteKey, IAppState, OrderNotification } from '../../types';
import { AppRoutes } from '../../config';

export interface ILayoutProps {
  children?: React.ReactNode | React.FunctionComponent;
  dashboard: () => void | string;
  logout: () => void;
  title: string;
  open: boolean;
  orderNotification: OrderNotification;
  handleOrderNotificationClose: () => void;
}

const useSidebarStyles = makeStyles({
  drawerPaper: {},
});

const MyUserMenu = (props: any) => {
  return (
    <UserMenu {...props}>
      <MenuItemLink sidebarIsOpen to={AppRoutes[IAppRouteKey.MY_PROFILE].path} primaryText="My Profile" leftIcon={<SettingsIcon />} />
    </UserMenu>
  );
};

function SlideTransition(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

export default function Layout(props: ILayoutProps) {
  const {
    children,
    logout,
    title,
    handleOrderNotificationClose,
    orderNotification: { show, message },
  } = props;
  const dispatch = useDispatch();
  const sidebarClasses = useSidebarStyles();
  const open = useSelector((state: IAppState) => state.admin.ui.sidebarOpen);

  useEffect(() => {
    dispatch(setSidebarVisibility(true));
  }, [setSidebarVisibility]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(handleOrderNotificationClose());
  };

  return (
    <div className="layout">
      <div className="app-frame">
        <AppBar title={title} open={open} logout={logout} className="app-bar" userMenu={<MyUserMenu />} />
        <main className="main">
          <Sidebar className="sidebar" classes={sidebarClasses}>
            <Menu logout={logout} />
          </Sidebar>
          <div className="content">{children}</div>
        </main>
        <Notification
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={show}
          autoHideDuration={null}
          onClose={handleClose}
          TransitionComponent={SlideTransition}
        >
          <MuiAlert elevation={6} onClose={handleClose} variant="filled" severity="info" {...props}>
            {message}
          </MuiAlert>
        </Snackbar>
      </div>
    </div>
  );
}
