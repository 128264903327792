import { BaseAPIService } from './BaseAPIService';
import { IFaq } from '../../types';

export class CreditCardsAPIService extends BaseAPIService {
  constructor() {
    super('admin/card');
  }

  public async getCards(): Promise<any> {
    return this.get(``);
  }
}
