export interface IUserNotification {
  title: string;
  fullText: string;
  resource: string;
  entity: string;
  action: string;
  user: number;
}

export enum UserNotificationResource {
  ORDER = 'ORDER',
  PATIENT = 'PATIENT',
  ACCOUNT = 'ACCOUNT',
}

export enum UserNotificationAction {
  NAVIGATE = 'NAVIGATE',
  TEXT = 'TEXT',
}
