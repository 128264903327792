import React from 'react';
import { useRedirect } from 'react-admin';
import { Route as ReactRoute } from 'react-router';
import { getStorageService } from '../../selectors';
import { IAppRouteKey, LocalStorageKey } from '../../types';
import { AppRoutes } from '../../config';

export interface IRouteProps {
  isPublic?: boolean;
  path: string;
  exact: boolean;
  component: React.Component<any, any>;
}

export default function Route(props: IRouteProps | any) {
  const { path, exact, component: Component, isPublic } = props;
  const redirect = useRedirect();

  const verifyUserAndRedirect = () => {
    const localStorage = getStorageService();

    if (!isPublic && !localStorage.get(LocalStorageKey.ACCESS_TOKEN)) {
      redirect(AppRoutes[IAppRouteKey.LOGIN].path);
      return null;
    }
    return <ReactRoute exact={exact} path={path} render={(props: any) => <Component {...props} />} />;
  };
  return verifyUserAndRedirect();
}
