export const getBase64Image = (file: any): Promise<string> =>
  new Promise(function (resolve, reject) {
    let reader = new FileReader();
    const img = new Blob([file], { type: 'image/png' });
    reader.readAsDataURL(img);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error: any) => reject(error);
  });

export function isBase64String(s: string) {
  return !!s?.substring(0, 30).match(
    /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i
  );
}
