export enum ValidationType {
  REQUIRED = 'REQUIRED',
  MAX_VALUE = 'MAX_VALUE',
  MIN_VALUE = 'MIN_VALUE',
  MAX_LENGTH = 'MAX_LENGTH',
  MIN_LENGTH = 'MIN_LENGTH',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  PASSWORD = 'PASSWORD',
  MIN_DATE = 'MIN_DATE',
}
