import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Chart, Title, Tooltip, PieSeries, Legend } from '@devexpress/dx-react-chart-material-ui';
import { EventTracker, Animation } from '@devexpress/dx-react-chart';

export default function PieChart(props: any) {
  const [targetItem, setTargetItem] = useState<any>(null);
  const changeTargetItem = (targetItem: any) => setTargetItem(targetItem);
  const { data: chartData, innerRadius } = props;

  return (
    <Paper>
      <Chart data={chartData}>
        <PieSeries valueField="quantity" argumentField="service" innerRadius={innerRadius} />
        <Title text="Medical services" />
        <Legend position="bottom" />
        <EventTracker />
        <Tooltip targetItem={targetItem} onTargetItemChange={changeTargetItem} />
        <Animation />
      </Chart>
    </Paper>
  );
}
