import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  SaveButton,
  Toolbar as RAToolbar,
  minLength,
} from 'react-admin';
import { RequestResource } from '../../types';
import { Button, Divider, Typography } from '@material-ui/core';
import { history } from '../../configureStore';

export interface ICreateLanguageProps {
  id?: string;
}

function Toolbar(props: any) {
  const { handleCancel } = props;

  return (
    <RAToolbar {...props} className="actions">
      <SaveButton />
      <Button variant="outlined" color="primary" className="cancel-button" onClick={handleCancel}>
        Cancel
      </Button>
    </RAToolbar>
  );
}

export default function CreateLanguage(props: ICreateLanguageProps) {
  const handleCancel = () => {
    history.push(`/${RequestResource.LANGUAGE}`);
  };

  return (
    <div className="create-language">
      <Typography variant="h5" className="create-language-title">
        Language create
      </Typography>
      <Divider className="divider" />
      <Create {...props}>
        <SimpleForm
          toolbar={<Toolbar {...props} handleCancel={handleCancel} />}
          redirect={`/${RequestResource.LANGUAGE}`}
        >
          <TextInput source="code" validate={[required(), minLength(3, 'Should not contain less characters than 3')]} />
          <TextInput
            source="shortCode"
            multiline
            validate={[required(), minLength(3, 'Should not contain less characters than 3')]}
          />
          <TextInput
            source="label"
            validate={[required(), minLength(3, 'Should not contain less characters than 3')]}
          />
          <BooleanInput label="Set as default" source="isDefault" defaultValue={false} />
        </SimpleForm>
      </Create>
    </div>
  );
}
