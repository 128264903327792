import jwtDecode from 'jwt-decode';
import { setRolePermission } from './actions/auth';
import { getAuthAPIService, getNotificationService, getStorageService } from './selectors';
import { generateUUID, isSuccessfulResponse } from './utils';
import {
  HTTPStatus,
  LocalStorageKey,
  IUser,
  IAppDispatch,
  IAppState,
  IPermission,
  NotificationType,
  Role,
} from './types';
import { ACCESS_DENIED } from './constants';
import { store } from './index';

const updateAuthState = (role: string, permissions: IPermission, userId: string) => {
  const dispatch = store.dispatch as IAppDispatch;
  dispatch(
    setRolePermission({
      role,
      permissions,
      userId,
    })
  );
};

export const checkAuthCallback = (expirationTime: number, updateNow: boolean = false) => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  timeoutId = setTimeout(() => authProvider.checkAuth({}, updateNow || expirationTime < 0), expirationTime);
};

let hasActiveRequest: boolean = false;

const handleShowNotification = (message: string, type: NotificationType) => {
  const notificationService = getNotificationService();

  if (message) {
    notificationService.show({ message, type });
  }
};

let timeoutId: NodeJS.Timeout | null = null;

const authProvider = {
  verifyUserRegister: async (params: any) => {
    const authAPIService = getAuthAPIService();
    try {
      const request = await authAPIService.verifyRegister({
        ...params,
        deviceId: 1,
      });
      const message = request?.data?.message || null;
      handleShowNotification(message, NotificationType.SUCCESS);
      return Promise.resolve(request);
    } catch (request) {
      const message = request?.data?.message || null;
      handleShowNotification(message, NotificationType.ERROR);
      return Promise.reject(request);
    }
  },

  reSendVerifyKey: async (params: any) => {
    const authAPIService = getAuthAPIService();
    try {
      const request = await authAPIService.reSendVerifyKey({
        ...params,
        deviceId: Math.random(),
      });
      const message = request?.data?.message || null;
      handleShowNotification(message, NotificationType.SUCCESS);
      return Promise.resolve(request);
    } catch (request) {
      const message = request?.data?.message || null;
      handleShowNotification(message, NotificationType.ERROR);
      return Promise.reject(request);
    }
  },

  login: async (params: any) => {
    const authAPIService = getAuthAPIService();
    const storageService = getStorageService();
    const deviceId = generateUUID();
    try {
      const request = await authAPIService.login({
        ...params,
        rememberMe: true,
        deviceId: deviceId,
      });
      const message = request?.data?.message || null;
      const { jwtToken, refreshKey } = request.data.result;
      const {
        exp,
        role: { name: role, permissions },
        userId,
      } = jwtDecode(jwtToken) as IUser;
      if (role === Role.CLIENT) {
        return Promise.reject(ACCESS_DENIED);
      }
      storageService.set(LocalStorageKey.ACCESS_TOKEN, jwtToken);
      storageService.set(LocalStorageKey.REFRESH_KEY, refreshKey);
      storageService.set(LocalStorageKey.DEVICE_ID, deviceId);
      storageService.set(LocalStorageKey.ROLE, JSON.stringify(role));
      storageService.set(LocalStorageKey.EXP, exp.toString());
      updateAuthState(role, permissions, userId as string);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error?.data?.message);
    }
  },
  checkAuth: async (params: any, updateNow: boolean = false) => {
    const authAPIService = getAuthAPIService();
    const storageService = getStorageService();
    const existingAccessToken = storageService.get(LocalStorageKey.ACCESS_TOKEN);
    const existingRefreshKey = storageService.get(LocalStorageKey.REFRESH_KEY);
    const exp = Number(storageService.get(LocalStorageKey.EXP));

    const halfMinutes: number = 0.5 * 60 * 1000;
    const expirationTime = exp * 1000 - new Date().getTime();

    if (!existingAccessToken || !existingRefreshKey) {
      return Promise.reject();
    }

    if (!updateNow) {
      if (expirationTime < halfMinutes) {
        checkAuthCallback(expirationTime);
      }
      return Promise.resolve();
    }

    if (!hasActiveRequest) {
      hasActiveRequest = true;
      try {
        const request = await authAPIService.updateAccessToken(existingRefreshKey);
        const message = request?.data?.message || null;
        const { newJwtToken } = request.data;
        const {
          exp: newExp,
          role: { name: role, permissions },
          userId,
        } = jwtDecode(newJwtToken) as IUser;
        storageService.set(LocalStorageKey.ACCESS_TOKEN, newJwtToken);
        storageService.set(LocalStorageKey.ROLE, JSON.stringify(role));
        storageService.set(LocalStorageKey.EXP, newExp.toString());
        updateAuthState(role, permissions, userId as string);
        checkAuthCallback(expirationTime);
        hasActiveRequest = false;
        return Promise.resolve();
      } catch (error) {
        hasActiveRequest = false;
        return Promise.reject(error?.data?.message);
      }
    }
  },
  logout: () => {
    const storageService = getStorageService();
    storageService.remove(LocalStorageKey.ACCESS_TOKEN);
    storageService.remove(LocalStorageKey.REFRESH_KEY);
    storageService.remove(LocalStorageKey.DEVICE_ID);
    storageService.remove(LocalStorageKey.ROLE);
    storageService.remove(LocalStorageKey.EXP);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    return Promise.resolve();
  },
  checkError: (params: any) => {
    return Promise.resolve();
  },
  getPermissions: () => {
    const state = store.getState() as IAppState;
    const permissions = state.auth.permissions;

    if (!permissions) {
      return Promise.reject();
    }
    return Promise.resolve(permissions);
  },

  forgotPassword: async (params: any) => {
    const authAPIService = getAuthAPIService();
    try {
      const request = await authAPIService.forgotPassword({
        ...params,
      });
      const message = request?.data?.message || null;
      handleShowNotification(message, NotificationType.SUCCESS);
      return Promise.resolve(request);
    } catch (request) {
      const message = request?.data?.message || null;
      handleShowNotification(message, NotificationType.ERROR);
      return Promise.reject();
    }
  },
  resetPassword: async (params: any) => {
    const authAPIService = getAuthAPIService();
    try {
      const request = await authAPIService.resetPassword({
        ...params,
      });
      const message = request?.data?.message || null;
      handleShowNotification(message, NotificationType.SUCCESS);
      return Promise.resolve(request);
    } catch (request) {
      const message = request?.data?.message || null;
      handleShowNotification(message, NotificationType.ERROR);
      return Promise.reject(request);
    }
  },
};

export default authProvider;
