import { Create, required, SaveButton, SimpleForm, TextInput } from 'react-admin';
import React from 'react';
import { IPermission, RequestResource } from '../../types';
import { Button, Divider, Typography } from '@material-ui/core';
import { history } from '../../configureStore';
import { minLength } from 'ra-core/esm/form/validate';
import { Toolbar as RAToolbar } from 'ra-ui-materialui/lib/form';

export interface ICreateFaq {
  permissions: IPermission;
}

function Toolbar(props: any) {
  const { handleCancel } = props;

  return (
    <RAToolbar {...props} className="actions">
      <SaveButton />
      <Button variant="outlined" color="primary" className="cancel-button" onClick={handleCancel}>
        Cancel
      </Button>
    </RAToolbar>
  );
}

export default function CreateFaq(props: ICreateFaq) {
  const handleCancel = () => {
    history.push(`/${RequestResource.FAQ}`);
  };

  return (
    <div className="create-faq">
      <Typography variant="h5" className="create-faq-title">
        FAQ create
      </Typography>
      <Divider className="divider" />
      <Create {...props}>
        <SimpleForm redirect={`/${RequestResource.FAQ}`} toolbar={<Toolbar {...props} handleCancel={handleCancel} />}>
          <TextInput
            source="topic"
            validate={[required(), minLength(3, 'Should not contain less characters than 3')]}
            multiline
          />
          <TextInput
            source="question"
            validate={[required(), minLength(3, 'Should not contain less characters than 3')]}
          />
          <TextInput
            source="answer"
            validate={[required(), minLength(3, 'Should not contain less characters than 3')]}
            multiline
          />
        </SimpleForm>
      </Create>
    </div>
  );
}
