import { BaseAPIService } from './BaseAPIService';
import { IUserData, UserStatus } from '../../types';

export class UsersAPIService extends BaseAPIService {
  constructor() {
    super('admin/user');
  }

  public async getList({ query, filter }: any): Promise<any> {
    let q = '';
    if (filter?.q) {
      const splitedName = filter.q.split(' ');
      if (splitedName?.[0] && splitedName?.[1]) {
        q = `&search=firstName,like,${splitedName?.[0]}&search=lastName,like,${splitedName?.[1]}`;
      } else if (!splitedName?.[1]) {
        q = `&search=firstName,like,${splitedName?.[0]}|lastName,like,${splitedName?.[0]}|id,=,${splitedName?.[0]}|phone,like,${splitedName?.[0]}|email,like,${splitedName?.[0]}`;
      }
    }
    const role = filter?.role > 0 ? `&search=role^id,=,${filter.role}` : '';
    let newQuery = '';
    switch (filter?.status) {
      case UserStatus.DELETED:
        newQuery = `?withDeleted=true&projection=role,specialist,patient${q}${role}&search=deletedAt,is not,null&${query}`;
        break;
      case UserStatus.BLOCKED:
        newQuery = `?withDeleted=true&projection=role,specialist,patient${q}${role}&search=blockedAt,is not,null&${query}`;
        break;
      case UserStatus.ACTIVE:
        newQuery = `?projection=role,specialist,patient${q}${role}&search=blockedAt,is,null&search=userVerifiedDate,is not,null&${query}`;
        break;
      case UserStatus.UNVERIFIED:
        newQuery = `?projection=role,specialist,patient${q}${role}&search=userVerifiedDate,is,null&${query}`;
        break;
      default:
        newQuery = `?projection=role,specialist,patient${q}${role}&${query}`;
        break;
    }

    return this.get(newQuery);
  }

  public async getOne(id: { id: number; withDeleted: boolean }): Promise<any> {
    return this.get(
      `/${id.id}?prefix=user&projection=role,specialist,patient${id.withDeleted ? '&withDeleted=true' : ''}`
    );
  }

  public async create(data: IUserData): Promise<any> {
    return this.post(``, data);
  }

  public async update(data: IUserData, id: number): Promise<any> {
    return this.put(`/${id}`, data);
  }

  public async remove(id: number): Promise<any> {
    return this.delete(`/${id}`);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(``, data);
  }

  public async restore(data: any): Promise<any> {
    return this.post(`/restore`, data);
  }

  public async getUsers(role: number): Promise<any> {
    return this.get(
      `?projection=role,specialist,patient&search=role^id,=,${role}&search=blockedAt,is,null&prefix=user`
    );
  }

  public async getCards(): Promise<any> {
    return this.get(`/admin/card`);
  }

  public async verify(id: number): Promise<any> {
    return this.post(`/verify`, { id });
  }
}
