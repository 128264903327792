import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  BulkDeleteButton,
  downloadCSV,
  useListContext,
  ExportButton,
} from 'react-admin';
import { hasPermission } from '../../utils';
import { RequestResource, PermissionLevel, IPermission, Role } from '../../types';

// @ts-ignore
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

export interface IListLanguageProps {
  permissions: IPermission;
  currentUserRole: string;
}

export default function ListLanguage(props: IListLanguageProps) {
  const { permissions, currentUserRole } = props;
  const canDelete = hasPermission(permissions, RequestResource.LANGUAGE, PermissionLevel.DELETE);

  const exporter = (orders: any, selectedIds: any) => {
    const languagesForExport = Object.values(orders).filter((order: any) => {
      return selectedIds.includes(order.id);
    });

    const csv = convertToCSV({
      data: languagesForExport,
      fields: ['id', 'code', 'shortCode', 'label', 'font'],
    });
    downloadCSV(csv, 'languages');
  };

  const BulkActionButtons = (props: any) => {
    const { selectedIds } = props;
    const { total, data } = useListContext();

    return (
      <React.Fragment>
        {canDelete && <BulkDeleteButton {...props} />}
        {[Role.ADMIN, Role.SUPER_ADMIN].includes(currentUserRole as Role) && (
          <ExportButton disabled={!total} onClick={() => exporter(data, selectedIds)} />
        )}
      </React.Fragment>
    );
  };

  return (
    <List className="languages-list" exporter={false} bulkActionButtons={<BulkActionButtons />} {...props}>
      <Datagrid isRowSelectable={() => canDelete} className="react-admin-table">
        <TextField source="id" sortable={false} />
        <TextField source="code" sortable={false} />
        <TextField source="shortCode" sortable={false} />
        <TextField source="label" sortable={false} />
        {hasPermission(permissions, RequestResource.LANGUAGE, PermissionLevel.UPDATE) ? <EditButton /> : null}
      </Datagrid>
    </List>
  );
}
