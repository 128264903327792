import React, { useEffect, useState } from 'react';
import { useDataProvider, Loading, DeleteButton } from 'react-admin';
import Autocomplete from '@material-ui/lab/Autocomplete';
import countries from 'countries-list';
import {
  IPermission,
  RequestResource,
  DataNames,
  IUserData,
  IUserError,
  UserGender,
  IRolePermission,
  ResetPassword,
  Role,
  PermissionLevel,
  LocalStorageKey,
  IPatientData,
  T,
  ValidationType,
  IErrors,
  IResetData,
  IResetError,
  ICreditCard,
} from '../../types';
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  IconButton,
  Input as InputField,
  InputAdornment,
  Checkbox,
  ListItemText,
  CardActions,
  Card,
  Typography,
  Divider,
} from '@material-ui/core';
import { history } from '../../configureStore';
import {
  capitalizeFirstLetter,
  DEFAULT_VALIDATIONS_INFO,
  FieldValue,
  formatDate,
  getMaxDate,
  hasHigherRoleThan,
  hasPermission,
  isValid,
  removeSymbolAndCapitalize,
  validate,
} from '../../utils';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../core/dialog/Dialog';
import { AppConfig } from '../../config';
import authProvider from '../../authProvider';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { getStorageService, t } from '../../selectors';
import { dateFormat } from '../../constants';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import AttachedPatients from '../../containers/manageUsers/AttachedPatients';
import CreatePatient from '../../containers/manageUsers/CreatePatient';
import EditPatient from './EditPatient';
import CreditCard from '../credit-card/CreditCard';
import EyeIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import DatePicker from '../core/picker/datepicker/DatePicker';
import Input from '../core/input/Input';

const initialData: IUserData = {
  [DataNames.FIRSTNAME]: '',
  [DataNames.LASTNAME]: '',
  [DataNames.EMAIL]: '',
  [DataNames.PHONE]: '',
  [DataNames.CITY]: '',
  [DataNames.GENDER]: '',
  [DataNames.BIRTHDATE]: null,
  [DataNames.ADDRESS]: '',
  [DataNames.ROLEID]: '',
  [DataNames.SPECIALISTS]: undefined,
  [DataNames.COUNTRY]: '',
  [DataNames.USER_VERIFIED_BY]: '',
  [DataNames.USER_VERIFIED_DATE]: '',
  [DataNames.RATING]: '',
};

const initialError: IUserError = {
  [DataNames.FIRSTNAME]: { ...DEFAULT_VALIDATIONS_INFO },
  [DataNames.LASTNAME]: { ...DEFAULT_VALIDATIONS_INFO },
  [DataNames.PHONE]: { ...DEFAULT_VALIDATIONS_INFO },
  [DataNames.ROLEID]: { ...DEFAULT_VALIDATIONS_INFO },
};

const initialResetData: IResetData = {
  [DataNames.PASSWORD]: '',
  [DataNames.USER_VERIFY_KEY]: '',
};
const initialResetError: IResetError = {
  [DataNames.PASSWORD]: { ...DEFAULT_VALIDATIONS_INFO },
  [DataNames.USER_VERIFY_KEY]: { ...DEFAULT_VALIDATIONS_INFO },
};

export interface IEditUserProps {
  permissions: IPermission;
  cards: ICreditCard[];
  id?: number;
  currentUserId: string | any;
}

export const genderData: Array<string> = [UserGender.MALE, UserGender.FEMALE];
export const medicalRole = Role.AGENT;

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42);',
    },
    '&&:after': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42);',
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EditUser(props: IEditUserProps) {
  const [data, setData] = useState<IUserData>(initialData);
  const [error, setError] = useState<IUserError>(initialError);
  const [loading, setLoading] = useState<boolean>(false);
  const [rolesData, setRolesData] = useState<IRolePermission[]>([]);
  const [role, setRole] = useState<string>('');
  const [uploadedImage, setUploadedImage] = useState<File>();
  const [userVerifyToken, setUserVerifyToken] = useState<string>('');
  const [showBlockPopup, setShowBlockPopup] = useState<boolean>(false);
  const [showForgotPopup, setShowForgotPopup] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [value, setValue] = useState<string>(ResetPassword.EMAIL);
  const [specializationList, setSpecializationList] = useState<{ type: string }[]>([]);
  const [createPatientPopup, setCreatePatientPopup] = useState<boolean>(false);
  const [editPatientPopup, setEditPatientPopup] = useState<boolean>(false);
  const [patient, setPatient] = useState<Array<IPatientData>>([]);
  const [countriesData, setCountriesData] = useState<Array<{}>>([]);
  const [country, setCountry] = useState<{}>({});
  const [rowData, setRowData] = useState<IPatientData | {}>({});
  const [errors, setErrors] = useState<IErrors>(initialResetError);
  const [selectedCard, setSelectedCard] = useState<ICreditCard | null>(null);
  const [resetPasswordData, setResetPasswordData] = useState<IResetData>(initialResetData);
  const dataProvider = useDataProvider();
  const { id, permissions, currentUserId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const userId = currentUserId.toString(); // id is a string, thats why replacing currentUserId to string

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getOne(RequestResource.USER, { id: { id } } as any)
      .then((res) => {
        setLoading(false);
        const {
          firstName,
          lastName,
          email,
          phone,
          userVerifiedDate,
          country,
          city,
          gender,
          birthDate,
          address,
          roles,
          profilePicture,
          defaultPicture,
          specialists,
          rating,
          userVerifiedBy,
          patients,
          cards,
        } = res.data;
        const specialization = specialists?.map((el: { type: string }) => el.type);

        setData({
          ...data,
          firstName,
          lastName,
          email,
          phone,
          gender,
          userVerifiedDate,
          country,
          city,
          profilePicture,
          defaultPicture,
          address,
          rating,
          userVerifiedBy,
          roleId: roles[0].id,
          birthDate: new Date(birthDate),
          specialists: specialization,
          cards,
        });
        setPatient(patients);
        setRole(roles[0].name);
        const countriesList = Object.values(countries.countries);
        const countriesSortedList = countriesList.sort((a: { name: string }, b: { name: string }) => {
          return a.name > b.name ? 1 : -1;
        });
        setCountriesData(countriesSortedList);
        const newCountry = countriesSortedList.find((el: { name: string }) => el.name === country);
        setCountry(newCountry as {});
      })
      .catch(() => {
        setLoading(false);
      });
    dataProvider
      .getList(RequestResource.ROLE, {} as any)
      .then((res) => {
        const newResult = res.data.filter((el) => {
          const currentUserRole = getStorageService().get(LocalStorageKey.ROLE) || '';
          return hasHigherRoleThan(JSON.parse(currentUserRole) as Role, el.name);
        });
        setRolesData(newResult as IRolePermission[]);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    const currentUserRole = JSON.parse(getStorageService().get(LocalStorageKey.ROLE) as string) || '';
    if (currentUserRole !== Role.CONTENT_WRITER && currentUserRole) {
      dataProvider
        .getList(RequestResource.USER_SPECIALIZATION_LIST, {} as any)
        .then((res) => {
          setSpecializationList(res.data as any);
        })
        .catch(() => {});
    }
  }, []);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadedImage(e.currentTarget.files?.[0]);
  };

  const handleSaveImage = () => {
    const formData = new FormData();
    setLoading(true);
    formData.append('profilePicture', uploadedImage as Blob);
    dataProvider
      .update(RequestResource.USERPICTURE, { data: formData, id } as any)
      .then((res) => {})
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const defaultProps = {
    options: countriesData,
    getOptionLabel: (option: any) => option.name,
  };

  const getValidationTypes = (name: DataNames) => {
    const validationTypes = [ValidationType.REQUIRED];
    let config = {};
    if (name === DataNames.FIRSTNAME || name === DataNames.LASTNAME) {
      validationTypes.push(ValidationType.MIN_LENGTH, ValidationType.MAX_LENGTH);
      config = {
        minLength: 3,
        maxLength: 30,
      };
    }
    // if (name === DataNames.EMAIL) {
    //   validationTypes.push(ValidationType.EMAIL);
    //   config = {};
    // }
    if (name === DataNames.PHONE) {
      validationTypes.push(ValidationType.PHONE);
      config = {
        minLength: 11,
      };
    }
    // if (name === DataNames.PASSWORD) {
    //   validationTypes.push(ValidationType.PASSWORD);
    //   config = {
    //     minLength: 9,
    //   };
    // }
    if (name === DataNames.USER_VERIFY_KEY) {
      validationTypes.push(ValidationType.MIN_LENGTH);
      config = {
        minLength: 6,
      };
    }
    // if (name === DataNames.ADDRESS) {
    //   validationTypes.push(ValidationType.MIN_LENGTH);
    //   config = {
    //     minLength: 3,
    //   };
    // }
    // if (name === DataNames.CITY) {
    //   validationTypes.push(ValidationType.MIN_LENGTH);
    //   config = {
    //     minLength: 3,
    //   };
    // }
    return {
      validationTypes,
      config,
    };
  };

  const handleChangeData = (target: { name?: string; value: string | number | Date | null }) => {
    const { value, name } = target;
    const { validationTypes, config } = getValidationTypes(name as DataNames);

    if (name === DataNames.ROLEID) {
      const newRole = rolesData.find((el: { id: number }) => el.id === value)?.name || '';
      setRole(newRole);
    }
    setData({ ...data, [name as string]: value });

    if (name && initialError[name as string]) {
      let newErrors = {
        ...error,
        [name as string]: validate(value as FieldValue, validationTypes, name as string, config),
      };
      setError(newErrors);
    }
  };

  const handleSaveUser = () => {
    const specialistData = data[DataNames.SPECIALISTS] as string[] | undefined;
    const checkedSpecializations = specialistData
      ?.map((el: string) => {
        return specializationList.find((data: { type: string }) => data.type === el);
      })
      .map((s: any) => ({ id: s.id }));
    const newPatient = patient.map((el: IPatientData) => {
      const { patientId, ...rest } = el;
      return rest;
    });

    const newData =
      role !== medicalRole
        ? {
            ...data,
            patients: newPatient,
            [DataNames.BIRTHDATE]: (data[DataNames.BIRTHDATE] as Date)?.toISOString() || '',
          }
        : {
            ...data,
            patients: newPatient,
            [DataNames.BIRTHDATE]: (data[DataNames.BIRTHDATE] as Date)?.toISOString() || '',
            [DataNames.SPECIALISTS]: checkedSpecializations,
          };

    const keys = Object.keys(error);
    let newErrors = { ...error };
    for (const key of keys) {
      const value = (newData as any)[key];
      const { validationTypes, config } = getValidationTypes(key as DataNames);
      newErrors = {
        ...newErrors,
        [key]: validate(value as FieldValue, validationTypes, key as string, config),
      };
    }
    if (role !== medicalRole) {
      newErrors = {
        ...newErrors,
        [DataNames.SPECIALISTS]: { ...DEFAULT_VALIDATIONS_INFO },
      };
    }

    if (isValid(newErrors)) {
      setLoading(true);
      dataProvider
        .update(RequestResource.USER, { data: { ...newData }, id } as any)
        .then((res) => {
          setPatient(res.data.patients);
          setLoading(false);
          handleClose();
        })
        .catch(() => {
          setLoading(false);
        });
    }
    setError(newErrors);
  };

  const toggleBlockDialog = (showPopup: boolean) => {
    setShowBlockPopup(showPopup);
  };

  const handleClose = () => {
    history.push(`/${RequestResource.USER}`);
  };

  const handleBlockUser = () => {
    const specialistData = data[DataNames.SPECIALISTS] as string[] | undefined;
    const checkedSpecializations = specialistData?.map((el: string) => {
      return specializationList.find((data: { type: string }) => data.type === el);
    });
    const newPatient = patient.map((el: IPatientData) => {
      const { patientId, ...rest } = el;
      return rest;
    });
    const newData =
      role !== medicalRole
        ? {
            ...data,
            patients: newPatient,
            [DataNames.BIRTHDATE]: (data[DataNames.BIRTHDATE] as Date)?.toISOString() || '',
          }
        : {
            ...data,
            patients: newPatient,
            [DataNames.BIRTHDATE]: (data[DataNames.BIRTHDATE] as Date)?.toISOString() || '',
            [DataNames.SPECIALISTS]: checkedSpecializations,
          };
    dataProvider
      .update(RequestResource.USER, { data: { ...newData, blockedAt: new Date().toISOString() }, id } as any)
      .then(() => {
        toggleBlockDialog(false);
        handleClose();
      })
      .catch(() => {});
  };

  const toggleForgotDialog = (showPopup: boolean) => {
    setShowForgotPopup(showPopup);
  };

  const toggleResetDialog = (showPopup: boolean) => {
    setShowForgotPopup(showPopup);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
  };

  const handleForgotPassword = () => {
    const sendType = value;
    authProvider
      .forgotPassword({ [sendType]: (data as any)[sendType] } as any)
      .then((res: any) => {
        setUserVerifyToken(res.data.userVerifyToken);
      })
      .catch(() => {});
  };

  const handleChangeResetData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    const { validationTypes, config } = getValidationTypes(name as DataNames);
    const newErrors = {
      ...errors,
      [name as string]: validate(value as FieldValue, validationTypes, name as string, config),
    };
    setResetPasswordData({ ...resetPasswordData, [name as string]: value });
    setErrors(newErrors);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleResetPassword = () => {
    const keys = Object.keys(errors);
    let newErrors = { ...errors };
    for (const key of keys) {
      const value = (resetPasswordData as any)[key];
      const { validationTypes, config } = getValidationTypes(key as DataNames);
      newErrors = {
        ...newErrors,
        [key]: validate(value as FieldValue, validationTypes, key as string, config),
      };
    }
    setErrors(newErrors);
    if (isValid(newErrors)) {
      authProvider
        .resetPassword({
          userVerifyToken,
          userVerifyKey: resetPasswordData.userVerifyKey,
          newPassword: resetPasswordData.password,
        })
        .then((res: any) => {
          setShowForgotPopup(false);
          handleClose();
        })
        .catch((err: any) => {});
    }
  };

  const toggleCreatePatientDialog = (showPopup: boolean) => {
    setCreatePatientPopup(showPopup);
  };

  const addPatient = (newPatient: IPatientData) => {
    setPatient([...patient, newPatient]);
  };

  const editPatient = (editedPatient: IPatientData) => {
    const newPatientList = patient.map((el: IPatientData) => {
      if (!el.patientId && el.id === editedPatient.id) {
        return editedPatient;
      } else if (el.patientId && el.patientId === editedPatient.patientId) {
        return editedPatient;
      }
      return el;
    });
    setPatient(newPatientList);
    if (!editedPatient.patientId) {
      const newData = {
        ...data,
        patients: newPatientList,
        [DataNames.BIRTHDATE]: (data[DataNames.BIRTHDATE] as Date)?.toISOString() || '',
        [DataNames.SPECIALISTS]: [],
      };
      dataProvider
        .update(RequestResource.USER, { data: { ...newData }, id } as any)
        .then((res) => {
          setPatient(res.data.patients);
        })
        .catch(() => {});
    }
  };

  const handleDelete = (deletedPatient: IPatientData) => {
    const patientId = deletedPatient.id ? deletedPatient.id : deletedPatient.patientId;
    const newPatientList = patient.filter((el: IPatientData) => el.id !== patientId && el.patientId !== patientId);
    setPatient(newPatientList);
    if (!deletedPatient.patientId) {
      const newData = {
        ...data,
        patients: newPatientList,
        [DataNames.BIRTHDATE]: (data[DataNames.BIRTHDATE] as Date)?.toISOString() || '',
        [DataNames.SPECIALISTS]: [],
      };
      dataProvider
        .update(RequestResource.USER, { data: { ...newData }, id } as any)
        .then((res) => {
          setPatient(res.data.patients);
          setLoading(false);
          setEditPatientPopup(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setEditPatientPopup(false);
    }
  };
  const handleEditOrCancelIconClick = (row: IPatientData) => {
    setRowData(row);
    toggleEditPatientDialog(true);
  };

  const toggleEditPatientDialog = (showPopup: boolean) => {
    setEditPatientPopup(showPopup);
  };

  const handleRemoveCard = (id: number) => {
    console.log(id);
  };

  const handleViewCard = (handleCardView: ICreditCard) => {
    setSelectedCard(handleCardView);
  };

  const renderAttachedCards = () => {
    return data?.cards?.map((props: ICreditCard) => {
      const { id, maskedNumber, expiryDate } = props;

      return (
        <div className="credit-card-info">
          <label className="card-info">{`Credit card ending in ${maskedNumber?.slice(
            maskedNumber.length - 4
          )}, Exp date ${expiryDate}`}</label>
          <div className="card-actions">
            <IconButton className="icon view" aria-label="view" onClick={() => handleViewCard(props)}>
              <EyeIcon />
            </IconButton>
            <IconButton className="icon delete" aria-label="delete" onClick={() => handleRemoveCard(id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      );
    });
  };

  if (loading) return <Loading />;

  return (
    <div className="edit-user">
      <div className="edit-user-form">
        <div className="wrapper">
          <Typography variant="h5">User Edit</Typography>
          <Divider className="divider" />
          <div className="uploader-container">
            <div className="image-wrapper">
              <input type="file" onChange={handleUpload} id="imageUploader" />
              <IconButton>
                <label className="profile-image-upload-label" htmlFor="imageUploader">
                  <img
                    className="profile-picture"
                    src={
                      uploadedImage
                        ? URL.createObjectURL(uploadedImage)
                        : data.profilePicture || data.defaultPicture
                        ? `${AppConfig.httpAPIGatewayURL}/${data.profilePicture || data.defaultPicture}`
                        : ''
                    }
                    alt="Preview"
                  />
                </label>
              </IconButton>
            </div>
            <Button
              onClick={handleSaveImage}
              variant="contained"
              color="primary"
              className="image-save-button"
              startIcon={<SaveIcon />}
            >
              save
            </Button>
          </div>
          <div className="edit-user-data-container">
            <div className="edit-user-data">
              <Input
                id="firstName-input"
                required={true}
                variant="standard"
                name={DataNames.FIRSTNAME}
                label="First name"
                value={data[DataNames.FIRSTNAME]}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
                errorInfo={error[DataNames.FIRSTNAME]}
              />
              <Input
                id="email-input"
                variant="standard"
                name={DataNames.EMAIL}
                label="Email"
                value={data[DataNames.EMAIL]}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
                autoComplete="off"
              />
              <Autocomplete
                {...defaultProps}
                id="country-input"
                value={country}
                onChange={(event: any, country: any) => {
                  setCountry(country);
                  setData({ ...data, [DataNames.COUNTRY]: country?.name || '' });
                }}
                renderInput={(params: any) => <TextField {...params} label="Country" />}
              />
              <FormControl>
                <InputLabel id="gender-input-label">Gender</InputLabel>
                <Select
                  labelId="gender-select-label"
                  id="gender-select"
                  name={DataNames.GENDER}
                  value={data[DataNames.GENDER]}
                  onChange={(e) => handleChangeData(e.target as any)}
                >
                  {genderData.map((el: string, index: number) => (
                    <MenuItem value={el} key={index}>
                      {capitalizeFirstLetter(el)}
                    </MenuItem>
                  ))}
                </Select>
                {/*<FormHelperText error={true}>{error?.gender?.message}</FormHelperText>*/}
              </FormControl>
              <FormControl error={!!error?.roleId?.error}>
                <InputLabel id="role-input-label">Role*</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="role-select"
                  name={DataNames.ROLEID}
                  value={data[DataNames.ROLEID]}
                  color="primary"
                  onChange={(e) => handleChangeData(e.target as any)}
                >
                  {rolesData?.map((data) => {
                    return (
                      <MenuItem key={data.id} value={data.id}>
                        {removeSymbolAndCapitalize(data.name)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {role === medicalRole ? (
                <FormControl error={!!error?.specialists?.error}>
                  <InputLabel id="specialist-input-label">Specialist</InputLabel>
                  <Select
                    labelId="specialist-select-label"
                    id="specialist-multiple-checkbox"
                    name={DataNames.SPECIALISTS}
                    multiple
                    value={data[DataNames.SPECIALISTS] || []}
                    onChange={(e) => handleChangeData(e.target as any)}
                    input={<InputField />}
                    renderValue={(selected: any) => selected.map(capitalizeFirstLetter).join(', ')}
                    MenuProps={MenuProps}
                  >
                    {specializationList.map((item: { type: string }, index: number) => {
                      const specialistData = data[DataNames.SPECIALISTS] as string[] | undefined;
                      return (
                        <MenuItem key={index} value={item.type}>
                          <Checkbox checked={!!specialistData?.find((elem: any) => item.type === elem)} />
                          <ListItemText primary={t(item.type as T)} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {/*<FormHelperText error={true}>{error?.specialists?.message}</FormHelperText>*/}
                </FormControl>
              ) : null}
            </div>
            <div className="edit-user-data">
              <Input
                id="lastName-input"
                required={true}
                variant="standard"
                name={DataNames.LASTNAME}
                label="Last name"
                value={data[DataNames.LASTNAME]}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
                errorInfo={error[DataNames.LASTNAME]}
              />
              <Input
                id="phone-input"
                required={true}
                variant="standard"
                name={DataNames.PHONE}
                label="Phone"
                value={data[DataNames.PHONE]}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
                errorInfo={error[DataNames.PHONE]}
              />
              <Input
                id="city-input"
                variant="standard"
                name={DataNames.CITY}
                label="City"
                value={capitalizeFirstLetter(data[DataNames.CITY])}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
              />
              <DatePicker
                id="birthdate-date-picker"
                inputVariant="standard"
                label="Birth date"
                format={dateFormat}
                value={data[DataNames.BIRTHDATE] || null}
                inputProps={{
                  disabled: true,
                }}
                handleChange={(date: Date) => handleChangeData({ name: DataNames.BIRTHDATE, value: date })}
                maxDate={getMaxDate()}
              />
              <Input
                id="address-input"
                variant="standard"
                name={DataNames.ADDRESS}
                label="Address"
                value={capitalizeFirstLetter(data[DataNames.ADDRESS])}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
              />
            </div>
            <div className="edit-user-data">
              <Input
                id="userVerifiedBy-input"
                variant="standard"
                name={DataNames.USER_VERIFIED_BY}
                label="Verified"
                value={
                  data[DataNames.USER_VERIFIED_BY]
                    ? `${capitalizeFirstLetter(data[DataNames.USER_VERIFIED_BY])} verified`
                    : ''
                }
                disabled
                inputProps={{ classes }}
                errorInfo={DEFAULT_VALIDATIONS_INFO}
              />
              <Input
                id="userVerifiedDate-input"
                variant="standard"
                name={DataNames.USER_VERIFIED_DATE}
                label="Verified date"
                value={formatDate(data[DataNames.USER_VERIFIED_DATE] || '')}
                disabled
                inputProps={{ classes }}
                errorInfo={DEFAULT_VALIDATIONS_INFO}
              />
              {medicalRole === role ? (
                <Input
                  id="rating-input"
                  variant="standard"
                  label="Rating"
                  value={data[DataNames.RATING] || ''}
                  disabled
                  inputProps={{ classes }}
                  errorInfo={DEFAULT_VALIDATIONS_INFO}
                />
              ) : null}
            </div>
          </div>
          <div className="container">
            <div>
              {role === Role.CLIENT && data?.cards?.length ? (
                <div className="payment-methods">
                  <Typography variant="h5">Payment Methods</Typography>
                  <div className="attached-cards">{renderAttachedCards()}</div>
                </div>
              ) : null}
            </div>
            <div className="buttons-container">
              <div className="reset-block-buttons-container">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => toggleResetDialog(true)}
                  className="reset-password-button"
                >
                  Reset Password
                </Button>
                {hasPermission(permissions, RequestResource.USER, PermissionLevel.DELETE) && id !== userId ? (
                  <Button variant="contained" color="primary" onClick={() => toggleBlockDialog(true)}>
                    Block User
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
          <div className="patient-data">
            {data.userVerifiedDate && role === Role.CLIENT ? (
              <AttachedPatients
                rows={patient}
                defaultPicture={data.defaultPicture}
                handleDelete={handleDelete}
                editPatient={editPatient}
                editPatientPopup={editPatientPopup}
                handleEditOrCancelIconClick={handleEditOrCancelIconClick}
              />
            ) : null}
            {data.userVerifiedDate && role === Role.CLIENT ? (
              <IconButton
                aria-label="add"
                className="add-patient-button"
                color="primary"
                onClick={() => toggleCreatePatientDialog(true)}
              >
                <AddIcon fontSize="large" />
              </IconButton>
            ) : null}
          </div>
        </div>
        <Card className="card">
          <CardActions className="card-actions">
            <div>
              {hasPermission(permissions, RequestResource.USER, PermissionLevel.UPDATE) ? (
                <Button
                  variant="contained"
                  color="primary"
                  className="save-user-button"
                  onClick={handleSaveUser}
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              ) : null}
              <Button variant="contained" color="primary" onClick={handleClose} className="cancel-button">
                Cancel
              </Button>
            </div>
          </CardActions>
        </Card>
      </div>
      {showForgotPopup ? (
        <Dialog onClose={() => toggleResetDialog(false)} classes={['crc-reset-user-dialog']}>
          <DialogTitle title="Reset password" />
          <DialogContent>
            {userVerifyToken ? (
              <>
                <Input
                  id="userVerifyKey-input"
                  required={true}
                  variant="standard"
                  name={DataNames.USER_VERIFY_KEY}
                  label="User Verify Key"
                  value={resetPasswordData[DataNames.USER_VERIFY_KEY]}
                  handleChange={handleChangeResetData}
                  inputProps={{
                    type: 'text',
                    autoComplete: 'off',
                  }}
                  errorInfo={errors[DataNames.USER_VERIFY_KEY]}
                />
                <FormControl error={!!errors?.password?.error}>
                  <InputLabel htmlFor="password-input">Password</InputLabel>
                  <InputField
                    id="password-input"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    type={showPassword ? DataNames.TEXT : DataNames.PASSWORD}
                    name={DataNames.PASSWORD}
                    value={resetPasswordData[DataNames.PASSWORD]}
                    onChange={handleChangeResetData}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id="password-error-text">{errors?.password?.message}</FormHelperText>
                </FormControl>
              </>
            ) : (
              <FormControl component="fieldset">
                <FormLabel component="legend"></FormLabel>
                <RadioGroup aria-label="reset-password" name="email-phone" value={value} onChange={handleChange}>
                  <FormControlLabel value={ResetPassword.EMAIL} control={<Radio />} label="Reset via E-mail" />
                  <FormControlLabel value={ResetPassword.PHONE} control={<Radio />} label="Reset via Mobile" />
                </RadioGroup>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            {userVerifyToken ? (
              <>
                <Button variant="contained" color="primary" onClick={handleResetPassword}>
                  Submit
                </Button>
                <Button variant="contained" color="primary" onClick={() => toggleResetDialog(false)}>
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button variant="contained" color="primary" onClick={handleForgotPassword}>
                  Continue
                </Button>
                <Button variant="contained" color="primary" onClick={() => toggleForgotDialog(false)}>
                  Cancel
                </Button>
              </>
            )}
            <DeleteButton />
          </DialogActions>
        </Dialog>
      ) : null}
      {selectedCard ? (
        <Dialog onClose={() => setSelectedCard(null)} classes={['credit-card-dialog']}>
          <DialogTitle title="Card details" />
          <DialogContent>
            <CreditCard {...selectedCard} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => setSelectedCard(null)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {showBlockPopup ? (
        <Dialog onClose={() => toggleBlockDialog(false)} classes={['crc-block-user-dialog']}>
          <DialogTitle title="Are you sure you want to block this user?" />
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleBlockUser}>
              Continue
            </Button>
            <Button variant="contained" color="primary" onClick={() => toggleBlockDialog(false)}>
              Cancel
            </Button>
            <DeleteButton />
          </DialogActions>
        </Dialog>
      ) : null}
      {createPatientPopup ? (
        <CreatePatient
          toggleCreatePatientDialog={toggleCreatePatientDialog}
          addPatient={addPatient}
          userId={id}
          defaultPicture={data.defaultPicture}
        />
      ) : null}
      {editPatientPopup && (
        <EditPatient
          rowData={rowData as IPatientData}
          toggleEditPatientDialog={toggleEditPatientDialog}
          editPatient={editPatient}
          handleDelete={handleDelete}
          defaultPicture={data.defaultPicture || ''}
        />
      )}
    </div>
  );
}
