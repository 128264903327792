/**
 * Describes the methods which should be implemented by HTTP Service wrappers.
 */
export interface IHTTPService {
  getType: () => HTTPServiceType;
  initialize: () => void;
  get: (path: string, useCredentials?: boolean, requestOptions?: any) => Promise<any> | undefined;
  post: (path: string, postBody: any, useCredentials?: boolean, requestOptions?: any) => Promise<any> | undefined;
  put: (path: string, putBody: any, useCredentials: boolean) => Promise<any> | undefined;
  delete: (path: string, deleteBody: any, useCredentials: boolean) => Promise<any> | undefined;
}

export enum HTTPServiceType {
  AXIOS = 'AXIOS',
  FETCH = 'FETCH',
}

export enum HTTPStatus {
  BAD_REQUEST = 400,
  UNAUTHORISED = 401,
  FORBIDDEN = 403,
  CONFLICT = 409,
  SUCCESS = 200,
  CREATED = 201,
}
