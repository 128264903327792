import { HTTPServiceType, IHTTPService, ServiceType } from '../types';
import { AxiosService } from '../services';

export class ServiceFactory {
  /**
   * Generate a service instance based on the type and subType.
   * @param type: ServiceType
   * @param subType: HTTPServiceType
   */
  public static createService(type: ServiceType, subType: HTTPServiceType): IHTTPService | null {
    switch (type) {
      case ServiceType.HTTP:
      default:
        switch (subType) {
          case HTTPServiceType.AXIOS:
            return new AxiosService();
        }
        break;
    }

    return null;
  }
}
