export function isValidEmail(str: string) {
  const regLetters = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

  return regLetters.test(str);
}

export function isValidPhoneNumber(str: string) {
  const regLetters = /^[\+]\d{11}$/;

  return regLetters.test(str);
}

export function isValidPassword(str: string) {
  const regLetters = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])[A-Za-z\d\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]{9,}$/;

  return regLetters.test(str);
}
