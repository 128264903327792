import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Chart, BarSeries, Title, ArgumentAxis, ValueAxis, Tooltip } from '@devexpress/dx-react-chart-material-ui';
import { EventTracker, HoverState } from '@devexpress/dx-react-chart';

export default function BarChart(props: any) {
  const [targetItem, setTargetItem] = useState<any>(null);
  const [hover, setHover] = useState<any>(null);
  const changeTargetItem = (targetItem: any) => setTargetItem(targetItem);
  const changeHover = (hover: any) => setHover(hover);
  const { data: chartData, rotated } = props;

  return (
    <Paper>
      <Chart data={chartData} rotated={rotated}>
        <ArgumentAxis />
        <ValueAxis />
        <BarSeries valueField="quantity" argumentField="service" />
        <Title text="Medical services" />
        <EventTracker />
        <Tooltip targetItem={targetItem} onTargetItemChange={changeTargetItem} />
        <HoverState hover={hover} onHoverChange={changeHover} />
      </Chart>
    </Paper>
  );
}
