import { IErrors, IPermission, OrderFields, RequestResource, ValidationType } from '../../types';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import { Button, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  capitalizeFirstLetter,
  DEFAULT_VALIDATIONS_INFO,
  isValid,
  removeSymbolAndCapitalize,
  validate,
} from '../../utils';
import { history } from '../../configureStore';
import Input from '../core/input/Input';

export enum DataNames {
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phone',
  GENDER = 'gender',
  ROLE = 'role',
}

export interface IDataNames {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  gender: string;
  role: string;
}

const initalData = { firstName: '', lastName: '', email: '', phone: '', gender: '', role: '' };
const genderData = ['male', 'female'];

export interface IEditProfileProps {
  permissions: IPermission;
  userId: string;
  role: string;
}

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42);',
    },
    '&&:after': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42);',
    },
  },
});

export default function ProfileEdit(props: IEditProfileProps) {
  const [data, setData] = useState<IDataNames>(initalData);
  const [dataErrors, setDataErrors] = useState<IErrors>({
    [DataNames.FIRSTNAME]: {...DEFAULT_VALIDATIONS_INFO},
    [DataNames.LASTNAME]: {...DEFAULT_VALIDATIONS_INFO},
    [DataNames.EMAIL]: {...DEFAULT_VALIDATIONS_INFO},
    [DataNames.PHONE]: {...DEFAULT_VALIDATIONS_INFO},
  })
  const [gender, setGender] = useState<string>('');
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState<boolean>(false);
  const { userId, role } = props;

  const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeGender = (e: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
    setGender(e.target.value as string);
  };

  const handleSave = () => {
    setLoading(true);
    const newData = { ...data, gender };
    dataProvider
      .update(RequestResource.PROFILE, { data: newData, id: userId } as any)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getOne(RequestResource.PROFILE, { id: userId })
      .then((res) => {
        setLoading(false);
        const { firstName, lastName, email, phone, gender } = res.data;
        setData({
          ...data,
          [DataNames.FIRSTNAME]: firstName,
          [DataNames.LASTNAME]: lastName,
          [DataNames.EMAIL]: email,
          [DataNames.PHONE]: phone,
          [DataNames.ROLE]: removeSymbolAndCapitalize(role),
        });
        setGender(gender);
      })
      .catch(() => {
        setLoading(false);
      });

  }, []);

  useEffect(() => {
    if (history.location.search) {
      /*
      This type of code works on redirect from ListOrder.tsx component and changes route search param after this component initialisation
      so cancel button wil not work.
      We need to find another way to handle this part.
        const { setFilters } = useListContext();
        useEffect(() => {
          return () => setFilters({}, []);
        }, []);
      */
      history.goBack()
    }
  }, [history.location.search])

  if (loading) return <Loading />;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, name: DataNames) => {
    const { value } = e.target;

    const { validationTypes, config } = getValidationTypes(name);
    const newErrors = {
      ...dataErrors,
      [name]: validate(value, validationTypes, name, config),
    };
    setData({ ...data, [name]: value });
    setDataErrors(newErrors)
  };

  const getValidationTypes = (name: DataNames) => {
    const validationTypes = [ValidationType.REQUIRED];
    let config = {};
    if (name === DataNames.FIRSTNAME || name === DataNames.LASTNAME) {
      validationTypes.push(ValidationType.MIN_LENGTH, ValidationType.MAX_LENGTH);
      config = {
        minLength: 3,
        maxLength: 30,
      };
    }
    if (name === DataNames.EMAIL) {
      validationTypes.push(ValidationType.EMAIL);
      config = {};
    }
    if (name === DataNames.PHONE) {
      validationTypes.push(ValidationType.PHONE);
      config = {
        minLength: 11,
      };
    }

    return {
      validationTypes,
      config,
    };
  };

  const checkValidationAndSave = () => {
    const keys = Object.keys(dataErrors);
    let newErrors = { ...dataErrors };

    for (const key of keys) {
      const value = data[key as DataNames];
      const { validationTypes, config } = getValidationTypes(key as DataNames);

      newErrors = {
        ...newErrors,
        [key]: validate(value, validationTypes, key, config),
      };
    }
    if (isValid(newErrors)) {
      handleSave();
    }
    setDataErrors(newErrors);
  };

  return (
    <>
      <div className="profile">
        <div className="profile-data">
          <Input
            required={true}
            label="First name"
            value={data.firstName || ''}
            errorInfo={dataErrors[DataNames.FIRSTNAME]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, DataNames.FIRSTNAME)}
          />
          <Input
            required={true}
            label="Email"
            value={data.email || ''}
            errorInfo={dataErrors[DataNames.EMAIL]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, DataNames.EMAIL)}
          />
          <TextField
            id="standard-full-width"
            disabled
            label="Role"
            name={DataNames.ROLE}
            value={data.role}
            InputProps={{ classes }}
          />
        </div>
        <div className="profile-data">
          <Input
            required={true}
            label="Last name"
            value={data.lastName || ''}
            errorInfo={dataErrors[DataNames.LASTNAME]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, DataNames.LASTNAME)}
          />
          <Input
            required={true}
            label="Mobile"
            value={data.phone || ''}
            errorInfo={dataErrors[DataNames.PHONE]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, DataNames.PHONE)}
          />
          <FormControl>
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={gender}
              onChange={handleChangeGender}
            >
              {genderData.map((el: string, index) => (
                <MenuItem value={el} key={index}>{capitalizeFirstLetter(el)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="profile-save-button">
        <Button color="primary" variant="contained" onClick={checkValidationAndSave}>
          Save
        </Button>
        <Button color="primary" variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </>
  );
}
