import { IHTTPService, ServiceType } from '../../types';
import { ServiceFactory } from '../../factories';
import { AppConfig } from '../../config';

class ServiceSelector {
  /**
   * Singleton instance of the HTTP service.
   */
  static httpService: IHTTPService;

  /**
   * Singleton getter method for the HTTP service.
   * Creates a new instance based on the AppConfig.httpServiceType if it does not already exists,
   * Returns the existing one in the opposite case.
   */
  public static getHTTPService(): IHTTPService {
    if (!this.httpService) {
      this.httpService = ServiceFactory.createService(ServiceType.HTTP, AppConfig.httpServiceType) as IHTTPService;
      this.httpService.initialize();
    }
    return this.httpService;
  }
}

export function getHTTPService(): IHTTPService {
  return ServiceSelector.getHTTPService();
}
