import { connect } from 'react-redux';
import OrderService from '../../components/order-service/OrderService';
import {
  getOrderService,
  saveOrderServiceAttachments,
  removeOrderServiceAttachment,
  saveOrderService,
  emptyOrderService,
} from '../../actions/order';
import { IAppState, IAppDispatch } from '../../types';
import { getUsers } from '../../actions/user';

const mapStateToProps = (state: IAppState) => ({
  orderService: state.order.orderService,
  orderLoading: state.order.orderLoading,
  loading: state.order.loading,
  users: state.user.users,
  permissions: state.auth.permissions,
  role: state.auth.role,
  userId: state.auth.userId,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({
  getOrderService: (id: number) => dispatch(getOrderService(id)),
  removeOrderServiceAttachment: (orderServiceId: number, attachmentId: number) =>
    dispatch(removeOrderServiceAttachment(orderServiceId, attachmentId)),
  saveOrderServiceAttachments: (orderServiceId: number, files: any) =>
    dispatch(saveOrderServiceAttachments(orderServiceId, files)),
  getAgents: (roleId: number) => dispatch(getUsers(roleId)),
  saveOrderService: (id: number, data: any) => dispatch(saveOrderService(id, data)),
  emptyOrderService: () => dispatch(emptyOrderService()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderService);
