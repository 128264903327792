import { connect } from 'react-redux';
import { IAppState, IAppDispatch } from '../../types';
import ListUserNotification from '../../components/userNotification/ListUserNotification';

const mapStateToProps = (state: IAppState) => ({
  userNotificationCount: state.admin.resources.userNotification.list.total,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListUserNotification);
