import React from 'react';
import {
  Dialog as MatDialog,
  DialogTitle as MatDialogTitle,
  DialogContent as MatDialogContent,
  DialogActions as MatDialogActions,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface IDialogProps {
  classes?: string[];
  children: React.ReactNode;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  onClose: () => void;
}

interface IDialogTitleProps {
  classes?: string[];
  title: string;
  onClose?: () => void;
}

interface IDialogContentProps {
  classes?: string[];
  children: React.ReactNode;
}

interface IDialogActionsProps {
  classes?: string[];
  children: React.ReactNode;
}

export function DialogTitle(props: IDialogTitleProps) {
  const { title, classes, onClose } = props;
  const className = classes ? ` ${classes.join(' ')}` : '';

  return (
    <MatDialogTitle disableTypography className={`crs-dialog-title${className}`} id="dialog-title">
      <Typography variant="h6">{title}</Typography>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MatDialogTitle>
  );
}

export function DialogContent(props: IDialogContentProps) {
  const { children, classes } = props;
  const className = classes ? ` ${classes.join(' ')}` : '';

  return (
    <MatDialogContent dividers className={`crs-dialog-content${className}`}>
      {children}
    </MatDialogContent>
  );
}

export function DialogActions(props: IDialogActionsProps) {
  const { children, classes } = props;
  const className = classes ? ` ${classes.join(' ')}` : '';

  return <MatDialogActions className={`crs-dialog-actions${className}`}>{children}</MatDialogActions>;
}

export default function Dialog(props: IDialogProps) {
  const { onClose, children, classes } = props;

  const handleClose = () => {
    onClose();
  };
  const className = classes ? ` ${classes.join(' ')}` : '';

  return (
    <MatDialog
      className={`crs-dialog${className}`}
      maxWidth={props.maxWidth}
      classes={{
        paper: 'crs-dialog-paper',
      }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      {children}
    </MatDialog>
  );
}
