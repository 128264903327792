import React, { useEffect, useState } from 'react';
import { Loading, useDataProvider, useRedirect } from 'react-admin';
import {
  Button,
  Card,
  CardActions,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Input as InputField,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Input from '../core/input/Input';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  capitalizeFirstLetter,
  DEFAULT_VALIDATIONS_INFO,
  getBirthDate,
  getMinDay,
  getMinMonth,
  getMonthDays,
  getYearsTillNow,
  hasPermission,
  isArray,
  isValid,
  queryBuilder,
  removeSymbolAndCapitalize,
  validate,
} from '../../utils';
import {
  RequestResource,
  PermissionLevel,
  IPermission,
  INotificationTemplate,
  NotificationTemplateField,
  NOTIFICATION_TEMPLATE_TYPES,
  IErrors,
  ValidationType,
  ScheduleOptions,
  IRolePermission,
  NOTIFICATION_TEMPLATE_PERIODICITY,
  comparePeriodicityLavel,
  Periodicity,
  NOTIFICATION_TEMPLATE_WEEKDAY,
  IScheduleOptions,
  IfilterObject,
  genderSelectData,
  IAgeRangeData,
  ageRangeData,
  OrderPatientFields,
} from '../../types';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { AppConfig } from '../../config';
import DatePicker, { PickerView } from '../core/picker/datepicker/DatePicker';
import Autocomplete from '@material-ui/lab/Autocomplete';
import countries from 'countries-list';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { dateHourFormat, dateMinuteFormat, dateMonthFormat, dateYearFormat } from '../../constants';

export interface IEditTemplateProps {
  permissions: IPermission;
  showNotification: any;
  id: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: '15px 55px 15px 0',
      width: '270px',
    },
  })
);

const DEFAULT_DATA = {
  [NotificationTemplateField.ACTION]: '',
  [NotificationTemplateField.TYPE]: NOTIFICATION_TEMPLATE_TYPES[0],
  [NotificationTemplateField.FROM]: '',
  [NotificationTemplateField.SUBJECT]: '',
  [NotificationTemplateField.CONTENT]: null,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const parseQuery = (query: string) => {
  const splitedData: string[] = query.split('&search=').slice(1);
  const res = [];
  const filter: IfilterObject = {};
  for (const item of splitedData) {
    if (item.includes('|')) {
      res.push(...item.split('|'));
    } else {
      res.push(item);
    }
  }
  const ageRangeArr: string[] = [];
  res.forEach((el) => {
    if (el.includes(',like,')) {
      const [key, value] = el.split(',like,');
      const prevValue = filter[key];
      const targetValue = key in filter ? (isArray(prevValue) ? [...prevValue, value] : [prevValue, value]) : value;
      filter[key] = targetValue;
    } else if (el.includes(',=,')) {
      const [key, value] = el.split(',=,');
      filter[key] = value;
    } else if (el.includes(',range,')) {
      const [key, value] = el.split(',range,');
      const range = value.replace(/[[\]]/g, '').split('^');
      const [firstValue, secondValue] = range;
      let targetAgeRange = '';
      if (firstValue) {
        targetAgeRange = range.map((el: string) => getYearsTillNow(new Date(el) as Date, 'years')).join('-');
      } else {
        targetAgeRange = getYearsTillNow(new Date(secondValue) as Date, 'years').toString();
      }
      ageRangeArr.push(targetAgeRange);
      filter[key] = ageRangeArr;
    }
  });
  return filter;
};

export default function EditTemplate(props: IEditTemplateProps) {
  const { permissions } = props;
  const [actionsList, setActionsList] = useState<string[]>([]);
  const redirect = useRedirect();
  const [data, setData] = useState<INotificationTemplate>(DEFAULT_DATA);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<IErrors>({
    [NotificationTemplateField.SUBJECT]: { ...DEFAULT_VALIDATIONS_INFO },
    [NotificationTemplateField.CONTENT]: { ...DEFAULT_VALIDATIONS_INFO },
    [NotificationTemplateField.FROM]: { ...DEFAULT_VALIDATIONS_INFO },
    [NotificationTemplateField.ACTION]: { ...DEFAULT_VALIDATIONS_INFO },
  });
  const [countriesData, setCountriesData] = useState<Array<{}>>([]);
  const [rolesData, setRolesData] = useState<null | IRolePermission[]>(null);
  const classes = useStyles();
  const [periodicity, setPeriodicity] = useState<string>('');
  const [yearData, setYear] = useState<Date | null>(null);
  const [monthData, setMonth] = useState<Date | null>(null);
  const [date, setDate] = useState<number | null>(null);
  const [dayOfWeek, setDayOfWeek] = useState<string[]>([]);
  const [hourData, setHour] = useState<Date | null>(null);
  const [minuteData, setMinute] = useState<Date | null>(null);
  const [role, setRole] = useState<string[]>([]);
  const [gender, setGender] = useState<string>('');
  const [country, setCountry] = useState<{ name: 'string' } | null>(null);
  const [age, setAge] = React.useState<IAgeRangeData>(ageRangeData);
  const dataProvider = useDataProvider();

  const defaultProps = {
    options: countriesData,
    getOptionLabel: (option: any) => option.name,
  };

  useEffect(() => {
    const countriesList = Object.values(countries.countries);
    const countriesSortedList = countriesList.sort((a: { name: string }, b: { name: string }) => {
      return a.name > b.name ? 1 : -1;
    });
    setCountriesData(countriesSortedList);
  }, []);

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getOne(RequestResource.NOTIFICATION_TEMPLATE, { id: props.id })
      .then((res) => {
        setLoading(false);
        const countriesList = Object.values(countries.countries);
        const countriesSortedList = countriesList.sort((a: { name: string }, b: { name: string }) => {
          return a.name > b.name ? 1 : -1;
        });
        setCountriesData(countriesSortedList);
        const { action, content, from, subject, type, sendToCondition, schedule } = res.data;
        if (sendToCondition) {
          const { 'role.name': role, gender, country, birthDate } = parseQuery(sendToCondition);
          const newCountry = countriesSortedList.find((el: { name: string }) => el.name === country);
          newCountry && setCountry(newCountry as { name: 'string' });
          setGender(gender);
          if (role && typeof role === 'object') {
            setRole(role);
          } else if (role) {
            setRole([role]);
          }
          if (birthDate.length) {
            let newAge = Object.entries({ ...age });
            birthDate.forEach((el: string) => {
              newAge = newAge.map(([key, item]) => {
                if (item.value === el) {
                  return [key, { ...item, checked: true }];
                }
                return [key, item];
              });
            });
            setAge(Object.fromEntries(newAge));
          }
        }
        setData({ ...data, action, content, from, subject, type } as any);
        if (schedule) {
          const { year, month, date, dayOfWeek: weekDay, hour, minute } = schedule;
          const newDate = new Date();
          year && setYear(new Date(newDate.setFullYear(year)));
          month && setMonth(new Date(newDate.setMonth(month)));
          date && setDate(date);
          (hour || hour === 0) && setHour(new Date(newDate.setHours(hour)));
          (minute || minute === 0) && setMinute(new Date(newDate.setMinutes(minute)));
          if (typeof weekDay === 'number') {
            const nameOfWeekDay = NOTIFICATION_TEMPLATE_WEEKDAY.find((elem: { name: string; value: number }) => {
              return weekDay === elem.value;
            })?.name as string;
            setDayOfWeek([nameOfWeekDay]);
          } else if (weekDay) {
            const arrayOfNamesWeekDay = weekDay.map((el: number) => {
              return NOTIFICATION_TEMPLATE_WEEKDAY.find((elem: { name: string; value: number }) => {
                return el === elem.value;
              })?.name;
            }) as string[];
            setDayOfWeek(arrayOfNamesWeekDay);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dataProvider
      .getList(RequestResource.ROLE, {} as any)
      .then((res: any) => {
        const data = res.data;
        setRolesData(data as IRolePermission[]);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    dataProvider
      .getList(RequestResource.NOTIFICATION_TEMPLATE_ACTIONS, {} as any)
      .then((res) => {
        let newActionsData = Object.values(res.data as any);
        setActionsList(newActionsData as string[]);
      })
      .catch(() => {});
  }, []);

  const handleChange = (value: string, elem: string) => {
    let newErrors = {
      ...errors,
    };
    const config = {
      minLength: 3,
    };
    newErrors = {
      ...newErrors,
      [elem]: validate(value as string, [ValidationType.REQUIRED, ValidationType.MIN_LENGTH], elem, config),
    };
    setErrors(newErrors);
    setData({ ...data, [elem]: value } as INotificationTemplate);
  };

  const handleCancelClick = () => {
    redirect(`/${RequestResource.NOTIFICATION_TEMPLATE}`);
  };

  const handleDelete = () => {
    if (!props.id) {
      return;
    }
    setLoading(true);
    dataProvider
      .delete(RequestResource.NOTIFICATION_TEMPLATE, {
        id: +props.id,
      } as any)
      .then(() => {
        redirect(`/${RequestResource.NOTIFICATION_TEMPLATE}`);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSave = () => {
    if (!props.id || !isValid(errors)) {
      return;
    }
    let schedule: null | IScheduleOptions = null;
    let sendToCondition: string = '';
    if (data.action === 'schedule') {
      schedule = {};
      yearData && (schedule[ScheduleOptions.YEAR] = yearData?.getFullYear());
      monthData && (schedule[ScheduleOptions.MONTH] = monthData?.getMonth());
      hourData && (schedule[ScheduleOptions.HOUR] = hourData?.getHours());
      if (minuteData) {
        schedule[ScheduleOptions.MINUTE] = minuteData.getMinutes();
      } else if (hourData && !minuteData) {
        schedule[ScheduleOptions.MINUTE] = 0;
      }
      if (dayOfWeek.length === 1) {
        const numberOfWeekDay = NOTIFICATION_TEMPLATE_WEEKDAY?.find((elem: { name: string; value: number }) => {
          return elem.name === dayOfWeek[0];
        })?.value;
        schedule[ScheduleOptions.DAYOFWEEK] = numberOfWeekDay;
      } else if (dayOfWeek.length > 1) {
        const arrayOfNumbersWeekDay = dayOfWeek.map((el: string) => {
          return NOTIFICATION_TEMPLATE_WEEKDAY.find((elem: { name: string; value: number }) => {
            return el === elem.name;
          })?.value;
        });
        schedule[ScheduleOptions.DAYOFWEEK] = arrayOfNumbersWeekDay as number[];
      }
      date && (schedule[ScheduleOptions.DATE] = date);
      const filter: IfilterObject = {};
      role.length && (filter['role>name~like'] = role);
      gender && (filter['gender~='] = gender);
      country && (filter['country~='] = country.name);
      const ages = Object.values(age).filter((item: { checked: boolean; value: string }) => {
        return item.checked;
      });
      const q = ages
        .map((ageRange: { checked: boolean; value: string }) => {
          const [first, second] = ageRange.value.split('-');
          if (second) {
            return `birthDate,range,[${getBirthDate(+first)}^${getBirthDate(+second)}]`;
          }
          return `birthDate,range,^${getBirthDate(+first)}]`;
        })
        .join('|');
      sendToCondition =
        (Object.keys(filter).length && q) || q
          ? `projection=role&${queryBuilder({ filter: filter }, 'user').query}&search=${q}`
          : Object.keys(filter).length
          ? `projection=role&${queryBuilder({ filter: filter }, 'user').query}`
          : '';
    }
    const newData =
      schedule && Object.keys(schedule as IScheduleOptions).length
        ? { ...data, schedule, sendToCondition }
        : { ...data };
    setLoading(true);
    dataProvider
      .update(RequestResource.NOTIFICATION_TEMPLATE, {
        id: +props.id,
        data: newData,
      } as any)
      .then(() => {
        redirect(`/${RequestResource.NOTIFICATION_TEMPLATE}`);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  function uploadAdapter(loader: any) {
    let str = data.content;
    let tmp = str && str.replace(/<img .*?>/g, ' ');
    setData({ ...data, content: tmp });
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file: any) => {
            formData.append('notificationTemplateImage', file);
            dataProvider
              .create(RequestResource.NOTIFICATION_TEMPLATE_PICTURE, { data: formData } as any)
              .then((res: any) => {
                const url = res?.data.path;
                resolve({
                  default: `${AppConfig.httpAPIGatewayURL}/${url}`,
                });
              })
              .catch((err) => {
                reject(err.data.message);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return uploadAdapter(loader);
    };
  }

  const handleReset = () => {
    setYear(null);
    setMonth(null);
    setDate(null);
    setDayOfWeek([]);
    setHour(null);
    setMinute(null);
  };

  const handleCancelSelectedDays = () => {
    setDate(null);
  };

  const handleCancelSelectedGender = () => {
    setGender('');
  };

  const quantityOfDays = +getMonthDays(yearData, monthData);
  const NOTIFICATION_TEMPLATE_DATE = new Array(quantityOfDays).fill(1).map((item, index) => index + 1);
  const minDay: number | undefined = getMinDay(yearData, monthData);

  if (loading) return <Loading />;
  return (
    <div className="edit-notification-template">
      <FormControl className="edit-notification-template-form">
        <Typography variant="h5">Template edit</Typography>
        <Divider className="divider" />
        <div className="body">
          <FormControl error={errors?.[NotificationTemplateField.ACTION]?.error}>
            <InputLabel id="action-input-label">Action*</InputLabel>
            <Select
              labelId="action-select-label"
              id="action-select"
              value={data?.action}
              onChange={(e) => handleChange(e.target.value as string, NotificationTemplateField.ACTION)}
            >
              {actionsList.map((action: string) => (
                <MenuItem value={action} key={action}>
                  {removeSymbolAndCapitalize(action)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={true}>{errors?.[NotificationTemplateField.ACTION]?.message}</FormHelperText>
          </FormControl>
          <FormControl>
            <InputLabel id="type-input-label">Type*</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={data?.type || NOTIFICATION_TEMPLATE_TYPES[0]}
              onChange={(e) => handleChange(e.target.value as string, NotificationTemplateField.TYPE)}
            >
              {NOTIFICATION_TEMPLATE_TYPES.map((type: string) => (
                <MenuItem value={type} key={type}>
                  {removeSymbolAndCapitalize(type)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Input
            label="From"
            required={true}
            value={data?.from || ''}
            errorInfo={errors[NotificationTemplateField.FROM]}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.currentTarget.value, NotificationTemplateField.FROM)
            }
          />
          <Input
            label="Subject / Translation Key"
            required={true}
            value={data?.subject || ''}
            errorInfo={errors[NotificationTemplateField.SUBJECT]}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.currentTarget.value, NotificationTemplateField.SUBJECT)
            }
          />
          {data?.content !== null && !loading ? (
            <CKEditor
              editor={ClassicEditor}
              data={data?.content}
              onChange={(e: any, editor: any) => {
                setTimeout(() => {
                  const data = editor.getData();
                  handleChange(data, NotificationTemplateField.CONTENT);
                }, 0);
              }}
              config={{
                extraPlugins: [uploadPlugin],
              }}
            />
          ) : (
            ''
          )}
          <span className="ckeditor-error-notification">{errors[NotificationTemplateField.CONTENT].message}</span>
        </div>
        {data.action === 'schedule' && (
          <>
            <div className="schedule">
              <FormControl>
                <InputLabel id="period-input-label">Period</InputLabel>
                <Select
                  labelId="period-select-label"
                  id="period-select"
                  value={periodicity || NOTIFICATION_TEMPLATE_PERIODICITY[0]}
                  onChange={(e) => setPeriodicity(e.target.value as string)}
                >
                  {NOTIFICATION_TEMPLATE_PERIODICITY.map((item: string) => (
                    <MenuItem value={item} key={item}>
                      {capitalizeFirstLetter(item)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {(comparePeriodicityLavel(periodicity as Periodicity).includes(Periodicity.YEAR) || !periodicity) && (
                <div>
                  <span>in</span>
                  <DatePicker
                    required={false}
                    inputVariant="standard"
                    disableToolbar={true}
                    label="Year"
                    views={['year'] as PickerView}
                    format={dateYearFormat}
                    value={yearData}
                    errorInfo={DEFAULT_VALIDATIONS_INFO}
                    classes={['text-field']}
                    inputProps={{
                      disabled: true,
                    }}
                    handleChange={(date: Date) => {
                      setYear(date);
                      setMonth(null);
                      setDayOfWeek([]);
                      setDate(null);
                    }}
                  />
                </div>
              )}
              {(comparePeriodicityLavel(periodicity as Periodicity).includes(Periodicity.MONTH) || !periodicity) && (
                <div>
                  <span>in</span>
                  <DatePicker
                    required={false}
                    inputVariant="standard"
                    disableToolbar={true}
                    label="Month"
                    views={['month'] as PickerView}
                    format={dateMonthFormat}
                    value={monthData}
                    errorInfo={DEFAULT_VALIDATIONS_INFO}
                    classes={['text-field']}
                    inputProps={{
                      disabled: true,
                    }}
                    minDate={getMinMonth(yearData)}
                    handleChange={(date: Date) => setMonth(date)}
                  />
                </div>
              )}
              {(comparePeriodicityLavel(periodicity as Periodicity).includes(Periodicity.DAY) || !periodicity) &&
                periodicity !== Periodicity.WEEK && (
                  <div>
                    <span>on</span>
                    <FormControl>
                      <InputLabel id="day-input-label">Day of month</InputLabel>
                      <Select
                        MenuProps={{
                          className: 'dates',
                        }}
                        labelId="day-select-label"
                        id="day-select"
                        value={date}
                        onChange={(e) => setDate(e.target.value as number)}
                        disabled={!!dayOfWeek.length}
                      >
                        {NOTIFICATION_TEMPLATE_DATE.map((item: number) => (
                          <MenuItem value={item} key={item} disabled={item < (minDay || 0)}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                      <div className="icon-wrapper" onClick={handleCancelSelectedDays}>
                        <ClearIcon />
                      </div>
                    </FormControl>
                  </div>
                )}
              {(comparePeriodicityLavel(periodicity as Periodicity).includes(Periodicity.WEEK) || !periodicity) &&
                periodicity !== Periodicity.DAY && (
                  <div>
                    <span>on</span>
                    <FormControl error={false}>
                      <InputLabel id="weekDay-input-label">Day of week</InputLabel>
                      <Select
                        labelId="weekDay-select-label"
                        id="weekDay-multiple-checkbox"
                        multiple
                        value={dayOfWeek}
                        onChange={(e) => {
                          setDayOfWeek(e.target.value as string[]);
                        }}
                        disabled={!!date}
                        input={<InputField />}
                        renderValue={(selected: any) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {NOTIFICATION_TEMPLATE_WEEKDAY.map((item: { name: string; value: number }, index: number) => (
                          <MenuItem key={index} value={item.name}>
                            <Checkbox checked={!!dayOfWeek.find((elem) => elem === item.name)} />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
              {(comparePeriodicityLavel(periodicity as Periodicity).includes(Periodicity.HOUR) || !periodicity) && (
                <div>
                  <span>at</span>
                  <DatePicker
                    required={false}
                    inputVariant="standard"
                    disableToolbar={true}
                    label="Hour"
                    views={['hours'] as PickerView}
                    format={dateHourFormat}
                    value={hourData}
                    errorInfo={DEFAULT_VALIDATIONS_INFO}
                    classes={['text-field']}
                    inputProps={{
                      disabled: true,
                    }}
                    handleChange={(date: Date) => setHour(date)}
                  />
                </div>
              )}
              {(comparePeriodicityLavel(periodicity as Periodicity).includes(Periodicity.MINUTE) || !periodicity) && (
                <div>
                  <span>:</span>
                  <DatePicker
                    required={false}
                    inputVariant="standard"
                    disableToolbar={true}
                    label="Minute"
                    views={['minutes'] as PickerView}
                    format={dateMinuteFormat}
                    value={minuteData}
                    errorInfo={DEFAULT_VALIDATIONS_INFO}
                    classes={['text-field']}
                    inputProps={{
                      disabled: true,
                    }}
                    handleChange={(date: Date) => setMinute(date)}
                  />
                </div>
              )}
              <Button variant="contained" className="clear-button" onClick={handleReset}>
                Clear
              </Button>
            </div>
            <Divider className="divider secondary" />
            <Typography variant="h6">Filters</Typography>
            <Divider className="divider secondary" />
            <div className="filter-data">
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Age range</FormLabel>
                <FormGroup>
                  {Object.values(age).map(({ checked, name, label }) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={(e) => {
                              const name: string = e.target.name;
                              const checked: boolean = e.target.checked;
                              setAge({ ...age, [name as string]: { ...age[name as string], checked } });
                            }}
                            name={name}
                          />
                        }
                        label={label}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
              <FormControl>
                <InputLabel id="gender-input-label">Gender</InputLabel>
                <Select
                  labelId="gender-select-label"
                  id="gender-select"
                  value={gender}
                  onChange={(e) => setGender(e.target.value as string)}
                >
                  {genderSelectData.map((el: string, index: number) => (
                    <MenuItem value={el} key={index}>
                      {capitalizeFirstLetter(el)}
                    </MenuItem>
                  ))}
                </Select>
                <div className="icon-wrapper" onClick={handleCancelSelectedGender}>
                  <ClearIcon />
                </div>
              </FormControl>
              <Autocomplete
                {...defaultProps}
                id="country-input"
                value={country}
                onChange={(event: any, country: any) => {
                  setCountry(country);
                }}
                renderInput={(params: any) => <TextField {...params} label="Country" />}
              />
              <FormControl error={false}>
                <InputLabel id="role-input-label">Role</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="role-multiple-checkbox"
                  multiple
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value as string[]);
                  }}
                  input={<InputField />}
                  renderValue={(selected: any) => selected.map(removeSymbolAndCapitalize).join(', ')}
                  MenuProps={MenuProps}
                >
                  {rolesData?.map((item: { name: string }, index: number) => (
                    <MenuItem key={index} value={item.name}>
                      <Checkbox checked={!!role.find((elem: string) => elem === item.name)} />
                      <ListItemText primary={removeSymbolAndCapitalize(item.name)} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </>
        )}
        <Card className="card">
          <CardActions className="card-actions">
            <div>
              {hasPermission(permissions, RequestResource.NOTIFICATION_TEMPLATE, PermissionLevel.UPDATE) ? (
                <Button variant="contained" color="primary" onClick={handleSave} startIcon={<SaveIcon />}>
                  Save
                </Button>
              ) : null}
              <Button variant="outlined" color="primary" onClick={handleCancelClick} className="cancel-button">
                Cancel
              </Button>
            </div>
            {hasPermission(permissions, RequestResource.NOTIFICATION_TEMPLATE, PermissionLevel.DELETE) ? (
              <Button
                className="delete"
                variant="contained"
                color="secondary"
                onClick={handleDelete}
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            ) : null}
          </CardActions>
        </Card>
      </FormControl>
    </div>
  );
}
