import { connect } from 'react-redux';
import AttachedPatients from '../../components/manageUsers/AttachedPatients';
import { IAppState, IAppDispatch } from '../../types';
import { showNotification } from 'react-admin';
import { bindActionCreators } from 'redux';

const mapStateToProps = (state: IAppState) => ({});

const mapDispatchToProps = (dispatch: IAppDispatch) =>
  bindActionCreators(
    {
      showNotification,
    },
    dispatch
  );

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(AttachedPatients);
