import React from 'react';
import {
  BulkDeleteButton,
  Datagrid,
  downloadCSV,
  EditButton,
  ExportButton,
  FunctionField,
  List,
  TextField,
  useListContext,
} from 'react-admin';
import { hasPermission } from '../../utils';
import { IPermission, PermissionLevel, RequestResource, Role } from '../../types';

// @ts-ignore
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

export interface IListFaq {
  permissions: IPermission;
  currentUserRole: string;
}

export default function ListFaq(props: IListFaq) {
  const { permissions, currentUserRole } = props;

  const exporter = (orders: any, selectedIds: any) => {
    const faqsForExport = Object.values(orders)
      .filter((order: any) => {
        return selectedIds.includes(order.id);
      })
      .map((el: any) => ({ ...el, question: el.question, answer: el.answer, topic: el.topic }));
    const csv = convertToCSV({
      data: faqsForExport,
      fields: ['id', 'question', 'answer', 'topic'],
    });
    downloadCSV(csv, 'faqs');
  };
  const canDelete = hasPermission(permissions, RequestResource.FAQ, PermissionLevel.DELETE);
  const BulkActionButtons = (props: any) => {
    const { selectedIds } = props;
    const { total, data } = useListContext();
    return (
      <React.Fragment>
        {canDelete && <BulkDeleteButton {...props} />}
        {[Role.ADMIN, Role.SUPER_ADMIN].includes(currentUserRole as Role) && (
          <ExportButton disabled={!total} onClick={() => exporter(data, selectedIds)} />
        )}
      </React.Fragment>
    );
  };

  return (
    <List bulkActionButtons={<BulkActionButtons />} exporter={false} {...props}>
      <Datagrid isRowSelectable={() => canDelete} className="react-admin-table">
        <TextField source="id" />
        <FunctionField label="Topic" render={(record: any) => record.topic} />
        <FunctionField label="Question" render={(record: any) => record.question} />
        <FunctionField label="Answer" render={(record: any) => record.answer} />
        {hasPermission(permissions, RequestResource.FAQ, PermissionLevel.UPDATE) ? <EditButton /> : null}
      </Datagrid>
    </List>
  );
}
