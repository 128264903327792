import { showNotification } from 'react-admin';
import { IAppDispatch, INotification, INotificationService } from '../../types';
import { store } from '../../index';
import { t } from '../../selectors/';

/**
 * Singleton service class for showing and managing app notifications.
 */
export class NotificationService implements INotificationService {
  public show({ type, message }: INotification): void {
    const dispatch = store.dispatch as IAppDispatch;

    if (message) {
      dispatch(showNotification(t(message), type));
    }
  }
}
