import { getHTTPService } from '../../selectors';
import { HTTPStatus, IAppRouteKey, IHTTPService } from '../../types';
import authProvider, { checkAuthCallback } from '../../authProvider';
import { history } from '../../configureStore';
import { AppRoutes } from '../../config';

export class BaseAPIService {
  protected httpService: IHTTPService;
  private readonly prefix: string = '';

  constructor(prefix: string) {
    this.httpService = getHTTPService();
    this.prefix += prefix;
  }

  public get(
    url: string,
    useCredentials: boolean = true,
    requestOptions = {},
    prefix = '',
    withCustomError: boolean = false,
    errorHandler?: (error: any) => void
  ): Promise<any> | undefined {
    prefix = prefix || this.prefix;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(`${prefix}${url}`, useCredentials, requestOptions)
        ?.then((response) => resolve(response))
        ?.catch(({ response: errorResponse }) => {
          if (withCustomError) {
            if (typeof errorHandler === 'function') {
              return errorHandler(errorResponse);
            }
          }
          this.handleError(errorResponse);
          reject(errorResponse);
        });
    });
  }

  public post(
    path: string,
    postBody: any,
    useCredentials: boolean = true,
    requestOptions = {},
    withCustomError: boolean = false,
    errorHandler?: (err: any) => void
  ): Promise<any> | undefined {
    return new Promise((resolve, reject) => {
      this.httpService
        .post(`${this.prefix}${path}`, postBody, useCredentials, requestOptions)
        ?.then((response) => {
          return resolve(response);
        })
        ?.catch(({ response: errorResponse }) => {
          if (withCustomError) {
            if (typeof errorHandler === 'function') {
              return errorHandler(errorResponse);
            }
          }
          this.handleError(errorResponse);
          reject(errorResponse);
        });
    });
  }

  public put(
    path: string,
    putBody: any,
    useCredentials: boolean = true,
    withCustomError: boolean = false,
    errorHandler?: (err: any) => void
  ): Promise<any> | undefined {
    return new Promise((resolve, reject) => {
      this.httpService
        .put(`${this.prefix}${path}`, putBody, useCredentials)
        ?.then((response) => resolve(response))
        ?.catch(({ response: errorResponse }) => {
          if (withCustomError) {
            if (typeof errorHandler === 'function') {
              return errorHandler(errorResponse);
            }
          }
          this.handleError(errorResponse);
          reject(errorResponse);
        });
    });
  }

  public delete(path: string, deleteBody: any = {}, useCredentials: boolean = true): Promise<any> | undefined {
    return new Promise((resolve, reject) => {
      this.httpService
        .delete(`${this.prefix}${path}`, deleteBody, useCredentials)
        ?.then((response) => resolve(response))
        ?.catch(({ response: errorResponse }) => {
          this.handleError(errorResponse);
          reject(errorResponse);
        });
    });
  }

  private handleError(err: any) {
    const { status, config } = err || {};
    const { url } = config || {};

    if (status === HTTPStatus.UNAUTHORISED) {
      if (url.includes('refresh-token')) {
        authProvider.logout();
        // @ts-ignore
        window.location = process.env.REACT_APP_DOMAIN_URL + AppRoutes[IAppRouteKey.LOGIN].path;
      } else {
        checkAuthCallback(0, true);
      }
    }
  }
}
