export function formatCurrency(value: number): string {
  if (isNaN(value) || value === null) {
    return '';
  }
  const splitted = value?.toString().split('') || [];
  const result: string[] = [];
  const endPart = '.00';
  let length = splitted.length;
  const partsCount = Math.floor(length / 3);

  for (let i = 0; i < partsCount; i++) {
    const part = splitted.splice(length - 3, length);
    result.unshift(part.join(''));
    length = splitted.length;
  }
  if (splitted.length) {
    result.unshift(splitted.join(''));
  }
  return result.join(',') + endPart;
}
