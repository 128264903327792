import Dialog, { DialogActions, DialogContent, DialogTitle } from '../core/dialog/Dialog';
import { Button, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core';
import { AppConfig } from '../../config';
import { dateFormat } from '../../constants';
import { DataNames, IPatientData, IPatientError, UserGender, ValidationType } from '../../types';
import {
  capitalizeFirstLetter,
  DEFAULT_VALIDATIONS_INFO,
  FieldValue,
  getBase64Image,
  getMaxDate,
  isBase64String,
  isValid,
  validate,
} from '../../utils';
import { DeleteButton } from 'react-admin';
import React, { useState } from 'react';
import DatePicker from '../core/picker/datepicker/DatePicker';
import Input from '../core/input/Input';

const initialError: IPatientError = {
  [DataNames.FIRSTNAME]: { ...DEFAULT_VALIDATIONS_INFO },
  [DataNames.LASTNAME]: { ...DEFAULT_VALIDATIONS_INFO },
  // [DataNames.EMAIL]: { ...DEFAULT_VALIDATIONS_INFO },
  // [DataNames.PHONE]: { ...DEFAULT_VALIDATIONS_INFO },
  // [DataNames.GENDER]: { ...DEFAULT_VALIDATIONS_INFO },
  // [DataNames.BIRTHDATE]: { ...DEFAULT_VALIDATIONS_INFO },
};

const genderData: Array<string> = [UserGender.MALE, UserGender.FEMALE];

export interface ICreatePatientProps {
  rowData: IPatientData;
  defaultPicture: string;
  toggleEditPatientDialog: (arg: boolean) => void;
  editPatient: (arg: IPatientData) => void;
  handleDelete: (arg: IPatientData) => void;
}

export default function EditPatient(props: ICreatePatientProps) {
  const { rowData, toggleEditPatientDialog, defaultPicture, editPatient, handleDelete } = props;
  const [error, setError] = useState<IPatientError>(initialError);
  const [uploadedImage, setUploadedImage] = useState<File>();
  const [encodedImg, setEncodedImg] = useState<string>();
  const [data, setData] = useState<IPatientData>({
    [DataNames.FIRSTNAME]: rowData.firstName,
    [DataNames.LASTNAME]: rowData.lastName,
    [DataNames.EMAIL]: rowData.email,
    [DataNames.PHONE]: rowData.phone,
    [DataNames.GENDER]: rowData.gender,
    [DataNames.BIRTHDATE]: rowData.birthDate ? new Date(rowData.birthDate) : '',
  });

  const getValidationTypes = (name: DataNames) => {
    const validationTypes = [ValidationType.REQUIRED];
    let config = {};
    if (name === DataNames.FIRSTNAME || name === DataNames.LASTNAME) {
      validationTypes.push(ValidationType.MIN_LENGTH, ValidationType.MAX_LENGTH);
      config = {
        minLength: 3,
        maxLength: 30,
      };
    }
    return {
      validationTypes,
      config,
    };
  };

  const handleChangeData = (target: { name?: string; value: string | number | Date | null }) => {
    const { value, name } = target;
    const { validationTypes, config } = getValidationTypes(name as DataNames);

    if (name && initialError[name as string]) {
      let newErrors = {
        ...error,
        [name as string]: validate(value as FieldValue, validationTypes, name as string, config),
      };
      setError(newErrors);
    }

    setData({ ...data, [name as string]: value });
  };

  const handleEditPatient = () => {
    const newData = {
      ...data,
      [DataNames.BIRTHDATE]: data[DataNames.BIRTHDATE] ? (data[DataNames.BIRTHDATE] as Date)?.toISOString() || '' : '',
    };

    if (isValid(error)) {
      if (rowData.id) {
        editPatient({
          ...newData,
          profilePicture: encodedImg || rowData.profilePicture,
          isEditMode: false,
          id: rowData.id,
        });
      } else {
        editPatient({
          ...newData,
          profilePicture: encodedImg || rowData.profilePicture,
          isEditMode: false,
          patientId: rowData.patientId,
        });
      }
      toggleEditPatientDialog(false);
    }
  };

  const deletePatient = () => {
    handleDelete(rowData);
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.files?.[0];
    setUploadedImage(value);
    let encoded;
    getBase64Image(value).then((result: string) => {
      encoded = result;
      setEncodedImg(encoded);
    });
  };

  return (
    <Dialog onClose={() => toggleEditPatientDialog(false)} classes={['crc-patient-dialog']}>
      <DialogTitle title="Edit patient" />
      <DialogContent>
        <div className="uploader-container">
          <div className="image-wrapper">
            <input type="file" onChange={handleUpload} id="patientImageUploader" />
            <IconButton>
              <label className="profile-image-upload-label" htmlFor="patientImageUploader">
                <img
                  className="profile-picture"
                  src={
                    uploadedImage
                      ? URL.createObjectURL(uploadedImage)
                      : isBase64String(rowData?.profilePicture as string)
                      ? (rowData?.profilePicture as string)
                      : `${AppConfig.httpAPIGatewayURL}/${
                          rowData.profilePicture || rowData.defaultPicture || defaultPicture
                        }`
                  }
                  alt="Preview"
                />
              </label>
            </IconButton>
          </div>
        </div>
        <Input
          id="firstName-input"
          required={true}
          variant="standard"
          name={DataNames.FIRSTNAME}
          label="First name"
          value={data[DataNames.FIRSTNAME]}
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
          errorInfo={error[DataNames.FIRSTNAME]}
        />
        <Input
          id="lastName-input"
          required={true}
          variant="standard"
          name={DataNames.LASTNAME}
          label="Last name"
          value={data[DataNames.LASTNAME]}
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
          errorInfo={error[DataNames.LASTNAME]}
        />
        <DatePicker
          id="birthdate-date-picker"
          inputVariant="standard"
          label="Birth date"
          format={dateFormat}
          value={data[DataNames.BIRTHDATE] || null}
          inputProps={{
            disabled: true,
          }}
          handleChange={(date: Date) => handleChangeData({ name: DataNames.BIRTHDATE, value: date })}
          maxDate={getMaxDate()}
        />
        <FormControl>
          <InputLabel id="gender-input-label">Gender</InputLabel>
          <Select
            labelId="gender-select-label"
            id="gender-select"
            name={DataNames.GENDER}
            value={data[DataNames.GENDER]}
            onChange={(e) => handleChangeData(e.target as any)}
          >
            {genderData.map((el: string, index: number) => (
              <MenuItem value={el} key={index}>
                {capitalizeFirstLetter(el)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Input
          id="email-input"
          variant="standard"
          name={DataNames.EMAIL}
          label="Email"
          value={data[DataNames.EMAIL]}
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
          autoComplete="off"
        />
        <Input
          id="phone-input"
          variant="standard"
          name={DataNames.PHONE}
          label="Phone"
          value={data[DataNames.PHONE]}
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData(e.target)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleEditPatient}>
          Save
        </Button>
        <Button variant="contained" color="primary" onClick={() => toggleEditPatientDialog(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={deletePatient}>
          Delete
        </Button>
        <DeleteButton />
      </DialogActions>
    </Dialog>
  );
}
