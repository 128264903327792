import { BaseAPIService } from './BaseAPIService';
export class PatientProfilePictureAPIService extends BaseAPIService {
  constructor() {
    super('');
  }

  public async getList({}: any): Promise<any> {
    return this.get(``);
  }

  public async getOne(id: number): Promise<any> {
    return this.get(``);
  }

  public async create(data: any): Promise<any> {
    return this.post(``, data);
  }

  public async update(data: any, id: number): Promise<any> {
    return this.put(`admin/patient/${id}/profile-picture`, data);
  }

  public async remove(id: number): Promise<any> {
    return this.delete(``);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(``, data);
  }
}
