import React, { ReactElement } from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { useState } from 'react';

interface IButtonWithConfirmProps {
  title: string;
  onConfirm: () => void;
}

const ButtonWithConfirm: React.FC<IButtonWithConfirmProps> = ({ title, onConfirm, children }) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  return (
    <>
      {React.cloneElement(children as ReactElement, { onClick: () => setShowConfirmDialog(true) })}
      {showConfirmDialog && (
        <Dialog open onClose={() => setShowConfirmDialog(false)}>
          <DialogTitle>{title}</DialogTitle>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => setShowConfirmDialog(false)}>
              Close
            </Button>
            <Button variant="contained" color="primary" onClick={onConfirm}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ButtonWithConfirm;
