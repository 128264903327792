import { BaseAPIService } from './BaseAPIService';
import { IProfile } from '../../types';
export class ProfileAPIService extends BaseAPIService {
  constructor() {
    super('admin/user');
  }

  public async getList({}: any): Promise<any> {
    return this.get(``);
  }

  public async getOne(id: number): Promise<any> {
    return this.get(`/${id}`);
  }

  public async create(data: IProfile): Promise<any> {
    return this.post(``, data);
  }

  public async update(data: IProfile, id: number): Promise<any> {
    return this.put(`/${id}`, data);
  }

  public async remove(id: number): Promise<any> {
    return this.delete(`/${id}`);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(``, data);
  }
}
