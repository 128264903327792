import { IAppDispatch, ICreditCard, NotificationType } from '../types';
import { isSuccessfulResponse } from '../utils';
import { getNotificationService, getUsersAPIService, getCreditCardsAPIService } from '../selectors';

export const RESTORE_REQUEST = 'user/RESTORE_REQUEST';

export interface IData {
  ids: Array<number>;
}
export const restoreUser = (data: IData) => async (dispatch: IAppDispatch) => {
  dispatch({
    type: RESTORE_REQUEST,
  });
  try {
    const request = await getUsersAPIService().restore(data);
    const message = request.data.message;
    if (!isSuccessfulResponse(request.status)) {
      return dispatch(restoreUserFailure(message));
    }

    dispatch(restoreUserSuccess(message));
  } catch (e) {
    dispatch(restoreUserFailure(e.response?.data?.message));
  }
};

export const RESTORE_SUCCESS = 'user/RESTORE_SUCCESS';

const restoreUserSuccess = (message: string = '') => (dispatch: IAppDispatch) => {
  getNotificationService().show({
    message,
    type: NotificationType.SUCCESS,
  });
  dispatch({
    type: RESTORE_SUCCESS,
  });
};

export const RESTORE_FAILURE = 'user/RESTORE_FAILURE';

const restoreUserFailure = (message: string = '') => (dispatch: IAppDispatch) => {
  getNotificationService().show({
    message,
    type: NotificationType.ERROR,
  });
  dispatch({
    type: RESTORE_FAILURE,
  });
};

export const GET_USERS_REQUEST = 'Order/GET_USERS_REQUEST';

export const getUsers = (roleId: number) => (dispatch: IAppDispatch) => async () => {
  dispatch({
    type: GET_USERS_REQUEST,
  });
  try {
    const request = await getUsersAPIService().getUsers(roleId);
    const data = request.data.result;

    if (!isSuccessfulResponse(request.status)) {
      return dispatch(getUsersFailure());
    }
    dispatch(getUsersSuccess(data));
  } catch (e: any) {
    dispatch(getUsersFailure());
  }
};

export const GET_USERS_SUCCESS = 'Orders/GET_USERS_SUCCESS';
export type GET_USERS_SUCCESS = any;

const getUsersSuccess = (users: GET_USERS_SUCCESS) => (dispatch: IAppDispatch) => {
  dispatch({
    type: GET_USERS_SUCCESS,
    payload: users,
  });
};

export const GET_USERS_FAILURE = 'Orders/GET_USERS_FAILURE';

const getUsersFailure = () => (dispatch: IAppDispatch) => {
  dispatch({
    type: GET_USERS_FAILURE,
  });
};

export const GET_USER_CARDS_REQUEST = 'Order/GET_USER_CARDS_REQUEST';

// export const getUserCards = () => (dispatch: IAppDispatch) => async () => {
//   dispatch({
//     type: GET_USER_CARDS_REQUEST,
//   });
//   try {
//     const request = await getCreditCardsAPIService().getCards();
//     const data = request.data.result;
//
//     if (!isSuccessfulResponse(request.status)) {
//       return dispatch(getUserCardsFailure());
//     }
//     dispatch(getUserCardsSuccess(data));
//   } catch (e: any) {
//     dispatch(getUserCardsFailure());
//   }
// };

export const GET_USER_CARD_SUCCESS = 'Orders/GET_USER_CARD_SUCCESS';
export type GET_USER_CARD_SUCCESS = ICreditCard;

// const getUserCardsSuccess = (users: GET_USER_CARD_SUCCESS) => (dispatch: IAppDispatch) => {
//   dispatch({
//     type: GET_USER_CARD_SUCCESS,
//     payload: users,
//   });
// };

export const GET_USER_CARD_FAILURE = 'Orders/GET_USER_CARD_FAILURE';

// const getUserCardsFailure = () => (dispatch: IAppDispatch) => {
//   dispatch({
//     type: GET_USER_CARD_FAILURE,
//   });
// };
