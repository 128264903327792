export interface IService {
  name: string;
  description: string;
  price: number;
}

export enum ServiceColumn {
  ID = 'id',
  NAME = 'name',
  DESCRIPTION = 'description',
  PRICE = 'price',
  PRICE_CLINIC = 'priceClinic',
  CREATED_BY = 'createdBy',
  IS_PUBLIC = 'isPublic',
}

export enum ServiceField {
  NAME = 'name',
  DESCRIPTION = 'description',
  PRICE = 'price',
  PRICE_CLINIC = 'priceClinic',
  CREATED_BY = 'createdBy',
  IS_PUBLIC = 'isPublic',
  ICON = 'icon',
}
