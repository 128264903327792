import { NotificationService } from '../../services';

class NotificationServiceSelector {
  /**
   * Singleton instance of the NotificationService.
   */
  private static instance: NotificationService;

  /**
   * Singleton getter method for the NotificationService.
   * Creates a new instance if it does not already exists,
   * Returns the existing one in the opposite case.
   */
  public static getInstance(): NotificationService {
    if (!this.instance) {
      this.instance = new NotificationService();
    }
    return this.instance;
  }
}

export function getNotificationService(): NotificationService {
  return NotificationServiceSelector.getInstance();
}
