import { BaseAPIService } from './BaseAPIService';
import { IUserData, UserStatus } from '../../types';

export class QuickSearchAPIService extends BaseAPIService {
  constructor() {
    super('admin/user');
  }

  public async getList({ query, filter }: any): Promise<any> {
    let q = '';
    if (filter?.q) {
      q = `criteria=${filter?.q}`;
    }

    const newQuery = `?${q}&${query}`;

    return this.get(newQuery)?.then((res) => {
      res.data.result = res.data.result.map((i: any) => ({ ...i, id: i.id + '_' + i.type }));
      if (!filter?.q) {
        res.data.result = [];
        res.data.count = 0;
      }

      return res;
    });
  }

  public async getOne(id: { id: number; withDeleted: boolean }): Promise<any> {
    return this.get(
      `/${id.id}?prefix=user&projection=role,specialist,patient${id.withDeleted ? '&withDeleted=true' : ''}`
    );
  }

  public async create(data: IUserData): Promise<any> {
    return this.post(``, data);
  }

  public async update(data: IUserData, id: number): Promise<any> {
    return this.put(`/${id}`, data);
  }

  public async remove(id: number): Promise<any> {
    return this.delete(`/${id}`);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(``, data);
  }

  public async restore(data: any): Promise<any> {
    return this.post(`/restore`, data);
  }

  public async getUsers(role: number): Promise<any> {
    return this.get(
      `?projection=role,specialist,patient&search=role^id,=,${role}&search=blockedAt,is,null&prefix=user`
    );
  }

  public async getCards(): Promise<any> {
    return this.get(`/admin/card`);
  }

  public async verify(id: number): Promise<any> {
    return this.post(`/verify`, { id });
  }
}
