export interface INotificationTemplate {
  action: string;
  type: string;
  from: string;
  subject: string;
  content: string | null;
}

export interface IScheduleOptions {
  year?: number;
  month?: number;
  date?: number;
  dayOfWeek?: number | number[];
  hour?: number;
  minute?: number;
  second?: number;
}

export enum NotificationTemplateField {
  ACTION = 'action',
  TYPE = 'type',
  FROM = 'from',
  SUBJECT = 'subject',
  CONTENT = 'content',
}

export enum ScheduleOptions {
  YEAR = 'year',
  MONTH = 'month',
  DATE = 'date',
  DAYOFWEEK = 'dayOfWeek',
  HOUR = 'hour',
  MINUTE = 'minute',
  SECOND = 'second',
}

export enum Periodicity {
  YEAR = 'year',
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
  HOUR = 'hour',
  MINUTE = 'minute',
  SECOND = 'second',
}

export enum PeriodicityLavel {
  YEAR = 5,
  MONTH = 4,
  WEEK = 3,
  DAY = 3,
  HOUR = 2,
  MINUTE = 1,
  SECOND = 0,
}

export const PeriodicityMapper = {
  [Periodicity.YEAR]: PeriodicityLavel.YEAR,
  [Periodicity.MONTH]: PeriodicityLavel.MONTH,
  [Periodicity.WEEK]: PeriodicityLavel.WEEK,
  [Periodicity.DAY]: PeriodicityLavel.DAY,
  [Periodicity.HOUR]: PeriodicityLavel.HOUR,
  [Periodicity.MINUTE]: PeriodicityLavel.MINUTE,
  [Periodicity.SECOND]: PeriodicityLavel.SECOND,
};

export const comparePeriodicityLavel = (periodicity: Periodicity) => {
  const arrayOfPeriodicityLavels = [];
  for (let key in PeriodicityMapper) {
    if (PeriodicityMapper[key as Periodicity] <= PeriodicityMapper[periodicity as Periodicity]) {
      arrayOfPeriodicityLavels.push(key);
    }
  }
  return arrayOfPeriodicityLavels;
};
export const NOTIFICATION_TEMPLATE_TYPES = ['email', 'sms', 'push'];
export const NOTIFICATION_TEMPLATE_PERIODICITY = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];
export const NOTIFICATION_TEMPLATE_WEEKDAY = [
  {
    name: 'Sunday',
    value: 0,
  },
  {
    name: 'Monday',
    value: 1,
  },
  {
    name: 'Tuesday',
    value: 2,
  },
  {
    name: 'Wednesday',
    value: 3,
  },
  {
    name: 'Thursday',
    value: 4,
  },
  {
    name: 'Friday',
    value: 5,
  },
  {
    name: 'Saturday',
    value: 6,
  },
];

export interface IfilterObject {
  [key: string]: any;
}

export enum AgeRange {
  FIRSTRANGE = 'firstRange',
  SECONDRANGE = 'secondRange',
  THIRDRANGE = 'thirdRange',
  FOURTHRANGE = 'fourthRange',
}

export const ageRangeData: IAgeRangeData = {
  firstRange: {
    checked: false,
    value: '30-18',
    name: AgeRange.FIRSTRANGE,
    label: '18-30 years',
  },
  secondRange: {
    checked: false,
    value: '45-31',
    name: AgeRange.SECONDRANGE,
    label: '31-45 years',
  },
  thirdRange: {
    checked: false,
    value: '65-46',
    name: AgeRange.THIRDRANGE,
    label: '46-65 years',
  },
  fourthRange: {
    checked: false,
    value: '66',
    name: AgeRange.FOURTHRANGE,
    label: '66 years and above',
  },
};

export interface IAgeRangeData {
  [key: string]: {
    checked: boolean;
    value: string;
    name: string;
    label: string;
  };
}

export const genderSelectData = ['male', 'female'];
