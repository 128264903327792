import React, { useEffect, useState } from 'react';
import { Loading, useDataProvider, useRedirect } from 'react-admin';
import { GetListParams } from 'ra-core';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardActions,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import Input from '../core/input/Input';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../core/dialog/Dialog';
import {
  DEFAULT_VALIDATIONS_INFO,
  formatCurrency,
  getBase64Image,
  hasPermission,
  isValid,
  removeSymbolAndCapitalize,
  validate,
} from '../../utils';
import {
  BundleField,
  ColumnType,
  IErrors,
  IInputField,
  IPermission,
  IRow,
  PermissionLevel,
  RequestResource,
  ServiceColumn,
  ValidationType,
} from '../../types';
import Table from '../core/table/Table';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';

export interface ICreateBundleProps {
  permissions: IPermission;
}

interface IAttachedServicesProps {
  handleDelete: (id: number) => void;
  rows: IRow[];
}

interface IServicesProps {
  handleAdd: (row: IRow) => void;
  rows: IRow[];
}

const COLUMNS = [
  {
    field: ServiceColumn.ID,
    headerName: removeSymbolAndCapitalize(ServiceColumn.ID),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: ServiceColumn.NAME,
    headerName: removeSymbolAndCapitalize(ServiceColumn.NAME),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: ServiceColumn.DESCRIPTION,
    headerName: removeSymbolAndCapitalize(ServiceColumn.DESCRIPTION),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: ServiceColumn.PRICE,
    headerName: removeSymbolAndCapitalize(ServiceColumn.PRICE),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: IRow) => formatCurrency(row.price as number),
  },
  {
    field: ServiceColumn.PRICE_CLINIC,
    headerName: removeSymbolAndCapitalize(ServiceColumn.PRICE_CLINIC),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: IRow) => formatCurrency(row.priceClinic as number),
  },
  {
    field: ServiceColumn.CREATED_BY,
    headerName: removeSymbolAndCapitalize(ServiceColumn.CREATED_BY),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: IRow) =>
      (row?.createdBy as any)?.id ? (
        <Link to={`/${RequestResource.USER}/${(row?.createdBy as any)?.id}`}>
          {(row?.createdBy as any)?.name || ''}
        </Link>
      ) : (
        (row?.createdBy as any)?.name || ''
      ),
  },
  {
    field: '',
    headerName: 'Actions',
    editable: false,
    type: ColumnType.COMPONENT,
  },
];

function AttachedServices(props: IAttachedServicesProps) {
  const { handleDelete, rows } = props;

  return (
    <Table rows={rows} columns={COLUMNS} canDelete={true} handleDelete={handleDelete} noDataMessage="No services" />
  );
}

const Services = (props: IServicesProps) => {
  const { handleAdd, rows } = props;
  return <Table rows={rows} columns={COLUMNS} canAdd={true} handleAdd={handleAdd} />;
};

export default function CreateBundle(props: ICreateBundleProps) {
  const { permissions } = props;
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [showAddServiceDialog, setShowAddServiceDialog] = useState<boolean>(false);
  const [rows, setRows] = useState<IRow[]>([]);
  const [services, setServices] = useState<IRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [attachedServiceIds, setAttachedServiceIds] = useState<number[]>([]);
  const [filter, setFilter] = useState<string>('');

  const [errors, setErrors] = useState<IErrors>({
    [BundleField.NAME]: { ...DEFAULT_VALIDATIONS_INFO },
    [BundleField.DESCRIPTION]: { ...DEFAULT_VALIDATIONS_INFO },
    [BundleField.PRICE]: { ...DEFAULT_VALIDATIONS_INFO },
    [BundleField.BUNDLE_FOR]: { ...DEFAULT_VALIDATIONS_INFO },
  });
  const [values, setValues] = useState<IInputField>({
    [BundleField.NAME]: '',
    [BundleField.DESCRIPTION]: '',
    [BundleField.PRICE]: '',
    [BundleField.BUNDLE_FOR]: '1',
    [BundleField.ICON]: '',
    [BundleField.IS_PUBLIC]: false,
  });

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList(RequestResource.SERVICE, {} as GetListParams)
      .then(({ data }) => {
        setLoading(false);
        const servicesData = data as any;
        setServices(servicesData);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const getAttachedServiceIds = (services: IRow[]): number[] => {
    return services.length ? services.map((service: IRow) => service.id as number) : [];
  };

  const toggleAddServiceDialog = (toggle: boolean) => setShowAddServiceDialog(toggle);
  const handleAdd = (row: IRow) => {
    const newRows = [...rows, row];
    setRows(newRows);
    setAttachedServiceIds(getAttachedServiceIds(newRows));
  };
  const handleDelete = (id: number) => {
    const newRows = [...rows];
    const index = rows.findIndex((row: IRow) => row.id === id);
    newRows.splice(index, 1);
    setAttachedServiceIds(getAttachedServiceIds(newRows));
    setRows(newRows);
  };

  if (loading) return <Loading />;

  const { name, description, price, bundleFor, isPublic } = values;

  const handleChange = (value: string | number | boolean, name: BundleField) => {
    let newErrors = {
      ...errors,
    };
    if (name !== BundleField.IS_PUBLIC) {
      const { validationTypes, config } = getValidationTypes(name);
      newErrors[name] = validate('' + value, validationTypes, name, config);
    }

    setValues({ ...values, [name]: value });
    setErrors(newErrors);
  };

  const getUniqueServices = () =>
    services
      .filter((service: IRow) => !attachedServiceIds.includes(service.id as number))
      ?.filter(
        ({ id, name, description }: IRow) =>
          (name as string).toLowerCase().includes(filter) ||
          (id as number).toString() === filter ||
          (description as string).toLowerCase().includes(filter)
      ) || [];

  const checkValidationAndSave = () => {
    const keys = Object.keys(errors);
    let newErrors = { ...errors };
    for (const key of keys) {
      const value = values[key];
      const { validationTypes, config } = getValidationTypes(key as BundleField);
      newErrors = {
        ...newErrors,
        [key]: validate(value, validationTypes, key, config),
      };
    }

    if (isValid(newErrors)) {
      handleSave();
    } else {
    }
    setErrors(newErrors);
  };

  const getValidationTypes = (name: BundleField) => {
    const validationTypes = [ValidationType.REQUIRED];
    let config = {};

    if (name === BundleField.PRICE || name === BundleField.BUNDLE_FOR) {
      validationTypes.push(ValidationType.MIN_VALUE);
      config = {
        minValue: 1,
      };
    } else {
      validationTypes.push(ValidationType.MIN_LENGTH);
      config = {
        minLength: 3,
      };
    }
    return {
      validationTypes,
      config,
    };
  };

  const handleSave = () => {
    const { icon, ...rest } = values;
    const newValues = values[BundleField.ICON] ? { ...values } : rest;

    setLoading(true);
    dataProvider
      .create(RequestResource.BUNDLE, {
        data: {
          ...newValues,
          services: rows.map((r) => ({
            id: r.id,
          })),
        },
      } as any)
      .then(() => {
        setLoading(false);
        redirect(`/${RequestResource.BUNDLE}`);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleCancelClick = () => {
    redirect(`/${RequestResource.BUNDLE}`);
  };

  const handleUploadIcon = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.files?.[0];
    let encoded;
    getBase64Image(value).then((result: string) => {
      encoded = result;
      setValues({ ...values, [BundleField.ICON]: encoded });
    });
  };

  const handleFilter = _.debounce((term: string) => {
    setFilter(term);
  }, 300);

  return (
    <div className="create-bundle">
      <FormControl className="create-bundle-form">
        <div className="bundle-header">
          <Typography variant="h5">Bundle create</Typography>
          <div className="icon-wrapper">
            <input type="file" onChange={handleUploadIcon} id="iconUploader" />
            <IconButton>
              <label className="icon-upload-label" htmlFor="iconUploader">
                {values[BundleField.ICON] ? (
                  <img className="bundle-icon" src={values[BundleField.ICON]} alt="Preview" />
                ) : (
                  <AddPhotoAlternateOutlinedIcon className="default-icon" />
                )}
              </label>
            </IconButton>
          </div>
        </div>
        <Divider className="divider" />
        <div className="body">
          <Input
            required={true}
            label="Name"
            value={name}
            errorInfo={errors[BundleField.NAME]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, BundleField.NAME)}
          />
          <Input
            required={true}
            label="Description"
            value={description}
            errorInfo={errors[BundleField.DESCRIPTION]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.value, BundleField.DESCRIPTION)
            }
          />
          <Input
            required={true}
            label="Price"
            value={price}
            type="number"
            errorInfo={errors[BundleField.PRICE]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, BundleField.PRICE)}
          />

          <Divider className="divider" />
          <FormControl>
            <FormLabel id="bundle-for" className="">
              Bundle for
            </FormLabel>
            <RadioGroup
              onChange={(e, val) => handleChange(val, BundleField.BUNDLE_FOR)}
              aria-labelledby="bundle-for"
              defaultValue="1"
              name="bundle_for"
              row
            >
              <FormControlLabel value="1" control={<Radio />} label="App" />
              <FormControlLabel value="2" control={<Radio />} label="Clinic" />
            </RadioGroup>
          </FormControl>

          <Divider className="divider" />
          <FormControlLabel
            className="is-public-switcher"
            control={
              <Switch
                checked={!!isPublic}
                onChange={(e) => handleChange(e.target.checked, BundleField.IS_PUBLIC)}
                name="Is Public"
                color="primary"
              />
            }
            label="Is Public"
          />
          <Divider className="divider secondary" />
          <div className="bundle-services">
            <Typography variant="h6">Services</Typography>
            <IconButton
              aria-label="add"
              className="add-service-button"
              color="primary"
              onClick={() => toggleAddServiceDialog(true)}
            >
              <AddIcon fontSize="large" />
            </IconButton>
          </div>
          <Divider className="divider secondary" />
          <AttachedServices rows={rows} handleDelete={handleDelete} />
        </div>
        <Card className="card">
          <CardActions className="card-actions">
            <div>
              {hasPermission(permissions, RequestResource.BUNDLE, PermissionLevel.CREATE) ? (
                <Button variant="contained" color="primary" onClick={checkValidationAndSave} startIcon={<SaveIcon />}>
                  Save
                </Button>
              ) : null}
              <Button variant="outlined" color="primary" onClick={handleCancelClick} className="cancel-button">
                Cancel
              </Button>
            </div>
          </CardActions>
        </Card>
      </FormControl>
      {showAddServiceDialog && (
        <Dialog onClose={() => toggleAddServiceDialog(false)}>
          <DialogTitle title="Add Service" onClose={() => toggleAddServiceDialog(false)} />
          <DialogContent>
            <TextField
              variant="filled"
              label="Search"
              className="filter"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilter(e.target.value)}
            />
            <Services rows={getUniqueServices()} handleAdd={handleAdd} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => toggleAddServiceDialog(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
