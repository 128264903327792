import { connect } from 'react-redux';
import ViewOrder from '../../components/order/ViewOrder';
import { IAppState, IAppDispatch } from '../../types';

const mapStateToProps = (state: IAppState) => ({
  orderLoading: state.order.orderLoading,
  role: state.auth.role,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrder);
