export function queryBuilder(params: any, resource: string) {
  const { filter, pagination, sort, customQuery } = params;

  if (customQuery) {
    return {
      filter,
      query: filter?.query,
    };
  }
  const { page, perPage } = pagination || {};
  const { field, order } = sort || {};
  const filterKeys: any = filter ? Object.keys(filter) : [];
  let query = `prefix=${resource}`;

  if (field && order) {
    query += `&sort=${field},${order}`;
  }
  if (page && perPage) {
    query += `&offset=${perPage * (page - 1)}&limit=${perPage}`;
  }
  const searchArr: string[] = [];

  for (const key of filterKeys) {
    const value = filter[key];
    const splittedSearch = key.split('|');
    const arr: string[] = [];

    splittedSearch.map((currentSearch: string) => {
      const [field, searchType] = currentSearch.split('~');
      if (field && searchType) {
        if (typeof value === 'object') {
          for (const key of value) {
            arr.push(`${field.replace('>', '^')},${searchType},${encodeURIComponent(key)}`);
          }
        } else {
          arr.push(`${field.replace('>', '^')},${searchType},${encodeURIComponent(value)}`);
        }
      }
    });
    if (arr?.length) {
      searchArr.push(`search=${arr.join('|')}`);
    }
  }
  if (searchArr.length) {
    query += `&${searchArr.join('&')}`;
  }

  return { query, filter };
}
