import { IAppDispatch, ITranslations, T } from '../types';
import { isSuccessfulResponse } from '../utils';
import { getTranslationsAPIService } from '../selectors';
import { AppConfig } from '../config';

export const LOAD_TRANSLATIONS = 'Localization/LOAD_TRANSLATIONS';

export const loadTranslations = () => async (dispatch: IAppDispatch) => {
  dispatch({
    type: LOAD_TRANSLATIONS,
  });
  try {
    const request = await getTranslationsAPIService().getList({ query: `language_code=${AppConfig.defaultLanguage}` });

    if (!isSuccessfulResponse(request.status)) {
      return dispatch(loadTranslationsFailure());
    }
    const translations: ITranslations = {};
    request.data.result?.forEach((el: { key: string; value: string }) => {
      translations[el.key?.toLowerCase() as T] = el.value;
    });
    dispatch(loadTranslationsSuccess(translations));
  } catch (e) {
    dispatch(loadTranslationsFailure());
  }
};

export const LOAD_TRANSLATIONS_SUCCESS = 'Localizations/LOAD_TRANSLATIONS_SUCCESS';
export type LOAD_TRANSLATIONS_SUCCESS = ITranslations;

const loadTranslationsSuccess = (transitions: LOAD_TRANSLATIONS_SUCCESS) => (dispatch: IAppDispatch) => {
  dispatch({
    type: LOAD_TRANSLATIONS_SUCCESS,
    payload: transitions,
  });
};

export const LOAD_TRANSLATIONS_FAILURE = 'Localizations/LOAD_TRANSLATIONS_FAILURE';

const loadTranslationsFailure = () => (dispatch: IAppDispatch) => {
  dispatch({
    type: LOAD_TRANSLATIONS_FAILURE,
  });
};
