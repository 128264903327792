import React from 'react';
import { BulkDeleteButton, Datagrid, EditButton, Filter, List, TextField, TextInput } from 'react-admin';
import { IPermission, PermissionLevel, RequestResource } from '../../types';
import { hasPermission } from '../../utils';

interface IListSettingsProps {
  permissions: IPermission;
}

const FilterBar = (props: any) => (
  <Filter {...props}>
    <TextInput className={'default_search_input'} label="Search" source="id~=|name~like" alwaysOn />
  </Filter>
);

export default function ListSettings(props: IListSettingsProps) {
  const { permissions } = props;
  const canDelete = hasPermission(permissions, RequestResource.BRANCH, PermissionLevel.DELETE);
  const BulkActionButtons = (props: any) => {
    return <React.Fragment>{canDelete && <BulkDeleteButton {...props} />}</React.Fragment>;
  };

  return (
    <React.Fragment>
      <List
        className="list-branch"
        exporter={false}
        filters={<FilterBar />}
        bulkActionButtons={<BulkActionButtons />}
        {...props}
      >
        <Datagrid isRowSelectable={() => canDelete}>
          <TextField source="id" />
          <TextField source="latitude" />
          <TextField source="longitude" />
          <TextField source="name" />
          {hasPermission(permissions, RequestResource.BRANCH, PermissionLevel.UPDATE) ? <EditButton /> : null}
        </Datagrid>
      </List>
    </React.Fragment>
  );
}
