import React, { useState } from 'react';
import { Loading, useDataProvider, useRedirect } from 'react-admin';
import { Button, Card, CardActions, Divider, FormControl, Typography } from '@material-ui/core';
import Input from '../core/input/Input';
import {
  BranchField,
  IErrors,
  IInputField,
  IPermission,
  PermissionLevel,
  RequestResource,
  ValidationType,
} from '../../types';
import { DEFAULT_VALIDATIONS_INFO, hasPermission, isValid, validate } from '../../utils';
import SaveIcon from '@material-ui/icons/Save';

interface ICreateBranchProps {
  permissions: IPermission;
}

const DEFAULT_ERRORS = {
  [BranchField.LATITUDE]: { ...DEFAULT_VALIDATIONS_INFO },
  [BranchField.LONGITUDE]: { ...DEFAULT_VALIDATIONS_INFO },
  [BranchField.NAME]: { ...DEFAULT_VALIDATIONS_INFO },
};

const DEFAULT_VALUES = {
  [BranchField.LATITUDE]: '',
  [BranchField.LONGITUDE]: '',
  [BranchField.NAME]: '',
};

export default function CreateBranch(props: ICreateBranchProps) {
  const { permissions } = props;
  const [values, setValues] = useState<IInputField>({ ...DEFAULT_VALUES });
  const [errors, setErrors] = useState<IErrors>({ ...DEFAULT_ERRORS });
  const [loading, setLoading] = useState<boolean>(false);
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const handleCancel = () => {
    redirect(`/${RequestResource.BRANCH}`);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, name: BranchField) => {
    const value = e.target.value;
    const newErrors = {
      ...errors,
      [name]: validate(value, [ValidationType.REQUIRED, ValidationType.MIN_LENGTH], name, { minLength: 3 }),
    };
    setValues({ ...values, [name]: value });
    setErrors(newErrors);
  };

  const checkValidationAndSave = () => {
    const keys = Object.keys(errors);
    let newErrors = { ...errors };

    for (const key of keys) {
      const value = values[key];
      newErrors = {
        ...newErrors,
        [key]: validate(value, [ValidationType.REQUIRED, ValidationType.MIN_LENGTH], key, { minLength: 3 }),
      };
    }
    if (isValid(newErrors)) {
      handleSave();
    }
    setErrors(newErrors);
  };

  const handleSave = () => {
    dataProvider
      .create(RequestResource.BRANCH, {
        data: {
          ...values,
        },
      } as any)
      .then(() => {
        handleCancel();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (loading) return <Loading />;

  return (
    <div className="create-branch">
      <FormControl className="create-branch-form">
        <div className="branch-header">
          <Typography variant="h5">Branch create</Typography>
        </div>
        <Divider className="divider" />
        <div className="body">
          <Input
            required={true}
            label="Latitude"
            type="number"
            value={values[BranchField.LATITUDE]}
            errorInfo={errors[BranchField.LATITUDE]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, BranchField.LATITUDE)}
          />
          <Input
            required={true}
            label="Longitude"
            type="number"
            value={values[BranchField.LONGITUDE]}
            errorInfo={errors[BranchField.LONGITUDE]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, BranchField.LONGITUDE)}
          />
          <Input
            required={true}
            label="Name"
            value={values[BranchField.NAME]}
            errorInfo={errors[BranchField.NAME]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, BranchField.NAME)}
          />
        </div>
        <Card className="card">
          <CardActions className="card-actions">
            <div>
              {hasPermission(permissions, RequestResource.BRANCH, PermissionLevel.CREATE) ? (
                <Button variant="contained" color="primary" onClick={checkValidationAndSave} startIcon={<SaveIcon />}>
                  Save
                </Button>
              ) : null}
              <Button variant="outlined" color="primary" onClick={handleCancel} className="cancel-button">
                Cancel
              </Button>
            </div>
          </CardActions>
        </Card>
      </FormControl>
    </div>
  );
}
