import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider, Picker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { IError } from '../../../../types';
import { dateFormat } from '../../../../constants';

export type PickerView = ('year' | 'date' | 'month' | 'hours' | 'minutes')[];
type Variant = 'dialog' | 'inline' | 'static';
type InputVariant = 'standard' | 'outlined' | 'filled';

interface IDatePickerProps {
  id?: string;
  variant?: Variant;
  views?: PickerView;
  inputVariant?: InputVariant;
  classes?: string[];
  errorInfo?: IError;
  label: string;
  value: string | null | Date;
  required?: boolean;
  disabled?: boolean;
  format?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  inputProps?: object;
  handleChange?: any;
  disableToolbar?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const DEFAULT_VARIANT = 'dialog';
const DEFAULT_INPUT_VARIANT = 'filled';
const DEFAULT_VIEWS: PickerView = ['year', 'date', 'month', 'hours', 'minutes'];

export default function DatePicker(props: IDatePickerProps) {
  const {
    id,
    variant,
    inputVariant,
    label,
    value,
    handleChange,
    required,
    disabled,
    classes,
    format,
    inputProps,
    disableFuture,
    disablePast,
    errorInfo,
    disableToolbar,
    views,
    minDate,
    maxDate,
  } = props;
  const className = classes ? ` ${classes.join(' ')}` : '';
  const { error, message } = errorInfo || {};

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar={!!disableToolbar}
        id={id}
        variant={variant || DEFAULT_VARIANT}
        inputVariant={inputVariant || DEFAULT_INPUT_VARIANT}
        disabled={disabled}
        required={required}
        error={error || undefined}
        helperText={message || ''}
        className={`crs-date-picker${className}`}
        format={format || dateFormat}
        label={label}
        value={value}
        onChange={handleChange}
        inputProps={inputProps || {}}
        disableFuture={Boolean(disableFuture)}
        disablePast={Boolean(disablePast)}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        minDate={minDate}
        maxDate={maxDate}
      />
    </MuiPickersUtilsProvider>
  );
}
