export enum RequestResource {
  BUNDLE = 'bundle',
  SERVICE = 'service',
  DASHBOARD = 'dashboard',
  ROLE = 'role',
  LANGUAGE = 'language',
  TRANSLATION = 'translation',
  PERMISSIONS = 'permissions',
  PROFILE = 'profile',
  USER = 'user',
  CATEGORY = 'category',
  USERPICTURE = 'user_picture',
  FAQ = 'faq',
  ORDER = 'order',
  LANGUAGE_FONT_FILE = 'language_font_file',
  USER_SPECIALIZATION_LIST = 'user-specialization-list',
  PATIENT = 'patient',
  PATIENTPICTURE = 'patient_picture',
  ORDER_ACTIONS = 'order_actions',
  SETTING = 'setting',
  NOTIFICATION_TEMPLATE = 'notification template',
  NOTIFICATION_TEMPLATE_PICTURE = 'notification template picture',
  NOTIFICATION_TEMPLATE_ACTIONS = 'notification template actions',
  PAYMENT = 'payment',
  BRANCH = 'branch',
  USER_NOTIFICATION = 'userNotification',
  QUICK_SEARCH = 'quick search',
}
