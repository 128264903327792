import { Box, Button } from '@material-ui/core';
import { Empty } from 'antd';
import { List, Datagrid, TextField, TextInput, useListContext, FunctionField } from 'react-admin';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';

const ListQuickSearch: React.FC = (props) => {
  const Filters = (props: any) => {
    const { setFilters, filterValues, total } = useListContext();

    return (
      <div style={{ flex: 1 }}>
        <Form
          initialValues={{ q: filterValues.q }}
          onSubmit={(values) => {
            setFilters(values, []);
          }}
          validate={(values) => {
            const errors: any = {};
            if (!values.q || values.q?.length < 3) {
              errors.q = 'Enter more than 2 characters';
            }
            return errors;
          }}
          render={({ handleSubmit }) => {
            return (
              <form
                onSubmit={handleSubmit}
                style={{
                  width: 'fit-content',
                  marginLeft: 'auto',
                }}
              >
                <Box>
                  <TextInput
                    style={{ margin: '0 20px' }}
                    className={'default_search_input'}
                    label="Search"
                    source="q"
                  />
                  <Button variant="contained" type="submit" style={{ height: '48px' }}>
                    Search
                  </Button>
                </Box>
              </form>
            );
          }}
        ></Form>
        {total === 0 && filterValues.q && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
            <Link to={{ pathname: '/user/create', state: { fromQuickSearch: true } }}>
              <Button variant="contained">Create new user</Button>
            </Link>
            <Empty description="No Results" />
          </div>
        )}
      </div>
    );
  };

  return (
    <List
      empty={<Filters />}
      exporter={false}
      actions={<Filters />}
      pagination={false}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid>
        <FunctionField label="Id" render={(record: any) => record.id.split('_')[0]} />
        <TextField source="firstName" sortable={false} />
        <TextField source="lastName" sortable={false} />
        <TextField source="phone" sortable={false} />
        <TextField source="type" sortable={false} />
        <FunctionField
          render={(record: any) => {
            let link = `/order/create?`;
            if (record.type === 'user') {
              link += `userId=${parseInt(record.id)}`;
            } else {
              link += `userId=${record.userId}&patientId=${parseInt(record.id)}`;
            }
            return (
              <Link to={link}>
                <Button variant="contained">Create Order</Button>
              </Link>
            );
          }}
        />
      </Datagrid>
    </List>
  );
};

export default ListQuickSearch;
