import { v4 as uuid } from 'uuid';

export function removeSymbolAndCapitalize(text: string = '', symbol: string = '_'): string {
  return text?.split(symbol).map((s: string) => capitalizeFirstLetter(s)).join(' ');
}

export function capitalizeFirstLetter(string: string = '', lowerTheRest: boolean = false) {
  if (!string) {
    return '';
  }
  const firstChar: string = string.charAt(0).toUpperCase();
  let theRest: string = string.slice(1);
  if (lowerTheRest) {
    theRest = theRest.toLowerCase();
  }
  return firstChar + theRest;
}

export function formatDate(dateStr: string) {
  return dateStr?.slice(0, 10);
}

export function convertCamelCaseToSnakeCase(text: string = ''): string {
  return text.replace(/\.?([A-Z])/g, (x: any, y: any) => '_' + y.toLowerCase()).replace(/^_/, '');
}

export function generateUUID(): string {
  return uuid();
}

export const booleanToYesOrNo = (bool: boolean) => bool ? 'Yes' : "No"
