import { BaseAPIService } from './BaseAPIService';
import { IFaq } from '../../types';

export class PaymentsAPIService extends BaseAPIService {
  constructor() {
    super('admin/payment');
  }

  public async getList({ query, filter }: any): Promise<any> {
    query = query?.length ? `&${query}` : '';
    const newQuery = `?projection=order,order.user,paymentStatusTransition${query}`;
    return this.get(`${newQuery}`);
  }

  public async getOne(id: number): Promise<any> {
    return this.get(`/${id}?prefix=payment&projection=order,order.user,paymentStatusTransition`);
  }

  public async create(data: IFaq): Promise<any> {
    return this.post(``, data);
  }

  public async update(data: IFaq, id: number): Promise<any> {
    return this.put(``, data);
  }

  public async remove(id: number): Promise<any> {
    return this.delete(``);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(``, data);
  }
}
