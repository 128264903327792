import { BaseAPIService } from './BaseAPIService';
export class UserSpecializationListAPIService extends BaseAPIService {
  constructor() {
    super('');
  }

  public async getList({}: any): Promise<any> {
    return this.get(`admin/specialist`);
  }

  public async getOne(id: number): Promise<any> {
    return this.get(`admin/specialist/${id}`);
  }

  public async create(data: any): Promise<any> {
    return this.post(`admin/specialist`, data);
  }

  public async update(data: any, id: number): Promise<any> {
    return this.put(`admin/specialist/${id}`, data);
  }

  public async remove(id: number): Promise<any> {
    return this.delete(`admin/specialist/${id}`);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(`admin/specialist`, data);
  }
}
