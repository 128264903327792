export interface ITranslation {
  languageId: number;
  key: string;
  value: string;
}

export enum Translation {
  TRANSLATION_ID = 'id',
  TRANSLATION_KEY = 'translation_key',
  TRANSLATION = 'translation',
}
