import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDataProvider, useRedirect, Loading } from 'react-admin';
import {
  Button,
  Card,
  CardActions,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from '@material-ui/core';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../core/dialog/Dialog';
import Input from '../core/input/Input';
import {
  DEFAULT_VALIDATIONS_INFO,
  getBase64Image,
  hasPermission,
  isValid,
  removeSymbolAndCapitalize,
  replaceInArray,
  validate,
} from '../../utils';
import {
  CategoryColumn,
  ColumnType,
  IErrors,
  IField,
  IInputField,
  IPermission,
  IRow,
  PermissionLevel,
  RequestResource,
  ValidationType,
  CategoryField,
  ServiceColumn,
  BundleField,
} from '../../types';
import SaveIcon from '@material-ui/icons/Save';
import Table from '../core/table/Table';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';

export interface ICreateCategoryProps {
  permissions: IPermission;
  id?: string;
}

interface ICategoriesProps {
  handleRowChange: (row: IRow, column: string, value: IField) => void;
  rows: IRow[];
}

const IS_OPEN: string = 'isOpen';
const RADIO_COLUMN: string = 'selected';
const IS_EDIT_MODE: string = 'isEditMode';
const COLLAPSE: string = 'collapse';

const COLUMNS = [
  {
    field: COLLAPSE,
    headerName: '',
    editable: false,
    type: ColumnType.COLLAPSE,
    width: '5%',
  },
  {
    field: CategoryColumn.ID,
    headerName: removeSymbolAndCapitalize(CategoryColumn.ID),
    editable: false,
    type: ColumnType.STRING,
    width: '5%',
  },
  {
    field: CategoryColumn.NAME,
    headerName: removeSymbolAndCapitalize(CategoryColumn.NAME),
    editable: false,
    type: ColumnType.STRING,
    width: '25%',
  },
  {
    field: CategoryColumn.DESCRIPTION,
    headerName: removeSymbolAndCapitalize(CategoryColumn.DESCRIPTION),
    editable: false,
    type: ColumnType.STRING,
    width: '60%',
  },
  {
    field: CategoryColumn.IS_PUBLIC,
    headerName: removeSymbolAndCapitalize(CategoryColumn.IS_PUBLIC),
    editable: false,
    type: ColumnType.STRING,
    width: '15%',
  },
  {
    field: CategoryColumn.CREATED_BY,
    headerName: removeSymbolAndCapitalize(CategoryColumn.CREATED_BY),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: IRow) =>
      (row?.createdBy as any)?.id ? (
        <Link to={`/${RequestResource.USER}/${(row?.createdBy as any)?.id}`}>
          {(row?.createdBy as any)?.name || ''}
        </Link>
      ) : (
        (row?.createdBy as any)?.name || ''
      ),
  },
  {
    field: RADIO_COLUMN,
    headerName: '',
    editable: true,
    type: ColumnType.CHECKBOX,
    width: '5%',
  },
];

const Categories = (props: ICategoriesProps) => {
  const { rows, handleRowChange } = props;
  return <Table handleChange={handleRowChange} rows={rows} columns={COLUMNS} />;
};

export default function CreateCategory(props: ICreateCategoryProps) {
  const { permissions } = props;
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [showAddCategoryDialog, setShowAddCategoryDialog] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<any>({ id: null, name: '' });
  const [rows, setRows] = useState<IRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<IErrors>({
    [CategoryField.NAME]: { ...DEFAULT_VALIDATIONS_INFO },
    [CategoryField.DESCRIPTION]: { ...DEFAULT_VALIDATIONS_INFO },
  });
  const [values, setValues] = useState<IInputField>({
    [CategoryField.NAME]: '',
    [CategoryField.DESCRIPTION]: '',
    [CategoryField.IS_PUBLIC]: false,
    [CategoryField.ICON]: '',
  });

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList(RequestResource.CATEGORY, { filter: { query: `with_tree=true` }, customQuery: true } as any)
      .then(({ data }) => {
        setLoading(false);
        const categoryData = data as any;
        setRows(prepareCategoriesData(categoryData));
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const prepareCategoriesData = (categories: IRow[]): IRow[] => {
    if (!categories || !categories.length) {
      return [];
    }
    return categories.map(({ categories, ...rest }: IRow) => {
      return {
        ...rest,
        rows: prepareCategoriesData(categories as IRow[]),
        [IS_OPEN]: true,
        [RADIO_COLUMN]: false,
        [IS_EDIT_MODE]: true,
      };
    });
  };

  const toggleAddCategoryDialog = (toggle: boolean) => setShowAddCategoryDialog(toggle);

  if (loading) return <Loading />;

  const { name, description, isPublic } = values;

  const handleChange = (value: string | number | boolean, name: CategoryField) => {
    const newErrors = {
      ...errors,
      [name]: validate('' + value, [ValidationType.REQUIRED, ValidationType.MIN_LENGTH], name, { minLength: 3 }),
    };
    setValues({ ...values, [name]: value });
    setErrors(newErrors);
  };

  const checkValidationAndSave = () => {
    const keys = Object.keys(errors);
    let newErrors = { ...errors };

    for (const key of keys) {
      const value = values[key];
      newErrors = {
        ...newErrors,
        [key]: validate(value, [ValidationType.REQUIRED], key),
      };
    }
    if (isValid(newErrors)) {
      handleSave();
    }
    setErrors(newErrors);
  };

  const handleSave = () => {
    const { icon, ...rest } = values;
    const newValues = values[CategoryField.ICON] ? { ...values } : rest;
    setLoading(true);
    dataProvider
      .create(RequestResource.CATEGORY, {
        data: {
          ...newValues,
          categoryId: selectedCategory.id,
        },
      } as any)
      .then(() => {
        setLoading(false);
        redirect(`/${RequestResource.CATEGORY}`);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleRowChange = (row: IRow, column: string, value: IField) => {
    const isRadioColumn: boolean = column === RADIO_COLUMN;
    const notFoundReplacementData = isRadioColumn ? { [column]: false } : {};
    let newRows: IRow[] = replaceInArray(
      rows,
      { key: 'id', value: row.id, arrayFieldKey: 'rows' },
      { [column]: value },
      notFoundReplacementData
    );

    if (isRadioColumn) {
      if (value) {
        setSelectedCategory({
          id: row.id,
          name: row.name,
        });
      } else {
        setSelectedCategory({ id: null, name: '' });
      }
    }
    setRows(newRows);
  };

  const handleCancelClick = () => {
    redirect(`/${RequestResource.CATEGORY}`);
  };

  const handleRemove = () => {
    setSelectedCategory({ id: null, name: '' });
  };

  const handleUploadIcon = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.files?.[0];
    let encoded;
    getBase64Image(value).then((result: string) => {
      encoded = result;
      setValues({ ...values, [CategoryField.ICON]: encoded });
    });
  };

  return (
    <div className="create-category">
      <FormControl className="create-category-form">
        <div className="category-header">
          <Typography variant="h5">Category Create</Typography>
          <div className="icon-wrapper">
            <input type="file" onChange={handleUploadIcon} id="iconUploader" />
            <IconButton>
              <label className="icon-upload-label" htmlFor="iconUploader">
                {values[CategoryField.ICON] ? (
                  <img className="category-icon" src={values[CategoryField.ICON]} alt="Preview" />
                ) : (
                  <AddPhotoAlternateOutlinedIcon className="default-icon" />
                )}
              </label>
            </IconButton>
          </div>
        </div>
        <Divider className="divider" />
        <div className="body">
          <Input
            required={true}
            label="Name"
            value={name}
            errorInfo={errors[CategoryField.NAME]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, CategoryField.NAME)}
          />
          <Input
            multiline={true}
            required={true}
            label="Description"
            value={description}
            errorInfo={errors[CategoryField.DESCRIPTION]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.value, CategoryField.DESCRIPTION)
            }
          />
          <FormControlLabel
            className="is-public-switcher"
            control={
              <Switch
                checked={!!isPublic}
                onChange={(e) => handleChange(e.target.checked, CategoryField.IS_PUBLIC)}
                name="Is Public"
                color="primary"
              />
            }
            label="Is Public"
          />
          <div className="parent-category">
            {selectedCategory.id ? (
              <Link to={`/${RequestResource.CATEGORY}/${selectedCategory.id}`}>{selectedCategory.name || ''}</Link>
            ) : (
              ''
            )}
            <div className="attached-category-actions">
              {selectedCategory.id ? (
                <React.Fragment>
                  <IconButton aria-label="edit" color="primary" onClick={() => toggleAddCategoryDialog(true)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton aria-label="delete" color="primary" onClick={handleRemove}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              ) : (
                <Button aria-label="add" color="primary" onClick={() => toggleAddCategoryDialog(true)}>
                  Add Category
                  <AddIcon fontSize="small" />
                </Button>
              )}
            </div>
          </div>
        </div>
        <Card className="card">
          <CardActions className="card-actions">
            <div>
              {hasPermission(permissions, RequestResource.CATEGORY, PermissionLevel.CREATE) ? (
                <Button variant="contained" color="primary" onClick={checkValidationAndSave} startIcon={<SaveIcon />}>
                  Save
                </Button>
              ) : null}
              <Button variant="outlined" color="primary" onClick={handleCancelClick} className="cancel-button">
                Cancel
              </Button>
            </div>
          </CardActions>
        </Card>
      </FormControl>
      {showAddCategoryDialog && (
        <Dialog onClose={() => toggleAddCategoryDialog(false)}>
          <DialogTitle title="Add Category" onClose={() => toggleAddCategoryDialog(false)} />
          <DialogContent>
            <Categories rows={rows} handleRowChange={handleRowChange} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => toggleAddCategoryDialog(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
