import { required, TextInput, Edit, SimpleForm, SaveButton, DeleteButton } from 'react-admin';
import React from 'react';
import { IPermission, PermissionLevel, RequestResource } from '../../types';
import { Button, Divider, Typography } from '@material-ui/core';
import { history } from '../../configureStore';
import { minLength } from 'ra-core/esm/form/validate';
import { Toolbar as RAToolbar } from 'ra-ui-materialui/lib/form';
import { hasPermission } from '../../utils';

export interface ICreateFaq {
  permissions: IPermission;
}

function Toolbar(props: any) {
  const { handleCancel, permissions } = props;

  return (
    <RAToolbar {...props} className="actions">
      <SaveButton />
      <Button variant="outlined" color="primary" className="cancel-button" onClick={handleCancel}>
        Cancel
      </Button>
      {hasPermission(permissions, RequestResource.FAQ, PermissionLevel.DELETE) ? (
        <DeleteButton className="delete-button" />
      ) : null}
    </RAToolbar>
  );
}

export default function CreateFaq(props: ICreateFaq) {
  const handleCancel = () => {
    history.push(`/${RequestResource.FAQ}`);
  };

  return (
    <div className="edit-faq">
      <Typography variant="h5" className="edit-faq-title">
        FAQ edit
      </Typography>
      <Divider className="divider" />
      <Edit {...props}>
        <SimpleForm toolbar={<Toolbar {...props} handleCancel={handleCancel} />}>
          <TextInput source="id" disabled />
          <TextInput
            source="topic"
            validate={[required(), minLength(3, 'Should not contain less characters than 3')]}
            multiline
          />
          <TextInput
            source="question"
            validate={[required(), minLength(3, 'Should not contain less characters than 3')]}
          />
          <TextInput
            source="answer"
            validate={[required(), minLength(3, 'Should not contain less characters than 3')]}
            multiline
            fullWidth={true}
          />
        </SimpleForm>
      </Edit>
    </div>
  );
}
