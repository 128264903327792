import { convertCamelCaseToSnakeCase, removeSymbolAndCapitalize } from './stringUtils';
import { IError, IErrors, ValidationType } from '../types';
import { isValidEmail, isValidPassword, isValidPhoneNumber } from './regexp';
import { isObject } from './objectUtils';
import moment from 'moment';
import { validationDateFormat } from '../constants';

export const DEFAULT_VALIDATIONS_INFO = {
  error: false,
  message: '',
};

export type FieldValue = string | number | Date | Array<string> | [] | Object;

export function validate(value: FieldValue, types: ValidationType[], fieldName: string, config?: any) {
  let result = { ...DEFAULT_VALIDATIONS_INFO };

  if(typeof value === 'string') {
    value = value.trim()
  }

  for (let i = 0; i < types.length; i++) {
    const type = types[i];
    let newResult;

    switch (type) {
      case ValidationType.MAX_VALUE:
        newResult = validateMaxValue(value, type, fieldName, config);
        break;
      case ValidationType.MIN_VALUE:
        newResult = validateMinValue(value, type, fieldName, config);
        break;
      case ValidationType.MAX_LENGTH:
        newResult = validateMaxLength(value, type, fieldName, config);
        break;
      case ValidationType.MIN_LENGTH:
        newResult = validateMinLength(value, type, fieldName, config);
        break;
      case ValidationType.EMAIL:
        newResult = validateEmail(value, type, fieldName, config);
        break;
      case ValidationType.PHONE:
        newResult = validatePhone(value, type, fieldName, config);
        break;
      case ValidationType.PASSWORD:
        newResult = validatePassword(value, type, fieldName, config);
        break;
      case ValidationType.MIN_DATE:
        newResult = validateMinDate(value, type, fieldName, config);
        break;
      case ValidationType.REQUIRED:
      default:
        newResult = validateRequire(value, type, fieldName);
        break;
    }

    if (newResult.error) {
      result = { ...newResult };
      break;
    }
  }

  return result;
}

export function isValid(errors: IErrors = {}): boolean {
  const keys = Object.keys(errors);
  let valid = true;

  if (!keys || !keys.length) {
    return valid;
  }

  for (const key of keys) {
    const { error } = errors[key];

    if (error) {
      valid = false;
      break;
    }
  }
  return valid;
}

function validateRequire(value: FieldValue, type: ValidationType, fieldName: string): IError {
  if (Array.isArray(value) && value.length === 0) {
    return getErrorMessage(type, fieldName);
  } else if (isObject(value) && !(value instanceof Date) && !Object.keys(value).length) {
    return getErrorMessage(type, fieldName);
  } else if (value || value === 0) {
    return DEFAULT_VALIDATIONS_INFO;
  }
  return getErrorMessage(type, fieldName);
}

function validateMaxValue(value: FieldValue, type: ValidationType, fieldName: string, config?: any): IError {
  const maxValue = config?.maxValue;
  return (maxValue || maxValue === 0) && value > maxValue
    ? getErrorMessage(type, fieldName, maxValue)
    : DEFAULT_VALIDATIONS_INFO;
}

function validateMinValue(value: FieldValue, type: ValidationType, fieldName: string, config?: any): IError {
  const minValue = config?.minValue;
  return (minValue || minValue === 0) && value < minValue
    ? getErrorMessage(type, fieldName, minValue)
    : DEFAULT_VALIDATIONS_INFO;
}

function validateMaxLength(value: FieldValue, type: ValidationType, fieldName: string, config?: any): IError {
  const maxLength = config?.maxLength;
  return maxLength && value?.toString().length > maxLength
    ? getErrorMessage(type, fieldName, maxLength)
    : DEFAULT_VALIDATIONS_INFO;
}

function validateMinLength(value: FieldValue, type: ValidationType, fieldName: string, config?: any): IError {
  const minLength = config?.minLength;
  return minLength && value?.toString().length < minLength
    ? getErrorMessage(type, fieldName, minLength)
    : DEFAULT_VALIDATIONS_INFO;
}

function validateEmail(value: FieldValue, type: ValidationType, fieldName: string, config?: any): IError {
  return !isValidEmail(value as string) ? getErrorMessage(type, fieldName) : DEFAULT_VALIDATIONS_INFO;
}

function validatePhone(value: FieldValue, type: ValidationType, fieldName: string, config?: any): IError {
  const minLength = config?.minLength;
  return !isValidPhoneNumber(value as string) ? getErrorMessage(type, fieldName, minLength) : DEFAULT_VALIDATIONS_INFO;
}

function validatePassword(value: FieldValue, type: ValidationType, fieldName: string, config?: any): IError {
  const minLength = config?.minLength;
  return !isValidPassword(value as string) ? getErrorMessage(type, fieldName, minLength) : DEFAULT_VALIDATIONS_INFO;
}

function validateMinDate(value: FieldValue, type: ValidationType, fieldName: string, config?: any): IError {
  const minDate = config?.minDate;
  return minDate && value < minDate ? getErrorMessage(type, fieldName, minDate) : DEFAULT_VALIDATIONS_INFO;
}

function getErrorMessage(type: ValidationType, fieldName: string, extraInfo?: any) {
  extraInfo = extraInfo || extraInfo === 0 ? extraInfo : '';
  switch (type) {
    case ValidationType.MAX_VALUE:
      return {
        error: true,
        message: `${removeSymbolAndCapitalize(
          convertCamelCaseToSnakeCase(fieldName)
        )} should not be more than ${extraInfo}`,
      };
    case ValidationType.MIN_VALUE:
      return {
        error: true,
        message: `${removeSymbolAndCapitalize(
          convertCamelCaseToSnakeCase(fieldName)
        )} should not be less than ${extraInfo}`,
      };
    case ValidationType.MAX_LENGTH:
      return {
        error: true,
        message: `${removeSymbolAndCapitalize(
          convertCamelCaseToSnakeCase(fieldName)
        )} should not contain more characters than ${extraInfo}`,
      };
    case ValidationType.MIN_LENGTH:
      return {
        error: true,
        message: `${removeSymbolAndCapitalize(
          convertCamelCaseToSnakeCase(fieldName)
        )} should not contain less characters than ${extraInfo}`,
      };
    case ValidationType.EMAIL:
      return {
        error: true,
        message: `${removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(fieldName))} should be valid`,
      };
    case ValidationType.PHONE:
      return {
        error: true,
        message: `${removeSymbolAndCapitalize(
          convertCamelCaseToSnakeCase(fieldName)
        )} should not contain less digits than ${extraInfo}, starting with a plus sign`,
      };
    case ValidationType.PASSWORD:
      return {
        error: true,
        message: `${removeSymbolAndCapitalize(
          convertCamelCaseToSnakeCase(fieldName)
        )} should not contain less characters than ${extraInfo}, at least one uppercase letter, one lowercase letter, one number and one special character`,
      };
    case ValidationType.MIN_DATE:
      return {
        error: true,
        message: `${removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(fieldName))} should be after ${moment(
          extraInfo
        ).format(validationDateFormat)}`,
      };
    case ValidationType.REQUIRED:
    default:
      return {
        error: true,
        message: `${removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(fieldName))} is required`,
      };
  }
}
