import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { List, Datagrid, TextField, EditButton, ShowButton, DeleteButton, useDataProvider, Loading } from 'react-admin';
import { Button, Tooltip, Typography } from '@material-ui/core';
import { hasPermission, removeSymbolAndCapitalize } from '../../utils';
import { IPermission, PermissionLevel, RequestResource } from '../../types';
import Dialog, { DialogActions, DialogTitle } from '../core/dialog/Dialog';
import ButtonWithConfirm from '../ButtonWithConfirm';

export interface IListRolePermissionProps {
  permissions: IPermission;
}

const IdentifierField = ({ record }: any) => {
  return <Typography>{removeSymbolAndCapitalize(record.name)}</Typography>;
};

export default function ListRolePermission(props: IListRolePermissionProps) {
  const { permissions } = props;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const dataProvider = useDataProvider();

  const onRowSelect = (id: any) => {
    setSelectedRowId(id);
    return id;
  };

  const handleDelete = () => {
    setIsLoading(true);
    dataProvider.delete(RequestResource.ROLE, { id: selectedRowId } as any).finally(() => {
      setIsLoading(false);
    });

    setIsDeleteDialogOpen(false);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <List {...props} className="permission-list">
          <Datagrid className="react-admin-table" rowClick={(id: any) => onRowSelect(id)}>
            <TextField source="id" />
            <TextField source="name" />
            <IdentifierField label="Display Name" />
            <ShowButton />
            {hasPermission(permissions, RequestResource.USER, PermissionLevel.READ) && <EditButton />}
            <ButtonWithConfirm onConfirm={handleDelete} title="Are you sure you want to delete this role?">
              <Tooltip placement="top" title="Delete Role">
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  className="delete-button"
                  startIcon={<DeleteIcon />}
                ></Button>
              </Tooltip>
            </ButtonWithConfirm>
          </Datagrid>
        </List>
      )}
    </>
  );
}
