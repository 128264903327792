import { connect } from 'react-redux';
import EditUser from '../../components/manageUsers/EditUser';
import { IAppState, IAppDispatch } from '../../types';

const mapStateToProps = (state: IAppState) => ({
  cards: state.user.cards,
  currentUserId: state.auth.userId,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
