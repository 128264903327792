import { connect } from 'react-redux';
import ListService from '../../components/services/ListService';
import { IAppState, IAppDispatch } from '../../types';

const mapStateToProps = (state: IAppState) => ({
  currentUserRole: state.auth.role,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListService);
