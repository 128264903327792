import { BaseAPIService } from './BaseAPIService';
import { IFaq } from '../../types';

export class FaqAPIService extends BaseAPIService {
  constructor() {
    super('');
  }

  public async getList({ query, filter }: any): Promise<any> {
    query = query?.length ? `?${query}` : '';
    return this.get(`admin/faq${query}`);
  }

  public async getOne(id: number): Promise<any> {
    return this.get(`admin/faq/${id}`);
  }

  public async create(data: IFaq): Promise<any> {
    return this.post(`admin/faq`, data);
  }

  public async update(data: IFaq, id: number): Promise<any> {
    return this.put(`admin/faq/${id}`, data);
  }

  public async remove(id: number): Promise<any> {
    return this.delete(`admin/faq/${id}`);
  }

  public async removeMany(data: any): Promise<any> {
    return this.delete(`admin/faq`, data);
  }
}
