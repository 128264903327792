import React, { useEffect, useState } from 'react';
import { Button, Switch, FormControlLabel, Typography, Divider, Card, CardActions } from '@material-ui/core';
import { DEFAULT_VALIDATIONS_INFO, hasPermission, isValid, validate } from '../../utils';
import {
  IErrors,
  IPermission,
  PermissionLevel,
  RequestResource,
  ValidationType,
  IInitialData,
  LanguageField,
} from '../../types';
import { history } from '../../configureStore';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
import Input from '../core/input/Input';
import dataProvider from '../../dataProvider';
import { Loading } from 'react-admin';

export interface IEditLanguageProps {
  permissions: IPermission;
  id?: string;
}

const defaultErrors = {
  [LanguageField.CODE]: { ...DEFAULT_VALIDATIONS_INFO },
  [LanguageField.SHORT_CODE]: { ...DEFAULT_VALIDATIONS_INFO },
  [LanguageField.LABEL]: { ...DEFAULT_VALIDATIONS_INFO },
};

const initialData = {
  code: '',
  shortCode: '',
  label: '',
};

export default function EditLanguage(props: IEditLanguageProps) {
  const [uploadedFile, setUploadedFile] = useState<any>();
  const [values, setValues] = useState<IInitialData>(initialData);
  const [checked, setChecked] = useState(false);
  const [incorrectFileFormat, setIncorrectFileFormat] = useState(false)
  const [errors, setErrors] = useState<IErrors>({ ...defaultErrors });
  const [loading, setLoading] = useState<boolean>(false);
  const { id, permissions } = props;

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getOne(RequestResource.LANGUAGE, { id })
      .then((res) => {
        const { code, shortCode, label, isDefault, font } = res.data;
        setChecked(isDefault);
        setValues({ ...values, code, shortCode, label, font });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIncorrectFileFormat(false);
    if (e.currentTarget.files?.[0].type === "") {
      setUploadedFile(e.currentTarget.files?.[0]);
      setIncorrectFileFormat(false);
    } else {
      setIncorrectFileFormat(true);
      setTimeout(() => setIncorrectFileFormat(false), 5000)
      return;
    }
  };

  const handleSaveFile = () => {
    const formData = new FormData();
    formData.append('font', uploadedFile);
    dataProvider
      .update(RequestResource.LANGUAGE_FONT_FILE, { data: formData, id } as any)
      .then((res) => { })
      .catch(() => { });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const value = e.target.value;
    const newErrors = {
      ...errors,
      [name]: validate(value, [ValidationType.REQUIRED, ValidationType.MIN_LENGTH], name, { minLength: 3 }),
    };
    setErrors(newErrors);
    setValues({ ...values, [name]: value });
  };

  const handleChangeChecked = (event: any) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = () => {
    const keys = Object.keys(errors);
    let newErrors = { ...errors };
    for (const key of keys) {
      const value = values[key as LanguageField];
      newErrors = {
        ...newErrors,
        [key]: validate(value as string | number, [ValidationType.REQUIRED, ValidationType.MIN_LENGTH], key, {
          minLength: 3,
        }),
      };
    }
    setErrors(newErrors);
    if (!isValid(newErrors)) {
      return;
    }
    const data = { ...values, isDefault: checked };
    setLoading(true);
    dataProvider
      .update(RequestResource.LANGUAGE, { data, id } as any)
      .then((res) => {
        setLoading(false);
        history.push(`/${RequestResource.LANGUAGE}`);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    history.push(`/${RequestResource.LANGUAGE}`);
  };

  const handleDelete = () => {
    setLoading(true);
    dataProvider
      .delete(RequestResource.LANGUAGE, { id } as any)
      .then(() => {
        setLoading(false);
        history.push(`/${RequestResource.LANGUAGE}`);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (loading) return <Loading />;
  return (
    <div className="edit-language-container">
      <div className="edit-language-form">
        <div className="wrapper">
          <Typography variant="h5" className="edit-language-title">
            Language edit
          </Typography>
          <Divider className="divider" />
          <div className="container">
            <div className="uploaded-font-name">
              {uploadedFile?.name || values.font?.split('/')?.pop() || 'No Font Uploaded'}
            </div>
            <div className="uploader-container">
              <input
                id="file"
                type="file"
                accept=".ttf,.otf,.woff,.eot"
                className="font-upload-input"
                onChange={handleUpload} />
              <Tooltip title="You can only upload fonts in .TTF, .OTF, .WOFF, .EOT format." placement="top-start">
                <Button
                  variant="contained"
                  color="default"
                  startIcon={
                    <label htmlFor="file" className="file-upload-label">
                      <CloudUploadIcon />
                    </label>
                  }
                >
                  <label htmlFor="file" className="file-upload-label">
                    Upload
                  </label>
                </Button>

              </Tooltip>
              <Button
                disabled={!uploadedFile}
                onClick={handleSaveFile}
                variant="contained"
                color="primary"
                className="file-save-button"
              >
                Save
              </Button>
            </div>
            {
              incorrectFileFormat && <Alert severity="error">Incorrect font. You can only upload fonts in .TTF, .OTF, .WOFF, .EOT format.</Alert>
            }
            <div className="edit-language">
              <Input
                label="Code"
                value={values.code}
                errorInfo={errors[LanguageField.CODE]}
                classes={['text-field']}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, LanguageField.CODE)}
              />
              <Input
                label="Short code"
                value={values.shortCode}
                errorInfo={errors[LanguageField.SHORT_CODE]}
                classes={['text-field']}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, LanguageField.SHORT_CODE)}
              />
              <Input
                label="Label"
                value={values.label}
                errorInfo={errors[LanguageField.LABEL]}
                classes={['text-field']}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, LanguageField.LABEL)}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={handleChangeChecked}
                    name="checked"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label="Set as default"
              />
            </div>
          </div>
        </div>
        <Card className="card">
          <CardActions className="card-actions">
            <div>
              {hasPermission(permissions, RequestResource.LANGUAGE, PermissionLevel.UPDATE) ? (
                <Button variant="contained" color="primary" onClick={handleSubmit} startIcon={<SaveIcon />}>
                  Save
                </Button>
              ) : null}
              <Button variant="outlined" color="primary" onClick={handleClose} className="cancel-button">
                Cancel
              </Button>
            </div>
            {hasPermission(permissions, RequestResource.LANGUAGE, PermissionLevel.DELETE) ? (
              <Button
                variant="contained"
                onClick={handleDelete}
                startIcon={<DeleteIcon />}
                color="secondary"
                className="delete"
              >
                Delete
              </Button>
            ) : null}
          </CardActions>
        </Card>
      </div>
    </div>
  );
}
