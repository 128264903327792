import { handleActions, Action } from 'redux-actions';
import { SET_ROLE_PERMISSION } from '../actions/auth';
import { IAuthState } from '../types';

const DEFAULT_STATE: IAuthState = {
  role: '',
  permissions: {},
  userId: '',
};

export const auth = handleActions<IAuthState, any>(
  {
    [SET_ROLE_PERMISSION]: (state: IAuthState, action: Action<SET_ROLE_PERMISSION>): IAuthState => {
      return {
        ...state,
        role: action.payload.role,
        permissions: action.payload.permissions,
        userId: action.payload.userId,
      };
    },
  },
  DEFAULT_STATE
);
