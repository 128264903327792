import { connect } from 'react-redux';
import ProfileEdit from '../../components/profile/ProfileEdit';
import { IAppState, IAppDispatch } from '../../types';

const mapStateToProps = (state: IAppState) => ({
  userId: state.auth.userId,
  role: state.auth.role,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);
