import Moment from 'moment';
import PrintIcon from '@material-ui/icons/Print';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading, useDataProvider, useRedirect } from 'react-admin';
import {
  Box,
  Button,
  Card,
  CardActions,
  Divider,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import EyeIcon from '@material-ui/icons/Visibility';
import {
  capitalizeFirstLetter,
  convertCamelCaseToSnakeCase,
  convertServerDateToLocal,
  DEFAULT_VALIDATIONS_INFO,
  formatCurrency,
  hasPermission,
  isValid,
  removeSymbolAndCapitalize,
  validate,
} from '../../utils';
import {
  ColumnType,
  DataNames,
  IColumn,
  IError,
  IErrors,
  IPermission,
  IRow,
  IStatusTagColor,
  OrderServiceFields,
  OrderStatus,
  OrderTransitionFields,
  PermissionLevel,
  RequestResource,
  Role,
  ValidationType,
} from '../../types';
import Table from '../core/table/Table';
import Uploader from '../core/uploader/Uploader';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../core/dialog/Dialog';
import StatusTag from '../core/status-tag/StatusTag';
import { dateTimeFormatForMoment, ORDER_STATUS_COLORS } from '../../constants';
import { AppConfig } from '../../config';
import { usePrevious } from '../../hooks';
import Input from '../core/input/Input';
import { toInteger } from 'lodash';

interface IOrderServiceProps {
  getOrderService: (id: number) => void;
  match: any;
  orderService: any;
  users: any[];
  orderLoading: boolean;
  loading: boolean;
  removeOrderServiceAttachment: (orderServiceId: number, attachmentId: number) => void;
  saveOrderServiceAttachments: (orderServiceId: number, files: any) => void;
  getAgents: (roleId: number) => void;
  emptyOrderService: () => void;
  saveOrderService: (id: number, data: any) => void;
  permissions: IPermission;
  role: string;
  userId: number | any;
}

const NO_TRANSITION_AUTHOR = 'No Transition Author';
const FILE_UPLOAD_KEY = 'attachment';
const ACTIONS = 'actions';

const TRANSITION_COLUMNS: IColumn[] = [
  {
    field: OrderTransitionFields.ID,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderTransitionFields.ID)),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: OrderTransitionFields.STATUS,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderTransitionFields.STATUS)),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: any) => (
      <StatusTag
        styles={ORDER_STATUS_COLORS[row.status as OrderStatus] as IStatusTagColor}
        label={removeSymbolAndCapitalize(row.status as string)}
      />
    ),
  },
  {
    field: OrderTransitionFields.AUTHOR_NAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(OrderTransitionFields.AUTHOR_NAME)),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: any) =>
      row?.author?.id ? (
        <Link to={`/${RequestResource.USER}/${row?.author?.id}`}>{row?.author?.name || NO_TRANSITION_AUTHOR}</Link>
      ) : (
        row?.author?.name || NO_TRANSITION_AUTHOR
      ),
  },
  {
    field: OrderTransitionFields.DATE,
    headerName: capitalizeFirstLetter(OrderTransitionFields.DATE),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: any) => convertServerDateToLocal(row.date, dateTimeFormatForMoment),
  },
];

const AGENTS_COLUMNS: IColumn[] = [
  {
    field: DataNames.ID,
    headerName: capitalizeFirstLetter(DataNames.ID),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: DataNames.FIRSTNAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(DataNames.FIRSTNAME)),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: DataNames.LASTNAME,
    headerName: removeSymbolAndCapitalize(convertCamelCaseToSnakeCase(DataNames.LASTNAME)),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: DataNames.EMAIL,
    headerName: capitalizeFirstLetter(DataNames.EMAIL),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: DataNames.PHONE,
    headerName: capitalizeFirstLetter(DataNames.PHONE),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: DataNames.GENDER,
    headerName: capitalizeFirstLetter(DataNames.GENDER),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: ACTIONS,
    headerName: capitalizeFirstLetter(ACTIONS),
    editable: false,
    type: ColumnType.COMPONENT,
  },
];

const statusRoles: Role[] = [Role.ADMIN, Role.AGENT];
const availableStatuses: OrderStatus[] = [OrderStatus.IN_PROGRESS, OrderStatus.COMPLETED, OrderStatus.PENDING];
const defaultErrors: IErrors = {
  [OrderServiceFields.NOTES]: { ...DEFAULT_VALIDATIONS_INFO },
  [OrderServiceFields.DIAGNOSIS]: { ...DEFAULT_VALIDATIONS_INFO },
};

export default function OrderService(props: IOrderServiceProps) {
  const {
    match,
    getOrderService,
    orderService,
    orderLoading,
    loading,
    getAgents,
    users,
    permissions,
    saveOrderService,
    role,
    emptyOrderService,
    userId,
  } = props;

  const [PrintServiceDialogOpen, setPrintServiceDialogOpen] = useState<boolean>(false);
  const [attachedAgents, setAttachedAgents] = useState<any>([]);
  const [showAddAgentDialog, setShowAddAgentDialog] = useState<boolean>(false);
  const [showAttachmentPreviewDialog, setShowAttachmentPreviewDialog] = useState<boolean>(false);
  const [attachedAgentIds, setAttachedAgentIds] = useState<number[]>([]);
  const [selectedAttachment, setSelectedAttachment] = useState<any>(null);
  const [orderInfo, setOrderInfo] = useState<any>({
    [OrderServiceFields.NOTES]: '',
    [OrderServiceFields.DIAGNOSIS]: '',
  });
  const [errors, setErrors] = useState<IErrors>(defaultErrors);
  const [diagnosisError, setDiagnosisError] = useState<IError>({ ...DEFAULT_VALIDATIONS_INFO });
  const { id } = match?.params || {};
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const prevOrderService: any = usePrevious(orderService);
  const cancelServicePermission = role === Role.SUPER_ADMIN || role === Role.ADMIN || role === Role.OPERATOR;

  const [profile, setProfile] = useState<any>();

  useEffect(() => {
    dataProvider.getOne(RequestResource.PROFILE, { id: userId }).then((res) => {
      const { firstName, lastName, email, phone, gender } = res.data;
      setProfile({
        [DataNames.FIRSTNAME]: firstName,
        [DataNames.LASTNAME]: lastName,
        [DataNames.EMAIL]: email,
        [DataNames.PHONE]: phone,
      });
    });
  }, []);

  useEffect(() => {
    getOrder();
    dataProvider
      .getList(RequestResource.ROLE, {} as any)
      .then((res) => {
        const roles: any = res.data;
        const agentRoleId = roles?.find((role: any) => role.name === Role.AGENT)?.id;
        if (agentRoleId) {
          dispatch(getAgents(agentRoleId as number));
        }
      })
      .catch(() => {});
    return () => dispatch(emptyOrderService());
  }, []);

  useEffect(() => {
    if (prevOrderService && !Object.keys(prevOrderService).length && orderStatus && Object.keys(orderService).length) {
      const { users, diagnosis, notes, price } = orderService;
      setAttachedAgents(users);
      setAttachedAgentIds(getAttachedAgentIds(users));
      setOrderInfo({
        [OrderServiceFields.NOTES]: notes || '',
        [OrderServiceFields.DIAGNOSIS]: diagnosis || '',
      });
    }
  }, [orderService]);

  useEffect(() => {
    if (!loading) {
      getOrder();
    }
  }, [loading]);

  const getOrder = () => {
    if (!id) {
      return;
    }
    dispatch(getOrderService(id));
  };

  if (loading || orderLoading || !orderService || !Object.keys(orderService).length) return <Loading />;

  const handleAttachmentDelete = (attachmentId: number) => {
    if (!id) {
      return;
    }
    const { removeOrderServiceAttachment } = props;
    dispatch(removeOrderServiceAttachment(+id, attachmentId));
  };

  const togglePrintOrderServiceDialog = (toggle: boolean, row: any = null) => {
    row = row || OrderService;
    setPrintServiceDialogOpen(toggle);
  };

  const printOrderService = () => {
    // a: boolean, row: any
    //alert('Print Service ! from dialog');
    window.print();
  };

  const renderAttachments = () => {
    return attachments.map((attachment: IRow) => {
      return (
        <div className="attachment" key={attachment.id as number}>
          {attachment.filename}
          <div className="icons">
            <IconButton
              className="icon view"
              aria-label="view"
              onClick={() => toggleAttachmentPreviewDialog(true, attachment)}
            >
              <EyeIcon />
            </IconButton>
            {![OrderStatus.CANCELLED, OrderStatus.CLOSED, OrderStatus.DONE].includes(status) &&
            ![OrderStatus.CANCELLED, OrderStatus.CLOSED].includes(orderStatus) ? (
              <IconButton
                className="icon"
                aria-label="delete"
                onClick={() => handleAttachmentDelete(attachment.id as number)}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
          </div>
        </div>
      );
    });
  };

  const handleSaveAttachment = (files: any): any => {
    if (!id || !files?.length) {
      return;
    }
    const { saveOrderServiceAttachments } = props;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append(`${FILE_UPLOAD_KEY}`, files[i]);
    }
    saveOrderServiceAttachments(id, formData);
  };

  const handleRedirect = (to: string): void => {
    redirect(to);
  };

  const {
    id: orderServiceId,
    order: { id: orderId, status: orderStatus },
    status,
    attachments,
    users: orderUsers,
    service: { id: serviceId, name: serviceName, price: servicePrice },
    bundle,
    statusTransitions,
  } = orderService;
  const Patient = orderService.order['patient'];
  const { diagnosis, notes, price } = orderInfo;
  const getAttachedAgentIds = (attachedAgents: IRow[]): number[] => {
    return attachedAgents?.length ? attachedAgents.map((attachedAgent: any) => attachedAgent.id) : [];
  };

  const getUniqueAgents = () => {
    return users?.filter((user: any) => !attachedAgentIds.includes(user.id as number));
  };

  const handleAgentAdd = (row: IRow) => {
    const agents = [...attachedAgents, row];
    setAttachedAgentIds(getAttachedAgentIds(agents));
    setAttachedAgents(agents);
  };

  const handleAgentDelete = (id: number) => {
    const newAttachedAgents = [...attachedAgents];
    const index = attachedAgents.findIndex((row: IRow) => row.id === id);
    newAttachedAgents.splice(index, 1);
    setAttachedAgentIds(getAttachedAgentIds(newAttachedAgents));
    setAttachedAgents(newAttachedAgents);
  };

  const toggleAddAgentDialog = (toggle: boolean) => {
    setShowAddAgentDialog(toggle);
  };

  const toggleAttachmentPreviewDialog = (toggle: boolean, attachment: any = null) => {
    setShowAttachmentPreviewDialog(toggle);
    setSelectedAttachment(attachment);
  };

  const handleCancelClick = () => {
    if (orderId) {
      redirect(`/${RequestResource.ORDER}/${orderId}`);
    }
  };

  const handleSave = () => {
    updateOrderService({
      users: attachedAgents,
      notes: orderInfo.notes,
      diagnosis: orderInfo.diagnosis,
    });
  };

  const updateOrderService = (data: any) => {
    if (!id) {
      return;
    }
    dispatch(saveOrderService(orderServiceId, data));
  };

  const handleOrderStatusChange = (status: OrderStatus) => {
    const data: { status: OrderStatus; diagnosis?: string; notes?: string } = { status };

    if (status === OrderStatus.COMPLETED) {
      const keys = Object.keys(errors);
      let newErrors = { ...errors };

      for (const key of keys) {
        const value = orderInfo[key];
        newErrors = {
          ...newErrors,
          [key]: validate(value, [ValidationType.REQUIRED, ValidationType.MIN_LENGTH], key, { minLength: 3 }),
        };
      }
      data.diagnosis = diagnosis;
      data.notes = notes;
      if (!isValid(newErrors)) {
        setErrors(newErrors);
        return;
      }
      setErrors(defaultErrors);
    }
    updateOrderService(data);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, name: OrderServiceFields) => {
    const { value } = e.target;
    const newErrors = {
      ...errors,
      [name]: validate(value, [ValidationType.REQUIRED, ValidationType.MIN_LENGTH], name, { minLength: 3 }),
    };
    setErrors(newErrors);
    setOrderInfo({ ...orderInfo, [name]: value });
  };

  const { path: attachmentPath, filename: attachmentName } = selectedAttachment || {};

  return (
    <div className="order-service">
      <FormControl className="edit-order-service-form">
        <Typography variant="h5">Order Service edit</Typography>
        <Divider className="divider" />
        <div className="body">
          <div className="order-info">
            <div className="order-service-info">
              <List component="div">
                <ListItem>
                  <ListItemIcon className="list-item">Order ID</ListItemIcon>
                  <ListItemText
                    className="href"
                    primary={orderId || ''}
                    onClick={() => handleRedirect(`/${RequestResource.ORDER}/${orderId}`)}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon className="list-item">Bundle ID</ListItemIcon>
                  {bundle && (
                    <ListItemText
                      className="href"
                      primary={bundle.name || ''}
                      onClick={() => handleRedirect(`/${RequestResource.BUNDLE}/${bundle.id}`)}
                    />
                  )}
                </ListItem>
                <ListItem>
                  <ListItemIcon className="list-item">Service Name</ListItemIcon>
                  <ListItemText
                    className="href"
                    primary={serviceName || ''}
                    onClick={() => handleRedirect(`/${RequestResource.SERVICE}/${serviceId}`)}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon className="list-item">Price</ListItemIcon>
                  <ListItemText primary={formatCurrency(servicePrice || '')} />
                </ListItem>
              </List>
              <Input
                required={true}
                multiline={true}
                label="Notes"
                value={notes || ''}
                errorInfo={errors[OrderServiceFields.NOTES]}
                classes={['text-field']}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, OrderServiceFields.NOTES)}
                disabled={
                  [OrderStatus.CANCELLED, OrderStatus.CLOSED, OrderStatus.COMPLETED, OrderStatus.DONE].includes(
                    status
                  ) || [OrderStatus.CANCELLED, OrderStatus.CLOSED].includes(orderStatus)
                }
              />
              <Input
                required={true}
                multiline={true}
                label="Diagnosis"
                value={diagnosis || ''}
                errorInfo={errors[OrderServiceFields.DIAGNOSIS]}
                classes={['text-field']}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, OrderServiceFields.DIAGNOSIS)}
                disabled={
                  [OrderStatus.CANCELLED, OrderStatus.CLOSED, OrderStatus.COMPLETED, OrderStatus.DONE].includes(
                    status
                  ) || [OrderStatus.CANCELLED, OrderStatus.CLOSED].includes(orderStatus)
                }
              />
              {status !== OrderStatus.CANCELLED && availableStatuses.includes(status) && (
                <div>
                  <Divider className="divider secondary" />
                  <Typography variant="h6">Status</Typography>
                  <Divider className="divider secondary" />
                  <div className="button-wrapper">
                    {statusRoles.includes(role as Role) && (
                      <div className="order-status-buttons">
                        {status === OrderStatus.IN_PROGRESS && (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleOrderStatusChange(OrderStatus.COMPLETED)}
                            className="order-status-button"
                          >
                            Complete
                          </Button>
                        )}
                        {status === OrderStatus.COMPLETED && (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleOrderStatusChange(OrderStatus.DONE)}
                            className="order-status-button"
                          >
                            Done
                          </Button>
                        )}
                      </div>
                    )}
                    {(status === OrderStatus.IN_PROGRESS || status === OrderStatus.PENDING) && cancelServicePermission && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleOrderStatusChange(OrderStatus.CANCELLED)}
                        className="order-status-button"
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      color="primary"
                      className="order-status-button"
                      onClick={() => setPrintServiceDialogOpen(true)}
                    >
                      <PrintIcon /> Print
                    </Button>
                  </div>
                </div>
              )}
              <Divider className="divider secondary" />
              <Typography variant="h6">Status Transition</Typography>
              <Divider className="divider secondary" />
              <Table columns={TRANSITION_COLUMNS} rows={statusTransitions} />
              <Divider className="divider secondary" />
              <Typography variant="h6">Agents</Typography>
              <Divider className="divider secondary" />
              <Table
                columns={AGENTS_COLUMNS}
                rows={attachedAgents}
                canDelete={
                  ![OrderStatus.CANCELLED, OrderStatus.CLOSED, OrderStatus.DONE].includes(status) &&
                  ![OrderStatus.CANCELLED, OrderStatus.CLOSED].includes(orderStatus)
                }
                handleDelete={handleAgentDelete}
              />
              {![OrderStatus.CANCELLED, OrderStatus.CLOSED, OrderStatus.DONE].includes(status) &&
                ![OrderStatus.CANCELLED, OrderStatus.CLOSED].includes(orderStatus) && (
                  <IconButton
                    aria-label="add"
                    className="add-service-button"
                    color="primary"
                    onClick={() => toggleAddAgentDialog(true)}
                  >
                    <AddIcon fontSize="large" />
                  </IconButton>
                )}
              <Divider className="divider secondary" />
              <Typography variant="h6">Order Attachments</Typography>
              <Divider className="divider secondary" />
              <div className="attachments">{renderAttachments()}</div>
              <div>
                <Uploader
                  onSave={handleSaveAttachment}
                  unLimitFiles={true}
                  readOnly={
                    [OrderStatus.CANCELLED, OrderStatus.CLOSED, OrderStatus.DONE].includes(status) ||
                    [OrderStatus.CANCELLED, OrderStatus.CLOSED].includes(orderStatus)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <Card className="card">
          <CardActions className="card-actions">
            <div>
              {hasPermission(permissions, RequestResource.ORDER, PermissionLevel.UPDATE) &&
              ![OrderStatus.CANCELLED, OrderStatus.DONE].includes(status) &&
              ![OrderStatus.CANCELLED, OrderStatus.CLOSED].includes(orderStatus) ? (
                <Button variant="contained" color="primary" onClick={handleSave} startIcon={<SaveIcon />}>
                  Save
                </Button>
              ) : null}
              <Button variant="outlined" color="primary" onClick={handleCancelClick} className="cancel-button">
                Cancel
              </Button>
            </div>
          </CardActions>
        </Card>
      </FormControl>
      {showAddAgentDialog && (
        <Dialog onClose={() => toggleAddAgentDialog(false)}>
          <DialogTitle title="Add Agents" onClose={() => toggleAddAgentDialog(false)} />
          <DialogContent>
            <Table columns={AGENTS_COLUMNS} rows={getUniqueAgents()} canAdd={true} handleAdd={handleAgentAdd} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => toggleAddAgentDialog(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {showAttachmentPreviewDialog && (
        <Dialog onClose={() => toggleAttachmentPreviewDialog(false)} classes={['attachment-preview-dialog']}>
          <DialogTitle title={attachmentName} onClose={() => toggleAttachmentPreviewDialog(false)} />
          <DialogContent>
            <img src={`${AppConfig.httpAPIGatewayURL}/${attachmentPath}`} alt={attachmentPath} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => toggleAttachmentPreviewDialog(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {PrintServiceDialogOpen && (
        <Dialog onClose={() => togglePrintOrderServiceDialog(false)}>
          <DialogTitle
            title="Print Order Service"
            onClose={() => togglePrintOrderServiceDialog(false)}
            classes={['no-print', 'test-title']}
          />
          <DialogContent>
            <div className="do-print">
              <div className="line">
                <Box display="flex" justifyContent="space-between">
                  <div>Visit N - {orderService.uid}</div>
                </Box>
              </div>
              <div className="line">
                <Box display="flex" justifyContent="space-between">
                  <div>{Patient.uid}</div>
                  <div>
                    {Patient.firstName} {Patient.lastName}
                  </div>
                  <div>
                    {Moment(Patient.birthDate).format('YYYY-MM-DD')} (
                    {toInteger(Moment().year()) - toInteger(Moment(Patient.birthDate).format('YYYY'))})
                  </div>
                </Box>
                <div className="underline">Patient first name, last name, age</div>
              </div>
              <div className="line">
                <Box display="flex" style={{ gap: 10 }}>
                  <Box>Diagnose:</Box>
                  <Box flex={1}>
                    {diagnosis}
                    <div className="underline"></div>
                  </Box>
                </Box>
              </div>
              <div className="line">
                <Box display="flex" style={{ gap: 10 }}>
                  <Box textAlign="center" flex={1}>
                    {orderService.service.uid}
                    <div className="underline">Service N</div>
                  </Box>
                  <Box textAlign="center" flex={3}>
                    {serviceName}
                    <div className="underline">Service</div>
                  </Box>
                </Box>
              </div>
              <div className="line">
                <Box display="flex" style={{ gap: 10 }}>
                  <Box>Price:</Box>
                  <Box flex={1}>
                    {servicePrice}
                    <div className="underline"></div>
                  </Box>
                </Box>
              </div>
              <div className="line">
                <Box display="flex" style={{ gap: 10 }}>
                  <Box>Referrer Doctor:</Box>
                  <Box textAlign="center" flex={2}>
                    {profile.firstName} {profile.lastName}
                    <div className="underline"></div>
                  </Box>
                  <Box textAlign="center" flex={1}>
                    <br />
                    <div className="underline">Signature</div>
                  </Box>
                </Box>
              </div>
              {orderService.users.map((u: any) => (
                <div key={u.id} className="line">
                  <Box display="flex" style={{ gap: 10 }}>
                    <Box>Performing Doctor:</Box>
                    <Box textAlign="center" flex={2}>
                      {u.firstName} {u.lastName}
                      <div className="underline"></div>
                    </Box>
                    <Box textAlign="center" flex={1}>
                      <br />
                      <div className="underline">Signature</div>
                    </Box>
                  </Box>
                </div>
              ))}
              {/*
            birthDate: "2004-03-01T16:27:00.000Z"
createdAt: "2022-03-14T16:27:49.158Z"
createdById: 1
createdByName: "Cursus Care"
defaultPicture: "profile_icon.png"
deletedAt: null
email: "client@example.com"
firstName: "Client"
gender: "male"
id: 125
lastName: "ClientLname"
phone: "+37400000085"
profilePicture: ""
updatedAt: "2022-03-14T16:27:49.000Z"
      */}
            </div>
          </DialogContent>
          <DialogActions classes={['no-print']}>
            <Button variant="contained" color="primary" onClick={() => printOrderService()}>
              Print Service
            </Button>
            <Button variant="contained" color="primary" onClick={() => togglePrintOrderServiceDialog(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
