import React from 'react';
import TextField from '@material-ui/core/TextField';
import { IError } from '../../../types';

type Variant = 'standard' | 'outlined' | 'filled';

interface IInputProps {
  id?: string;
  type?: string;
  variant?: Variant;
  classes?: string[];
  errorInfo?: IError;
  label: string;
  value: string;
  required?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  inputProps?: object;
  params?: any;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  autoComplete?: string;
  inputRef?: any;
}

const DEFAULT_VARIANT = 'filled';
const DEFAULT_TYPE = 'text';

export default function Input(props: IInputProps) {
  const {
    id,
    variant,
    label,
    value,
    handleChange,
    required,
    disabled,
    classes,
    type,
    multiline,
    inputProps,
    params,
    errorInfo,
    name,
    autoComplete,
    inputRef,
  } = props;
  const className = classes ? ` ${classes.join(' ')}` : '';
  const extraParams = params || {};
  const { error, message } = errorInfo || {};

  return (
    <TextField
      multiline={multiline}
      error={error || ''}
      className={`crs-input${className}`}
      id={id}
      type={type || DEFAULT_TYPE}
      required={!!required}
      disabled={!!disabled}
      label={label}
      onChange={handleChange}
      value={value}
      helperText={message || ''}
      variant={variant || DEFAULT_VARIANT}
      InputProps={inputProps || {}}
      name={name}
      autoComplete={autoComplete}
      inputRef={inputRef}
      {...extraParams}
    />
  );
}
