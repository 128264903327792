import React, { useEffect, useState } from 'react';
import { useDataProvider, useRedirect, Loading } from 'react-admin';
import {
  capitalizeFirstLetter,
  DEFAULT_VALIDATIONS_INFO,
  findItemByField,
  getBase64Image,
  hasPermission,
  isBase64String,
  isValid,
  removeSymbolAndCapitalize,
  replaceInArray,
  validate,
} from '../../utils';
import {
  RequestResource,
  PermissionLevel,
  IPermission,
  IRow,
  IField,
  ColumnType,
  CategoryColumn,
  CategoryService,
  CategoryServiceColumn,
  CategoryField,
  IErrors,
  ValidationType,
  BundleField,
  IInputField,
} from '../../types';
import {
  Button,
  Card,
  CardActions,
  Divider,
  FormControl,
  TextField,
  Typography,
  IconButton,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../core/dialog/Dialog';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '../core/table/Table';
import Input from '../core/input/Input';
import { AppConfig } from '../../config';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';

export interface IEditCategoryProps {
  permissions: IPermission;
  id?: string;
}

interface ICategoriesProps {
  handleRowChange: (row: IRow, column: string, value: IField) => void;
  rows: IRow[];
}

interface IChildCategoriesProps {
  handleRowChange: (row: IRow, column: string, value: IField) => void;
  rows: IRow[];
}

const IS_OPEN: string = 'isOpen';
const DISABLED: string = 'disabled';
const ALWAYS_DISABLED: string = 'alwaysDisabled';
const RADIO_COLUMN: string = 'selected';
const IS_EDIT_MODE: string = 'isEditMode';
const COLLAPSE: string = 'collapse';
const ACTIONS: string = 'actions';

const COLUMNS = [
  {
    field: COLLAPSE,
    headerName: '',
    editable: false,
    type: ColumnType.COLLAPSE,
    width: '5%',
  },
  {
    field: CategoryColumn.ID,
    headerName: removeSymbolAndCapitalize(CategoryColumn.ID),
    editable: false,
    type: ColumnType.STRING,
    width: '5%',
  },
  {
    field: CategoryColumn.NAME,
    headerName: removeSymbolAndCapitalize(CategoryColumn.NAME),
    editable: false,
    type: ColumnType.STRING,
    width: '25%',
  },
  {
    field: CategoryColumn.DESCRIPTION,
    headerName: removeSymbolAndCapitalize(CategoryColumn.DESCRIPTION),
    editable: false,
    type: ColumnType.STRING,
    width: '60%',
  },
  {
    field: CategoryColumn.IS_PUBLIC,
    headerName: removeSymbolAndCapitalize(CategoryColumn.IS_PUBLIC),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: CategoryColumn.CREATED_BY,
    headerName: removeSymbolAndCapitalize(CategoryColumn.CREATED_BY),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: IRow) =>
      (row?.createdBy as any)?.id ? (
        <Link to={`/${RequestResource.USER}/${(row?.createdBy as any)?.id}`}>
          {(row?.createdBy as any)?.name || ''}
        </Link>
      ) : (
        (row?.createdBy as any)?.name || ''
      ),
  },
  {
    field: RADIO_COLUMN,
    headerName: '',
    editable: true,
    type: ColumnType.CHECKBOX,
    width: '5%',
  },
];

const CHILD_CATEGORIES_COLUMNS = [
  {
    field: COLLAPSE,
    headerName: '',
    editable: false,
    type: ColumnType.COLLAPSE,
    width: '5%',
  },
  {
    field: CategoryServiceColumn.ID,
    headerName: removeSymbolAndCapitalize(CategoryServiceColumn.ID),
    editable: false,
    type: ColumnType.STRING,
    width: '5%',
  },
  {
    field: CategoryServiceColumn.NAME,
    headerName: removeSymbolAndCapitalize(CategoryServiceColumn.NAME),
    editable: false,
    type: ColumnType.STRING,
    width: '25%',
  },
  {
    field: CategoryServiceColumn.DESCRIPTION,
    headerName: removeSymbolAndCapitalize(CategoryServiceColumn.DESCRIPTION),
    editable: false,
    type: ColumnType.STRING,
    width: '50%',
  },
  {
    field: CategoryServiceColumn.TYPE,
    headerName: removeSymbolAndCapitalize(CategoryServiceColumn.TYPE),
    editable: false,
    type: ColumnType.STRING,
    width: '15%',
  },
  {
    field: CategoryColumn.IS_PUBLIC,
    headerName: removeSymbolAndCapitalize(CategoryColumn.IS_PUBLIC),
    editable: false,
    type: ColumnType.STRING,
  },
  {
    field: CategoryColumn.CREATED_BY,
    headerName: removeSymbolAndCapitalize(CategoryColumn.CREATED_BY),
    editable: false,
    type: ColumnType.STRING,
    cellRenderer: (row: IRow) =>
      (row?.createdBy as any)?.id ? (
        <Link to={`/${RequestResource.USER}/${(row?.createdBy as any)?.id}`}>
          {(row?.createdBy as any)?.name || ''}
        </Link>
      ) : (
        (row?.createdBy as any)?.name || ''
      ),
  },
];

const Categories = (props: ICategoriesProps) => {
  const { rows, handleRowChange } = props;
  return <Table handleChange={handleRowChange} rows={rows} columns={COLUMNS} />;
};

const ChildCategories = (props: IChildCategoriesProps) => {
  const { rows, handleRowChange } = props;
  return <Table rows={rows} columns={CHILD_CATEGORIES_COLUMNS} handleChange={handleRowChange} />;
};

export default function EditCategory(props: IEditCategoryProps) {
  const { id, permissions } = props;
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [showAddCategoryDialog, setShowAddCategoryDialog] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<any>({ id: null, name: '' });
  const [rows, setRows] = useState<IRow[]>([]);
  const [childCategories, setChildCategories] = useState<IRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<IErrors>({
    [CategoryField.NAME]: { ...DEFAULT_VALIDATIONS_INFO },
    [CategoryField.DESCRIPTION]: { ...DEFAULT_VALIDATIONS_INFO },
  });
  const [values, setValues] = useState<IInputField>({
    [CategoryField.NAME]: '',
    [CategoryField.DESCRIPTION]: '',
    [CategoryField.ICON]: '',
  });

  useEffect(() => {
    if (!id) {
      return;
    }
    setLoading(true);
    dataProvider
      .getOne(RequestResource.CATEGORY, { id })
      .then(({ data }) => {
        const singleCategoryData = data as any;
        setValues({
          [CategoryField.NAME]: singleCategoryData[CategoryField.NAME],
          [CategoryField.DESCRIPTION]: singleCategoryData[CategoryField.DESCRIPTION],
          [CategoryField.ICON]: singleCategoryData[CategoryField.ICON],
          [CategoryField.IS_PUBLIC]: singleCategoryData[CategoryField.IS_PUBLIC],
        });
        const { services, categories } = singleCategoryData;
        let childCategoriesData: any = [];
        if (services) {
          childCategoriesData = [...childCategoriesData, ...prepareSingleServicesData(services)];
        }
        if (categories) {
          childCategoriesData = [...childCategoriesData, ...prepareSingleCategoriesData(categories)];
        }
        setChildCategories(childCategoriesData);
        dataProvider
          .getList(RequestResource.CATEGORY, { filter: { query: `with_tree=true` }, customQuery: true } as any)
          .then(({ data }) => {
            setLoading(false);
            const categoryData = data as any;
            const { id, categoryId } = singleCategoryData;
            const preparedRows = prepareCategoriesData(categoryData, categoryId, id, false);

            if (categoryId) {
              const currentParentCategory = findItemByField(preparedRows, {
                key: 'id',
                value: categoryId,
                arrayFieldKey: 'rows',
              });
              if (currentParentCategory) {
                setSelectedCategory({
                  id: currentParentCategory.id,
                  name: currentParentCategory.name,
                });
              }
            }
            setRows(preparedRows);
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const prepareSingleCategoriesData = (categories: IRow[]): IRow[] => {
    if (!categories || !categories.length) {
      return [];
    }
    return categories.map(({ categories, services, ...rest }: IRow) => {
      return {
        ...rest,
        type: capitalizeFirstLetter(CategoryService.CATEGORY),
        rows: [...prepareSingleCategoriesData(categories as IRow[]), ...prepareSingleServicesData(services as IRow[])],
        [IS_OPEN]: true,
      };
    });
  };

  const prepareSingleServicesData = (services: IRow[]): IRow[] => {
    if (!services || !services.length) {
      return [];
    }
    return services.map((service: IRow) => ({
      ...service,
      type: capitalizeFirstLetter(CategoryService.SERVICE),
      className: 'services',
      rows: [],
    }));
  };

  const handleChildCategoriesRowChange = (row: IRow, column: string, value: IField) => {
    const newChildCategoriesData: IRow[] = replaceInArray(
      childCategories,
      { key: 'id', value: row.id, arrayFieldKey: 'rows' },
      { [column]: value }
    );
    setChildCategories(newChildCategoriesData);
  };

  const prepareCategoriesData = (
    categories: IRow[],
    categoryId: number,
    id: number,
    disabled: boolean = false
  ): IRow[] => {
    if (!categories || !categories.length) {
      return [];
    }
    return categories.map(({ categories, ...rest }: IRow) => {
      const selected = categoryId ? categoryId === rest.id : id === rest.id;
      const isDisabled = id === rest.id;
      const isSubDisabled = !!(isDisabled || disabled || rest.disabled) as boolean;
      return {
        ...rest,
        rows: prepareCategoriesData(categories as IRow[], categoryId, id, isSubDisabled),
        [IS_OPEN]: true,
        [RADIO_COLUMN]: selected,
        [DISABLED]: Boolean(disabled || isDisabled),
        [ALWAYS_DISABLED]: Boolean(disabled || isDisabled),
        [IS_EDIT_MODE]: true,
      };
    });
  };

  const toggleAddCategoryDialog = (toggle: boolean) => setShowAddCategoryDialog(toggle);
  if (loading) return <Loading />;
  const { name, description, isPublic } = values;

  const handleChange = (value: string | number | boolean, name: string) => {
    const newErrors = {
      ...errors,
      [name]: validate('' + value, [ValidationType.REQUIRED, ValidationType.MIN_LENGTH], name, { minLength: 3 }),
    };
    setValues({ ...values, [name]: value });
    setErrors(newErrors);
  };

  const handleSave = () => {
    if (!id || !isValid(errors)) {
      return;
    }
    const { icon, ...rest } = values;
    const changedValues = isBase64String(values[CategoryField.ICON]) ? { ...values } : rest;
    setLoading(true);
    dataProvider
      .update(RequestResource.CATEGORY, {
        id: +id,
        data: {
          ...changedValues,
          categoryId: selectedCategory.id,
        },
      } as any)
      .then(() => {
        setLoading(false);
        redirect(`/${RequestResource.CATEGORY}`);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleServiceDelete = () => {
    if (!id) {
      return;
    }
    setLoading(true);
    dataProvider
      .delete(RequestResource.CATEGORY, {
        id: +id,
      } as any)
      .then(() => {
        setLoading(false);
        redirect(`/${RequestResource.CATEGORY}`);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleRowChange = (row: IRow, column: string, value: IField) => {
    const isRadioColumn: boolean = column === RADIO_COLUMN;
    const notFoundReplacementData = isRadioColumn ? { [column]: false } : {};
    let newRows: IRow[] = replaceInArray(
      rows,
      { key: 'id', value: row.id, arrayFieldKey: 'rows' },
      { [column]: value },
      notFoundReplacementData
    );

    const updateDisabled = (categories: IRow[], disabled: boolean = false): IRow[] => {
      if (!categories || !categories.length) {
        return [];
      }
      return categories.map((currentRow: IRow) => {
        const isSubDisabled = !!(disabled || currentRow.disabled) as boolean;
        return {
          ...currentRow,
          rows: updateDisabled(currentRow.rows as IRow[], isSubDisabled),
          [DISABLED]: currentRow[ALWAYS_DISABLED] || disabled,
        };
      });
    };
    newRows = updateDisabled(newRows);
    if (isRadioColumn) {
      if (value) {
        setSelectedCategory({
          id: row.id,
          name: row.name,
        });
      } else {
        setSelectedCategory({ id: null, name: '' });
      }
    }
    setRows(newRows);
  };

  const handleCancelClick = () => {
    redirect(`/${RequestResource.CATEGORY}`);
  };

  const handleRemove = () => {
    const updateRows = (categories: IRow[]): IRow[] => {
      if (!categories || !categories.length) {
        return [];
      }
      return categories.map((currentRow: IRow) => {
        return {
          ...currentRow,
          rows: updateRows(currentRow.rows as IRow[]),
          [IS_OPEN]: false,
          [DISABLED]: false,
          [RADIO_COLUMN]: false,
        };
      });
    };
    const newRows = updateRows(rows);
    setRows(newRows);
    setSelectedCategory({ id: null, name: '' });
  };

  const handleUploadIcon = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.files?.[0];
    let encoded;
    getBase64Image(value).then((result: string) => {
      encoded = result;
      setValues({ ...values, [BundleField.ICON]: encoded });
    });
  };

  return (
    <div className="edit-category">
      <FormControl className="edit-category-form">
        <div className="category-header">
          <Typography variant="h5">Category edit</Typography>
          <div className="icon-wrapper">
            <input type="file" onChange={handleUploadIcon} id="iconUploader" />
            <IconButton>
              <label className="icon-upload-label" htmlFor="iconUploader">
                {values[CategoryField.ICON] ? (
                  <img
                    className="category-icon"
                    src={
                      isBase64String(values[CategoryField.ICON])
                        ? values[CategoryField.ICON]
                        : `${AppConfig.httpAPIGatewayURL}/${values[CategoryField.ICON]}`
                    }
                    alt="Preview"
                  />
                ) : (
                  <AddPhotoAlternateOutlinedIcon className="default-icon" />
                )}
              </label>
            </IconButton>
          </div>
        </div>
        <Divider className="divider" />
        <div className="body">
          <Input
            required={true}
            label="Name"
            value={name}
            errorInfo={errors[CategoryField.NAME]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, CategoryField.NAME)}
          />
          <Input
            multiline={true}
            required={true}
            label="Description"
            value={description}
            errorInfo={errors[CategoryField.DESCRIPTION]}
            classes={['text-field']}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.value, CategoryField.DESCRIPTION)
            }
          />
          <FormControlLabel
            className="is-public-switcher"
            control={
              <Switch
                checked={!!isPublic}
                onChange={(e) => handleChange(e.target.checked, CategoryField.IS_PUBLIC)}
                name="Is Public"
                color="primary"
              />
            }
            label="Is Public"
          />
          <div className="parent-category">
            {selectedCategory.id ? (
              <Link to={`/${RequestResource.CATEGORY}/${selectedCategory.id}`}>{selectedCategory.name || ''}</Link>
            ) : (
              ''
            )}
            <div className="attached-category-actions">
              {selectedCategory.id ? (
                <React.Fragment>
                  <IconButton aria-label="edit" color="primary" onClick={() => toggleAddCategoryDialog(true)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton aria-label="delete" color="primary" onClick={handleRemove}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              ) : (
                <Button aria-label="add" color="primary" onClick={() => toggleAddCategoryDialog(true)}>
                  add category
                  <AddIcon fontSize="small" />
                </Button>
              )}
            </div>
          </div>
          <div className="child-categories">
            <ChildCategories rows={childCategories} handleRowChange={handleChildCategoriesRowChange} />
          </div>
        </div>
        <Card className="card">
          <CardActions className="card-actions">
            <div>
              {hasPermission(permissions, RequestResource.CATEGORY, PermissionLevel.UPDATE) ? (
                <Button variant="contained" color="primary" onClick={handleSave} startIcon={<SaveIcon />}>
                  Save
                </Button>
              ) : null}
              <Button variant="outlined" color="primary" onClick={handleCancelClick} className="cancel-button">
                Cancel
              </Button>
            </div>
            {hasPermission(permissions, RequestResource.CATEGORY, PermissionLevel.DELETE) ? (
              <Button
                className="delete"
                variant="contained"
                color="secondary"
                onClick={handleServiceDelete}
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            ) : null}
          </CardActions>
        </Card>
      </FormControl>
      {showAddCategoryDialog && (
        <Dialog onClose={() => toggleAddCategoryDialog(false)}>
          <DialogTitle title="Add Category" onClose={() => toggleAddCategoryDialog(false)} />
          <DialogContent>
            <Categories rows={rows} handleRowChange={handleRowChange} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => toggleAddCategoryDialog(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
