export enum BranchField {
  LATITUDE = 'latitude',
  LONGITUDE = 'longitude',
  NAME = 'name',
}

export interface IBranch {
  latitude: string;
  longitude: string;
  name: string;
}
